import OrderModalBase from 'components/OrderModalBase'
import { RowBetween } from 'components/Row'
import { useCallback } from 'react'
import { CloseIcon, TYPE } from 'theme'

import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { ContentWrapper, PaddedColumn, SubmitFooter } from './styled'

export default function MaxFollowingModal() {
  const isOpen = useModalOpen(ApplicationModal.MAX_FOLLOWING)
  const toggleModal = useToggleModal(ApplicationModal.MAX_FOLLOWING)

  const handleDismiss = useCallback(() => {
    toggleModal()
  }, [toggleModal])

  const handleSubmit = useCallback(() => {
    toggleModal()
  }, [toggleModal])

  return (
    <OrderModalBase isOpen={isOpen} onDismiss={handleDismiss} minHeight={false} maxHeight={700}>
      <ContentWrapper>
        <PaddedColumn gap="14px">
          <RowBetween>
            <TYPE.heavy fontWeight={500} fontSize={22}>
              At Max Followers
            </TYPE.heavy>
            <CloseIcon onClick={handleDismiss} />
          </RowBetween>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          Carmel is currently in beta. You can only follow up to 10 wallets for now. {`(Update coming ASAP)`}
        </PaddedColumn>
        <SubmitFooter
          disabled={false}
          onClick={() => {
            handleSubmit()
          }}
        >
          <TYPE.error error={false}>{'Dismiss'}</TYPE.error>
        </SubmitFooter>
      </ContentWrapper>
    </OrderModalBase>
  )
}
