import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
// import { Sale } from 'api/types'
import { SaleData } from 'validators/accounts/sales'

export const updateFirebaseSaleRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ saleAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; saleData: SaleData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; saleAddress: string; requestID: string }>
}> = {
  pending: createAction('sales/createSaleRequest/pending'),
  fulfilled: createAction('sales/createSaleRequest/fulfilled'),
  rejected: createAction('sales/createSaleRequest/rejected'),
}
export const fetchFirebaseSaleRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ saleAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; saleData: SaleData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; saleAddress: string }>
}> = {
  pending: createAction('sales/fetchFirebaseSaleRequest/pending'),
  fulfilled: createAction('sales/fetchFirebaseSaleRequest/fulfilled'),
  rejected: createAction('sales/fetchFirebaseSaleRequest/rejected'),
}
export const fetchChainSaleRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ saleAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; saleData: SaleData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('sales/fetchChainSaleRequest/pending'),
  fulfilled: createAction('sales/fetchChainSaleRequest/fulfilled'),
  rejected: createAction('sales/fetchChainSaleRequest/rejected'),
}

export const addRequiredSales = createAction<string[]>('sales/addRequiredSales')
export const refreshSale = createAction<string>('sales/refreshSale')
// export const  = createAction<string>('lists/disableList')
