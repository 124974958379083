import { useAnchorWallet } from '@solana/wallet-adapter-react'
import CollectionRow from 'components/CollectionRow'
import Column, { AutoColumn } from 'components/Column'
import MintArtDisplay from 'components/MintArtDisplay'
import Row, { AutoRow, RowBetween } from 'components/Row'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { addRequiredCollections } from 'state/collections/actions'
import { useSafeCollections } from 'state/collections/hooks'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

const MintCardContainer = styled.div`
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.7rem;

  // height: 100%;
  align-items: center;
  text-align: left;
  outline: none;
  // cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  background-color: ${({ theme }) => transparentize(0.0, theme.bg1)};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.1, theme.bg1)};
  }
  // &:hover {
  //   background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  // }

  overflow: visible;
  // display: flex;
`

const HorizontalGrid = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 300px 1fr;
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-items: start;
  // align-items: center;
`

const DetailsSection = styled(AutoColumn)`
  padding: 15px 17px 0px 17px;
  width: 100%;
  height: 100%;
`

const MintDisplayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  // overflow: auto;
  // width: 100%;
  // height: 100%;
`

export default function MintCard({ mintAddress }: { mintAddress?: string }) {
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false)
  const [mintCardIsHovered, setMintCardIsHovered] = React.useState(false)
  const { tokenRegistry } = useTokenRegistry()

  const wallet = useAnchorWallet()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userPubkey = wallet?.publicKey

  const mint = useSafeMint(mintAddress)

  useEffect(() => {
    if (mintAddress) {
      dispatch(addRequiredMints([mintAddress]))
    }
  }, [dispatch, mintAddress])

  const mintCollections = useSafeCollections(mint?.collections ?? [])
  useEffect(() => {
    if (mint?.collections && mint?.collections?.length > 0) {
      dispatch(addRequiredCollections(mint?.collections))
    }
  }, [mint?.collections, dispatch])

  console.log('MintCard mintCollections:', mintCollections)

  const topCollection = useMemo(() => {
    if (mintCollections.length > 0) {
      return mintCollections[0]
    } else {
      return undefined
    }
  }, [mintCollections])
  console.log('MintCard topCollection:', topCollection)

  return (
    <MintCardContainer
      // to={buttonIsHovered ? history.location : `/m/${mint?.address}`}
      key={`${mintAddress}`}
      onMouseEnter={() => {
        setMintCardIsHovered(true)
      }}
      onMouseLeave={() => {
        if (!buttonIsHovered) {
          setMintCardIsHovered(false)
        }
      }}
    >
      {/* {action?.type} */}
      <Column>
        <MintDisplayContainer>
          <div style={{ minHeight: '300px' }}>
            <MintArtDisplay key={mintAddress} setIsHovered={setButtonIsHovered} mintAddress={mintAddress} clickable />
          </div>
        </MintDisplayContainer>
        <AutoRow>
          <RowBetween>
            <Row>
              {/* <UserRow userAddress={action?.wallet} size="22px" /> */}
              <Row style={{ marginLeft: '10px' }}>
                <TYPE.heavy>{mint?.metadata?.data.name}</TYPE.heavy>
                {topCollection ? (
                  <>
                    <TYPE.heavy fontSize={'14px'} color={theme.text2}>
                      {`·`}
                    </TYPE.heavy>
                    <div style={{ marginLeft: '10px' }}>
                      <CollectionRow size="18px" collectionID={topCollection.id} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Row>
            </Row>
            {/* <TYPE.heavy>{timeSince}</TYPE.heavy> */}
          </RowBetween>
        </AutoRow>
      </Column>

      {/* <Confetti start={buttonIsHovered} /> */}
    </MintCardContainer>
  )
}
