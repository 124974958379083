import { nanoid } from '@reduxjs/toolkit'
import { doc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import getLatestSlot from 'hooks/useLatestSlot'
import useLatestSlot from 'hooks/useLatestSlot'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'

import {
  appendWallets,
  createFirebaseWalletRequest,
  fetchChainWalletRequest,
  fetchFirebaseWalletRequest,
  syncWalletToSlot,
  updateFirebaseWalletRequest,
} from './actions'
import { useFirebaseWallet } from './hooks'
import { Wallet } from './models'

export const WALLETS_COLLECTION = 'wallets4'

export function useFetchFirebaseWallet(): (walletAddress: string) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (walletAddress: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseWalletRequest.pending({ walletAddress, requestID }))
      try {
        const db = getFirestore()
        const walletSnap = await getDoc(doc(db, WALLETS_COLLECTION, walletAddress))
        console.log('firebase collections: ', walletSnap)

        if (walletSnap.exists()) {
          // console.log('firebase Document data:', walletSnap.data())
          const wallet = decodeFirebaseWallet(walletSnap.data())
          // console.log('firebase wallet decoded: ', wallet)
          // if (wallet) dispatch(fetchFirebaseWalletRequest.fulfilled({ requestID, wallet }))
        } else {
          console.log('firebase Wallet not found. Creating.')
          const wallet = await createFirebaseWallet(walletAddress)

          // dispatch(fetchFirebaseWalletRequest.fulfilled({ requestID, wallet }))
        }
        const unsubscribe = onSnapshot(doc(db, WALLETS_COLLECTION, walletAddress), (walletSnapshot) => {
          // const querySnapshot = await getDocs(q)
          // const wallet = querySnapshot.docs.map((doc) => {
          console.log('firebase wallet callback walletSnapshot:', walletSnapshot)
          console.log('firebase wallet callback data:', walletSnapshot.data())

          if (walletSnapshot.data()) {
            console.log('firebase wallet callback data:', walletSnapshot.data())
            const wallet = decodeFirebaseWallet(walletSnapshot.data())
            dispatch(fetchFirebaseWalletRequest.fulfilled({ requestID, wallet }))
            // unsubscribe()
          }
          // })
          // const last = querySnapshot.docs[querySnapshot.docs.length - 1]
          // const lastDecoded = decodeFirebaseAction(last.data())
          // console.log('firebase lastDecoded: ', lastDecoded.id)
          // console.log(`firebase query actions (${roq.key}): `, actions)
        })
        // return wallet
      } catch (e) {
        console.error(e)
        dispatch(fetchChainWalletRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

export function useEditWalletFollowing(): (
  wallet: Wallet,
  followeeWalletAddress: string,
  isFollowing: boolean
) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (wallet: Wallet, followeeWalletAddress: string, isFollowing: boolean) => {
      const requestID = nanoid()
      dispatch(updateFirebaseWalletRequest.pending({ walletAddress: wallet.pubkey, requestID }))
      const filteredFollowing = wallet.following?.filter((w) => w !== followeeWalletAddress) ?? []
      try {
        const newWallet: Wallet = {
          ...wallet,
          following: isFollowing ? [...filteredFollowing, followeeWalletAddress] : filteredFollowing,
        }
        console.log('useEditWalletFollowing isFollowing: ', isFollowing)
        console.log('useEditWalletFollowing newWallet: ', newWallet)
        const db = getFirestore()
        // const userObj = encodeUserObject({ ...newUser })
        await setDoc(doc(db, WALLETS_COLLECTION, wallet.pubkey), newWallet, { merge: true }).then(() => {
          console.log('firebase user updated (useEditWalletFollowing)')
          dispatch(updateFirebaseWalletRequest.fulfilled({ requestID, wallet: newWallet }))
          // if (isSaved) {
          //   dispatch(incrementMintSavedByLocal({ mintAddress, userAddress: user.address }))
          // } else {
          //   dispatch(decrementMintSavedByLocal({ mintAddress, userAddress: user.address }))
          // }
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseWalletRequest.rejected({ walletAddress: wallet.pubkey, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}

async function createFirebaseWallet(walletAddress: string) {
  const db = getFirestore()
  const slot = await getLatestSlot()
  const wallet: any = {
    pubkey: walletAddress,
    syncToSlot: slot,
    syncedToSlot: 0,
    // ownedTokens: [],
    // dotSolNames: [],
    // followedBy: [],
    // following: [],
  }
  return setDoc(doc(db, WALLETS_COLLECTION, walletAddress), wallet, { merge: true })
    .then(() => {
      return wallet
    })
    .catch((e) => {
      throw e
    })
}

export const decodeFirebaseWallet = (res: any) => {
  console.log('decodeFirebaseWallet: ', res)
  return create(res, Wallet)
}

export function useSyncWalletToSlot(): (walletAddress: string, syncToSlot: number) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (walletAddress: string, syncToSlot: number) => {
      const requestID = nanoid()
      dispatch(syncWalletToSlot.pending({ walletAddress, requestID }))

      try {
        const wallet = {
          pubkey: walletAddress,
          syncToSlot,
          // profileMint: oldFirebaseWallet?.profileMint ?? '',
        }
        const db = getFirestore()
        // const walletObj = encodeWalletObject({ ...wallet })
        console.log('firebase dbagg uploading wallet (syncWalletToBlock): ', wallet)
        // const unsubscribe = onSnapshot(doc(db, WALLETS_COLLECTION, walletAddress), (walletSnap) => {
        //   console.log('firebase wallet data:', walletSnap.data())
        //   if (walletSnap.data()) {
        //     const wallet = decodeFirebaseWallet(walletSnap.data())
        //     console.log('firebase dbagg wallet decoded (useFetchFirebaseWallet): ', wallet)
        //     dispatch(appendWallets([wallet]))
        //   }
        //   // unsubscribe()
        // })
        await updateDoc(doc(db, WALLETS_COLLECTION, walletAddress), {
          syncToSlot,
        }).then(() => {
          // setDoc(doc(db, WALLETS_COLLECTION, walletAddress), wallet, { merge: true }).then(() => {
          console.log('firebase dbagg wallet uploaded (syncWalletToBlock)')

          dispatch(syncWalletToSlot.fulfilled({ requestID, walletAddress, syncToSlot }))
        })
      } catch (e) {
        console.error(e)
        dispatch(syncWalletToSlot.rejected({ walletAddress, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}
