import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import {
  addRequiredWallets,
  appendWallets,
  editSavedMint,
  fetchChainWalletRequest,
  fetchFirebaseWalletRequest,
  refreshWallets,
  syncWalletToSlot,
} from './actions'
import { updateFirebaseWalletRequest } from './actions'
import { Wallet } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface WalletsState {
  readonly firebaseWallets: Wallet[]
  readonly firebaseRequests: Request[]
  readonly requiredWallets: string[]
}

const initialState: WalletsState = {
  firebaseWallets: [],
  firebaseRequests: [],
  requiredWallets: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshWallets, (state, { payload: walletAddresses }) => {
      state = {
        ...state,
        requiredWallets: [
          ...state.requiredWallets.filter((wallet) => !walletAddresses.includes(wallet)),
          ...walletAddresses,
        ],
      }
      return state
    })
    .addCase(appendWallets, (state, { payload: wallets }) => {
      const oldWallets = state.firebaseWallets
      const newWallets = wallets.filter((wallet) => !oldWallets.some((oldWallet) => oldWallet.pubkey === wallet.pubkey))
      state = {
        ...state,
        firebaseWallets: [...oldWallets, ...newWallets],
      }
      return state
    })
    .addCase(addRequiredWallets, (state, { payload: wallets }) => {
      console.log('addRequiredWallets', wallets)
      console.log('state', state)
      const set = [...state.requiredWallets, ...wallets]
      const filtered = set.filter((item, index) => set.indexOf(item) === index)
      console.log('addRequiredWallets set', filtered)
      state = {
        ...state,
        requiredWallets: filtered,
      }
      return state
    })
    .addCase(syncWalletToSlot.pending, (state, { payload: { requestID, walletAddress } }) => {
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          status: RequestStatus.Pending,
          type: 'update',
          context: walletAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(syncWalletToSlot.fulfilled, (state, { payload: { requestID, walletAddress, syncToSlot } }) => {
      const oldWallet = state.firebaseWallets.find((u) => u.pubkey == walletAddress)
      if (oldWallet) {
        const wallet = {
          ...oldWallet,
          syncToSlot,
          // syncedToSlot: syncToSlot,
        }
        state = {
          ...state,
          firebaseRequests: [
            ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'update',
              context: walletAddress,
              status: RequestStatus.Fulfilled,
              timestamp: Date.now(),
            },
          ],
          firebaseWallets: [...state.firebaseWallets.filter((u) => u.pubkey !== walletAddress), wallet],
        }
      }
      return state
    })
    .addCase(syncWalletToSlot.rejected, (state, { payload: { requestID, errorMessage, walletAddress } }) => {
      console.log('aState query pending', { state, requestID, walletAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: walletAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseWalletRequest.pending, (state, { payload: { requestID, walletAddress } }) => {
      console.log('aState fetchWalletRequest pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: walletAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseWalletRequest.fulfilled, (state, { payload: { requestID, wallet } }) => {
      console.log('aState fetchFirebaseWalletRequest fulfilled', { state, requestID, wallet: wallet.pubkey })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: wallet.pubkey,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseWallets: [...state.firebaseWallets.filter((u) => u.pubkey !== wallet.pubkey), wallet],
      }
      return state
    })
    .addCase(fetchFirebaseWalletRequest.rejected, (state, { payload: { requestID, errorMessage, walletAddress } }) => {
      console.log('aState fetchWalletRequest rejected', { state, requestID, walletAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: walletAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(updateFirebaseWalletRequest.pending, (state, { payload: { requestID, walletAddress } }) => {
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          status: RequestStatus.Pending,
          type: 'update',
          context: walletAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(updateFirebaseWalletRequest.fulfilled, (state, { payload: { requestID, wallet } }) => {
      const oldWallet = state.firebaseWallets.find((w) => w.pubkey == wallet.pubkey)
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: wallet.pubkey,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseWallets: [...state.firebaseWallets.filter((w) => w.pubkey != wallet.pubkey), wallet],
      }
      return state
    })
    .addCase(updateFirebaseWalletRequest.rejected, (state, { payload: { requestID, errorMessage, walletAddress } }) => {
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: walletAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
