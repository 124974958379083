import Loader, { LocalLoader } from 'components/Loader'
import SolanaManager from 'components/SolanaManager'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/HeaderTwo'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import FeedPage from './FeedPage'

const Vote = lazy(() => import('./Vote'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;

  align-items: flex-start;
  height: 100%;
  // background-color: ${({ theme }) => theme.green1};
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  `};
  height: 100%;
  max-height: 100%;
  // background-color: ${({ theme }) => theme.red1};
  // scroll-behavior: smooth;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function AppTwo() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
  }, [])
  return (
    <ErrorBoundary>
      <SolanaManager>
        <>
          {/* <Route component={GoogleAnalyticsReporter} /> */}
          <Route component={DarkModeQueryParamReader} />
          <Route component={ApeModeQueryParamReader} />
          {/* <Web3ReactManager> */}
          <>
            {loading ? (
              <LocalLoader fill={true}></LocalLoader>
            ) : (
              <AppWrapper>
                <HeaderWrapper>
                  <Header />
                </HeaderWrapper>
                <BodyWrapper>
                  <Popups />
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route path="/:type?/:id?/:refinement?" component={FeedPage} />
                    </Switch>
                  </Suspense>
                  <Marginer />
                </BodyWrapper>
              </AppWrapper>
            )}
          </>
          {/* </Web3ReactManager> */}
        </>
      </SolanaManager>
    </ErrorBoundary>
  )
}
