import * as anchor from '@project-serum/anchor'
import { nanoid } from '@reduxjs/toolkit'
import { AccountLayout, ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import {
  AccountMeta,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js'
import { createWrappedSolAccount } from 'apollo/instructions'
import {
  ataTXIIfEmpty,
  generateMetadataPDA,
  getExtendedArt,
  pubkeyFromString,
  pubkeyFromStringForced,
} from 'apollo/utils'
import { useApolloProgram } from 'components/SolanaManager'
import { curatorAddress, curatorBasisPoints, providerURL } from 'hooks/web3'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { useCallback } from 'react'
import { updateFirebaseCollectionRequest } from 'state/callections/actions'
import { useAppDispatch } from 'state/hooks'
import { MintData } from 'state/mints/models'
import { refreshUsers } from 'state/users/actions'
import { assert, create, is } from 'superstruct'
import { mintIsNative } from 'utils/utils'
import { Metadata } from 'validators/accounts/sales'

import {
  addRequiredOrders,
  // createOrder,
  createOrderOnChainRequest,
  matchOrdersOnChainRequest,
  revokeOrderOnChainRequest,
  updateFirebaseOrderRequest,
} from './actions'
import { useUpdateFirebaseOrder } from './firebase'
import { Order } from './models'
import { calculateCreatorFees, calculateCuratorFees, getATAForMint } from './utils'

const url = providerURL()
const connection = new Connection(url)

const SELL_PREFIX = 'sell'
const BUY_PREFIX = 'buy'
const BUY_KEY = 0
const SELL_KEY = 1

export enum ORDER_SIDE {
  BUY = BUY_KEY,
  SELL = SELL_KEY,
}

// const craftSellOrder = async (
//   apollo: anchor.Program,
//   seller: string,
//   assetSource: string,
//   assetMint: string,
//   assetSize: number,
//   paymentMint: string,
//   paymentBaseSize: number,
//   listingDate?: number,
//   expirationDate?: string
//   // taker?: string,
// ) => {
//   const sellerPubkey = pubkeyFromStringForced(seller)
//   const assetMintPubkey = pubkeyFromStringForced(assetMint)
//   const paymentMintPubkey = pubkeyFromStringForced(paymentMint)
//   const assetSourcePubkey = pubkeyFromStringForced(assetSource)
//   // const sourceATA = (await getATAForMint(assetMintPubkey, sellerPubkey))[0]

//   return order
// }

const craftBuyOrder = async (
  apollo: anchor.Program,
  buyer: string,
  paymentSource: string,
  assetMint: string,
  assetSize: number,
  paymentMint: string,
  paymentBaseSize: number,
  listingDate?: number,
  expirationDate?: string
  // taker?: string,
) => {
  const buyerPubkey = pubkeyFromStringForced(buyer)
  const assetMintPubkey = pubkeyFromStringForced(assetMint)
  const paymentMintPubkey = pubkeyFromStringForced(paymentMint)
  const paymentSourcePubkey = pubkeyFromStringForced(paymentSource)
  const assetSizeBN = new anchor.BN(assetSize)
  const paymentBaseSizeBN = new anchor.BN(paymentBaseSize)
  const listingDateBN = listingDate ? new anchor.BN(listingDate) : new anchor.BN(0)
  const expirationDateBN = expirationDate ? new anchor.BN(expirationDate) : new anchor.BN(0)
  const curatorBasisPointsBN = new anchor.BN(curatorBasisPoints())
  const BUY_KEY_BN = new anchor.BN(BUY_KEY)
  const curatorPubkey = pubkeyFromStringForced(curatorAddress())
  const creatorsFeeSize = await calculateCreatorFees(assetMint, paymentBaseSize)
  const curatorsFeeSize = await calculateCuratorFees(curatorBasisPoints(), paymentBaseSize)
  const creatorsFeeSizeBN = new anchor.BN(creatorsFeeSize)
  const curatorsFeeSizeBN = new anchor.BN(curatorsFeeSize)
  const [delegatePDA, delegatePDABumpSeed] = await anchor.web3.PublicKey.findProgramAddress(
    [
      // Buffer.from(ORDER_PREFIX),
      buyerPubkey.toBuffer(), // maker
      SystemProgram.programId.toBuffer(), // taker
      paymentSourcePubkey.toBuffer(), // src
      assetMintPubkey.toBuffer(), // asset_mint
      assetSizeBN.toArrayLike(Buffer, 'le', 8), // asset_size
      paymentMintPubkey.toBuffer(), // payment_mint
      paymentBaseSizeBN.toArrayLike(Buffer, 'le', 8), // payment_base_size
      creatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // creators_fee_size!!
      curatorPubkey.toBuffer(), // curator
      curatorBasisPointsBN.toArrayLike(Buffer, 'le', 8), // curator_bps
      curatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // curator_fee_size!!
      listingDateBN.toArrayLike(Buffer, 'le', 8), // listing_date
      expirationDateBN.toArrayLike(Buffer, 'le', 8), // expiration_date
      BUY_KEY_BN.toArrayLike(Buffer, 'le', 8), // side
    ],
    apollo.programId
  )
  const order = {
    delegatePDA,
    delegatePDABumpSeed,
    maker: buyer,
    taker: '',
    source: paymentSource,
    assetMint,
    assetSize,
    paymentMint,
    paymentBaseSize,
    creatorsFeeSize,
    curator: curatorAddress(),
    curatorBPS: curatorBasisPoints(),
    curatorFeeSize: curatorsFeeSize,
    listingDate,
    expirationDate,
    side: BUY_KEY_BN,
    revoked: false,
  }
  return order
}

export function useCreateSellOrder(): (
  seller: string,
  assetMint: string,
  assetDecimals: number,
  assetSize: number,
  paymentMint: string,
  paymentBaseSize: number,
  listingDate?: number,
  expirationDate?: number
) => Promise<string> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const updateFirebaseOrder = useUpdateFirebaseOrder()
  return useCallback(
    async (
      seller: string,
      assetMint: string,
      assetDecimals: number,
      assetSize: number,
      paymentMint: string,
      paymentBaseSize: number,
      listingDate?: number,
      expirationDate?: number
    ) => {
      console.log('useCreateSellOrder params:', {
        seller,
        assetMint,
        assetDecimals,
        assetSize,
        paymentMint,
        paymentBaseSize,
        listingDate,
        expirationDate,
      })
      const requestID = nanoid()
      // dispatch(createOrder.pending({ requestID }))
      try {
        const sellerPubkey = pubkeyFromStringForced(seller)
        const assetMintPubkey = pubkeyFromStringForced(assetMint)
        const paymentMintPubkey = pubkeyFromStringForced(paymentMint)
        const allATAS = await getATAForMint(assetMintPubkey, sellerPubkey)
        console.log('useCreateSellOrder allATAS:', allATAS)
        const assetATA = (await getATAForMint(assetMintPubkey, sellerPubkey))[0]
        const newAssetAccount = anchor.web3.Keypair.generate()

        const accountRentExempt = await connection.getMinimumBalanceForRentExemption(AccountLayout.span)

        const createATAInst = await ataTXIIfEmpty(connection, assetMintPubkey, assetATA, sellerPubkey, sellerPubkey)

        // const createNewAssetAccountInst1 = SystemProgram.createAccount({
        //   fromPubkey: sellerPubkey,
        //   newAccountPubkey: newAssetAccount.publicKey,
        //   lamports: accountRentExempt,
        //   space: AccountLayout.span,
        //   programId: new PublicKey(TOKEN_PROGRAM_ID),
        // })
        // const createNewAssetAccountInst2 = Token.createInitAccountInstruction(
        //   TOKEN_PROGRAM_ID,
        //   assetMintPubkey,
        //   newAssetAccount.publicKey,
        //   sellerPubkey
        // )
        console.log('useCreateSellOrder assetATA (assetMintPubkey):', assetMintPubkey.toBase58())
        console.log('useCreateSellOrder assetATA:', assetATA.toBase58())
        // const transferToNewAccountInst = Token.createTransferCheckedInstruction(
        //   TOKEN_PROGRAM_ID,
        //   assetATA,
        //   assetMintPubkey,
        //   newAssetAccount.publicKey,
        //   sellerPubkey,
        //   [],
        //   assetSize,
        //   assetDecimals
        // )

        const assetSizeBN = new anchor.BN(assetSize)
        const paymentBaseSizeBN = new anchor.BN(paymentBaseSize)
        const listingDateBN = listingDate ? new anchor.BN(listingDate) : new anchor.BN(0)
        const expirationDateBN = expirationDate ? new anchor.BN(expirationDate) : new anchor.BN(0)
        const curatorBasisPointsBN = new anchor.BN(curatorBasisPoints())
        const SELL_KEY_BN = new anchor.BN(SELL_KEY)
        const curatorPubkey = pubkeyFromStringForced(curatorAddress())
        const creatorsFeeSize = await calculateCreatorFees(assetMint, paymentBaseSize)
        const curatorsFeeSize = await calculateCuratorFees(curatorBasisPoints(), paymentBaseSize)
        const creatorsFeeSizeBN = new anchor.BN(creatorsFeeSize)
        const curatorsFeeSizeBN = new anchor.BN(curatorsFeeSize)
        // console.log('useCreateSellOrder assetSize:', assetSize)
        // console.log('useCreateSellOrder assetSizeBN:', assetSizeBN)
        // console.log('useCreateSellOrder assetSizeBN buffer:', assetSizeBN.toBuffer())
        const [delegatePDA, delegatePDABumpSeed] = await anchor.web3.PublicKey.findProgramAddress(
          [
            Buffer.from(SELL_PREFIX),
            sellerPubkey.toBuffer(), // maker
            SystemProgram.programId.toBuffer(), // taker
            assetATA.toBuffer(), // src
            assetMintPubkey.toBuffer(), // asset_mint
            assetSizeBN.toArrayLike(Buffer, 'le', 8), // asset_size
            paymentMintPubkey.toBuffer(), // payment_mint
            paymentBaseSizeBN.toArrayLike(Buffer, 'le', 8), // payment_base_size
            creatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // creators_fee_size!!
            curatorPubkey.toBuffer(), // curator
            // curatorBasisPointsBN.toArrayLike(Buffer, 'le', 8), // curator_bps
            curatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // curator_fee_size!!
            listingDateBN.toArrayLike(Buffer, 'le', 8), // listing_date
            expirationDateBN.toArrayLike(Buffer, 'le', 8), // expiration_date
            // SELL_KEY_BN.toArrayLike(Buffer, 'le', 8), // side
          ],
          apollo.programId
        )

        const order: Order = {
          delegatePDA: delegatePDA.toBase58(),
          delegatePDABumpSeed,
          maker: seller,
          taker: SystemProgram.programId.toBase58(),
          source: assetATA.toBase58(),
          assetMint,
          assetSize,
          paymentMint,
          paymentBaseSize,
          creatorsFeeSize,
          curator: curatorAddress(),
          curatorBPS: curatorBasisPoints(),
          curatorFeeSize: curatorsFeeSize,
          listingDate: listingDate ?? 0,
          expirationDate: expirationDate ?? 0,
          side: SELL_KEY,
          revoked: false,
          revokedDate: 0,
          matched: false,
          matchedDate: 0,
          matchedWith: '',
          programID: apollo.programId.toBase58(),
        }

        const approveInst = Token.createApproveInstruction(
          TOKEN_PROGRAM_ID,
          assetATA,
          delegatePDA,
          sellerPubkey,
          [],
          assetSize
        )

        const tx = new anchor.web3.Transaction()
        if (createATAInst) {
          tx.add(createATAInst)
        }
        tx.add(...[approveInst])
        console.log(
          'useCreateSellOrder tx:',
          tx.instructions.map((txi) => txi.keys.map((k) => k.pubkey.toBase58()))
        )
        // also create source ATA?
        // const onChainRequestID = nanoid()
        dispatch(createOrderOnChainRequest.pending({ requestID, order }))
        try {
          const res = await apollo.provider.send(tx, [], {})
          if (res) {
            dispatch(createOrderOnChainRequest.fulfilled({ requestID, order, res }))
            // dispatch(updateFirebaseOrderRequest.pending({ requestID, order }))
            try {
              // upload to firebase
              updateFirebaseOrder(order, requestID)

              // dispatch(createOrder.fulfilled({ requestID, order }))
            } catch (e) {
              console.error(e)
              // dispatch(updateFirebaseOrderRequest.rejected({ requestID, errorMessage: e.message,  }))
              throw e
            }
          }
        } catch (e) {
          console.error(e)
          dispatch(createOrderOnChainRequest.rejected({ requestID, errorMessage: e.message }))
          throw e
        }

        return requestID
      } catch (e) {
        console.error(e)
        // dispatch(createOrder.rejected({ requestID, errorMessage: e }))
        // throw e
        return requestID
      }
    },
    [apollo.programId, apollo.provider, updateFirebaseOrder, dispatch]
  )
}

export function useCreateBuyOrder(): (
  buyer: string,
  assetMint: string,
  assetDecimals: number,
  assetSize: number,
  paymentMint: string,
  paymentBaseSize: number,
  listingDate?: number,
  expirationDate?: number,
  seller?: string
) => Promise<string> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const updateFirebaseOrder = useUpdateFirebaseOrder()
  return useCallback(
    async (
      buyer: string,
      assetMint: string,
      assetDecimals: number,
      assetSize: number,
      paymentMint: string,
      paymentBaseSize: number,
      listingDate?: number,
      expirationDate?: number,
      seller?: string
    ) => {
      console.log('useCreateBuyOrder params:', {
        buyer,
        assetMint,
        assetDecimals,
        assetSize,
        paymentMint,
        paymentBaseSize,
        listingDate,
        expirationDate,
        seller,
      })
      const requestID = nanoid()
      // dispatch(createOrder.pending({ requestID }))
      try {
        const sellerPubkey = seller ? pubkeyFromStringForced(seller) : SystemProgram.programId
        const buyerPubkey = pubkeyFromStringForced(buyer)
        const assetMintPubkey = pubkeyFromStringForced(assetMint)
        const paymentMintPubkey = pubkeyFromStringForced(paymentMint)
        // const allATAS = await getATAForMint(assetMintPubkey, sellerPubkey)
        // console.log('useCreateSellOrder allATAS:', allATAS)
        // const paymentATA = (await getATAForMint(paymentMintPubkey, buyerPubkey))[0]
        const creatorsFeeSize = await calculateCreatorFees(assetMint, paymentBaseSize)
        const curatorsFeeSize = await calculateCuratorFees(curatorBasisPoints(), paymentBaseSize)
        const isWrapped = mintIsNative(paymentMintPubkey.toString())
        const newAccount = Keypair.generate()
        const signers = []
        const wrappedTXIs: TransactionInstruction[] = []
        const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(connection)
        if (isWrapped) {
          wrappedTXIs.push(
            ...[
              SystemProgram.createAccount({
                fromPubkey: buyerPubkey,
                newAccountPubkey: newAccount.publicKey,
                lamports: balanceNeeded,
                space: AccountLayout.span,
                programId: TOKEN_PROGRAM_ID,
              }),
              SystemProgram.transfer({
                fromPubkey: buyerPubkey,
                toPubkey: newAccount.publicKey,
                lamports: paymentBaseSize + creatorsFeeSize + curatorsFeeSize,
              }),
              Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, NATIVE_MINT, newAccount.publicKey, buyerPubkey),
            ]
          )
          signers.push(newAccount)
        }

        const paymentATA = isWrapped ? newAccount.publicKey : (await getATAForMint(paymentMintPubkey, buyerPubkey))[0]
        const createATAInst = await ataTXIIfEmpty(connection, paymentMintPubkey, paymentATA, buyerPubkey, buyerPubkey)

        const assetSizeBN = new anchor.BN(assetSize)
        const paymentBaseSizeBN = new anchor.BN(paymentBaseSize)
        const listingDateBN = listingDate ? new anchor.BN(listingDate) : new anchor.BN(0)
        const expirationDateBN = expirationDate ? new anchor.BN(expirationDate) : new anchor.BN(0)
        const curatorBasisPointsBN = new anchor.BN(curatorBasisPoints())
        const curatorPubkey = pubkeyFromStringForced(curatorAddress())

        const creatorsFeeSizeBN = new anchor.BN(creatorsFeeSize)
        const curatorsFeeSizeBN = new anchor.BN(curatorsFeeSize)
        // console.log('useCreateSellOrder assetSize:', assetSize)
        // console.log('useCreateSellOrder assetSizeBN:', assetSizeBN)
        // console.log('useCreateSellOrder assetSizeBN buffer:', assetSizeBN.toBuffer())
        const [delegatePDA, delegatePDABumpSeed] = await anchor.web3.PublicKey.findProgramAddress(
          [
            Buffer.from(BUY_PREFIX),
            buyerPubkey.toBuffer(), // maker
            SystemProgram.programId.toBuffer(), // taker
            paymentATA.toBuffer(), // src
            assetMintPubkey.toBuffer(), // asset_mint
            assetSizeBN.toArrayLike(Buffer, 'le', 8), // asset_size
            paymentMintPubkey.toBuffer(), // payment_mint
            paymentBaseSizeBN.toArrayLike(Buffer, 'le', 8), // payment_base_size
            creatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // creators_fee_size!!
            curatorPubkey.toBuffer(), // curator
            curatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // curator_fee_size!!
            listingDateBN.toArrayLike(Buffer, 'le', 8), // listing_date
            expirationDateBN.toArrayLike(Buffer, 'le', 8), // expiration_date
          ],
          apollo.programId
        )
        console.log('useCreateBuyOrder delegatePDA:', delegatePDA)

        const order: Order = {
          delegatePDA: delegatePDA.toBase58(),
          delegatePDABumpSeed,
          maker: buyer,
          taker: sellerPubkey.toBase58(),
          source: paymentATA.toBase58(),
          assetMint,
          assetSize,
          paymentMint,
          paymentBaseSize,
          creatorsFeeSize,
          curator: curatorAddress(),
          curatorBPS: curatorBasisPoints(),
          curatorFeeSize: curatorsFeeSize,
          listingDate: listingDate ?? 0,
          expirationDate: expirationDate ?? 0,
          side: BUY_KEY,
          revoked: false,
          revokedDate: 0,
          matched: false,
          matchedDate: 0,
          matchedWith: '',
          programID: apollo.programId.toBase58(),
        }

        const totalPaymentSize = paymentBaseSize + creatorsFeeSize + curatorsFeeSize

        const approveInst = Token.createApproveInstruction(
          TOKEN_PROGRAM_ID,
          paymentATA,
          delegatePDA,
          buyerPubkey,
          [],
          totalPaymentSize
        )

        // also create source ATA?
        // const onChainRequestID = nanoid()
        dispatch(createOrderOnChainRequest.pending({ requestID, order }))
        try {
          const tx = new anchor.web3.Transaction()
          if (wrappedTXIs) {
            tx.add(...wrappedTXIs)
          } else if (createATAInst) {
            tx.add(createATAInst)
          }
          tx.add(approveInst)
          console.log(
            'useCreateBuyOrder tx:',
            tx.instructions.map((txi) => txi.keys.map((k) => k.pubkey.toBase58()))
          )
          const res = await apollo.provider.send(tx, signers, {})
          if (res) {
            dispatch(createOrderOnChainRequest.fulfilled({ requestID, order, res }))
            // dispatch(updateFirebaseOrderRequest.pending({ requestID, order }))
            try {
              // upload to firebase
              updateFirebaseOrder(order, requestID)

              // dispatch(createOrder.fulfilled({ requestID, order }))
            } catch (e) {
              console.error(e)
              // dispatch(updateFirebaseOrderRequest.rejected({ requestID, errorMessage: e.message,  }))
              throw e
            }
          }
        } catch (e) {
          console.error(e)
          dispatch(createOrderOnChainRequest.rejected({ requestID, errorMessage: e.message }))
          throw e
        }

        return requestID
      } catch (e) {
        console.error(e)
        // dispatch(createOrder.rejected({ requestID, errorMessage: e }))
        // throw e
        return requestID
      }
    },
    [apollo.programId, apollo.provider, updateFirebaseOrder, dispatch]
  )
}

export function useMatchSellOrder(): (sellOrder: Order, assetMintData: MintData) => Promise<string> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const updateFirebaseOrder = useUpdateFirebaseOrder()
  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey

  return useCallback(
    async (sellOrder: Order, assetMintData: MintData) => {
      console.log('useCreateSellOrder params:', {
        sellOrder,
      })
      const requestID = nanoid()
      // dispatch(createOrder.pending({ requestID }))
      try {
        const sellerPubkey = pubkeyFromStringForced(sellOrder.maker)
        const assetMintPubkey = pubkeyFromStringForced(sellOrder.assetMint)
        const assetSrcPubkey = pubkeyFromStringForced(sellOrder.source)
        const paymentMintPubkey = pubkeyFromStringForced(sellOrder.paymentMint)
        const assetSizeBN = new anchor.BN(sellOrder.assetSize)
        const paymentBaseSizeBN = new anchor.BN(sellOrder.paymentBaseSize)
        const listingDateBN = sellOrder.listingDate ? new anchor.BN(sellOrder.listingDate) : new anchor.BN(0)
        const expirationDateBN = sellOrder.expirationDate ? new anchor.BN(sellOrder.expirationDate) : new anchor.BN(0)
        const curatorBasisPointsBN = new anchor.BN(sellOrder.curatorBPS)
        const BUY_KEY_BN = new anchor.BN(BUY_KEY)
        const curatorPubkey = pubkeyFromStringForced(sellOrder.curator)
        const creatorsFeeSizeBN = new anchor.BN(sellOrder.creatorsFeeSize)
        const curatorsFeeSizeBN = new anchor.BN(sellOrder.curatorFeeSize)

        if (!userPubkey) {
          throw new Error('No user pubkey')
        }

        const isWrapped = mintIsNative(paymentMintPubkey.toString())
        const newAccount = Keypair.generate()
        const signers = []
        const wrappedTXIs: TransactionInstruction[] = []
        const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(connection)
        if (isWrapped) {
          wrappedTXIs.push(
            ...[
              SystemProgram.createAccount({
                fromPubkey: userPubkey,
                newAccountPubkey: newAccount.publicKey,
                lamports: balanceNeeded,
                space: AccountLayout.span,
                programId: TOKEN_PROGRAM_ID,
              }),
              SystemProgram.transfer({
                fromPubkey: userPubkey,
                toPubkey: newAccount.publicKey,
                lamports: sellOrder.paymentBaseSize + sellOrder.creatorsFeeSize + sellOrder.curatorFeeSize,
              }),
              Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, NATIVE_MINT, newAccount.publicKey, userPubkey),
            ]
          )
          signers.push(newAccount)
        }

        const paymentATA = isWrapped ? newAccount.publicKey : (await getATAForMint(paymentMintPubkey, userPubkey))[0]
        const createATAInst = await ataTXIIfEmpty(connection, paymentMintPubkey, paymentATA, userPubkey, userPubkey)

        const [delegatePDA, delegatePDABumpSeed] = await anchor.web3.PublicKey.findProgramAddress(
          [
            Buffer.from(BUY_PREFIX),
            userPubkey.toBuffer(), // maker
            sellerPubkey.toBuffer(), // taker
            paymentATA.toBuffer(), // src
            assetMintPubkey.toBuffer(), // asset_mint
            assetSizeBN.toArrayLike(Buffer, 'le', 8), // asset_size
            paymentMintPubkey.toBuffer(), // payment_mint
            paymentBaseSizeBN.toArrayLike(Buffer, 'le', 8), // payment_base_size
            creatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // creators_fee_size!!
            curatorPubkey.toBuffer(), // curator
            // curatorBasisPointsBN.toArrayLike(Buffer, 'le', 8), // curator_bps
            curatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // curator_fee_size!!
            listingDateBN.toArrayLike(Buffer, 'le', 8), // listing_date
            expirationDateBN.toArrayLike(Buffer, 'le', 8), // expiration_date
            // BUY_KEY_BN.toArrayLike(Buffer, 'le', 8), // side
          ],
          apollo.programId
        )

        const buyOrder: Order = {
          delegatePDA: delegatePDA.toBase58(),
          delegatePDABumpSeed,
          maker: userPubkey.toBase58(),
          taker: sellerPubkey.toBase58(),
          source: paymentATA.toBase58(),
          assetMint: sellOrder.assetMint,
          assetSize: sellOrder.assetSize,
          paymentMint: sellOrder.paymentMint,
          paymentBaseSize: sellOrder.paymentBaseSize,
          creatorsFeeSize: sellOrder.creatorsFeeSize,
          curator: sellOrder.curator,
          curatorBPS: sellOrder.curatorBPS,
          curatorFeeSize: sellOrder.curatorFeeSize,
          listingDate: sellOrder.listingDate,
          expirationDate: sellOrder.expirationDate,
          side: BUY_KEY,
          revoked: false,
          revokedDate: 0,
          matched: true,
          matchedWith: sellOrder.delegatePDA,
          matchedDate: Date.now(),
          programID: sellOrder.programID,
        }

        const totalPaymentSize = buyOrder.paymentBaseSize + buyOrder.creatorsFeeSize + buyOrder.curatorFeeSize

        const approveInst = Token.createApproveInstruction(
          TOKEN_PROGRAM_ID,
          paymentATA,
          delegatePDA,
          userPubkey,
          [],
          totalPaymentSize
        )

        const feePayer = userPubkey
        const seller = sellerPubkey
        const buyer = userPubkey
        const saleOrderListedTaker = SystemProgram.programId
        const buyOrderListedTaker = sellerPubkey
        const assetMint = assetMintPubkey
        const assetSrc = assetSrcPubkey
        const assetTransferAuthority = pubkeyFromStringForced(sellOrder.delegatePDA)
        const paymentMint = paymentMintPubkey
        const paymentSrc = paymentATA
        const paymentTransferAuthority = pubkeyFromStringForced(buyOrder.delegatePDA)
        const curator = curatorPubkey
        const curatorPaymentATA = (await getATAForMint(paymentMint, curatorPubkey))[0]
        const sellerATA = (await getATAForMint(paymentMint, sellerPubkey))[0]
        const buyerATA = (await getATAForMint(assetMint, userPubkey))[0]
        const { remainingAccounts, ataTXIs } = await orderMatchRemainingAccounts(
          paymentMintPubkey,
          assetMintPubkey,
          assetMintData,
          curatorPubkey,
          userPubkey
        )

        const accounts = {
          feePayer,
          seller,
          buyer,
          saleOrderListedTaker,
          buyOrderListedTaker,
          assetMint,
          assetSrc,
          assetTransferAuthority,
          paymentMint,
          paymentSrc,
          paymentTransferAuthority,
          curator,
          // curatorPaymentAta: curatorPaymentATA,
          sellerAta: sellerATA,
          buyerAta: buyerATA,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
          clock: SYSVAR_CLOCK_PUBKEY,
        }

        console.log('useMatchSellOrder accounts:', accounts)
        // console.log('useMatchSellOrder useMatchSellOrder:', useMatchSellOrder)

        // asset_size: u64,
        // payment_base_size: u64,
        // curator_bps: u128,
        // buy_order_listing_date: i64,
        // buy_order_expiration_date: i64,
        // sell_order_listing_date: i64,
        // sell_order_expiration_date: i64,
        // sell_order_bump_seed: u8,
        // buy_order_bump_seed: u8,

        const matchOrderInst = await apollo.instruction.matchOrder(
          assetSizeBN,
          paymentBaseSizeBN,
          curatorBasisPointsBN,
          new anchor.BN(buyOrder.listingDate),
          new anchor.BN(buyOrder.expirationDate),
          new anchor.BN(sellOrder.listingDate),
          new anchor.BN(sellOrder.expirationDate),
          new anchor.BN(sellOrder.delegatePDABumpSeed),
          new anchor.BN(buyOrder.delegatePDABumpSeed),
          {
            accounts,
            remainingAccounts,
          }
        )

        // also create source ATA?
        // const onChainRequestID = nanoid()
        dispatch(matchOrdersOnChainRequest.pending({ requestID, matchedOrder: sellOrder, newOrder: buyOrder }))
        try {
          const tx = new anchor.web3.Transaction()

          if (wrappedTXIs) {
            tx.add(...wrappedTXIs)
          } else if (createATAInst) {
            tx.add(createATAInst)
          }

          console.log('useMatchSellOrder wrappedTXIs) :', wrappedTXIs)
          console.log('useMatchSellOrder createATAInst:', createATAInst)

          tx.add(approveInst)
          console.log('useMatchSellOrder ataTXIs:', ataTXIs)

          // if (ataTXIs.length > 0) {
          //   tx.add(...ataTXIs)
          // }
          tx.add(matchOrderInst)
          // console
          console.log(
            'useMatchSellOrder tx:',
            tx.instructions.map((txi) => txi.keys.map((k) => k.pubkey.toBase58()))
          )
          console.log('useMatchSellOrder sellOrder:', sellOrder)
          console.log('useMatchSellOrder SystemProgram.programId.toBuffer():', SystemProgram.programId.toBuffer())
          console.log('useMatchSellOrder SystemProgram.programId.toBase58():', SystemProgram.programId.toBase58())
          const res = await apollo.provider.send(tx, signers, {})
          console.log('useMatchSellOrder res:', res)
          if (res) {
            // sellOrder.matchedWith = buyOrder.delegatePDA
            // buyOrder.matchedWith = sellOrder.delegatePDA
            dispatch(matchOrdersOnChainRequest.fulfilled({ requestID, txRes: res }))

            // dispatch(updateFirebaseOrderRequest.pending({ requestID, order }))

            updateFirebaseOrder({ ...buyOrder }, requestID)
            updateFirebaseOrder(
              { ...sellOrder, matched: true, matchedDate: new Date().valueOf(), matchedWith: buyOrder.delegatePDA },
              requestID
            )
          }
        } catch (e) {
          console.error('useMatchSellOrder error:', e)
          dispatch(matchOrdersOnChainRequest.rejected({ requestID, errorMessage: e.message }))
          throw e
        }

        // return requestID
      } catch (e) {
        console.error(e)
        // dispatch(createOrder.rejected({ requestID, errorMessage: e }))
        // throw e
      }
      return requestID
    },
    [apollo.instruction, apollo.programId, apollo.provider, dispatch, updateFirebaseOrder, userPubkey]
  )
}

export function useRevokeOrder(): (order: Order) => Promise<string> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const updateFirebaseOrder = useUpdateFirebaseOrder()
  return useCallback(
    async (order: Order) => {
      const requestID = nanoid()
      try {
        const sourcePubkey = pubkeyFromStringForced(order.source)
        const makerPubkey = pubkeyFromStringForced(order.maker)

        const newOrder = { ...order, revoked: true }
        const revokeInst = Token.createRevokeInstruction(TOKEN_PROGRAM_ID, sourcePubkey, makerPubkey, [])
        const tx = new anchor.web3.Transaction()
        tx.add(...[revokeInst])

        dispatch(revokeOrderOnChainRequest.pending({ requestID, order: newOrder }))
        try {
          const res = await apollo.provider.send(tx, [], {})
          if (res) {
            dispatch(revokeOrderOnChainRequest.fulfilled({ requestID, order: newOrder }))
            dispatch(updateFirebaseOrderRequest.pending({ requestID, orderPDA: newOrder.delegatePDA }))
            try {
              // upload to firebase
              updateFirebaseOrder(newOrder, requestID)
              // dispatch(updateFirebaseOrderRequest.fulfilled({ requestID, order: newOrder }))
            } catch (e) {
              console.error(e)
              dispatch(
                updateFirebaseOrderRequest.rejected({
                  requestID,
                  orderPDA: newOrder.delegatePDA,
                  errorMessage: e.message,
                })
              )
              throw e
            }
          }
        } catch (e) {
          console.error(e)
          dispatch(revokeOrderOnChainRequest.rejected({ requestID, errorMessage: e.message }))
          throw e
        }

        return requestID
      } catch (e) {
        console.error(e)
        // throw e
        return requestID
      }
    },
    [apollo.provider, dispatch, updateFirebaseOrder]
  )
}

export function useMatchBuyOrder(): (buyOrder: Order, assetMintData: MintData) => Promise<string> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()
  const updateFirebaseOrder = useUpdateFirebaseOrder()
  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey

  return useCallback(
    async (buyOrder: Order, assetMintData: MintData) => {
      console.log('useMatchBuyOrder params:', {
        buyOrder,
        assetMintData,
      })
      const requestID = nanoid()
      // dispatch(createOrder.pending({ requestID }))
      try {
        const buyerPubkey = pubkeyFromStringForced(buyOrder.maker)
        const takerPubkey = pubkeyFromStringForced(buyOrder.taker)
        const assetMintPubkey = pubkeyFromStringForced(buyOrder.assetMint)
        // const assetSrcPubkey = pubkeyFromStringForced(buyOrder.source)
        const paymentMintPubkey = pubkeyFromStringForced(buyOrder.paymentMint)
        const assetSizeBN = new anchor.BN(buyOrder.assetSize)
        const paymentBaseSizeBN = new anchor.BN(buyOrder.paymentBaseSize)
        const listingDateBN = buyOrder.listingDate ? new anchor.BN(buyOrder.listingDate) : new anchor.BN(0)
        const expirationDateBN = buyOrder.expirationDate ? new anchor.BN(buyOrder.expirationDate) : new anchor.BN(0)
        const curatorBasisPointsBN = new anchor.BN(buyOrder.curatorBPS)
        const BUY_KEY_BN = new anchor.BN(BUY_KEY)
        const curatorPubkey = pubkeyFromStringForced(buyOrder.curator)
        const creatorsFeeSizeBN = new anchor.BN(buyOrder.creatorsFeeSize)
        const curatorsFeeSizeBN = new anchor.BN(buyOrder.curatorFeeSize)

        if (!userPubkey) {
          throw new Error('No user pubkey')
        }

        const assetATA = (await getATAForMint(assetMintPubkey, userPubkey))[0]
        const createATAInst = await ataTXIIfEmpty(connection, assetMintPubkey, assetATA, userPubkey, userPubkey)

        const [delegatePDA, delegatePDABumpSeed] = await anchor.web3.PublicKey.findProgramAddress(
          [
            Buffer.from(SELL_PREFIX),
            userPubkey.toBuffer(), // maker
            buyerPubkey.toBuffer(), // taker
            assetATA.toBuffer(), // src
            assetMintPubkey.toBuffer(), // asset_mint
            assetSizeBN.toArrayLike(Buffer, 'le', 8), // asset_size
            paymentMintPubkey.toBuffer(), // payment_mint
            paymentBaseSizeBN.toArrayLike(Buffer, 'le', 8), // payment_base_size
            creatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // creators_fee_size!!
            curatorPubkey.toBuffer(), // curator
            // curatorBasisPointsBN.toArrayLike(Buffer, 'le', 8), // curator_bps
            curatorsFeeSizeBN.toArrayLike(Buffer, 'le', 8), // curator_fee_size!!
            listingDateBN.toArrayLike(Buffer, 'le', 8), // listing_date
            expirationDateBN.toArrayLike(Buffer, 'le', 8), // expiration_date
            // BUY_KEY_BN.toArrayLike(Buffer, 'le', 8), // side
          ],
          apollo.programId
        )

        const sellOrder: Order = {
          delegatePDA: delegatePDA.toBase58(),
          delegatePDABumpSeed,
          maker: userPubkey.toBase58(),
          taker: buyerPubkey.toBase58(),
          source: assetATA.toBase58(),
          assetMint: buyOrder.assetMint,
          assetSize: buyOrder.assetSize,
          paymentMint: buyOrder.paymentMint,
          paymentBaseSize: buyOrder.paymentBaseSize,
          creatorsFeeSize: buyOrder.creatorsFeeSize,
          curator: buyOrder.curator,
          curatorBPS: buyOrder.curatorBPS,
          curatorFeeSize: buyOrder.curatorFeeSize,
          listingDate: buyOrder.listingDate,
          expirationDate: buyOrder.expirationDate,
          side: SELL_KEY,
          revoked: false,
          revokedDate: 0,
          matched: true,
          matchedWith: buyOrder.delegatePDA,
          matchedDate: Date.now(),
          programID: buyOrder.programID,
        }

        const approveInst = Token.createApproveInstruction(
          TOKEN_PROGRAM_ID,
          assetATA,
          delegatePDA,
          userPubkey,
          [],
          sellOrder.assetSize
        )

        const feePayer = userPubkey
        const seller = userPubkey
        const buyer = buyerPubkey
        const saleOrderListedTaker = buyerPubkey
        const buyOrderListedTaker = pubkeyFromStringForced(buyOrder.taker)
        const assetMint = assetMintPubkey
        const assetSrc = assetATA
        const assetTransferAuthority = delegatePDA
        const paymentMint = paymentMintPubkey
        const paymentSrc = pubkeyFromStringForced(buyOrder.source)
        const paymentTransferAuthority = pubkeyFromStringForced(buyOrder.delegatePDA)
        const curator = curatorPubkey
        const sellerATA = (await getATAForMint(paymentMint, userPubkey))[0]
        const buyerATA = (await getATAForMint(assetMint, buyerPubkey))[0]
        const { remainingAccounts, ataTXIs } = await orderMatchRemainingAccounts(
          paymentMintPubkey,
          assetMintPubkey,
          assetMintData,
          curatorPubkey,
          userPubkey
        )

        const accounts = {
          feePayer,
          seller,
          buyer,
          saleOrderListedTaker,
          buyOrderListedTaker,
          assetMint,
          assetSrc,
          assetTransferAuthority,
          paymentMint,
          paymentSrc,
          paymentTransferAuthority,
          curator,
          // curatorPaymentAta: curatorPaymentATA,
          sellerAta: sellerATA,
          buyerAta: buyerATA,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          ataProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
          clock: SYSVAR_CLOCK_PUBKEY,
        }

        console.log('useMatchBuyOrder accounts:', accounts)
        // console.log('useMatchSellOrder useMatchSellOrder:', useMatchSellOrder)

        // asset_size: u64,
        // payment_base_size: u64,
        // curator_bps: u128,
        // buy_order_listing_date: i64,
        // buy_order_expiration_date: i64,
        // sell_order_listing_date: i64,
        // sell_order_expiration_date: i64,
        // sell_order_bump_seed: u8,
        // buy_order_bump_seed: u8,

        const matchOrderInst = await apollo.instruction.matchOrder(
          assetSizeBN,
          paymentBaseSizeBN,
          curatorBasisPointsBN,
          new anchor.BN(buyOrder.listingDate),
          new anchor.BN(buyOrder.expirationDate),
          new anchor.BN(sellOrder.listingDate),
          new anchor.BN(sellOrder.expirationDate),
          new anchor.BN(sellOrder.delegatePDABumpSeed),
          new anchor.BN(buyOrder.delegatePDABumpSeed),
          {
            accounts,
            remainingAccounts,
          }
        )

        // also create source ATA?
        // const onChainRequestID = nanoid()
        dispatch(matchOrdersOnChainRequest.pending({ requestID, matchedOrder: sellOrder, newOrder: buyOrder }))
        try {
          const tx = new anchor.web3.Transaction()

          if (createATAInst) {
            tx.add(createATAInst)
          }

          console.log('useMatchBuyOrder createATAInst:', createATAInst)

          tx.add(approveInst)
          console.log('useMatchBuyOrder ataTXIs:', ataTXIs)

          if (ataTXIs.length > 0) {
            tx.add(...ataTXIs)
          }
          tx.add(matchOrderInst)
          // console
          console.log(
            'useMatchBuyOrder tx:',
            tx.instructions.map((txi) => txi.keys.map((k) => k.pubkey.toBase58()))
          )
          console.log('useMatchBuyOrder sellOrder:', sellOrder)
          console.log('useMatchBuyOrder SystemProgram.programId.toBuffer():', SystemProgram.programId.toBuffer())
          console.log('useMatchBuyOrder SystemProgram.programId.toBase58():', SystemProgram.programId.toBase58())
          const res = await apollo.provider.send(tx, [], {})
          console.log('useMatchBuyOrder res:', res)
          if (res) {
            // sellOrder.matchedWith = buyOrder.delegatePDA
            // buyOrder.matchedWith = sellOrder.delegatePDA
            dispatch(matchOrdersOnChainRequest.fulfilled({ requestID, txRes: res }))
            dispatch(refreshUsers([buyer.toBase58(), seller.toBase58()]))
            // dispatch(updateFirebaseOrderRequest.pending({ requestID, order }))
            try {
              // upload to firebase
              updateFirebaseOrder({ ...sellOrder }, requestID)
              updateFirebaseOrder(
                { ...buyOrder, matched: true, matchedDate: new Date().valueOf(), matchedWith: sellOrder.delegatePDA },
                requestID
              )
              // dispatch(createOrder.fulfilled({ requestID, order }))
            } catch (e) {
              console.error(e)
              dispatch(matchOrdersOnChainRequest.rejected({ requestID, errorMessage: e.message }))
              throw e
            }
          }
        } catch (e) {
          console.error('useMatchBuyOrder error:', e)
          dispatch(createOrderOnChainRequest.rejected({ requestID, errorMessage: e.message }))
          throw e
        }

        // return requestID
      } catch (e) {
        console.error(e)
        // dispatch(createOrder.rejected({ requestID, errorMessage: e }))
        // throw e
      }
      return requestID
    },
    [apollo.instruction, apollo.programId, apollo.provider, dispatch, userPubkey]
  )
}

// export function useRevokeOrder(): (order: Order) => Promise<string> {
//   const dispatch = useAppDispatch()
//   const apollo = useApolloProgram()
//   const updateFirebaseOrder = useUpdateFirebaseOrder()
//   return useCallback(
//     async (order: Order) => {
//       const requestID = nanoid()
//       try {
//         const sourcePubkey = pubkeyFromStringForced(order.source)
//         const makerPubkey = pubkeyFromStringForced(order.maker)

//         const newOrder = { ...order, revoked: true }
//         const revokeInst = Token.createRevokeInstruction(TOKEN_PROGRAM_ID, sourcePubkey, makerPubkey, [])
//         const tx = new anchor.web3.Transaction()
//         tx.add(...[revokeInst])

//         dispatch(revokeOrderOnChainRequest.pending({ requestID, order: newOrder }))
//         try {
//           const res = await apollo.provider.send(tx, [], {})
//           if (res) {
//             dispatch(revokeOrderOnChainRequest.fulfilled({ requestID, order: newOrder }))
//             dispatch(updateFirebaseOrderRequest.pending({ requestID, orderPDA: newOrder.delegatePDA }))
//             try {
//               // upload to firebase
//               updateFirebaseOrder(newOrder, requestID)
//               // dispatch(updateFirebaseOrderRequest.fulfilled({ requestID, order: newOrder }))
//             } catch (e) {
//               console.error(e)
//               dispatch(
//                 updateFirebaseOrderRequest.rejected({
//                   requestID,
//                   orderPDA: newOrder.delegatePDA,
//                   errorMessage: e.message,
//                 })
//               )
//               throw e
//             }
//           }
//         } catch (e) {
//           console.error(e)
//           dispatch(revokeOrderOnChainRequest.rejected({ requestID, errorMessage: e.message }))
//           throw e
//         }

//         return requestID
//       } catch (e) {
//         console.error(e)
//         // throw e
//         return requestID
//       }
//     },
//     [dispatch]
//   )
// }

// remaining accts
// metadata
// for i in creators that exist:
//      wallet, ata
// curator wallet, curator ata
export const orderMatchRemainingAccounts = async (
  paymentMintPubkey: PublicKey,
  assetMintPubkey: PublicKey,
  assetMintData: MintData,
  curatorPubkey: PublicKey,
  payer: PublicKey
): Promise<{ remainingAccounts: AccountMeta[]; ataTXIs: TransactionInstruction[] }> => {
  const connection = new Connection(providerURL(), 'confirmed')
  // for (const tokenBox of bundle.tokenBoxes) {

  const metadataKey = await generateMetadataPDA(assetMintPubkey)
  const remainingAccts: AccountMeta[] = [{ pubkey: metadataKey, isWritable: false, isSigner: false }]
  const ataTXIs: TransactionInstruction[] = []

  const metadata = assetMintData.metadata
  if (metadata) {
    const extended = metadata?.extended
    if (!extended) {
      throw new Error('Metadata not found for ' + assetMintData.address)
    }
    console.log('extended', extended)
    // if (!metadata.data.creators) {
    //   console.error('creators not found for ' + assetMintData.address)
    //   // remainingAccts.push(nullAcct)
    //   return { remainingAccounts: remainingAccts, ataTXIs }
    // }
    for (const c of metadata.data.creators) {
      const creatorPubkey = new PublicKey(c.address)
      console.log('creators', c)
      if (c.share == 0) continue
      remainingAccts.push({ pubkey: creatorPubkey, isWritable: false, isSigner: false })
      const creatorDepositAcct = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        paymentMintPubkey,
        creatorPubkey,
        false
      )
      const ataRes = await ataTXIIfEmpty(connection, paymentMintPubkey, creatorDepositAcct, creatorPubkey, payer)
      if (ataRes) {
        ataTXIs.push(ataRes)
      }
      console.log('ataRes', ataRes)
      remainingAccts.push({ pubkey: creatorDepositAcct, isWritable: true, isSigner: false })
    }
  }
  // remainingAccts.push(...res)

  remainingAccts.push({ pubkey: curatorPubkey, isWritable: false, isSigner: false })
  const curatorDepositAcct = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    paymentMintPubkey,
    curatorPubkey,
    false
  )
  const ataRes = await ataTXIIfEmpty(connection, paymentMintPubkey, curatorDepositAcct, curatorPubkey, payer)
  if (ataRes) {
    ataTXIs.push(ataRes)
  }
  console.log('ataRes', ataRes)
  remainingAccts.push({ pubkey: curatorDepositAcct, isWritable: true, isSigner: false })

  return { remainingAccounts: remainingAccts, ataTXIs }
}
