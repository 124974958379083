import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import Vibrant from 'node-vibrant/lib/bundle'
import { shade } from 'polished'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { decrementMintSavedByLocal, incrementMintSavedByLocal } from 'state/mints/actions'
import { create } from 'superstruct'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'

import { fetchChainUserRequest, fetchFirebaseUserRequest, updateFirebaseUserRequest } from './actions'
import { User } from './models'

export const USERS_COLLECTION = 'users5'

export function useFetchFirebaseUser(): (userAddress: string) => Promise<User | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (userAddress: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseUserRequest.pending({ userAddress, requestID }))
      try {
        const db = getFirestore()
        const userSnap = await getDoc(doc(db, USERS_COLLECTION, userAddress))
        console.log('firebase collections: ', userSnap)
        if (userSnap.exists()) {
          console.log('firebase Document data:', userSnap.data())
          const user = decodeFirebaseUser(userSnap.data())
          console.log('firebase user decoded: ', user)
          dispatch(fetchFirebaseUserRequest.fulfilled({ requestID, user }))
          return user
        } else {
          console.log('firebase User not found. Creating.')
          return undefined
        }
        // return user
      } catch (e) {
        console.error(e)
        dispatch(fetchChainUserRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   user: StringPublicKey
//   data: Data
//   primaryUserHappened: boolean
//   isMutable: boolean
//   editionNonce: number | undefined
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | undefined
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | undefined
//   collection?: IMetadataCollection | undefined
// }

// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.address = args.address
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | undefined
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | undefined
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }

// export interface IMetadataCollection {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }
//
const encodeUserObject = (user: User) => {
  return user
  console.log('encodeUserObject: ', user)
  // const userObj = {
  //   // ...user,
  //   pubkey: user.pubkey.toBase58(),
  //   user: encodeUser(user.user),
  //   metadata: user.metadata
  //     ? {
  //         key: user.metadata.key,
  //         updateAuthority: user.metadata.updateAuthority.toBase58(),
  //         user: user.metadata.user.toBase58(),
  //         data: user.metadata.data
  //           ? {
  //               name: user.metadata.data?.name,
  //               symbol: user.metadata.data.symbol,
  //               uri: user.metadata.data.uri,
  //               sellerFeeBasisPoints: user.metadata.data.sellerFeeBasisPoints,
  //               creators: user.metadata.data.creators
  //                 ? user.metadata.data.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //             }
  //           : {},
  //         primaryUserHappened: user.metadata.primaryUserHappened,
  //         isMutable: user.metadata.isMutable,
  //         editionNonce: user.metadata.editionNonce ?? '',
  //         extended: user.metadata.extended
  //           ? {
  //               name: user.metadata.extended.name,
  //               symbol: user.metadata.extended.symbol,
  //               creators: user.metadata.extended.creators
  //                 ? user.metadata.extended.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //               description: user.metadata.extended.description,
  //               image: user.metadata.extended.image,
  //               animation_url: user.metadata.extended.animation_url ?? '',
  //               external_url: user.metadata.extended.external_url,
  //               seller_fee_basis_points: user.metadata.extended.seller_fee_basis_points,
  //               properties: {
  //                 files: user.metadata.extended.properties.files
  //                   ? user.metadata.extended.properties.files.map((file) => {
  //                       return {
  //                         file: file.valueOf(),
  //                         content_type: file.type ?? '',
  //                       }
  //                     })
  //                   : [],
  //                 category: user.metadata.extended.properties.category,
  //                 maxSupply: user.metadata.extended.properties.maxSupply ?? '',
  //               },
  //               attributes: user.metadata.extended.attributes
  //                 ? user.metadata.extended.attributes.map((attribute) => ({
  //                     trait_type: attribute.trait_type,
  //                     value: attribute.value,
  //                   }))
  //                 : [],
  //               collection: user.metadata.extended.collection
  //                 ? {
  //                     name: user.metadata.extended.collection.name,
  //                     family: user.metadata.extended.collection.family,
  //                   }
  //                 : {},
  //             }
  //           : {},
  //       }
  //     : {},
  //   asks: user.asks ? user.asks.map((ask) => encodeAskV2(ask)) : [],
  //   bids: user.bids ? user.bids.map((bid) => encodeBidV2(bid)) : [],
  //   owner: user.owner?.toBase58() ?? '',
  // }

  // console.log('encodeUserObject: ', userObj)

  // return userObj
}

export const decodeFirebaseUser = (res: any) => {
  console.log('decodeFirebaseUser: ', res)
  return create(res, User)
}

export function useUpdateFirebaseUser(): (chainUser: User, oldFirebaseUser?: User) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (chainUser: User, oldFirebaseUser?: User) => {
      const requestID = nanoid()
      dispatch(updateFirebaseUserRequest.pending({ userAddress: chainUser.address, requestID }))

      try {
        const user = {
          ...chainUser,
          // profileMint: oldFirebaseUser?.profileMint ?? '',
        }

        const db = getFirestore()
        const userObj = encodeUserObject({ ...user })
        console.log('firebase dbagg uploading user (useUpdateFirebaseUser): ', userObj)
        setDoc(doc(db, USERS_COLLECTION, user.address), userObj, { merge: true }).then(() => {
          console.log('firebase dbagg user uploaded (useUpdateFirebaseUser)')
          dispatch(updateFirebaseUserRequest.fulfilled({ requestID, user, preserveOffChain: true }))
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseUserRequest.rejected({ userAddress: chainUser.address, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}

export function useEditSavedMint(): (user: User, mintAddress: string, isSaved: boolean) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (user: User, mintAddress: string, isSaved: boolean) => {
      const requestID = nanoid()
      dispatch(updateFirebaseUserRequest.pending({ userAddress: user.address, requestID }))

      const filteredMints = user.savedMints?.filter((m) => m !== mintAddress) ?? []

      try {
        const newUser: User = {
          ...user,
          savedMints: isSaved ? [...filteredMints, mintAddress] : filteredMints,
        }

        const db = getFirestore()
        const userObj = encodeUserObject({ ...newUser })
        console.log('firebase dbagg uploading user (useEditSavedMint): ', userObj)
        setDoc(doc(db, USERS_COLLECTION, user.address), userObj, { merge: true }).then(() => {
          console.log('firebase dbagg user uploaded (useEditSavedMint)')
          dispatch(updateFirebaseUserRequest.fulfilled({ requestID, user: newUser }))
          if (isSaved) {
            dispatch(incrementMintSavedByLocal({ mintAddress, userAddress: user.address }))
          } else {
            dispatch(decrementMintSavedByLocal({ mintAddress, userAddress: user.address }))
          }
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseUserRequest.rejected({ userAddress: user.address, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}

export async function getColorFromUriPath(uri?: string): Promise<string | null> {
  if (!uri) {
    return null
  }
  const formattedPath = uriToHttp(uri)[0]

  const palette = await Vibrant.from(formattedPath).getPalette()
  if (!palette?.Vibrant) {
    return null
  }

  let detectedHex = palette.Vibrant.hex
  let AAscore = hex(detectedHex, '#FFF')
  while (AAscore < 3) {
    detectedHex = shade(0.005, detectedHex)
    AAscore = hex(detectedHex, '#FFF')
  }

  return detectedHex
}
