import { Trans } from '@lingui/macro'
import { PublicKey } from '@solana/web3.js'
import Badge, { BadgeVariant } from 'components/Badge'
import { ArtContent } from 'components/BundleArtDisplay/ArtContent'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import DoubleModal from 'components/DoubleModal'
import ExplorerLink from 'components/ExplorerLink'
import GenericArtDisplay from 'components/GenericArtDisplay'
import Loader from 'components/Loader'
import MintArtDisplay from 'components/MintArtDisplay'
import MintInfoContent from 'components/MintInfoContent'
import { LoadingView } from 'components/ModalViews'
import { RowFlat } from 'components/Row'
import SolTokenLogo from 'components/SolTokenLogo'
import { useExtendedArt } from 'metaplex/Art/useArt'
import { useAccountInfo, useFetchAccountInfo } from 'providers/accounts'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import styled from 'styled-components/macro'
import { CustomLightSpinner, TYPE } from 'theme'
import { MetadataAttribute } from 'validators/accounts/sales'

import Circle from '../../assets/images/blue-loader.svg'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import {
  useErrorModalData,
  useErrorModalToggle,
  useModalOpen,
  useNFTModalMintAddress,
  useNFTModalToggle,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import Modal from '../Modal'

const Wrapper = styled.div`
  // ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  // padding: 10px;
  // width: 100%;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
`

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

const InfoWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  // margin: 0;
  padding: 2rem 4rem;
  // background-color: ${({ theme }) => theme.bg1};
  // width: 100%;
`

// const Wrapper = styled.div`
//   width: 100%;
//   padding: 24px;
// `

export default function ErrorModal() {
  const modalOpen = useModalOpen(ApplicationModal.ERROR)
  const toggleModal = useErrorModalToggle()
  const errorData = useErrorModalData()
  const dispatch = useDispatch()

  return (
    <Modal isOpen={modalOpen} onDismiss={toggleModal}>
      <Wrapper>
        <AutoColumn gap="12px" justify={'center'}>
          <TYPE.error error={true} fontWeight={500} fontSize={20} textAlign="center">
            <Trans>{`Error ${errorData?.actionTitle ?? ''}`}</Trans>
          </TYPE.error>
          <TYPE.main fontWeight={500} fontSize={20} textAlign="center">
            <Trans>{`${errorData?.errorMessage ?? ''}`}</Trans>
          </TYPE.main>
          {/* {request?.status == RequestStatus.Fulfilled && (
            <StyledInternalLink to={`/collections/${collection?.id}`}>
              <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                <Trans>View Collection</Trans>
              </Text>
            </StyledInternalLink>
          )} */}
          <ButtonPrimary onClick={toggleModal} style={{ margin: '20px 0 0 0' }}>
            <TYPE.heavy fontWeight={500} fontSize={20}>
              <Trans>Return</Trans>
            </TYPE.heavy>
          </ButtonPrimary>
        </AutoColumn>
      </Wrapper>
    </Modal>
  )
}
