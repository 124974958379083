import { Trans } from '@lingui/macro'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { Currency } from '@uniswap/sdk-core'
import ActionCard from 'components/ActionCard'
import { ButtonGray } from 'components/Button'
import CollectionLogo from 'components/CollectionLogo'
import { AutoColumn } from 'components/Column'
import Loader from 'components/Loader'
import MintCard from 'components/MintCard'
import { AutoRow } from 'components/Row'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { darken, opacify, transparentize } from 'polished'
import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { fetchMoreForActionQuery } from 'state/actions/actions'
import { useActionQueryActions, useQueryHasFoundEnd, useQueryIsFetching } from 'state/actions/hooks'
import { Action } from 'state/actions/models'
import { useSafeCollection } from 'state/collections/hooks'
import { useSafeUser } from 'state/users/hooks'
import { useSafeWallet } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { CustomLightSpinner, TYPE } from 'theme'
import { truncateString } from 'utils'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency

const MintsGrid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  // padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

function Refresher({ refresh, hidden }: { refresh: () => void; hidden?: boolean }) {
  // const isInView = useInView()
  const { ref, inView } = useInView()
  const theme = useTheme()
  console.log('Refresher inView', inView)
  console.log('Refresher refresh', refresh)

  useEffect(() => {
    if (inView && refresh) {
      refresh()
    }
  }, [inView, refresh])

  return (
    <AutoRow hidden={hidden} width={'100%'} align="center" justify="center" ref={ref}>
      <Loader size={'18px'} stroke={theme.yellow1} />
    </AutoRow>
  )
}

export default function MintsFeed({ walletAddress }: { walletAddress?: string }) {
  // const actionIDs = useActionQueryActions(actionsQueryKey)
  // console.log('ActionsFeed actionIDs', actionIDs)
  const dispatch = useDispatch()

  // const wallet = useSafeWallet(walletAddress)

  // const queryIsFetching = useQueryIsFetching(actionsQueryKey)
  // const queryHasFoundEnd = useQueryHasFoundEnd(actionsQueryKey)

  // const refresh = useCallback(() => {
  //   if (actionsQueryKey && !queryIsFetching) {
  //     dispatch(fetchMoreForActionQuery({ queryKey: actionsQueryKey }))
  //   }
  // }, [queryIsFetching, actionsQueryKey, dispatch])

  const wallet = useSafeWallet(walletAddress)
  const ownedTokens = wallet ? wallet.ownedTokens : []

  return wallet ? (
    <>
      {/* {JSON.stringify(actionsQueryKey, null, 2)}
      {JSON.stringify(actionIDs?.length, null, 2)} */}
      <MintsGrid>
        {ownedTokens?.map((ownedToken, i) => {
          return ownedToken ? <MintCard mintAddress={ownedToken.mint}></MintCard> : <></>
        })}
        {/* {!queryHasFoundEnd && <Refresher refresh={refresh} hidden={queryHasFoundEnd}></Refresher>} */}
        <div style={{ height: '40px' }}></div>
      </MintsGrid>
    </>
  ) : (
    // </GridWrapper>
    <></>
  )
}
