import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { Mint } from 'api/types'
import { MintData } from './models'
import { SearchIndex, SearchRefinementIndex } from './reducer'

export const setSearchQuery = createAction<string>('search/setSearchQuery')
export const setSearchIndex = createAction<SearchIndex>('search/setSearchIndex')
export const addRefinementListValue = createAction<{ index: string; value: string }>('search/setRefinementList')
export const removeRefinementListValue = createAction<{ index: string; value: string }>(
  'search/setRefinementListBoolean'
)

export const resetToCollectionPage = createAction<{ collectionID: string }>('search/resetToCollectionPage')
export const resetToExploreCollections = createAction<{ param: string }>('search/resetToExploreCollections')

export const clearAllValues = createAction<{ index: string }>('search/clearAllValues')
