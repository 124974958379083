import { TokenInfo } from '@solana/spl-token-registry'
import { PublicKey } from '@solana/web3.js'
import { Currency } from '@uniswap/sdk-core'
import { Wrapper } from 'components/swap/styleds'
import { SupportedChainId } from 'constants/chains'
import React, { useMemo } from 'react'
import { Button } from 'rebass'
import { useNFTModalToggle, useSetNFTModalMintAddress } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { ExternalLink, ExternalLinkIcon, TYPE } from 'theme'
import { shortenAddress } from 'utils'
import { solExplorerAddressURL } from 'utils/getExplorerLink'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Logo from '../Logo'

type Network = 'ethereum' | 'arbitrum' | 'optimism'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.ARBITRUM_ONE:
      return 'arbitrum'
    case SupportedChainId.OPTIMISM:
      return 'optimism'
    default:
      return 'ethereum'
  }
}

export const getTokenLogoURL = (
  address: string,
  chainId: SupportedChainId = SupportedChainId.MAINNET
): string | void => {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.ARBITRUM_ONE, SupportedChainId.MAINNET, SupportedChainId.OPTIMISM]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ clickable?: boolean; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px;' : 'none')};
  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'none')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
  transition: ${({ clickable }) => (clickable ? ' all 0.2s ease-in-out' : 'none')};
`

const WrapperButton = styled(Wrapper)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddressLink = styled(ExternalLink)`
  // font-size: 0.825rem;
  // color: ${({ theme }) => theme.text3};
  margin-left: 0rem;
  // font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

export default function ExplorerLink({ address, iconOnly }: { address: string; iconOnly?: boolean }) {
  return (
    // <TYPE.body ...props>
    <AddressLink href={solExplorerAddressURL(address)}>
      {address && !iconOnly && `${shortenAddress(address, 6)}`}
      <ExternalLinkIcon href={solExplorerAddressURL(address)} />
    </AddressLink>
    // </TYPE.body>
  )
}
