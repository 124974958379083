import * as anchor from '@project-serum/anchor'
import { Token } from '@solana/spl-token'
import {
  // PublicKey,
  Keypair,
  PublicKey as Pubkey,
  TransactionInstruction,
} from '@solana/web3.js'
// import { SYSTEM } from 'metaplex/ids'

export class TX {
  txis: TransactionInstruction[] = []
  signers: Keypair[] = []

  append(txi: TransactionInstruction, signers: Keypair[]) {
    this.txis.push(txi)
    signers.forEach((signer) => {
      let exists = false
      this.signers.forEach((s) => {
        if (s.publicKey.equals(signer.publicKey)) {
          exists = true
        }
        if (!exists) {
          this.signers.push(signer)
        }
      })
    })
  }
  appendMulti(txis: TransactionInstruction[], signers: Keypair[]) {
    txis.forEach((txi) => {
      this.append(txi, signers)
    })
    signers.forEach((signer) => {
      let exists = false
      this.signers.forEach((s) => {
        if (s.publicKey.equals(signer.publicKey)) {
          exists = true
        }
        if (!exists) {
          this.signers.push(signer)
        }
      })
    })
  }
}

export type Collection = {
  authority: Pubkey
  sales: Pubkey[]
  salesHead: number
  salesTail: number
  slug: string
  name: string
  creators: string
}

export const decodeCollection = (res: any) => {
  const collection: Collection = {
    authority: res.authority,
    sales: res.sales,
    salesHead: res.salesHead,
    salesTail: res.salesTail,
    slug: new TextDecoder('utf-8').decode(new Uint8Array(res.slug)),
    name: new TextDecoder('utf-8').decode(new Uint8Array(res.name)),
    creators: res.creators,
  }
  return collection
}

export type CollectionsList = {
  authority: Pubkey
  collections: Pubkey[]
  slugs: string[]
  head: number
  tail: number
}

export const decodeCollectionsList = (res: any) => {
  const collections: CollectionsList = {
    authority: res.authority,
    collections: res.collections,
    slugs: res.slugs,
    head: res.head,
    tail: res.tail,
  }
  return collections
}

export type ReceiveMap = {
  authority: Pubkey
  forVault: Pubkey
  receiver: Pubkey
  locked: boolean
  head: number
  tail: number
  destinations: ReceiveDestination[]
}

export type ReceiveDestination = {
  tokenMint: Pubkey
  destinationAcct: Pubkey
  receiveMap: Pubkey
  locked: boolean
  expectedAmount: number
}

export type TokenBox = {
  vault: Pubkey
  tokenMint: Pubkey
  originalAcct: Pubkey
  storeAcct: Pubkey
  amount: number
}

export type Vault = {
  authority: Pubkey
  tokenAddWithdrawalAuthority: Pubkey
  creator: Pubkey
  tokenBoxes: TokenBox[]
  head: number
  tail: number
  isOffer: boolean
  sale: Pubkey
  offerer: Pubkey
  offerLamports: number
  lamportEscrow: Pubkey
  lamportEscrowBump: number
  offererReceiveMap: Pubkey
  sellerReceiveMap: Pubkey
  isLamportOnly: boolean
  creationDate: Date
  offerExpirationDate: number
  exists: boolean
  redeemed: boolean
  returned: boolean
  cancelled: boolean
}

export const decodeVault = (res: any) => {
  const vault: Vault = {
    authority: res.authority,
    tokenAddWithdrawalAuthority: res.tokenAddWithdrawalAuthority,
    creator: res.creator,
    tokenBoxes: res.tokenBoxes.map(
      (tb: { vault: any; tokenMint: any; originalAcct: any; storeAcct: any; amount: any }) => {
        return {
          vault: tb.vault,
          tokenMint: tb.tokenMint,
          originalAcct: tb.originalAcct,
          storeAcct: tb.storeAcct,
          amount: tb.amount,
        }
      }
    ),
    head: res.head,
    tail: res.tail,
    isOffer: res.isOffer,
    sale: res.sale,
    offerer: res.offerer,
    offerLamports: res.offerLamports,
    lamportEscrow: res.lamportEscrow,
    lamportEscrowBump: res.lamportEscrowBump,
    offererReceiveMap: res.offererReceiveMap,
    sellerReceiveMap: res.sellerReceiveMap,
    isLamportOnly: res.isLamportOnly,
    creationDate: new Date(res.creationDate * 1000),
    offerExpirationDate: res.offerExpirationDate,
    exists: res.exists,
    redeemed: res.redeemed,
    returned: res.returned,
    cancelled: res.cancelled,
  }
  return vault
}

export const decodeReceiveMap = (res: any) => {
  const receiveMap: ReceiveMap = {
    authority: res.authority,
    forVault: res.forVault,
    receiver: res.receiver,
    locked: res.locked,
    head: res.head,
    tail: res.tail,
    destinations: res.destinations.map(
      (dest: { tokenMint: any; destinationAcct: any; receiveMap: any; locked: any; expectedAmount: any }) => {
        return {
          tokenMint: dest.tokenMint,
          destinationAcct: dest.destinationAcct,
          receiveMap: dest.receiveMap,
          locked: dest.locked,
          expectedAmount: dest.expectedAmount,
        }
      }
    ),
  }
  return receiveMap
}

export type Sale = {
  authority: Pubkey
  sellerKey: Pubkey
  highestAuctionBid: number
  auction: boolean
  allowOffers: boolean
  allowInstantBuy: boolean
  startDate: Date
  endDate: Date
  instantBuyPrice: number
  offers: Pubkey[]
  offersHead: number
  offersTail: number
  collections: Pubkey[]
  collectionsHead: number
  collectionsTail: number
  vault: Pubkey
  completed: boolean
  cancelled: boolean
  instantBought: boolean
  offerAccepted: boolean
  expiredAndReturned: boolean
  vaultAuthBump: number
}

export const decodeSaleRes = (res: any) => {
  const sale: Sale = {
    authority: res.authority,
    sellerKey: res.sellerKey,
    auction: res.auction,
    highestAuctionBid: res.highestAuctionBid,
    allowOffers: res.allowOffers,
    allowInstantBuy: res.allowInstantBuy,
    startDate: new Date(res.startDate * 1000),
    endDate: new Date(res.endDate * 1000),
    instantBuyPrice: res.instantBuyPrice,
    offersHead: res.offersHead,
    offersTail: res.offersTail,
    offers: res.offers,
    collections: res.collections,
    collectionsHead: res.collectionsHead,
    collectionsTail: res.collectionsTail,

    // offers: res.offers.map(o => {
    //     return {
    //         sale: o.sale,
    //         offerer: o.offerer,
    //         tokenVault: o.tokenVault,
    //         lamportVault: o.lamportVault,
    //         offererReceiveMap: o.offererReceiveMap,
    //         sellerReceiveMap: o.sellerReceiveMap,
    //         isLamportOnly: o.isLamportOnly,
    //         expirationDate: new Date(o.expirationDate * 1000),
    //         creationDate: new Date(o.creationDate * 1000),
    //         lamports: o.lamports,
    //         exists: o.exists,
    //         redeemed: o.redeemed,
    //         returned: o.returned,
    //         cancelled: o.cancelled,
    //     }
    // }),
    vault: res.vault,
    completed: res.completed,
    cancelled: res.cancelled,
    instantBought: res.instantBought,
    offerAccepted: res.offerAccepted,
    expiredAndReturned: res.expiredAndReturned,
    vaultAuthBump: res.vaultAuthBump,
  }
  return sale
}

export type OfferToken = {
  tokenMint: Pubkey
  amount: number
  depositAccount: Pubkey
}

export type OfferTokenWithCollection = {
  tokenMint: Pubkey
  amount: number
  depositAccount: Pubkey
  collection?: Pubkey
  newCollectionName?: string
  newCollectionSlug?: string
  newCollectionCreators?: string[]
}

export type Store = {
  sales: Pubkey[]
}

export type StoreRes = {
  sales: Pubkey[]
}

export const decodeStoreRes = (res: StoreRes) => {
  const store: Store = {
    sales: res.sales.filter((s) => s.toBase58() != '11111111111111111111111111111111'),
  }
  return store
}
