import { Connection, PublicKey, StakeActivationData } from '@solana/web3.js'
import { pubkeyFromStringForced } from 'apollo/utils'
import { providerURL } from 'hooks/web3'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import { CollectionQueryProvider } from 'providers/collectionQuery'
import { TransactionsProvider } from 'providers/transactions'
import React from 'react'
import { metadataFromMint } from 'state/sales/chain'
import { create } from 'superstruct'
import { ParsedInfo } from 'validators'
// import { Metadata } from 'validators/accounts/apollo'
import { ConfigAccount } from 'validators/accounts/config'
import { NonceAccount } from 'validators/accounts/nonce'
import { Metadata } from 'validators/accounts/sales'
import { StakeAccount } from 'validators/accounts/stake'
import { SysvarAccount } from 'validators/accounts/sysvar'
import { MintAccountInfo, TokenAccount, TokenAccountInfo } from 'validators/accounts/token'
import {
  ProgramDataAccount,
  ProgramDataAccountInfo,
  UpgradeableLoaderAccount,
} from 'validators/accounts/upgradeable-program'
import { VoteAccount } from 'validators/accounts/vote'

import { BidProvider } from './bid'
// import { BundleProvider } from './bundle'
// import { CollectionsListProvider } from './collectionList'
import { FlaggedAccountsProvider } from './flagged-accounts'
import { HistoryProvider } from './history'
import { TokensProvider } from './tokens'
import { EditionData, getEditionData, getMetadata } from './utils/metadataHelpers'
import { VaultsProvider } from './vault'
export { useAccountHistory } from './history'

export type StakeProgramData = {
  program: 'stake'
  parsed: StakeAccount
  activation?: StakeActivationData
}

export type UpgradeableLoaderAccountData = {
  program: 'bpf-upgradeable-loader'
  parsed: UpgradeableLoaderAccount
  programData?: ProgramDataAccountInfo
}

export type NFTData = {
  metadata: Metadata
  editionData?: EditionData
}

export type TokenProgramData = {
  program: 'spl-token'
  parsed: TokenAccount
  nftData?: NFTData
}

export type VoteProgramData = {
  program: 'vote'
  parsed: VoteAccount
}

export type NonceProgramData = {
  program: 'nonce'
  parsed: NonceAccount
}

export type SysvarProgramData = {
  program: 'sysvar'
  parsed: SysvarAccount
}

export type ConfigProgramData = {
  program: 'config'
  parsed: ConfigAccount
}

export type ProgramData =
  | UpgradeableLoaderAccountData
  | StakeProgramData
  | TokenProgramData
  | VoteProgramData
  | NonceProgramData
  | SysvarProgramData
  | ConfigProgramData

export interface Details {
  executable: boolean
  owner: PublicKey
  space: number
  data?: ProgramData
}

export interface Account {
  pubkey: PublicKey
  lamports: number
  details?: Details
}

type State = Cache.State<Account>
type Dispatch = Cache.Dispatch<Account>

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

export const accountsLoaded = (accounts: Cache.CacheEntry<any>[], name: string) => {
  let loaded = true
  accounts.forEach((account) => {
    // console.log("a account:", account)
    if (!(account?.data && account?.status == FetchStatus.Fetched)) {
      loaded = false
    }
  })
  const r = loaded && accounts.length > 0
  if (name) {
    console.log(`${name} loaded: ${r}`)
  }
  return r
}

type AccountsProviderProps = { children: React.ReactNode }
export function AccountsProvider({ children }: AccountsProviderProps) {
  const url = providerURL()
  const [state, dispatch] = Cache.useReducer<Account>()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <TokensProvider>
          {/* <MetadataProvider> */}
          <CollectionQueryProvider>
            {/* <BundleProvider> */}
            <BidProvider>
              <VaultsProvider>
                <HistoryProvider>
                  <TransactionsProvider>
                    {/* <RewardsProvider> */}
                    <FlaggedAccountsProvider>{children}</FlaggedAccountsProvider>
                    {/* </RewardsProvider> */}
                  </TransactionsProvider>
                </HistoryProvider>
              </VaultsProvider>
            </BidProvider>
            {/* </BundleProvider> */}
          </CollectionQueryProvider>
          {/* </MetadataProvider> */}
        </TokensProvider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

// export const getAccountInfo = async (url: string, pubkey: PublicKey): Promise<any> => {
//   let data
//   try {
//     const connection = new Connection(url, 'confirmed')
//     const result = (await connection.getParsedAccountInfo(pubkey)).value
//     console.log('result', result)

//     let lamports, details
//     if (result === null) {
//       lamports = 0
//     } else {
//       lamports = result.lamports

//       // Only save data in memory if we can decode it
//       let space: number
//       if (!('parsed' in result.data)) {
//         space = result.data.length
//       } else {
//         space = result.data.space
//       }

//       let data: ProgramData | undefined
//       if ('parsed' in result.data) {
//         try {
//           const info = create(result.data.parsed, ParsedInfo)
//           switch (result.data.program) {
//             case 'bpf-upgradeable-loader': {
//               const parsed = create(info, UpgradeableLoaderAccount)

//               // Fetch program data to get program upgradeability info
//               let programData: ProgramDataAccountInfo | undefined
//               if (parsed.type === 'program') {
//                 const result = (await connection.getParsedAccountInfo(parsed.info.programData)).value
//                 if (result && 'parsed' in result.data && result.data.program === 'bpf-upgradeable-loader') {
//                   const info = create(result.data.parsed, ParsedInfo)
//                   programData = create(info, ProgramDataAccount).info
//                 } else {
//                   throw new Error(`invalid program data account for program: ${pubkey.toBase58()}`)
//                 }
//               }

//               data = {
//                 program: result.data.program,
//                 parsed,
//                 programData,
//               }
//               break
//             }
//             case 'stake': {
//               const parsed = create(info, StakeAccount)
//               const isDelegated = parsed.type === 'delegated'
//               const activation = isDelegated ? await connection.getStakeActivation(pubkey) : undefined

//               data = {
//                 program: result.data.program,
//                 parsed,
//                 activation,
//               }
//               break
//             }
//             case 'vote':
//               data = {
//                 program: result.data.program,
//                 parsed: create(info, VoteAccount),
//               }
//               break
//             case 'nonce':
//               data = {
//                 program: result.data.program,
//                 parsed: create(info, NonceAccount),
//               }
//               break
//             case 'sysvar':
//               data = {
//                 program: result.data.program,
//                 parsed: create(info, SysvarAccount),
//               }
//               break
//             case 'config':
//               data = {
//                 program: result.data.program,
//                 parsed: create(info, ConfigAccount),
//               }
//               break

//             case 'spl-token':
//               const parsed = create(info, TokenAccount)
//               let nftData

//               // Generate a PDA and check for a Metadata Account
//               if (parsed.type === 'mint') {
//                 const metadata = await getMetadata(pubkey, url)
//                 if (metadata) {
//                   // We have a valid Metadata account. Try and pull edition data.
//                   const editionData = await getEditionData(pubkey, url)
//                   nftData = { metadata, editionData }
//                 }
//               }
//               data = {
//                 program: result.data.program,
//                 parsed,
//                 nftData,
//               }
//               break
//             default:
//               data = undefined
//           }
//         } catch (error) {}
//       }

//       details = {
//         space,
//         executable: result.executable,
//         owner: result.owner,
//         data,
//       }
//     }
//     data = { pubkey, lamports, details }
//     return { data }
//   } catch (error) {
//     console.error(error)
//     return undefined
//   }
// }

async function fetchAccountInfo(dispatch: Dispatch, pubkey: PublicKey, url: string) {
  dispatch({
    type: ActionType.Update,
    key: pubkey.toBase58(),
    status: Cache.FetchStatus.Fetching,
  })

  let data
  let fetchStatus
  try {
    const connection = new Connection(url, 'confirmed')
    const result = (await connection.getParsedAccountInfo(pubkey)).value

    let lamports, details
    if (result === null) {
      lamports = 0
    } else {
      lamports = result.lamports

      // Only save data in memory if we can decode it
      let space: number
      if (!('parsed' in result.data)) {
        space = result.data.length
      } else {
        space = result.data.space
      }

      let data: ProgramData | undefined
      if ('parsed' in result.data) {
        try {
          const info = create(result.data.parsed, ParsedInfo)
          switch (result.data.program) {
            case 'bpf-upgradeable-loader': {
              const parsed = create(info, UpgradeableLoaderAccount)

              // Fetch program data to get program upgradeability info
              let programData: ProgramDataAccountInfo | undefined
              if (parsed.type === 'program') {
                const result = (await connection.getParsedAccountInfo(parsed.info.programData)).value
                if (result && 'parsed' in result.data && result.data.program === 'bpf-upgradeable-loader') {
                  const info = create(result.data.parsed, ParsedInfo)
                  programData = create(info, ProgramDataAccount).info
                } else {
                  throw new Error(`invalid program data account for program: ${pubkey.toBase58()}`)
                }
              }

              data = {
                program: result.data.program,
                parsed,
                programData,
              }
              break
            }
            case 'stake': {
              const parsed = create(info, StakeAccount)
              const isDelegated = parsed.type === 'delegated'
              const activation = isDelegated ? await connection.getStakeActivation(pubkey) : undefined

              data = {
                program: result.data.program,
                parsed,
                activation,
              }
              break
            }
            case 'vote':
              data = {
                program: result.data.program,
                parsed: create(info, VoteAccount),
              }
              break
            case 'nonce':
              data = {
                program: result.data.program,
                parsed: create(info, NonceAccount),
              }
              break
            case 'sysvar':
              data = {
                program: result.data.program,
                parsed: create(info, SysvarAccount),
              }
              break
            case 'config':
              data = {
                program: result.data.program,
                parsed: create(info, ConfigAccount),
              }
              break

            case 'spl-token':
              const parsed = create(info, TokenAccount)
              let nftData
              console.log('fetchaccountinfo parsed:', parsed)

              // Generate a PDA and check for a Metadata Account
              if (parsed.type === 'mint') {
                const metadata = await metadataFromMint(pubkey)
                console.log('fetchaccountinfo metadata:', metadata)

                if (metadata) {
                  // We have a valid Metadata account. Try and pull edition data.
                  const editionData = await getEditionData(pubkey, url)
                  nftData = { metadata, editionData }
                }
              }
              data = {
                program: result.data.program,
                parsed,
                nftData,
              }
              break
            default:
              data = undefined
          }
        } catch (error) {}
      }

      details = {
        space,
        executable: result.executable,
        owner: result.owner,
        data,
      }
    }
    data = { pubkey, lamports, details }
    fetchStatus = FetchStatus.Fetched
  } catch (error) {
    fetchStatus = FetchStatus.FetchFailed
  }
  dispatch({
    type: ActionType.Update,
    status: fetchStatus,
    data,
    key: pubkey.toBase58(),
  })
}

async function fetchAccountsInfos(dispatch: Dispatch, pubkeys: PublicKey[], url: string) {
  for (const pubkey of pubkeys) {
    dispatch({
      type: ActionType.Update,
      key: pubkey.toBase58(),
      status: Cache.FetchStatus.Fetching,
    })

    let data
    let fetchStatus
    try {
      const connection = new Connection(url, 'confirmed')
      const result = (await connection.getParsedAccountInfo(pubkey)).value

      let lamports, details
      if (result === null) {
        lamports = 0
      } else {
        lamports = result.lamports

        // Only save data in memory if we can decode it
        let space: number
        if (!('parsed' in result.data)) {
          space = result.data.length
        } else {
          space = result.data.space
        }

        let data: ProgramData | undefined
        if ('parsed' in result.data) {
          try {
            const info = create(result.data.parsed, ParsedInfo)
            switch (result.data.program) {
              case 'bpf-upgradeable-loader': {
                const parsed = create(info, UpgradeableLoaderAccount)

                // Fetch program data to get program upgradeability info
                let programData: ProgramDataAccountInfo | undefined
                if (parsed.type === 'program') {
                  const result = (await connection.getParsedAccountInfo(parsed.info.programData)).value
                  if (result && 'parsed' in result.data && result.data.program === 'bpf-upgradeable-loader') {
                    const info = create(result.data.parsed, ParsedInfo)
                    programData = create(info, ProgramDataAccount).info
                  } else {
                    throw new Error(`invalid program data account for program: ${pubkey.toBase58()}`)
                  }
                }

                data = {
                  program: result.data.program,
                  parsed,
                  programData,
                }

                break
              }
              case 'stake': {
                const parsed = create(info, StakeAccount)
                const isDelegated = parsed.type === 'delegated'
                const activation = isDelegated ? await connection.getStakeActivation(pubkey) : undefined

                data = {
                  program: result.data.program,
                  parsed,
                  activation,
                }
                break
              }
              case 'vote':
                data = {
                  program: result.data.program,
                  parsed: create(info, VoteAccount),
                }
                break
              case 'nonce':
                data = {
                  program: result.data.program,
                  parsed: create(info, NonceAccount),
                }
                break
              case 'sysvar':
                data = {
                  program: result.data.program,
                  parsed: create(info, SysvarAccount),
                }
                break
              case 'config':
                data = {
                  program: result.data.program,
                  parsed: create(info, ConfigAccount),
                }
                break

              case 'spl-token':
                const parsed = create(info, TokenAccount)
                let nftData

                // Generate a PDA and check for a Metadata Account
                if (parsed.type === 'mint') {
                  const metadata = await getMetadata(pubkey, url)
                  console.log('metadata22:', metadata)
                  if (metadata) {
                    // We have a valid Metadata account. Try and pull edition data.
                    const editionData = await getEditionData(pubkey, url)
                    nftData = { metadata, editionData }
                  }
                }
                data = {
                  program: result.data.program,
                  parsed,
                  nftData,
                }
                break
              default:
                data = undefined
            }
          } catch (error) {}
        }

        details = {
          space,
          executable: result.executable,
          owner: result.owner,
          data,
        }
      }
      data = { pubkey, lamports, details }
      fetchStatus = FetchStatus.Fetched
    } catch (error) {
      fetchStatus = FetchStatus.FetchFailed
    }
    dispatch({
      type: ActionType.Update,
      status: fetchStatus,
      data,
      key: pubkey.toBase58(),
    })
  }
}

export function useAccounts() {
  const context = React.useContext(StateContext)
  if (!context) {
    throw new Error(`useAccounts must be used within a AccountsProvider`)
  }
  return context.entries
}

export function useAccountInfo(address: string | undefined): Cache.CacheEntry<Account> | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useAccountInfo must be used within a AccountsProvider`)
  }
  if (address === undefined) return
  return context.entries[address]
}
export function useAccountsInfos(addresses: string[] | undefined): Cache.CacheEntry<Account>[] | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useAccountInfo must be used within a AccountsProvider`)
  }
  if (addresses === undefined) return
  return addresses.map((address) => context.entries[address])
}

export function useMintAccountInfo(address: string | undefined): MintAccountInfo | undefined {
  const accountInfo = useAccountInfo(address)
  return React.useMemo(() => {
    if (address === undefined) return

    try {
      const data = accountInfo?.data?.details?.data
      if (!data) return
      if (data.program !== 'spl-token' || data.parsed.type !== 'mint') {
        return
      }

      return create(data.parsed.info, MintAccountInfo)
    } catch (err) {
      return
    }
  }, [address, accountInfo])
}

export function metadataFromAccount(account?: Account): Metadata | undefined {
  const data = account?.details?.data
  // const isToken = data?.program === 'spl-token' && data?.parsed.type === 'mint'
  // const isNFT = isToken && data.nftData
  const metadata = (data as any)?.metadata
  return metadata ?? undefined
}

export function useTokenAccountInfo(address: string | undefined): TokenAccountInfo | undefined {
  const accountInfo = useAccountInfo(address)
  if (address === undefined) return

  try {
    const data = accountInfo?.data?.details?.data
    if (!data) return
    if (data.program !== 'spl-token' || data.parsed.type !== 'account') {
      return
    }

    return create(data.parsed.info, TokenAccountInfo)
  } catch (err) {
    return
  }
}

export function useFetchAccountInfo() {
  const dispatch = React.useContext(DispatchContext)
  if (!dispatch) {
    throw new Error(`useFetchAccountInfo must be used within a AccountsProvider`)
  }

  const url = providerURL()
  return React.useCallback(
    (address: string) => {
      fetchAccountInfo(dispatch, pubkeyFromStringForced(address), url)
    },
    [dispatch, url]
  )
}

export function useFetchAccountsInfos() {
  const dispatch = React.useContext(DispatchContext)
  if (!dispatch) {
    throw new Error(`useFetchAccountInfo must be used within a AccountsProvider`)
  }

  const url = providerURL()
  return React.useCallback(
    (pubkeys: PublicKey[]) => {
      fetchAccountsInfos(dispatch, pubkeys, url)
    },
    [dispatch, url]
  )
}
