import { PublicKey } from '@solana/web3.js'
import { pubkeyFromString } from 'apollo/utils'

export type Collection = {
  candyMachineIDs?: string[]
  creators?: string[]
  mints?: string[]
  description?: string
  discord?: string
  fromM?: string
  image?: string
  family?: string
  name?: string
  symbol?: string
  twitter?: string
  website?: string
}

export const decodeCollection = (res: any) => {
  const collection: Collection = {
    candyMachineIDs: res.candyMachineIDs,
    creators: res.creators,
    mints: res.mints,
    description: res.description,
    discord: res.discord,
    fromM: res.fromM,
    image: res.image,
    family: res.family,
    name: res.name,
    symbol: res.symbol,
    twitter: res.twitter,
    website: res.website,
  }
  return collection
}

// export type Mint = {
//   pubkey: PublicKey
//   metadata?: MetadataCombined
//   asks: PublicKey[]
//   offers: PublicKey[]
// }

// export const decodeFirebaseMint = (res: any) => {
//   const pubkey = pubkeyFzromString(res.pubkey)
//   const mint: Mint = {
//     pubkey: pubkey as PublicKey,
//     metadata: res.metadata,
//     asks: res.asks,
//     offers: res.offers,
//   }
//   return mint
// }
