import { ButtonSecondary, SavedIcon } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import HoverInlineText from 'components/HoverInlineText'
import MintArtDisplay from 'components/MintArtDisplay'
import MintRow from 'components/MintRow'
import Row, { RowFixed } from 'components/Row'
import UserRow from 'components/UserRow'
import { useFetchSearchResults } from 'hooks/search'
import useTheme from 'hooks/useTheme'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Hotkeys from 'react-hot-keys'
import { useHistory } from 'react-router-dom'
import { useSafeMints } from 'state/mints/hooks'
import styled from 'styled-components/macro'
import { HideSmall, TYPE } from 'theme'

const Container = styled.div`
  position: relative;
  z-index: 30;
  // width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(Row)`
  background-color: ${({ theme }) => theme.bg1};
  // bo
  padding: 10px 14px;
  width: 500px;
  height: 38px;
  border-radius: 20px;
  positon: relative;
  z-index: 9999;
  border: 1px solid ${({ theme }) => theme.bg2};

  @media (max-width: 1080px) {
    width: 100%;
  } ;
`

const StyledInput = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: none;
  border: none;
  width: 100%;
  font-size: 14px;
  outline: none;
  color: ${({ theme }) => theme.text1};

  ::placeholder {
    color: ${({ theme }) => theme.text3};
    font-size: 14px;
  }

  @media screen and (max-width: 640px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`

const Menu = styled.div<{ hide: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 9999;
  width: 800px;
  top: 50px;
  max-height: 600px;
  overflow: auto;
  right: 0;
  padding: 1.5rem;
  padding-bottom: 1.5rem;
  position: absolute;
  background: ${({ theme }) => theme.bg0};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 14px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.04);
  display: ${({ hide }) => hide && 'none'};
  border: 1px solid ${({ theme }) => theme.blue1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: absolute;
    margin-top: 4px;
    z-index: 9999;
    width: 100%;
    max-height: 400px;
  `};
`

const Blackout = styled.div`
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  z-index: 30;
  background-color: black;
  opacity: 0.2;
  left: 0;
  top: 0;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1.5fr repeat(3, 1fr);
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};
`

const Break = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.bg1};
  width: 100%;
`

const HoverText = styled.div<{ hide?: boolean | undefined }>`
  color: ${({ theme }) => theme.blue1};
  display: ${({ hide = false }) => hide && 'none'};
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const HoverRowLink = styled.div`
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const OptionButton = styled.div<{ enabled: boolean }>`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, enabled }) => (enabled ? theme.blue1 : 'transparent')};
  color: ${({ theme, enabled }) => (enabled ? theme.white : theme.blue1)};
  :hover {
    opacity: 0.6;
    cursor: pointer;
  }
`

const Search = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const history = useHistory()

  const ref = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)

  const theme = useTheme()

  const handleDown = useCallback(() => {
    if (ref != null && ref.current !== null) {
      ref.current.focus()
    }
  }, [])

  const [focused, setFocused] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState(false)
  const [value, setValue] = useState('')

  const { wallets, mints, calcedPubkey } = useFetchSearchResults(value)
  // const mintDatas = useSafeMints(mints)

  useEffect(() => {
    if (value !== '') {
      setFocused(true)
    } else {
      setFocused(false)
    }
  }, [value])

  const [walletsShown, setWalletsShown] = useState(3)
  const [mintsShown, setMintsShown] = useState(3)

  const handleClick = (e: any) => {
    if (!(menuRef.current && menuRef.current.contains(e.target)) && !(ref.current && ref.current.contains(e.target))) {
      setMintsShown(3)
      setWalletsShown(3)
      setShowMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  // watchlist
  // const [savedWallets, addSavedWallet] = useSavedWallets()
  // const [savedMints, addSavedMint] = useSavedMints()

  const handleNav = (to: string) => {
    setShowMenu(false)
    setMintsShown(3)
    setWalletsShown(3)
    history.push(to)
  }

  // get date for watchlist
  // const watchListWalletData = useWalletDatas(savedWallets)
  // const watchListMintData = useMintDatas(savedMints)

  // filter on view
  const [showWatchlist, setShowWatchlist] = useState(false)
  // const walletsForList = useMemo(
  //   () =>
  //     showWatchlist ? watchListWalletData ?? [] : wallets.sort((t0, t1) => (t0.volumeUSD > t1.volumeUSD ? -1 : 1)),
  //   [showWatchlist, wallets, watchListWalletData]
  // )
  // const mintForList = useMemo(
  //   () => (showWatchlist ? watchListMintData ?? [] : mints.sort((p0, p1) => (p0.volumeUSD > p1.volumeUSD ? -1 : 1))),
  //   [mints, showWatchlist, watchListMintData]
  // )

  return (
    <Hotkeys keyName="command+/" onKeyDown={handleDown}>
      {showMenu ? <Blackout /> : null}
      <Container>
        <Wrapper {...rest}>
          <StyledInput
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            placeholder="Search wallets"
            ref={ref}
            onFocus={() => {
              setFocused(true)
              setShowMenu(true)
            }}
            onBlur={() => setFocused(false)}
          />
          {!focused && <TYPE.gray pl="2px">⌘/</TYPE.gray>}
        </Wrapper>
        <Menu hide={!showMenu} ref={menuRef}>
          {/* {JSON.stringify(calcedPubkey)}
           */}
          <AutoColumn gap="lg">
            {calcedPubkey && (
              <ButtonSecondary
                onClick={() => {
                  handleNav('/user/' + calcedPubkey)
                }}
              >
                Go to {`${calcedPubkey}`}
              </ButtonSecondary>
            )}
            {wallets.length > 0 && (
              <>
                <ResponsiveGrid>
                  <TYPE.main>Wallets</TYPE.main>
                </ResponsiveGrid>
                {wallets.slice(0, walletsShown).map((t, i) => {
                  return (
                    <HoverRowLink onClick={() => handleNav('/user/' + t)} key={i}>
                      <ResponsiveGrid>
                        <UserRow userAddress={t}></UserRow>
                      </ResponsiveGrid>
                    </HoverRowLink>
                  )
                })}
                {wallets.length === 0 ? (
                  <TYPE.main>{showWatchlist ? 'Saved wallets will appear here' : 'No results'}</TYPE.main>
                ) : null}
                <HoverText
                  onClick={() => {
                    setWalletsShown(walletsShown + 5)
                  }}
                  hide={!(wallets.length > 3 && wallets.length >= walletsShown)}
                  ref={textRef}
                >
                  See more...
                </HoverText>
                <Break />
              </>
            )}
            {/* {mints.length > 0 && (
              <>
                <ResponsiveGrid>
                  <TYPE.main>Mints</TYPE.main>
                </ResponsiveGrid>
                {mints.slice(0, mintsShown).map((m, i) => {
                  return (
                    <ResponsiveGrid key={i}>
                      <MintRow mintAddress={m} key={m}></MintRow>
                    </ResponsiveGrid>
                  )
                })}
                {mints.length === 0 ? (
                  <TYPE.main>{showWatchlist ? 'Saved mints will appear here' : 'No results'}</TYPE.main>
                ) : null}
                <HoverText
                  onClick={() => {
                    setMintsShown(mintsShown + 5)
                  }}
                  hide={!(mints.length > 3 && mints.length >= mintsShown)}
                  ref={textRef}
                >
                  See more...
                </HoverText>
              </>
            )} */}
          </AutoColumn>
        </Menu>
      </Container>
    </Hotkeys>
  )
}

export default Search
