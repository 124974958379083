import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useWallet } from '@solana/wallet-adapter-react'
// import { ReactComponent as Logo } from 'assets/svg/carmelDark.svg'
import { ReactComponent as LogoLightMode } from 'assets/svg/carmelDark.svg'
import { ReactComponent as LogoDarkMode } from 'assets/svg/carmelLight.svg'
import { ConnectButton } from 'components/ConnectButton'
import Search from 'components/Search'
import UserLogo from 'components/UserLogo'
import { SupportedChainId } from 'constants/chains'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal, useWalletModalToggle } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { ExternalLink } from '../../theme'
// import LogoLightMode from '../assets/svg/carmelDark.svg'
// import LogoDarkMode from '../assets/svg/carmelLight.svg'
import ClaimModal from '../claim/ClaimModal'
import { CardNoise } from '../earn/styled'
import Menu from '../Menu'
import Row from '../Row'
import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import NavDropdown from './NavDropdown'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  // ${({ theme }) => theme.mediaWidth.upToLarge`
  //   grid-template-columns: 48px 1fr 1fr;
  // `};

  // ${({ theme }) => theme.mediaWidth.upToMedium`
  //   padding:  1rem;
  //   grid-template-columns: 1fr 1fr;
  // `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr;
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.bg2};
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  // transition: transform 0.3s ease;
  // :hover {
  //   transform: rotate(-5deg);
  // }

  position: relative;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }
`

export default function HeaderTwo() {
  const [darkMode] = useDarkModeManager()
  const theme = useTheme()

  const toggleClaimModal = useToggleSelfClaimModal()

  const showClaimPopup = useShowClaimPopup()

  const toggleConnectWallet = useWalletModalToggle()

  const scrollY = useScrollPosition()

  const { publicKey } = useWallet()
  const profileLink = useMemo(() => {
    return publicKey ? `/user/${publicKey}` : ''
  }, [publicKey])

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      <ClaimModal />
      <Title href=".">
        <UniIcon>
          {darkMode ? (
            <LogoDarkMode width="80px" height="100%" title="logo"></LogoDarkMode>
          ) : (
            <LogoLightMode width="80px" height="100%" title="logo"></LogoLightMode>
          )}
          {/* <Logo fill={darkMode ? theme.green1 : theme.green1} width="80px" height="100%" title="logo" /> */}
        </UniIcon>
      </Title>
      <HeaderLinks>
        {/* <StyledNavLink id={`swap-nav-link`} to={'/explore'}>
          <Trans>Explore</Trans>
        </StyledNavLink> */}
        {/* <NavDropdown /> */}
        <StyledNavLink
          id={`home-nav-link`}
          to={'/top'}
          isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('recent')}
        >
          <Trans>Top</Trans>
        </StyledNavLink>
        <StyledNavLink
          id={`home-nav-link`}
          to={'/recent'}
          isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('top')}
        >
          <Trans>Recent</Trans>
        </StyledNavLink>

        <StyledNavLink
          id={`home-nav-link`}
          to={'/home'}
          onClick={() => {
            if (!publicKey) {
              toggleConnectWallet()
            }
          }}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            (pathname.startsWith('/user') && !pathname.startsWith(profileLink)) ||
            pathname == '/' ||
            pathname.startsWith('/home')
          }
        >
          <Trans>Feed</Trans>
        </StyledNavLink>
        {/* <NavDropdown /> */}
        {publicKey && (
          <StyledNavLink
            id={`home-nav-link`}
            to={profileLink}
            isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith(profileLink)}
          >
            <Trans>Profile</Trans>
          </StyledNavLink>
        )}
      </HeaderLinks>

      <HeaderControls>
        <HeaderElement>
          {/* <Menu /> */}
          <Search></Search>
        </HeaderElement>
        <HeaderElement>
          {publicKey ? (
            <UserLogo border={true} size="28px" userAddress={publicKey.toBase58()}></UserLogo>
          ) : (
            <ConnectButton></ConnectButton>
          )}
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
