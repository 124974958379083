import Spline from '@splinetool/react-spline'
import LogoLightMode from 'assets/svg/carmelDark.svg'
import LogoDarkMode from 'assets/svg/carmelLight.svg'
import useTheme from 'hooks/useTheme'
import { useIsDarkMode } from 'state/user/hooks'
import styled, { css, keyframes } from 'styled-components/macro'
// import { Bars } from 'svg-loaders-react'
import { CustomLightSpinner } from 'theme'

import SpinningCircles from './spinning-circles.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div<{ fill: number; height?: string }>`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, fill }) => (fill ? (theme.darkMode ? 'black' : 'white') : 'transparent')};
  height: 100%;
  width: 100%;
  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 72px;
  }
`
export const LocalLoader = ({ fill }: { fill: boolean }) => {
  const darkMode = useIsDarkMode()
  return (
    <Wrapper fill={fill ? 1 : 0}>
      <AnimatedImg>
        <img src={darkMode ? LogoDarkMode : LogoLightMode} alt="loading-icon" />
      </AnimatedImg>
    </Wrapper>
  )
}

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.primary1};
  }
`

const SplineContainer = styled.div<{ size: string; stroke?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  max-width: ${({ size }) => size};
  max-height: ${({ size }) => size};
  pointer-events: none;
  overflow: hidden;
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = '14px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  const { darkMode } = useTheme()
  // return (
  //   <Wrapper fill={1}>
  //     <AnimatedImg>
  //       <img src={darkMode ? LogoDarkMode : LogoLightMode} alt="loading-icon" />
  //     </AnimatedImg>
  //   </Wrapper>
  // )
  // return <CustomLightSpinner src={SpinningCircles} size={size} {...rest} />
  return (
    <SplineContainer size={size}>
      <CustomLightSpinner size={size} />
      {/* <Spline width={size} height={size} scene="https://prod.spline.design/QcJgfDtBEV3MHlrx/scene.splinecode" /> */}
      {/* <Spline width={size} height={size} scene="https://prod.spline.design/dleFUBl3Tq8CdO9S/scene.splinecode" /> */}
    </SplineContainer>
  )
  // return (
  // <Bars width={size}></Bars>
  // <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
  // <svg width="58" height="58" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
  //   <g fill="none" fillRule="evenodd">
  //     <g transform="translate(2 1)" stroke="#FFF" strokeWidth="1.5">
  //       <circle cx="42.601" cy="11.462" r="5" fillOpacity="1" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="1;0;0;0;0;0;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="49.063" cy="27.063" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;1;0;0;0;0;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="42.601" cy="42.663" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;1;0;0;0;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="27" cy="49.125" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;0;1;0;0;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="11.399" cy="42.663" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;0;0;1;0;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="4.938" cy="27.063" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;0;0;0;1;0;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="11.399" cy="11.462" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;0;0;0;0;1;0"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //       <circle cx="27" cy="5" r="5" fillOpacity="0" fill="#fff">
  //         <animate
  //           attributeName="fillOpacity"
  //           begin="0s"
  //           dur="1.3s"
  //           values="0;0;0;0;0;0;0;1"
  //           calcMode="linear"
  //           repeatCount="indefinite"
  //         />
  //       </circle>
  //     </g>
  //   </g>
  // </svg>
  // </StyledSVG>
  // )
}

const loadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const LoadingRows = styled.div`
  display: grid;
  min-width: 75%;
  max-width: 100%;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.8em;
  grid-template-columns: repeat(3, 1fr);
  & > div {
    animation: ${loadingAnimation} 1.5s infinite;
    animation-fill-mode: both;
    background: linear-gradient(
      to left,
      ${({ theme }) => theme.bg1} 25%,
      ${({ theme }) => theme.bg2} 50%,
      ${({ theme }) => theme.bg1} 75%
    );
    background-size: 400%;
    border-radius: 12px;
    height: 2.4em;
    will-change: background-position;
  }
  & > div:nth-child(4n + 1) {
    grid-column: 1 / 3;
  }
  & > div:nth-child(4n) {
    grid-column: 3 / 4;
    margin-bottom: 2em;
  }
`
