import { nanoid } from '@reduxjs/toolkit'
import { Connection, PublicKey } from '@solana/web3.js'
import { decodeSale } from 'apollo/types'
import { getExtendedArt, pubkeyFromStringForced } from 'apollo/utils'
import { useApolloProgram } from 'components/SolanaManager'
import { providerURL } from 'hooks/web3'
import { asksFromSale, bidsFromSale, ownerFromSale } from 'providers/accounts/utils/bundleMints'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { assert, create, is } from 'superstruct'
import { MetadataCombined, MetadataDetails, Sale, SaleData } from 'validators/accounts/sales'

import { fetchChainSaleRequest } from './actions'

const url = providerURL()
const connection = new Connection(url)

export function useFetchSaleFromChain(): (salePubkey: PublicKey) => Promise<SaleData> {
  const dispatch = useAppDispatch()
  const apollo = useApolloProgram()

  // const sale = useSaleAccount(salePubkey.toBase58())
  return useCallback(
    async (salePubkey: PublicKey) => {
      const requestID = nanoid()
      dispatch(fetchChainSaleRequest.pending({ saleAddress: salePubkey.toBase58(), requestID }))
      try {
        const saleRes = await apollo.account.sale.fetch(salePubkey)
        console.log('useFetchSaleFromChain saleRes', saleRes)
        const sale: Sale = decodeSale(saleRes)
        console.log('useFetchSaleFromChain sale', sale)
        const metadata = (await metadataFromMint(pubkeyFromStringForced(sale.tokenMint))) as MetadataCombined
        const newData: MetadataDetails = {
          symbol: metadata.data.symbol,
          name: metadata.data.name,
          uri: metadata.data.uri,
          sellerFeeBasisPoints: metadata.data.sellerFeeBasisPoints,
          creators: metadata.data.creators.map((creator) => {
            return {
              address: creator.address,
              share: creator.share,
              verified: creator.verified,
            }
          }),
        }
        const m2 = create({ ...metadata, data: newData }, MetadataCombined)
        // console.log('useFetchSaleFromChain newData', newData as MetadataDetails)
        console.log('useFetchSaleFromChain metadata', metadata)
        console.log('useFetchSaleFromChain m2', m2)
        const owner = await ownerFromSale(connection, pubkeyFromStringForced(sale.receiptMint))
        console.log('useFetchSaleFromChain owner', owner)
        const asks = await asksFromSale(connection, apollo, salePubkey)
        const bids = await bidsFromSale(connection, apollo, salePubkey)
        const saleData: SaleData = {
          pubkey: salePubkey.toBase58(),
          sale,
          metadata: m2,
          asks: asks ?? [],
          bids: bids ?? [],
          owner: owner ? owner.toBase58() : undefined,
        }
        dispatch(fetchChainSaleRequest.fulfilled({ requestID, saleData }))
        return saleData
      } catch (e) {
        console.error(e)
        dispatch(fetchChainSaleRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [apollo, dispatch]
  )
}

export async function metadataFromMint(sale: PublicKey): Promise<MetadataCombined | undefined> {
  const metadata = await getMetadata(sale, providerURL())
  if (metadata) {
    const extended = await getExtendedArt(metadata)
    if (!extended) {
      console.error('Metadata not found for ' + sale.toBase58())
    }
    return {
      ...metadata,
      extended: extended ?? null,
    }
  }
  return undefined
}
