import { useAnchorWallet } from '@solana/wallet-adapter-react'
import Spline from '@splinetool/react-spline'
import ActionCard from 'components/ActionCard'
import ActionsFeed from 'components/ActionsFeed'
import { DarkCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import MintsCollectionFilter from 'components/filter/MintsCollectionFilter'
import MintsPriceFilter from 'components/filter/MintsPriceFilter'
import MintsStatusFilter from 'components/filter/MintsStatusFilter'
import Loader from 'components/Loader'
import useTheme from 'hooks/useTheme'
import { transparentize } from 'polished'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, RouteComponentProps, useLocation } from 'react-router-dom'
import { addRequiredActionQueries } from 'state/actions/actions'
import { useActionQueryActions, useAllActions } from 'state/actions/hooks'
import { ActionType, ALL_ACTIONS_TYPES, RequiredActionQuery } from 'state/actions/reducer'
import { addRequiredFeeds } from 'state/feeds/actions'
import { useSafeFeed } from 'state/feeds/hooks'
import { addRequiredUsers } from 'state/users/actions'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

export const ContentPageWrapper = styled.div`
  // height: fit-content;
  position: relative;
  min-width: 250px;
  // max-width: 500px;
  width: 100%;
  // overflow-x: visible !important;
  // overflow: scroll;
`

export const Grid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export const CollectionRowGrid = styled(DarkCard)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: center;
  // align-content: space-between;
  grid-template-columns: 15px 250px 75px 150px 110px 60px 50px;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

const SearchSettingsWrapper = styled(Column)`
  position: relative;
  top: 0;
  width: 100%;
  padding: 0px 0px 10px 0px;
`
const activeClassName = 'ACTIVE'

export const ExploreToggleElement = styled(NavLink).attrs({
  activeClassName,
})`
  display: flex;
  align-items: center;

  padding: 6px 8px;
  baction-radius: 6px;
  justify-content: start;
  height: 100%;
  background: ${({ theme }) => 'none'};
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme }) => theme.text2};
  }
  font-size: 14px;

  &.${activeClassName} {
    background: ${({ theme }) => theme.bg1};
    color: ${({ theme }) => theme.text2};
    font-weight: 700;
  }
  text-decoration: none;
`

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  baction-bottom: 1px solid ${({ theme }) => transparentize(0, theme.bg3)};
  background: ${({ theme }) => transparentize(0.9, theme.bg1)};
`
export const TopBarLeftWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`
export const FiltersWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`

enum ExploreType {
  NFTs = 'all',
  Collections = 'collections',
  Activity = 'activity',
}
const DEFAULT_FEED = 'TO7XMj8Gt2kyX4PXzq5C'

export default function AboutPage({
  match: {
    params: { type: typeFromURL },
  },
}: RouteComponentProps<{ type?: string }>) {
  const wallet = useAnchorWallet()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const theme = useTheme()
  const debouncedSetStateRef = useRef(null)

  useEffect(() => {
    dispatch(addRequiredFeeds([DEFAULT_FEED]))
  }, [dispatch])
  const feed = useSafeFeed(DEFAULT_FEED)

  const wallets = useMemo(() => feed?.wallets ?? [], [feed])
  useEffect(() => {
    if (wallet?.publicKey) {
      console.log('mintissaved dispatching addRequiredUsers', wallet.publicKey)
      dispatch(addRequiredUsers([wallet?.publicKey.toBase58()]))
    }
  }, [dispatch, wallet?.publicKey])

  // const actionQueryRequest = useMemo(() => new RequiredActionQuery(undefined, []), [])
  // console.log('ExplorePage actionQueryRequest', actionQueryRequest)

  // useEffect(() => {
  //   if (actionQueryRequest) {
  //     dispatch(addRequiredActionQueries([actionQueryRequest]))
  //   }
  // }, [dispatch, actionQueryRequest])

  const displayType = useMemo(() => {
    switch (pathname) {
      case '/explore/nfts':
        return ExploreType.NFTs
      case '/explore/collections':
        return ExploreType.Collections
      case '/explore/activity':
        return ExploreType.Activity
      default:
        return ExploreType.NFTs
    }
  }, [pathname])

  return (
    <ContentPageWrapper>
      About
      <Spline height={1024} width={1024} scene="https://prod.spline.design/QcJgfDtBEV3MHlrx/scene.splinecode" />
    </ContentPageWrapper>
  )
}
