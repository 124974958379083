import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { pubkeyFromString } from 'apollo/utils'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QueryConstraint,
  setDoc,
  where,
} from 'firebase/firestore'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { MintData } from 'state/mints/models'
import { create } from 'superstruct'

import { fetchFirebaseOrderQueryRequest, fetchFirebaseOrderRequest, updateFirebaseOrderRequest } from './actions'
import { Order } from './models'
import { OrderQuery, RequiredOrderQuery } from './reducer'

export const ORDERS_COLLECTION = 'orders2'

export function useFetchFirebaseOrder(): (orderPDA: string) => Promise<Order | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (orderPDA: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseOrderRequest.pending({ orderPDA, requestID }))
      try {
        const db = getFirestore()
        const orderSnap = await getDoc(doc(db, ORDERS_COLLECTION, orderPDA))
        console.log('firebase order snap: ', orderSnap)
        if (orderSnap.exists()) {
          console.log('firebase Document data:', orderSnap.data())
          const order = decodeFirebaseOrder(orderSnap.data())
          console.log('firebase order decoded: ', order)
          dispatch(fetchFirebaseOrderRequest.fulfilled({ requestID, order }))
          return order
        } else {
          dispatch(fetchFirebaseOrderRequest.rejected({ requestID, orderPDA, errorMessage: 'not found' }))
          // throw new Error('Order not found')
          return undefined
          // throw new Error(`Order ${orderPDA} does not exist`)
        }
        // return order
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseOrderRequest.rejected({ requestID, orderPDA, errorMessage: e.message }))
        throw e
      }
    },
    [dispatch]
  )
}

export function useFetchFirebaseOrdersQuery(): (roq: RequiredOrderQuery) => Promise<string[] | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (roq: RequiredOrderQuery) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseOrderQueryRequest.pending({ queryKey: roq.key, requestID }))
      try {
        const db = getFirestore()
        const queryConstraints = []
        console.log('useFetchFirebaseOrdersQuery roq: ', roq)
        if (roq.all) {
          // queryConstraints.push(where('assetMint', 'not-in', ['n/a']))
        } else {
          if (roq.assetMints) {
            queryConstraints.push(where('assetMint', 'in', roq.assetMints))
          }
          if (roq.orderSide) {
            queryConstraints.push(where('side', '==', roq.orderSide))
          }
          if (roq.onlyLive) {
            queryConstraints.push(where('expirationDate', '>', Date.now()))
            queryConstraints.push(where('revoked', '==', false))
            queryConstraints.push(where('matched', '==', false))
          }
        }
        console.log('queryConstraints: ', queryConstraints)
        // queryConstraints.push(where('expiration', '<', roq.orderSide))
        const q = query(collection(db, ORDERS_COLLECTION), ...queryConstraints)
        const querySnapshot = await getDocs(q)
        const orders = querySnapshot.docs.map((doc) => {
          return decodeFirebaseOrder(doc.data())
        })
        console.log(`firebase query orders (${roq.key}): `, orders)
        dispatch(fetchFirebaseOrderQueryRequest.fulfilled({ requestID, queryKey: roq.key, orders }))
        return orders.map((order) => order.delegatePDA)
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseOrderQueryRequest.rejected({ queryKey: roq.key, requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

const encodeOrderObject = (order: Order) => {
  return order
  console.log('encodeOrderObject: ', order)
}

export const decodeFirebaseOrder = (res: any) => {
  console.log('decodeFirebaseOrder: ', res)
  return create(res, Order)
}

export function useUpdateFirebaseOrder(): (order: Order, requestIDFromChain?: string) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (order: Order, requestIDFromChain?: string) => {
      const requestID = requestIDFromChain ?? nanoid()
      dispatch(updateFirebaseOrderRequest.pending({ orderPDA: order.delegatePDA, requestID }))
      try {
        const db = getFirestore()
        const orderObj = encodeOrderObject(order)
        console.log('firebase uploading order: ', orderObj)
        setDoc(doc(db, ORDERS_COLLECTION, order.delegatePDA), orderObj).then(() => {
          console.log('firebase order uploaded')
          dispatch(updateFirebaseOrderRequest.fulfilled({ requestID, order }))
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseOrderRequest.rejected({ orderPDA: order.delegatePDA, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}
