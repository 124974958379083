import { Connection, ParsedConfirmedTransaction, TransactionSignature } from '@solana/web3.js'
import { providerURL } from 'hooks/web3'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import React from 'react'

export interface Details {
  transaction?: ParsedConfirmedTransaction | null
}

type State = Cache.State<Details>
type Dispatch = Cache.Dispatch<Details>

export const StateContext = React.createContext<State | undefined>(undefined)
export const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

type DetailsProviderProps = { children: React.ReactNode }
export function DetailsProvider({ children }: DetailsProviderProps) {
  const url = providerURL()
  const [state, dispatch] = Cache.useReducer<Details>()

  React.useEffect(() => {
    dispatch({ type: ActionType.Clear, url })
  }, [dispatch, url])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

async function fetchDetails(dispatch: Dispatch, signature: TransactionSignature, url: string) {
  dispatch({
    type: ActionType.Update,
    status: FetchStatus.Fetching,
    key: signature,
  })

  let fetchStatus
  let transaction
  try {
    transaction = await new Connection(url).getParsedConfirmedTransaction(signature, 'confirmed')
    fetchStatus = FetchStatus.Fetched
  } catch (error) {
    fetchStatus = FetchStatus.FetchFailed
  }
  dispatch({
    type: ActionType.Update,
    status: fetchStatus,
    key: signature,
    data: { transaction },
  })
}

export function useFetchTransactionDetails() {
  const dispatch = React.useContext(DispatchContext)
  if (!dispatch) {
    throw new Error(`useFetchTransactionDetails must be used within a TransactionsProvider`)
  }

  const url = providerURL()
  return React.useCallback(
    (signature: TransactionSignature) => {
      url && fetchDetails(dispatch, signature, url)
    },
    [dispatch, url]
  )
}

export function useTransactionDetails(signature?: TransactionSignature): Cache.CacheEntry<Details> | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useTransactionDetails must be used within a TransactionsProvider`)
  }

  return signature ? context.entries[signature] : undefined
}

export type TransactionDetailsCache = {
  [key: string]: Cache.CacheEntry<Details>
}
export function useTransactionDetailsCache(): TransactionDetailsCache {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useTransactionDetailsCache must be used within a TransactionsProvider`)
  }

  return context.entries
}
