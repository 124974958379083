import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { createFirebaseWalletRequest } from 'state/wallets/actions'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import {
  addRequiredActionQueries,
  addRequiredActions,
  fetchFirebaseActionQueryRequest,
  fetchFirebaseActionRequest,
  fetchMoreForActionQuery,
  refreshAction,
  refreshQuery,
  updateFirebaseActionRequest,
} from './actions'
import { Action } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
  txRes?: string
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export type ChainActionStatus = {
  actionPDA: string
  found: boolean
  revoked: boolean
}

export type ActionQuery = {
  queryKey: string
  actionIDs: string[]
  pFetched: number
  last: QueryDocumentSnapshot<DocumentData> | null
  foundEnd: boolean
}

export enum ActionType {
  mint = 'mint',
  transfer = 'transfer',
  cancelListing = 'cancelListing',
  purchase = 'purchase',
  makeOffer = 'makeOffer',
  makeListing = 'makeListing',
}

export const ALL_ACTIONS_TYPES = [
  ActionType.purchase,
  ActionType.makeOffer,
  ActionType.makeListing,
  ActionType.cancelListing,
  ActionType.mint,
  ActionType.transfer,
]

export class RequiredActionQuery {
  public wallets: string[] | null
  public feed: string | null
  public type: ActionType | null
  public key: string
  public pToFetch: number

  // public live: boolean

  constructor(feed?: string, type?: ActionType, wallets?: string[]) {
    this.wallets = wallets || null
    this.feed = feed || null
    this.type = type || null
    this.key = `${this.feed}-${this.wallets}-${this.type}`
    this.pToFetch = 1
  }
}

export interface ActionsState {
  readonly requiredActions: string[]
  readonly firebaseActions: Action[]
  readonly firebaseRequests: Request[]
  readonly actionQueries: ActionQuery[]
  readonly requiredActionQueries: RequiredActionQuery[]
  readonly queryRequests: Request[]
}

const initialState: ActionsState = {
  requiredActions: [],
  firebaseActions: [],
  firebaseRequests: [],
  actionQueries: [],
  requiredActionQueries: [],
  queryRequests: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshAction, (state, { payload: actionId }) => {
      console.log('refreshAction', actionId)
      state = {
        ...state,
        firebaseActions: state.firebaseActions.filter((a) => a.id !== actionId),
      }
      return state
    })
    .addCase(addRequiredActions, (state, { payload: actions }) => {
      console.log('addRequiredActions', actions)
      console.log('state', state)
      const set = [...state.requiredActions, ...actions]
      const filtered = set.filter((item, index) => set.indexOf(item) === index)
      console.log('addRequiredActions set', filtered)
      state = {
        ...state,
        requiredActions: filtered,
      }
      return state
    })
    .addCase(addRequiredActionQueries, (state, { payload: actionQueries }) => {
      console.log('addRequiredActionQueries', actionQueries)
      const newKeys = actionQueries.map((oq) => oq.key)
      const set = state.requiredActionQueries.filter((rq) => !newKeys.includes(rq.key)).concat(actionQueries)
      console.log('addRequiredActionQueries set', set)
      state = {
        ...state,
        requiredActionQueries: set,
      }
      return state
    })
    .addCase(fetchFirebaseActionRequest.pending, (state, { payload: { requestID } }) => {
      console.log('aState fetch action request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests,
          { requestID, status: RequestStatus.Pending, type: 'fetch', timestamp: Date.now() },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseActionRequest.fulfilled, (state, { payload: { requestID, action } }) => {
      console.log('aState fetch action request fulfilled', { state, requestID })
      state = {
        ...state,
        firebaseActions: [...state.firebaseActions.filter((a) => a.id != action.id), action],
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseActionRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetch action request rejected', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseActionQueryRequest.pending, (state, { payload: { requestID, queryKey } }) => {
      console.log('aState fetchActionRequest pending', { state, requestID })
      state = {
        ...state,
        queryRequests: state.queryRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: queryKey,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(
      fetchFirebaseActionQueryRequest.fulfilled,
      (state, { payload: { requestID, actions, queryKey, page, last, foundEnd } }) => {
        console.log('aState fetchFirebaseActionQueryRequest fulfilled', {
          state,
          requestID,
          actions,
        })
        const oldActionQuery = state.actionQueries.find((aq) => aq.queryKey === queryKey)
        const oldActionIDs = oldActionQuery?.actionIDs ?? []
        const newActionIDs = [...oldActionIDs, ...actions.map((a) => a.id)]
        state = {
          ...state,
          queryRequests: [
            ...state.queryRequests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'fetch',
              context: queryKey,
              status: RequestStatus.Fulfilled,
              timestamp: Date.now(),
            },
          ],
          firebaseActions: [
            ...state.firebaseActions.filter((s) => !actions.map((a) => a.id).includes(s.id)),
            ...actions,
          ],
          actionQueries: [
            ...state.actionQueries.filter((s) => s.queryKey != queryKey),
            {
              queryKey,
              actionIDs: newActionIDs,
              pFetched: page,
              last,
              foundEnd,
            },
          ],
        }
        return state
      }
    )
    .addCase(fetchFirebaseActionQueryRequest.rejected, (state, { payload: { requestID, errorMessage, queryKey } }) => {
      console.log('aState fetchActionRequest rejected', { state, requestID, queryKey })
      state = {
        ...state,
        queryRequests: [
          ...state.queryRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: queryKey,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchMoreForActionQuery, (state, { payload: { queryKey } }) => {
      console.log('aState fetchMoreForActionQuery', { state, queryKey })
      const oldRequiredActionQuery = state.requiredActionQueries.find((aq) => aq.key === queryKey)
      const oldQuery = state.actionQueries.find((aq) => aq.queryKey === queryKey)
      if (
        (oldRequiredActionQuery && oldQuery && oldQuery.pFetched < oldRequiredActionQuery?.pToFetch) ||
        oldQuery?.foundEnd
      ) {
        return
      }
      if (oldRequiredActionQuery) {
        state = {
          ...state,
          requiredActionQueries: [
            ...state.requiredActionQueries.filter((rq) => rq.key !== queryKey),
            {
              ...oldRequiredActionQuery,
              pToFetch: oldRequiredActionQuery.pToFetch + 1,
            },
          ],
        }
      }
      return state
    })
    .addCase(refreshQuery, (state, { payload: queryKey }) => {
      console.log('aState refreshQuery', { state, queryKey })
      const oldRequiredActionQuery = state.requiredActionQueries.find((aq) => aq.key === queryKey)
      const oldQuery = state.actionQueries.find((aq) => aq.queryKey === queryKey)
      if (oldRequiredActionQuery && oldQuery) {
        state = {
          ...state,
          requiredActionQueries: [
            ...state.requiredActionQueries.filter((rq) => rq.key !== queryKey),
            {
              ...oldRequiredActionQuery,
              pToFetch: 1,
            },
          ],
          actionQueries: [
            ...state.actionQueries.filter((aq) => aq.queryKey != queryKey),
            {
              ...oldQuery,
              pFetched: 0,
              actionIDs: [],
            },
          ],
        }
      }
      return state
    })
)
