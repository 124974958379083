import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { Wallet } from './models'
import { Request } from './reducer'

export function useRequiredWallets(): string[] {
  console.log('aState useRequiredWallets')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredWallets useAppSelector state: ', state)
    return state.wallets.requiredWallets
  })
}

export function useAllFirebaseWallets(): Wallet[] {
  console.log('aState useHomepageWallets')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageWallets useAppSelector state: ', state)
    return state.wallets.firebaseWallets
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.wallets.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.wallets.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseWallet(walletAddress?: string): Wallet | undefined {
  const allWallets = useAllFirebaseWallets()
  const wallet = useMemo(() => {
    return allWallets.find((wallet) => wallet.pubkey === walletAddress)
  }, [allWallets, walletAddress])
  return wallet
}

export function useSafeWallet(walletAddress?: string): Wallet | undefined {
  const firebaseWallet = useFirebaseWallet(walletAddress)
  // const chainWallet = useChainWallet(walletAddress)
  const allRequiredWallets = useRequiredWallets()

  return useMemo(() => {
    console.log('aState useSafeWallet useMemo firebaseWallet: ', firebaseWallet)
    // console.log('aState useSafeWallet useMemo chainWallet: ', chainWallet)
    return firebaseWallet
  }, [firebaseWallet])
}

export function useSafeWallets(walletAddresses?: string[]): Wallet[] {
  const firebaseWallets = useAllFirebaseWallets()
  // const requiredWallets = useRequiredWallets()
  const safeWallets = useMemo(() => {
    return walletAddresses ? firebaseWallets.filter((wallet) => walletAddresses.includes(wallet.pubkey)) : []
  }, [firebaseWallets, walletAddresses])
  return safeWallets
}
