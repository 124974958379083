import { Idl, Program } from '@project-serum/anchor'
import { PublicKey } from '@solana/web3.js'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import React from 'react'
import { decodeVault } from 'sax/types'
import { Vault } from 'sax/types'

export type VaultWithPubkey = {
  vault: Vault
  pubkey: PublicKey
}

// interface SaleAccounts {
//   accounts?: SaleAccountWithPubkey[];
// }

type State = Cache.State<VaultWithPubkey>
type Dispatch = Cache.Dispatch<VaultWithPubkey>

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

type ProviderProps = { children: React.ReactNode }
export function VaultsProvider({ children }: ProviderProps) {
  const [state, dispatch] = Cache.useReducer<VaultWithPubkey>()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

async function fetchVaultAccount(dispatch: Dispatch, pubkey: PublicKey, sax: Program) {
  const key = pubkey.toBase58()
  dispatch({
    type: ActionType.Update,
    key,
    status: FetchStatus.Fetching,
  })

  let status
  let data
  try {
    const vaultAccountRes = await sax.account.vault.fetch(pubkey)
    const vaultAccount: Vault = decodeVault(vaultAccountRes)
    // data = {
    //   tokens: value.map((accountInfo) => {
    // const parsedInfo = saleAccountRes.info;
    data = {
      vault: vaultAccount,
      pubkey,
    }
    //     return { info, pubkey: accountInfo.pubkey };
    //   }),
    // };
    status = FetchStatus.Fetched
  } catch (error) {
    // if (cluster !== Cluster.Custom) {
    //   reportError(error, { url });
    // }
    status = FetchStatus.FetchFailed
  }
  if (data && status) {
    dispatch({ type: ActionType.Update, status, data, key })
  }
}

async function fetchVaultsAccounts(dispatch: Dispatch, pubkeys: PublicKey[], sax: Program) {
  for (const pubkey of pubkeys) {
    const key = pubkey.toBase58()
    dispatch({
      type: ActionType.Update,
      key,
      status: FetchStatus.Fetching,
    })

    let status
    let data
    try {
      const vaultAccountRes = await sax.account.vault.fetch(pubkey)
      const vaultAccount: Vault = decodeVault(vaultAccountRes)
      // data = {
      //   tokens: value.map((accountInfo) => {
      // const parsedInfo = saleAccountRes.info;
      data = {
        vault: vaultAccount,
        pubkey,
      }
      //     return { info, pubkey: accountInfo.pubkey };
      //   }),
      // };
      status = FetchStatus.Fetched
    } catch (error) {
      // if (cluster !== Cluster.Custom) {
      //   reportError(error, { url });
      // }
      status = FetchStatus.FetchFailed
    }
    if (data && status) {
      dispatch({ type: ActionType.Update, status, data, key })
    }
  }
}

export function useVaultAccount(address: string): Cache.CacheEntry<VaultWithPubkey> | undefined {
  console.log('useVaultAccount', address)
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useVaultAccount must be used within a AccountsProvider`)
  }

  return context.entries[address]
}
export function useVaultsAccounts(addresses: string[]): Cache.CacheEntry<VaultWithPubkey>[] | undefined {
  const context = React.useContext(StateContext)
  console.log('useVaultsAccounts addresses', addresses)

  if (!context) {
    throw new Error(`useVaultAccount must be used within a AccountsProvider`)
  }

  return addresses.map((address) => context.entries[address])
}

export function useFetchVaultAccount(sax: Program<Idl>) {
  const dispatch = React.useContext(DispatchContext)
  if (!dispatch) {
    throw new Error(`useFetchVaultAccounts must be used within a AccountsProvider`)
  }

  return React.useCallback(
    (pubkey: PublicKey) => {
      fetchVaultAccount(dispatch, pubkey, sax)
    },
    [dispatch, sax]
  )
}

export function useFetchVaultsAccounts(sax: Program<Idl>) {
  const dispatch = React.useContext(DispatchContext)
  if (!dispatch) {
    throw new Error(`useFetchVaultAccounts must be used within a AccountsProvider`)
  }

  return React.useCallback(
    (pubkeys: PublicKey[]) => {
      fetchVaultsAccounts(dispatch, pubkeys, sax)
    },
    [dispatch, sax]
  )
}
