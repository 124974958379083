import { createReducer } from '@reduxjs/toolkit'
import { ExpiationDuration } from 'components/ExpirationDateSelector'
import { ORDER_SIDE } from 'state/orders/chain'
import { WRAPPED_SOL_MINT } from 'utils/utils'

import {
  resetCreateOrderState,
  resetToBuyOrder,
  resetToSellOrder,
  setAssetMint,
  setExpirationDuration,
  setListDate,
  setPaymentMint,
  typeAssetSizeInput,
  typePaymentSizeInput,
} from './actions'

export interface CreateOrderState {
  readonly assetMint: string
  readonly assetSize: string
  readonly paymentMint: string // for the case when there's no liquidity
  readonly paymentSize: string
  readonly listDate: number
  readonly expirationDuration: ExpiationDuration
  readonly side: ORDER_SIDE
}

const initialState: CreateOrderState = {
  assetMint: '',
  assetSize: '',
  paymentMint: WRAPPED_SOL_MINT.toBase58(),
  paymentSize: '',
  listDate: 0,
  expirationDuration: 0,
  side: ORDER_SIDE.SELL,
}

export default createReducer<CreateOrderState>(initialState, (builder) =>
  builder
    .addCase(resetCreateOrderState, () => initialState)
    .addCase(typeAssetSizeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        assetSize: typedValue,
      }
    })
    .addCase(typePaymentSizeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        paymentSize: typedValue,
      }
    })
    .addCase(resetToSellOrder, (state, { payload: { assetMint } }) => {
      return {
        ...initialState,
        assetMint,
        side: ORDER_SIDE.SELL,
      }
    })
    .addCase(resetToBuyOrder, (state, { payload: { assetMint } }) => {
      return {
        ...initialState,
        assetMint,
        side: ORDER_SIDE.BUY,
      }
    })
    .addCase(setAssetMint, (state, { payload: { assetMint } }) => {
      return {
        ...state,
        assetMint,
      }
    })
    .addCase(setPaymentMint, (state, { payload: { paymentMint } }) => {
      return {
        ...state,
        paymentMint,
      }
    })
    .addCase(setExpirationDuration, (state, { payload: { expirationDuration } }) => {
      return {
        ...state,
        expirationDuration,
      }
    })
    .addCase(setListDate, (state, { payload: { listDate } }) => {
      return {
        ...state,
        listDate,
      }
    })
)
