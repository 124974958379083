import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import { ButtonGray, ButtonRadioChecked } from 'components/Button'
import Card from 'components/Card'
import { AutoColumn } from 'components/Column'
import { AutoRow, RowBetween } from 'components/Row'
import { useFeeTierDistribution } from 'hooks/useFeeTierDistribution'
import { PoolState, usePools } from 'hooks/usePools'
import usePrevious from 'hooks/usePrevious'
import { DynamicSection } from 'pages/CreateSale/styled'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { Box } from 'rebass'
import styled, { keyframes } from 'styled-components/macro'
import { TYPE } from 'theme'

const pulse = (color: string) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    box-shadow: 0 0 0 2px ${color};
  }

  100% {
    box-shadow: 0 0 0 0 ${color};
  }
`

const ResponsiveText = styled(TYPE.label)`
  line-height: 14px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    line-height: 12px;
  `};
`

const FocusedOutlineCard = styled(Card)<{ pulsing: boolean }>`
  border: 1px solid ${({ theme }) => theme.bg2};
  animation: ${({ pulsing, theme }) => pulsing && pulse(theme.primary1)} 0.6s linear;
  align-self: center;
`

const FeeAmountLabel = {
  [FeeAmount.LOW]: {
    label: '0.05',
    description: <Trans>Best for stable pairs.</Trans>,
  },
  [FeeAmount.MEDIUM]: {
    label: '0.3',
    description: <Trans>Best for most pairs.</Trans>,
  },
  [FeeAmount.HIGH]: {
    label: '1',
    description: <Trans>Best for exotic pairs.</Trans>,
  },
}

function FeeTierPercentageBadge({
  feeAmount,
  distributions,
  poolState,
}: {
  feeAmount: FeeAmount
  distributions: ReturnType<typeof useFeeTierDistribution>['distributions']
  poolState: PoolState
}) {
  return (
    <Badge>
      <TYPE.label fontSize={12}>
        {!distributions || poolState === PoolState.NOT_EXISTS || poolState === PoolState.INVALID ? (
          <Trans>Not created</Trans>
        ) : distributions[feeAmount] !== undefined ? (
          <Trans>{distributions[feeAmount]?.toFixed(0)}% select</Trans>
        ) : (
          <Trans>No data</Trans>
        )}
      </TYPE.label>
    </Badge>
  )
}

export enum ExpiationDuration {
  NONE = 0,
  ONEHOUR = 1,
  ONEDAY = 24,
  THREEDAYS = 3 * 24,
  SEVENDAYS = 7 * 24,
  TENDAYS = 10 * 24,
  THIRTYDAYS = 30 * 24,
  SIXTYDAYS = 60 * 24,
  NINETYDAYS = 90 * 24,
}

export const datifyExpiration = (expiration: number) => {
  const now = new Date()
  console.log('datify expiration:', expiration)
  const date = new Date(now.getTime())
  date.setHours(date.getHours() + 2)
  console.log('datifyExpiration', expiration, date.toLocaleString())
  return date
}

export default function ExpirationDurationSelector({
  expirDuration,
  handleExpirDurationSelect,
}: {
  expirDuration?: ExpiationDuration
  handleExpirDurationSelect: (expirDuration: ExpiationDuration) => void
}) {
  // const [showOptions, setShowOptions] = useState(false)
  // const [pulsing, setPulsing] = useState(false)

  // const previousSaleType = usePrevious(SaleType)

  // const recommended = useRef(false)

  // setShowOptions(saleType !== undefined)

  // useEffect(() => {
  //   setShowOptions(saleType !== undefined)
  // }, [saleType])

  return (
    <AutoColumn gap="14px">
      <DynamicSection gap="md" disabled={false}>
        {/* <FocusedOutlineCard pulsing={pulsing} onAnimationEnd={() => setPulsing(false)}>
          <RowBetween>
            <AutoColumn id="add-liquidity-selected-fee">
              {!saleType ? (
                <>
                  <TYPE.label>
                    <Trans>Sale Type</Trans>
                  </TYPE.label>
                  <TYPE.main fontWeight={400} fontSize="12px" textAlign="left">
                    <Trans>Desc1</Trans>
                  </TYPE.main>
                </>
              ) : (
                <>
                  <TYPE.label className="selected-fee-label">
                    <Trans>{saleType == SaleType.AUCTION ? 'auctoin d2' : 'fixed d2'}</Trans>
                  </TYPE.label>
                  <Box style={{ width: 'fit-content', marginTop: '8px' }} className="selected-fee-percentage"></Box>
                </>
              )}
            </AutoColumn>

            <ButtonGray onClick={() => setShowOptions(!showOptions)} width="auto" padding="4px" $borderRadius="6px">
              {showOptions ? <Trans>Hide</Trans> : <Trans>Edit</Trans>}
            </ButtonGray>
          </RowBetween>
        </FocusedOutlineCard> */}

        {/* {showOptions && ( */}
        <AutoRow gap="3px">
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.NONE}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.NONE)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>None</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.ONEHOUR}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.ONEHOUR)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>1 Hour</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.ONEDAY}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.ONEDAY)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>1 Day</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.THREEDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.THREEDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>3 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.SEVENDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.SEVENDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>7 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.TENDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.TENDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>10 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.THIRTYDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.THIRTYDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>30 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.SIXTYDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.SIXTYDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>60 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
          <ButtonRadioChecked
            width="fit-content"
            active={expirDuration === ExpiationDuration.NINETYDAYS}
            onClick={() => handleExpirDurationSelect(ExpiationDuration.NINETYDAYS)}
          >
            <AutoColumn gap="sm" justify="flex-start">
              <AutoColumn justify="flex-start" gap="1px">
                <ResponsiveText>
                  <Trans>90 Days</Trans>
                </ResponsiveText>
              </AutoColumn>
            </AutoColumn>
          </ButtonRadioChecked>
        </AutoRow>
        {/* )} */}
      </DynamicSection>
    </AutoColumn>
  )
}
