import { TokenInfo, TokenInfoMap, TokenListContainer, TokenListProvider } from '@solana/spl-token-registry'
import React from 'react'

const TokenRegistryContext = React.createContext<TokenInfoMap>(new Map())

type ProviderProps = { children: React.ReactNode }

export function TokenRegistryProvider({ children }: ProviderProps) {
  const [tokenRegistry, setTokenRegistry] = React.useState<TokenInfoMap>(new Map())

  React.useEffect(() => {
    new TokenListProvider().resolve().then((tokens: TokenListContainer) => {
      // const tokenList = cluster === Cluster.Custom ? [] : tokens.filterByClusterSlug(clusterSlug(cluster)).getList()
      const tokenList = tokens.getList()

      setTokenRegistry(
        tokenList.reduce((map: TokenInfoMap, item: TokenInfo) => {
          map.set(item.address, item)
          return map
        }, new Map())
      )
    })
  }, [])

  return <TokenRegistryContext.Provider value={tokenRegistry}>{children}</TokenRegistryContext.Provider>
}

export function useTokenRegistry() {
  const tokenRegistry = React.useContext(TokenRegistryContext)

  if (!tokenRegistry) {
    throw new Error(`useTokenRegistry must be used within a MintsProvider`)
  }

  return { tokenRegistry }
}
