import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { Feed } from 'api/types'
import { Feed } from './models'

export const fetchFirebaseFeedRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ feedID: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; feed: Feed }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; feedID: string }>
}> = {
  pending: createAction('feeds/fetchFirebaseFeedRequest/pending'),
  fulfilled: createAction('feeds/fetchFirebaseFeedRequest/fulfilled'),
  rejected: createAction('feeds/fetchFirebaseFeedRequest/rejected'),
}

export const addRequiredFeeds = createAction<string[]>('feeds/addRequiredFeeds')
export const refreshFeed = createAction<string>('feeds/refreshFeed')

export const incrementFeedSavedByLocal = createAction<{ feedID: string; userAddress: string }>(
  'feeds/incrementFeedSavedByLocal'
)
export const decrementFeedSavedByLocal = createAction<{ feedID: string; userAddress: string }>(
  'feeds/decrementFeedSavedByLocal'
)
