import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import {
  addRequiredUsers,
  editSavedMint,
  fetchChainUserRequest,
  fetchFirebaseUserRequest,
  refreshUsers,
} from './actions'
import { updateFirebaseUserRequest } from './actions'
import { User } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface UsersState {
  readonly firebaseUsers: User[]
  readonly chainUsers: User[]
  readonly firebaseRequests: Request[]
  readonly onChainRequests: Request[]
  readonly requiredUsers: string[]
}

const initialState: UsersState = {
  firebaseUsers: [],
  chainUsers: [],
  firebaseRequests: [],
  onChainRequests: [],
  requiredUsers: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshUsers, (state, { payload: userAddresses }) => {
      state = {
        ...state,
        chainUsers: state.chainUsers.filter((user) => !userAddresses.includes(user.address)),
        requiredUsers: [...state.requiredUsers.filter((user) => !userAddresses.includes(user)), ...userAddresses],
      }
      return state
    })
    .addCase(addRequiredUsers, (state, { payload: users }) => {
      console.log('addRequiredUsers', users)
      console.log('state', state)
      const set = [...state.requiredUsers, ...users]
      const filtered = set.filter((item, index) => set.indexOf(item) === index)
      console.log('addRequiredUsers set', filtered)
      state = {
        ...state,
        requiredUsers: filtered,
      }
      return state
    })
    .addCase(editSavedMint, (state, { payload: { userAddress, mintAddress, saved } }) => {
      const user = state.chainUsers.find((u) => u.address === userAddress)
      if (!user) {
        return {
          ...state,
          requiredUsers: state.requiredUsers.includes(userAddress)
            ? state.requiredUsers
            : [...state.requiredUsers, userAddress],
        }
      }
      const filteredUsers = state.chainUsers.filter((user) => user.address !== userAddress)
      const isSaved = user.savedMints?.includes(mintAddress)
      if (saved && isSaved) {
        return state
      }
      if (saved && !isSaved) {
        return {
          ...state,
          chainUsers: [...filteredUsers, { ...user, savedMints: [...(user.savedMints ?? []), mintAddress] }],
        }
      }
      if (!saved && isSaved) {
        return {
          ...state,
          chainUsers: [...filteredUsers, { ...user, savedMints: user.savedMints?.filter((m) => m !== mintAddress) }],
        }
      }
      return state
    })
    .addCase(updateFirebaseUserRequest.pending, (state, { payload: { requestID, userAddress } }) => {
      console.log('aState create user request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          status: RequestStatus.Pending,
          type: 'update',
          context: userAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(updateFirebaseUserRequest.fulfilled, (state, { payload: { requestID, user, preserveOffChain } }) => {
      console.log('aState createUserRequest fulfilled', { state, requestID, user })
      const oldUser = state.firebaseUsers.find((u) => u.address == user.address)
      if (preserveOffChain && oldUser) {
        if (oldUser.savedMints) {
          user.savedMints = oldUser.savedMints
        }
        if (oldUser.profileMint) {
          user.profileMint = oldUser.profileMint
        }
      }
      console.log('aState updateFirebaseUserRequest.fulfilled', { preserveOffChain, oldUser, user })

      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: user.address,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseUsers: [...state.firebaseUsers.filter((u) => u.address !== user.address), user],
      }
      return state
    })
    .addCase(updateFirebaseUserRequest.rejected, (state, { payload: { requestID, errorMessage, userAddress } }) => {
      console.log('aState query pending', { state, requestID, userAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: userAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseUserRequest.pending, (state, { payload: { requestID, userAddress } }) => {
      console.log('aState fetchUserRequest pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: userAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseUserRequest.fulfilled, (state, { payload: { requestID, user } }) => {
      console.log('aState fetchFirebaseUserRequest fulfilled', { state, requestID, user: user.address })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: user.address,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseUsers: [...state.firebaseUsers.filter((u) => u.address !== user.address), user],
      }
      return state
    })
    .addCase(fetchFirebaseUserRequest.rejected, (state, { payload: { requestID, errorMessage, userAddress } }) => {
      console.log('aState fetchUserRequest rejected', { state, requestID, userAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: userAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchChainUserRequest.pending, (state, { payload: { requestID, userAddress } }) => {
      console.log('aState fetchUserRequest pending', { state, requestID })
      state = {
        ...state,
        onChainRequests: state.onChainRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: userAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchChainUserRequest.fulfilled, (state, { payload: { requestID, user } }) => {
      console.log('aState fetchChainUserRequest fulfilled', { state, requestID, user })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Fulfilled,
            context: user.address,
            timestamp: Date.now(),
          },
        ],
        chainUsers: state.chainUsers.filter((s) => s.address != user.address).concat(user),
        // chainUsers: state.chainUsers.concat(user),
      }
      return state
    })
    .addCase(fetchChainUserRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetchUserRequest rejected', { state, requestID })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
