import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { decodeFirebaseFeed } from './firebase'
import { Feed } from './models'
import { Request } from './reducer'

export function useRequiredFeeds(): string[] {
  console.log('aState useRequiredFeeds')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredFeeds useAppSelector state: ', state)
    return state.feeds.requiredFeeds
  })
}

export function useAllFirebaseFeeds(): Feed[] {
  console.log('aState useAllFirebaseFeeds')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageFeeds useAppSelector state: ', state)
    return state.feeds.feeds
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.feeds.requests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.feeds.requests.filter((r) => r.context === context)
  })
}

export function useFirebaseFeed(feedID?: string): Feed | undefined {
  const allFeeds = useAllFirebaseFeeds()
  const feed = useMemo(() => {
    return allFeeds.find((feed) => feed.id === feedID)
  }, [allFeeds, feedID])
  return feed
}

// export function useAllChainFeeds(): Feed[] {
//   // console.log('aState useAllChainFeeds')
//   return useAppSelector((state: AppState) => {
//     // console.log('aState useAllChainFeeds useAppSelector state: ', state)
//     return state.feeds.chainFeeds
//   })
// }

// export function useAllChainRequests(): Request[] {
//   console.log('aState useChainRequests')
//   return useAppSelector((state: AppState) => {
//     console.log('aState useChainRequests useAppSelector state: ', state)
//     return state.feeds.onChainRequests
//   })
// }

// export function useChainRequestsByContext(context?: string): Request[] {
//   console.log('aState useChainRequests')
//   return useAppSelector((state: AppState) => {
//     console.log('aState useChainRequests useAppSelector state: ', state)
//     return state.feeds.onChainRequests.filter((r) => r.context === context)
//   })
// }

// export function useChainFeed(feedID?: string): Feed | undefined {
//   const allFeeds = useAllChainFeeds()
//   const feed = useMemo(() => {
//     return allFeeds.find((feed) => feed.id === feedID)
//   }, [allFeeds, feedID])
//   return feed
// }

export function useSafeFeed(feedID?: string): Feed | undefined {
  const firebaseFeed = useFirebaseFeed(feedID)
  // const chainFeed = useChainFeed(feedID)

  return useMemo(() => {
    console.log('aState useSafeFeed useMemo firebaseFeed: ', feedID, firebaseFeed)
    // console.log('aState useSafeFeed useMemo chainFeed: ', chainFeed)
    return firebaseFeed ?? undefined
  }, [feedID, firebaseFeed])
}

export function useSafeFeeds(feedIDs?: string[]): Feed[] {
  const feeds = useAllFirebaseFeeds()
  // const chainFeeds = useAllChainFeeds()

  return useMemo(() => {
    console.log('aState useSafeFeeds useMemo feeds: ', feeds)
    // console.log('aState useSafeFeeds useMemo chainFeeds: ', chainFeeds)
    return feeds.filter((feed) => feedIDs?.includes(feed.id)) ?? []
  }, [feedIDs, feeds])
}
