import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { SaleData } from 'validators/accounts/sales'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import { addRequiredSales, fetchChainSaleRequest, fetchFirebaseSaleRequest, refreshSale } from './actions'
import { updateFirebaseSaleRequest } from './actions'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface SalesState {
  readonly firebaseSales: SaleData[]
  readonly chainSales: SaleData[]
  readonly firebaseRequests: Request[]
  readonly onChainRequests: Request[]
  readonly requiredSales: string[]
}

const initialState: SalesState = {
  firebaseSales: [],
  chainSales: [],
  firebaseRequests: [],
  onChainRequests: [],
  requiredSales: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshSale, (state, { payload: salePubkey }) => {
      console.log('refreshSale', salePubkey)
      console.log('state', state)
      state = {
        ...state,
        // requiredSales: [...state.requiredSales.filter((s) => !s.includes(salePubkey)), salePubkey],
        chainSales: state.chainSales.filter((sale) => sale.pubkey !== salePubkey),
      }
      return state
    })
    .addCase(addRequiredSales, (state, { payload: sales }) => {
      console.log('addRequiredSales', sales)
      console.log('state', state)
      const set = [...state.requiredSales, ...sales]
      const filtered = set.filter((item, index) => set.indexOf(item) === index)
      console.log('addRequiredSales set', filtered)
      state = {
        ...state,
        requiredSales: filtered,
      }
      return state
    })
    .addCase(updateFirebaseSaleRequest.pending, (state, { payload: { requestID, saleAddress } }) => {
      console.log('aState create sale request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          status: RequestStatus.Pending,
          type: 'update',
          context: saleAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(updateFirebaseSaleRequest.fulfilled, (state, { payload: { requestID, saleData } }) => {
      console.log('aState createSaleRequest fulfilled', { state, requestID, saleData })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: saleData.pubkey,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseSales: state.firebaseSales.filter((sale) => sale.pubkey != saleData.pubkey).concat(saleData),
      }
      return state
    })
    .addCase(updateFirebaseSaleRequest.rejected, (state, { payload: { requestID, errorMessage, saleAddress } }) => {
      console.log('aState query pending', { state, requestID, saleAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: saleAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseSaleRequest.pending, (state, { payload: { requestID, saleAddress } }) => {
      console.log('aState fetchSaleRequest pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: saleAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseSaleRequest.fulfilled, (state, { payload: { requestID, saleData } }) => {
      console.log('aState fetchFirebaseSaleRequest fulfilled', { state, requestID, sale: saleData.pubkey })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: saleData.pubkey,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseSales: state.firebaseSales.filter((s) => s.pubkey != saleData.pubkey).concat(saleData),
      }
      return state
    })
    .addCase(fetchFirebaseSaleRequest.rejected, (state, { payload: { requestID, errorMessage, saleAddress } }) => {
      console.log('aState fetchSaleRequest rejected', { state, requestID, saleAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: saleAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchChainSaleRequest.pending, (state, { payload: { requestID, saleAddress } }) => {
      console.log('aState fetchSaleRequest pending', { state, requestID })
      state = {
        ...state,
        onChainRequests: state.onChainRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: saleAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchChainSaleRequest.fulfilled, (state, { payload: { requestID, saleData } }) => {
      console.log('aState fetchChainSaleRequest fulfilled', { state, requestID, saleData })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Fulfilled,
            context: saleData.pubkey,
            timestamp: Date.now(),
          },
        ],
        chainSales: state.chainSales.filter((s) => s.pubkey != saleData.pubkey).concat(saleData),
        // chainSales: state.chainSales.concat(saleData),
      }
      return state
    })
    .addCase(fetchChainSaleRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetchSaleRequest rejected', { state, requestID })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
