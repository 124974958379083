import { Connection, SignatureResult, TransactionConfirmationStatus, TransactionSignature } from '@solana/web3.js'
import { fetchCollectionFromID, fetchCollections } from 'api/firebaseHooks'
import { Collection } from 'api/types'
import { QueryConstraint } from 'firebase/firestore'
import { providerURL } from 'hooks/web3'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import React from 'react'

import { fetchCollection } from './collections'

// export interface CollectionInfo {
//   collection: Collection
//   result: SignatureResult
// }

export interface CollectionInfo {
  id: string
  collection?: Collection
}

export interface CollectionQueryInfo {
  query: string
  collections?: CollectionInfo[]
}

export const stringifyQueryConstraint = (queryConstraint: QueryConstraint): string => JSON.stringify(queryConstraint)
export const indexifyQueryConstraint = (queryConstraints: QueryConstraint[]): string =>
  queryConstraints.map(stringifyQueryConstraint).sort().join(',')

type State = Cache.State<CollectionQueryInfo>
type Dispatch = Cache.Dispatch<CollectionQueryInfo>

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

type CollectionQueryProviderProps = { children: React.ReactNode }
export function CollectionQueryProvider({ children }: CollectionQueryProviderProps) {
  const [state, dispatch] = Cache.useReducer<CollectionQueryInfo>()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export async function fetchCollectionQuery(dispatch: Dispatch, query: QueryConstraint[]) {
  const key = indexifyQueryConstraint(query)
  console.log('fetchCollectionQuery key', key)
  dispatch({
    type: ActionType.Update,
    key,
    status: FetchStatus.Fetching,
  })

  let fetchStatus
  let data
  try {
    console.log('fetchCollectionQuery query', query)
    const collections = await fetchCollections(query)
    data = { query: JSON.stringify(query), collections }
    console.log('fetchCollections:', collections)
    fetchStatus = FetchStatus.Fetched
  } catch (error) {
    console.error('fetchCollections error:', error)
    fetchStatus = FetchStatus.FetchFailed
  }

  dispatch({
    type: ActionType.Update,
    key,
    status: fetchStatus,
    data,
  })
}

export function useCollectionQuery(query?: QueryConstraint[]): Cache.CacheEntry<CollectionQueryInfo> | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useCollectionQuery must be used within a CollectionQueryProvider`)
  }

  if (query === undefined) {
    return undefined
  }

  return context.entries[indexifyQueryConstraint(query)]
}

// export function useFetchCollectionQuery(refresh?: boolean) {
//   const dispatch = React.useContext(DispatchContext)
//   if (!dispatch) {
//     throw new Error(`useCollectionQuery must be used within a CollectionQueryProvider`)
//   }

//   return React.useCallback(
//     (query: QueryConstraint[]) => {
//       fetchCollectionQuery(dispatch, query)
//     },
//     [dispatch]
//   )
// }

export function useFetchCollectionQuery(refresh?: boolean) {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)
  if (!dispatch) {
    throw new Error(`useFetchStoreAccounts must be used within a AccountsProvider`)
  }

  return React.useCallback(
    (query: QueryConstraint[]) => {
      console.log('useFetchCollectionQuery')
      if (!refresh) {
        if (!state?.entries[indexifyQueryConstraint(query)]) {
          fetchCollectionQuery(dispatch, query)
        } else {
          console.log('already fetched!')
        }
      } else {
        fetchCollectionQuery(dispatch, query)
      }
    },
    [refresh, state?.entries, dispatch]
  )
}
