import { Trans } from '@lingui/macro'
import { useWallet } from '@solana/wallet-adapter-react'
import { ButtonOutlined } from 'components/Button'
import Column from 'components/Column'
import Row, { AutoRow, RowBetween } from 'components/Row'
import UserLogo from 'components/UserLogo'
import UserRow, { walletDisplayText } from 'components/UserRow'
import useDebounce from 'hooks/useDebounce'
import useTheme from 'hooks/useTheme'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { addRequiredWallets } from 'state/wallets/actions'
import { useEditWalletFollowing } from 'state/wallets/firebase'
import { useSafeWallet, useSafeWallets } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { CloseIcon, TYPE } from 'theme'

import useLast from '../../hooks/useLast'
import Modal from '../Modal'
import { PaddedColumn, SearchInput, Separator } from './styleds'

interface CurrencySearchModalProps {
  userAddress: string
  isOpen: boolean
  onDismiss: () => void
  type?: UserListModalType
}

export enum UserListModalType {
  followers,
  following,
}

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.bg1};
  border-top: 1px solid ${({ theme }) => theme.bg2};
`
const ListRow = styled(RowBetween)`
  width: 100%;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  // background-color: ${({ theme }) => theme.bg1};
  border-top: 1px solid ${({ theme }) => theme.bg2};
`

export default function UserListModal({ isOpen, onDismiss, type, userAddress }: CurrencySearchModalProps) {
  const lastOpen = useLast(isOpen)

  const clientWallet = useWallet()

  // for token import view
  const pgWallet = useSafeWallet(userAddress)
  const dispatch = useDispatch()
  useEffect(() => {
    if (userAddress) {
      dispatch(addRequiredWallets([userAddress]))
    }
  }, [userAddress, dispatch, pgWallet])

  const walletsRaw = useMemo(() => {
    if (pgWallet) {
      if (type === UserListModalType.followers) {
        return pgWallet.followedBy ?? []
      } else if (type === UserListModalType.following) {
        return pgWallet.following ?? []
      }
    }
    return []
  }, [pgWallet, type])
  useEffect(() => {
    if (walletsRaw.length > 0) {
      dispatch(addRequiredWallets(walletsRaw))
    }
  }, [dispatch, walletsRaw])
  const wallets = useSafeWallets(walletsRaw)

  // change min height if not searching
  const minHeight = 50

  const theme = useTheme()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    setSearchQuery(input)
    fixedList.current?.scrollTo(0)
  }, [])

  const walletResults = useMemo(() => {
    return wallets.filter((wallet) => {
      if (searchQuery.length === 0) {
        return true
      }
      const walletDotSolNames = wallet.dotSolNames.map((n) => n.toLowerCase())
      return walletDotSolNames.some((name) => name.includes(searchQuery.toLowerCase()))
    })
  }, [searchQuery, wallets])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        // if (s === native?.symbol?.toLowerCase()) {
        //   handleCurrencySelect(native)
        // } else if (filteredSortedTokensWithETH.length > 0) {
        //   if (
        //     filteredSortedTokensWithETH[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
        //     filteredSortedTokensWithETH.length === 1
        //   ) {
        //     handleCurrencySelect(filteredSortedTokensWithETH[0])
        //   }
        // }
      }
    },
    [debouncedQuery]
  )

  const editWalletFollowing = useEditWalletFollowing()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} minHeight={minHeight}>
      {
        <ContentWrapper>
          <PaddedColumn gap="16px">
            <RowBetween>
              <TYPE.heavy fontWeight={500} fontSize={22}>
                <Trans>{type == UserListModalType.followers ? 'Followers' : 'Following'}</Trans>
              </TYPE.heavy>
              <CloseIcon onClick={onDismiss} />
            </RowBetween>
            <Row>
              <SearchInput
                type="text"
                id="users-search-input"
                placeholder={`Search users`}
                autoComplete="off"
                value={searchQuery}
                ref={inputRef as RefObject<HTMLInputElement>}
                onChange={handleInput}
                onKeyDown={handleEnter}
              />
            </Row>
          </PaddedColumn>
          <Separator />
          {/* {searchToken && !searchTokenIsAdded ? (
            <Column style={{ padding: '20px 0', height: '100%' }}>
              <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
            </Column>
          ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? ( */}
          <div style={{ flex: '1' }}>
            <AutoSizer disableWidth>
              {({ height }) =>
                walletResults.map((wallet, index) => {
                  return (
                    <ListRow key={index}>
                      <AutoRow>
                        <div onClick={onDismiss}>
                          <UserRow size={`28px`} userAddress={wallet.pubkey}></UserRow>
                          {/* <UserLogo size={`28px`} userAddress={wallet.pubkey} style={{}}></UserLogo> */}
                        </div>
                        {userAddress == clientWallet?.publicKey?.toBase58() && type == UserListModalType.following && (
                          <div style={{ marginLeft: 'auto' }}>
                            <ButtonOutlined
                              onClick={() => {
                                if (clientWallet && pgWallet) {
                                  editWalletFollowing(pgWallet, wallet.pubkey, false)
                                }
                              }}
                            >
                              Unfollow
                            </ButtonOutlined>
                          </div>
                        )}
                      </AutoRow>
                    </ListRow>
                  )
                })
              }
            </AutoSizer>
          </div>
          {/* ) : (
            <Column style={{ padding: '20px', height: '100%' }}>
              <ThemedText.Main color={theme.text3} textAlign="center" mb="20px">
                <Trans>No results found.</Trans>
              </ThemedText.Main>
            </Column>
          )} */}
          {/* <Footer> */}
          {/* <Row justify="center">
              <ButtonText onClick={showManageView} color={theme.primary1} className="list-token-manage-button">
                <RowFixed>
                  <IconWrapper size="16px" marginRight="6px" stroke={theme.primaryText1}>
                    <Edit />
                  </IconWrapper>
                  <ThemedText.Main color={theme.primaryText1}>
                    <Trans>Manage Token Lists</Trans>
                  </ThemedText.Main>
                </RowFixed>
              </ButtonText>
            </Row> */}
          {/* </Footer> */}
        </ContentWrapper>
      }
    </Modal>
  )
}
