import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import Vibrant from 'node-vibrant/lib/bundle'
import { shade } from 'polished'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'

import { fetchFirebaseCollectionRequest } from './actions'
import { Collection } from './models'

export const COLLECTIONS_COLLECTION = 'collections'

export function useFetchFirebaseCollection(): (collectionID: string) => Promise<Collection | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (collectionID: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseCollectionRequest.pending({ collectionID, requestID }))
      try {
        const db = getFirestore()
        const collectionSnap = await getDoc(doc(db, COLLECTIONS_COLLECTION, collectionID))
        console.log('firebase collections: ', collectionSnap)
        if (collectionSnap.exists()) {
          console.log('firebase Document data:', collectionSnap.data())
          const collection = decodeFirebaseCollection(collectionSnap.data())
          console.log('firebase collection decoded: ', collection)
          dispatch(fetchFirebaseCollectionRequest.fulfilled({ requestID, collection }))
          return collection
        } else {
          console.log('firebase Collection not found. Creating.')
          return undefined
        }
        // return collection
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseCollectionRequest.rejected({ requestID, errorMessage: e, collectionID }))
        throw e
      }
    },
    [dispatch]
  )
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   collection: StringPublicKey
//   data: Data
//   primaryCollectionHappened: boolean
//   isMutable: boolean
//   editionNonce: number | undefined
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | undefined
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | undefined
//   collection?: IMetadataCollection | undefined
// }

// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.id = args.id
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | undefined
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | undefined
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }

// export interface IMetadataCollection {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }
//
const encodeCollectionObject = (collection: Collection) => {
  return collection
  console.log('encodeCollectionObject: ', collection)
  // const collectionObj = {
  //   // ...collection,
  //   pubkey: collection.pubkey.toBase58(),
  //   collection: encodeCollection(collection.collection),
  //   metadata: collection.metadata
  //     ? {
  //         key: collection.metadata.key,
  //         updateAuthority: collection.metadata.updateAuthority.toBase58(),
  //         collection: collection.metadata.collection.toBase58(),
  //         data: collection.metadata.data
  //           ? {
  //               name: collection.metadata.data?.name,
  //               symbol: collection.metadata.data.symbol,
  //               uri: collection.metadata.data.uri,
  //               sellerFeeBasisPoints: collection.metadata.data.sellerFeeBasisPoints,
  //               creators: collection.metadata.data.creators
  //                 ? collection.metadata.data.creators.map((creator) => ({
  //                     address: creator.id.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //             }
  //           : {},
  //         primaryCollectionHappened: collection.metadata.primaryCollectionHappened,
  //         isMutable: collection.metadata.isMutable,
  //         editionNonce: collection.metadata.editionNonce ?? '',
  //         extended: collection.metadata.extended
  //           ? {
  //               name: collection.metadata.extended.name,
  //               symbol: collection.metadata.extended.symbol,
  //               creators: collection.metadata.extended.creators
  //                 ? collection.metadata.extended.creators.map((creator) => ({
  //                     address: creator.id.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //               description: collection.metadata.extended.description,
  //               image: collection.metadata.extended.image,
  //               animation_url: collection.metadata.extended.animation_url ?? '',
  //               external_url: collection.metadata.extended.external_url,
  //               seller_fee_basis_points: collection.metadata.extended.seller_fee_basis_points,
  //               properties: {
  //                 files: collection.metadata.extended.properties.files
  //                   ? collection.metadata.extended.properties.files.map((file) => {
  //                       return {
  //                         file: file.valueOf(),
  //                         content_type: file.type ?? '',
  //                       }
  //                     })
  //                   : [],
  //                 category: collection.metadata.extended.properties.category,
  //                 maxSupply: collection.metadata.extended.properties.maxSupply ?? '',
  //               },
  //               attributes: collection.metadata.extended.attributes
  //                 ? collection.metadata.extended.attributes.map((attribute) => ({
  //                     trait_type: attribute.trait_type,
  //                     value: attribute.value,
  //                   }))
  //                 : [],
  //               collection: collection.metadata.extended.collection
  //                 ? {
  //                     name: collection.metadata.extended.collection.name,
  //                     family: collection.metadata.extended.collection.family,
  //                   }
  //                 : {},
  //             }
  //           : {},
  //       }
  //     : {},
  //   asks: collection.asks ? collection.asks.map((ask) => encodeAskV2(ask)) : [],
  //   bids: collection.bids ? collection.bids.map((bid) => encodeBidV2(bid)) : [],
  //   owner: collection.owner?.toBase58() ?? '',
  // }

  // console.log('encodeCollectionObject: ', collectionObj)

  // return collectionObj
}

export const decodeFirebaseCollection = (res: any) => {
  console.log('decodeFirebaseCollection: ', res)
  const md2 = create({ ...res }, Collection)
  return md2
}

// export function useUpdateFirebaseCollection(): (collection: Collection) => Promise<void> {
//   const dispatch = useAppDispatch()
//   return useCallback(
//     async (collection: Collection) => {
//       const requestID = nanoid()
//       dispatch(updateFirebaseCollectionRequest.pending({ collectionID: collection.id, requestID }))
//       if (collection.savedByUsers || collection.collections) {
//         throw new Error('Cannot update collection with savedByUsers or collections')
//       }
//       try {
//         const db = getFirestore()
//         // let color = null
//         console.log('useUpdateFirebaseCollection collection: ', collection)
//         const collectionObj = encodeCollectionObject({ ...collection })
//         console.log('useUpdateFirebaseCollection collectionObj: ', collectionObj)
//         console.log('firebase uploading collection: ', collectionObj)
//         setDoc(doc(db, COLLECTIONS_COLLECTION, collection.id), collectionObj).then(() => {
//           console.log('firebase collection uploaded')
//           dispatch(updateFirebaseCollectionRequest.fulfilled({ requestID, collection, preserveOffChain: true }))
//         })
//       } catch (e) {
//         console.error(e)
//         dispatch(
//           updateFirebaseCollectionRequest.rejected({
//             collectionID: collection.id,
//             requestID,
//             errorMessage: e,
//           })
//         )
//         // throw e
//       }
//     },
//     [dispatch]
//   )
// }

export async function getColorFromUriPath(uri?: string): Promise<string | null> {
  if (!uri) {
    return null
  }
  const formattedPath = uriToHttp(uri)[0]

  const palette = await Vibrant.from(formattedPath).getPalette()
  if (!palette?.Vibrant) {
    return null
  }

  let detectedHex = palette.Vibrant.hex
  let AAscore = hex(detectedHex, '#FFF')
  while (AAscore < 3) {
    detectedHex = shade(0.005, detectedHex)
    AAscore = hex(detectedHex, '#FFF')
  }

  return detectedHex
}
