import { pubkeyFromString } from 'apollo/utils'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { useFetchFirebaseFeed } from './firebase'
import { useAllFirebaseFeeds, useAllFirebaseRequests, useRequiredFeeds } from './hooks'
import { Feed } from './models'
import { RequestStatus } from './reducer'

const shouldRefreshFirebaseFeed = (chainFeed: Feed, firebaseFeed: Feed) => {
  return false
}

export default function Updater(): null {
  const dispatch = useAppDispatch()

  const requiredFeeds = useRequiredFeeds()

  const allFirebaseFeeds = useAllFirebaseFeeds()

  const allFirebaseRequests = useAllFirebaseRequests()
  const fetchFirebaseFeed = useFetchFirebaseFeed()

  useEffect(() => {
    console.log('requiredFeeds requiredFeeds: ', requiredFeeds)
    requiredFeeds.forEach((feedID) => {
      // const pubkey = pubkeyFromString(feedID)
      // if (!pubkey) return
      console.log('requiredFeeds allFirebaseFeeds: ', feedID, allFirebaseFeeds)
      const firebaseFeed = allFirebaseFeeds.find((m) => m.id == feedID)
      console.log('requiredFeeds firebaseFeed: ', feedID, firebaseFeed)
      const firebaseFetchRequest = allFirebaseRequests.find(
        (r) => r.context == feedID && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
        // r.timestamp > Date.now() - 10
      )
      console.log('requiredFeeds firebaseFetchRequest: ', firebaseFeed)

      if (
        (firebaseFetchRequest && firebaseFetchRequest?.timestamp > Date.now() - 10 * 1000) ||
        (!firebaseFeed && !firebaseFetchRequest)
      ) {
        console.log('requiredFeeds request1')
        fetchFirebaseFeed(feedID)
        return
      }
    })
  }, [allFirebaseFeeds, allFirebaseRequests, dispatch, fetchFirebaseFeed, requiredFeeds])

  return null
}
