import { PublicKey } from '@solana/web3.js'
import { boolean, coerce, instance, number, string } from 'superstruct'

export const PublicKeyFromString = coerce(instance(PublicKey), string(), (value) => new PublicKey(value))

export const StringFromPublicKey = coerce(string(), instance(PublicKey), (value) => value.toBase58())

export const BoolFromNumber = coerce(boolean(), number(), (value) => value != 0)

export const NumberFromBool = coerce(number(), boolean(), (value) => (value ? 1 : 0))

export const NumberFromString = coerce(number(), string(), (value) => parseInt(value, 10))
// export const BoolFromNumber = coerce(instance(boolean()), string(), (value) => new PublicKey(value))
