/*
    Taken from: https://github.com/metaplex-foundation/metaplex/blob/master/js/packages/src/actions/metadata.ts
*/

import BN from 'bn.js'

import {
  EDITION_MARKER_BIT_SIZE,
  FileOrString,
  MetadataCategory,
  MetadataKey,
  MetaplexKey,
  StringPublicKey,
} from './types'

export class MasterEditionV1 {
  key: MetadataKey
  supply: BN
  maxSupply?: BN
  /// Can be used to mint tokens that give one-time permission to mint a single limited edition.
  printingMint: StringPublicKey
  /// If you don't know how many printing tokens you are going to need, but you do know
  /// you are going to need some amount in the future, you can use a token from this mint.
  /// Coming back to token metadata with one of these tokens allows you to mint (one time)
  /// any number of printing tokens you want. This is used for instance by Auction Manager
  /// with participation NFTs, where we dont know how many people will bid and need participation
  /// printing tokens to redeem, so we give it ONE of these tokens to use after the auction is over,
  /// because when the auction begins we just dont know how many printing tokens we will need,
  /// but at the end we will. At the end it then burns this token with token-metadata to
  /// get the printing tokens it needs to give to bidders. Each bidder then redeems a printing token
  /// to get their limited editions.
  oneTimePrintingAuthorizationMint: StringPublicKey

  constructor(args: {
    key: MetadataKey
    supply: BN
    maxSupply?: BN
    printingMint: StringPublicKey
    oneTimePrintingAuthorizationMint: StringPublicKey
  }) {
    this.key = MetadataKey.MasterEditionV1
    this.supply = args.supply
    this.maxSupply = args.maxSupply
    this.printingMint = args.printingMint
    this.oneTimePrintingAuthorizationMint = args.oneTimePrintingAuthorizationMint
  }
}

export class MasterEditionV2 {
  key: MetadataKey
  supply: BN
  maxSupply?: BN

  constructor(args: { key: MetadataKey; supply: BN; maxSupply?: BN }) {
    this.key = MetadataKey.MasterEditionV2
    this.supply = args.supply
    this.maxSupply = args.maxSupply
  }
}

export class EditionMarker {
  key: MetadataKey
  ledger: number[]

  constructor(args: { key: MetadataKey; ledger: number[] }) {
    this.key = MetadataKey.EditionMarker
    this.ledger = args.ledger
  }

  editionTaken(edition: number) {
    const editionOffset = edition % EDITION_MARKER_BIT_SIZE
    const indexOffset = Math.floor(editionOffset / 8)

    if (indexOffset > 30) {
      throw Error('bad index for edition')
    }

    const positionInBitsetFromRight = 7 - (editionOffset % 8)

    const mask = Math.pow(2, positionInBitsetFromRight)

    const appliedMask = this.ledger[indexOffset] & mask

    return appliedMask !== 0
  }
}

export class Edition {
  key: MetadataKey
  /// Points at MasterEdition struct
  parent: StringPublicKey
  /// Starting at 0 for master record, this is incremented for each edition minted.
  edition: BN

  constructor(args: { key: MetadataKey; parent: StringPublicKey; edition: BN }) {
    this.key = MetadataKey.EditionV1
    this.parent = args.parent
    this.edition = args.edition
  }
}
export class Creator {
  address: StringPublicKey
  verified: boolean
  share: number

  constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
    this.address = args.address
    this.verified = args.verified
    this.share = args.share
  }
}

export class DataTwo {
  name: string
  symbol: string
  uri: string
  sellerFeeBasisPoints: number
  creators: Creator[] | null
  constructor(args: {
    name: string
    symbol: string
    uri: string
    sellerFeeBasisPoints: number
    creators: Creator[] | null
  }) {
    this.name = args.name
    this.symbol = args.symbol
    this.uri = args.uri
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
    this.creators = args.creators
  }
}

export class Metadata {
  key: MetadataKey
  updateAuthority: StringPublicKey
  mint: StringPublicKey
  data: DataTwo
  primarySaleHappened: boolean
  isMutable: boolean
  editionNonce: number | null

  constructor(args: {
    updateAuthority: StringPublicKey
    mint: StringPublicKey
    data: DataTwo
    primarySaleHappened: boolean
    isMutable: boolean
    editionNonce: number | null
  }) {
    this.key = MetadataKey.MetadataV1
    this.updateAuthority = args.updateAuthority
    this.mint = args.mint
    this.data = args.data
    this.primarySaleHappened = args.primarySaleHappened
    this.isMutable = args.isMutable
    this.editionNonce = args.editionNonce
  }
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   mint: StringPublicKey
//   data?: Data
//   primarySaleHappened: boolean
//   isMutable: boolean
//   editionNonce: number | null
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | null
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | null
//   collection?: IMetadataCollection | null
// }

export interface IMetadataCollection {
  name: string
  family: string
}

export interface IMetadataAttribute {
  trait_type: string
  value: string
}

export const METADATA_SCHEMA = new Map<any, any>([
  [
    MasterEditionV1,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['supply', 'u64'],
        ['maxSupply', { kind: 'option', type: 'u64' }],
        ['printingMint', 'pubkeyAsString'],
        ['oneTimePrintingAuthorizationMint', 'pubkeyAsString'],
      ],
    },
  ],
  [
    MasterEditionV2,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['supply', 'u64'],
        ['maxSupply', { kind: 'option', type: 'u64' }],
      ],
    },
  ],
  [
    Edition,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['parent', 'pubkeyAsString'],
        ['edition', 'u64'],
      ],
    },
  ],
  [
    DataTwo,
    {
      kind: 'struct',
      fields: [
        ['name', 'string'],
        ['symbol', 'string'],
        ['uri', 'string'],
        ['sellerFeeBasisPoints', 'u16'],
        ['creators', { kind: 'option', type: [Creator] }],
      ],
    },
  ],
  [
    Creator,
    {
      kind: 'struct',
      fields: [
        ['address', 'pubkeyAsString'],
        ['verified', 'u8'],
        ['share', 'u8'],
      ],
    },
  ],
  [
    Metadata,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['updateAuthority', 'pubkeyAsString'],
        ['mint', 'pubkeyAsString'],
        ['data', DataTwo],
        ['primarySaleHappened', 'u8'], // bool
        ['isMutable', 'u8'], // bool
      ],
    },
  ],
  [
    EditionMarker,
    {
      kind: 'struct',
      fields: [
        ['key', 'u8'],
        ['ledger', [31]],
      ],
    },
  ],
])

export class WhitelistedCreator {
  key: MetaplexKey = MetaplexKey.WhitelistedCreatorV1
  address: StringPublicKey
  activated = true

  // Populated from name service
  twitter?: string
  name?: string
  image?: string
  description?: string

  constructor(args: { address: string; activated: boolean }) {
    this.address = args.address
    this.activated = args.activated
  }
}
