import useScrollPosition from '@react-hook/window-scroll'
import { useWallet } from '@solana/wallet-adapter-react'
import TopBar from 'components/Header/TopBar'
import { LocalLoader } from 'components/Loader'
import Row, { RowBetween } from 'components/Row'
import Search from 'components/Search'
import Sidebar from 'components/Sidebar'
import SolanaManager from 'components/SolanaManager'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import useDebounce from 'hooks/useDebounce'
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, Route, Switch, useLocation } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { AppGrid, CONTENT_LEFT_PADDING } from 'theme'

import LogoLightMode from '../assets/svg/carmelDark.svg'
import LogoDarkMode from '../assets/svg/carmelLight.svg'
// import LogoLightMode from '../assets/svg/carmelDark.svg'
// import LogoDarkMode from '../assets/svg/carmelLight.svg'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Popups from '../components/Popups'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import { ApplicationModal } from '../state/application/reducer'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AboutPage from './ExplorePage'
import FeedPage from './FeedPage'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  // justify-content: center;
  z-index: 1;
  height: 100%;
  overflow-x: visible !important;
  overflow-y: hidden;
  max-height: 100vh;
`

const BackgroundRadialGradient = styled.div<{ globalColor?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 200vw;
  height: 260vh;

  //  'radial-gradient(50% 50% at 50% 50%, black 0%,  rgba(252, 187, 7, 0.034) 70%, #fcec0804 80%, rgba(7, 252, 150, 0.059) 90%, rgba(255, 255, 255, 0) 100%);'
  // 'linear-gradient(339deg,rgba(14, 144, 237, 0.6),rgba(222, 126, 31, 0.6),rgba(106, 100, 227, 0.6));'} !important;
  transform: translate(-60vw, -150vh);
  transition: background-image 5s ease-in;
  // background: ${({ theme }) => theme.bg0};
  z-index: -1;
  // background-size: ${({ theme }) => (theme.darkMode ? '100% 100%' : '160% 180%')};
  -webkit-animation: AnimationName 25 ease infinite;
  -moz-animation: AnimationName 25 ease infinite;
  animation: AnimationName 25s ease infinite;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 0px 0px 0px;
  // border-top: 1px solid ${({ theme }) => theme.bg3};
  align-items: center;
  flex: 1;
  // z-index: 25;
  overflow-y: visible;
  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 14px 14px 14px;
  `};

  ${({ theme }) => theme.mediaWidth.upToLarge`
    // margin: 0px 10px;
  `};
`

const SepLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 110px 0px 0px 0px;
  border-top: 1px solid ${({ theme }) => theme.bg3};
  align-items: center;
  flex: 1;
  z-index: 23;
  height: 1px;
  position: fixed;
  top: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 14px 14px 14px;
  `};
`
const ContentWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // padding: 100px 14px 0px 14px;
  // align-items: center;
  // flex: 1;
  // z-index: 1;
  // height: 100vh;
  // width: 100%;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   padding: 6rem 14px 14px 14px;
  // `};
  // overflow-x: visible !important;
  padding: 0px 0px 0px ${CONTENT_LEFT_PADDING}px;
`

// const HeaderWrapper = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   position: fixed;
//   top: 0;
//   z-index: 2;
//   box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.bg3};
// `

const Marginer = styled.div`
  margin-top: 5rem;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

const HeaderWrapper = styled.div<{ showBackground: boolean }>`
  // position: fixed;
  // top: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 100%;
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  // height: 110px;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6rem 14px 14px 14px;
  // `};
  // z-index: 21;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 0px 0px 0px;
  // border-top: 1px solid ${({ theme }) => theme.bg3};
  align-items: center;
  flex: 1;
  // height: 110px;
  // z-index: 25;
  overflow-y: visible;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 14px 14px 14px;
  `};
`
const SidebarWrapper = styled.div`
  position: fixed;
  top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-size: 100% 200%;
  // height: 110px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6rem 14px 14px 14px;
  `};
  z-index: 21;
`
const HeaderAppGrid = styled(AppGrid)`
  // display: flex;
  align-items: center;
  width: 100%;
  // height: 100px;
  // justify-content: center;
  padding: 20px 0px 10px 0px;
`
const SidebarAppGrid = styled(AppGrid)`
  display: flex;
  // align-items: center;
  // width: 100%;
  // justify-content: center;
  // grid-template-columns: 277px 10px;
  // width: fit-content;
`
const ContentAppGrid = styled(AppGrid)`
  // display: flex;
  // align-items: center;
  // width: 100%;
  // justify-content: center;
  z-index: 10;
  // height: 0px;
  overflow: visible !important;
`
const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  // margin-right: 12px;
  :hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  // width: 42px;
  // overflow: hidden;
`}
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
  }, [])
  const darkMode = useIsDarkMode()
  const theme = useTheme()
  const { white, black } = theme
  const globalColor = useDebounce(theme.globalColor, 40)

  const wallet = useWallet()
  const dispatch = useDispatch()
  const location = useLocation()

  const scrollY = useScrollPosition()
  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      {/* <Route component={ApeModeQueryParamReader} /> */}
      <SolanaManager>
        {/* <SearchProvider index={'dymmt'}> */}
        {/* <Web3ReactManager> */}
        {loading ? (
          <LocalLoader fill={true} />
        ) : (
          <AppWrapper>
            <BackgroundRadialGradient
              globalColor={location.pathname.includes('explore') ? globalColor : globalColor}
            ></BackgroundRadialGradient>
            {/* <HeaderWrapper>
            <div></div> */}
            {/* <Header /> */}
            <HeaderWrapper showBackground={scrollY > 0}>
              <HeaderAppGrid>
                {/* <ColumnCenter style={{ height: '100%' }}> */}
                <Title to={'/explore'} style={{ width: '100%', height: '40px' }}>
                  <UniIcon>
                    <img width={'90px'} src={darkMode ? LogoDarkMode : LogoLightMode} alt="logo" />
                  </UniIcon>
                </Title>
                <RowBetween width={'100%'}>
                  {/* </ColumnCenter> */}
                  <Row width={'100%'}>{/* <Title to={'/explore'}></Title> */}</Row>
                  <Search></Search>
                </RowBetween>
                {/* <Header></Header> */}
              </HeaderAppGrid>
            </HeaderWrapper>

            {/* <SidebarWrapper>
            <SidebarAppGrid>
              <Sidebar></Sidebar>

              <></>
            </SidebarAppGrid>
          </SidebarWrapper> */}
            {/* <div
            style={{
              width: '100%',
              height: 20,
              boxShadow: `1px 1px 1px 1px ${theme.bg3};
`,
            }}
          ></div> */}
            {/* <div></div> */}
            {/* </HeaderWrapper> */}

            <BodyWrapper>
              {/* <HideMedium> */}
              {/* </HideMedium> */}
              <Popups />
              {/* <Polling /> */}

              <TopLevelModals />
              <ContentAppGrid>
                {/* <div style={{ opacity: '0', zIndex: 0, height: 0 }}>.</div> */}
                <Sidebar></Sidebar>

                <ContentWrapper>
                  {/* <NiceNav></NiceNav> */}
                  <Switch>
                    {/* <Route exact strict path="/vote" component={Vote} />
                <Route exact strict path="/vote/:governorIndex/:id" component={VotePage} />
                <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                <Route exact strict path="/uni" component={Earn} />
                <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />

                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/swap" component={Swap} />

                <Route exact strict path="/pool/v2/find" component={PoolFinder} />
                <Route exact strict path="/pool/v2" component={PoolV2} /> */}
                    {/* <Route exact strict path="/" component={HomePage} /> */}
                    {/* <Route exact strict path="/" component={HomePage} /> */}
                    {/* <Route exact strict path="/explore/:type?" component={ExplorePage} /> */}
                    <Route exact strict path="/about" component={AboutPage} />
                    {/* <Route exact strict path="/home" component={FeedPage} /> */}
                    {/* <Route exact strict path="/" component={FeedPage} /> */}
                    <Route path="/:type?/:id?/:refinement?" component={FeedPage} />
                    {/* <Route path="/wallet/:id?/:refinement?" component={FeedPage} /> */}
                    {/* <Route exact strict path="/:type?" component={FeedPage} />
                    <Route exact strict path="/:type?/:id?" component={FeedPage} /> */}
                    {/* <Route exact strict path="/rewards" component={RewardsPage} />
                    <Route exact strict path="/m/:mintAddress/:action?" component={MintPage} />
                    <Route exact strict path="/c/:collectionID/:action?" component={CollectionPage} />
                    <Route exact strict path="/c" component={CollectionsPage} />
                    <Route exact strict path="/collections/create" component={CreateCollection} />
                    <Route exact strict path="/create" component={CreateSale} />
                    <Route exact strict path="/user/:pubkey/:action?" component={UserPage} />
                    <Route exact strict path="/sale/:salePubkey/:action?" component={MintPage} /> */}
                    {/* <Route component={RedirectPathToSwapOnly} /> */}

                    {/* <Route exact strict path="/pool/:tokenId" component={PositionPage} />

                <Route
                  exact
                  strict
                  path="/add/v2/:currencyIdA?/:currencyIdB?"
                  component={RedirectDuplicateTokenIdsV2}
                />
                <Route
                  exact
                  strict
                  path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                  component={RedirectDuplicateTokenIds}
                />

                <Route
                  exact
                  strict
                  path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                  component={AddLiquidity}
                />

                <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />

                <Route exact strict path="/migrate/v2" component={MigrateV2} />
                <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} />

                <Route exact strict path="/create-proposal" component={CreateProposal} />
                <Route component={RedirectPathToSwapOnly} /> */}
                  </Switch>
                </ContentWrapper>
              </ContentAppGrid>
              <TopBar />

              <Marginer />
            </BodyWrapper>
            {/* <SepLine></SepLine> */}
          </AppWrapper>
        )}
        {/* </Web3ReactManager> */}
        {/* </SearchProvider> */}
      </SolanaManager>
    </ErrorBoundary>
  )
}
