import { Program } from '@project-serum/anchor'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { Connection, PublicKey } from '@solana/web3.js'
import { decodeBidV2, decodeSale } from 'apollo/types'
import { pubkeyFromString } from 'apollo/utils'
import { apolloProgramAddress, providerURL } from 'hooks/web3'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import React from 'react'
import { create } from 'superstruct'
import { ParsedInfo } from 'validators'
import { BidV2, Sale } from 'validators/accounts/sales'
import { TokenAccount, TokenAccountInfo } from 'validators/accounts/token'

import { asksFromSale, bidFromMint, bidsFromSale, ownerFromMint, saleFromMint } from './utils/bundleMints'

export interface BidData {
  // bundleMint: PublicKey
  pubkey: PublicKey
  bid: BidV2
  owner?: PublicKey
}

type State = Cache.State<BidData>
type Dispatch = Cache.Dispatch<BidData>

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

type ProviderProps = { children: React.ReactNode }
export function BidProvider({ children }: ProviderProps) {
  const [state, dispatch] = Cache.useReducer<BidData>()
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

async function fetchUserBidAccounts(pubkey: PublicKey, dispatch: Dispatch, apollo: Program) {
  const url = providerURL()
  const connection = new Connection(url)
  const programAddr = apolloProgramAddress()
  const programPubkey = new PublicKey(programAddr)

  const { value } = await new Connection(url, 'processed').getParsedTokenAccountsByOwner(pubkey, {
    programId: TOKEN_PROGRAM_ID,
  })
  console.log(value)
  const ownedMints = value.map((accountInfo) => {
    const parsedInfo = accountInfo.account.data.parsed.info
    const info = create(parsedInfo, TokenAccountInfo)
    // if (parseInt(info.tokenAmount.uiAmountString) > 0) {
    if (true) {
      return info.mint
    } else {
      return undefined
    }
  })

  console.log('ownedMints', ownedMints)

  ownedMints.forEach(async (mint) => {
    if (!mint) {
      return
    }
    const bidPubkey = await bidFromMint(connection, mint)

    // console.log('saleFromMintRes', saleFromMintRes?.sale.authority.toBase58())
    if (bidPubkey) {
      // const { sale, pubkey: salePubkey } = saleFromMintRes
      const saleRes = await apollo.account.sale.fetch(bidPubkey)
      const sale: Sale = decodeSale(saleRes)
      fetchBidAccount(bidPubkey, dispatch, apollo, pubkey)

      // const key = salePubkey.toBase58()
      // dispatch({
      //   type: ActionType.Update,
      //   key,
      //   status: FetchStatus.Fetching,
      // })

      // let status
      // let data: SaleData | undefined
      // try {
      //   console.log('in try')
      //   data = {
      //     pubkey: salePubkey,
      //     owner: pubkey,
      //     sale,
      //   }

      //   status = FetchStatus.Fetched
      // } catch (error) {
      //   console.error(error)
      //   status = FetchStatus.FetchFailed
      // }
      // dispatch({ type: ActionType.Update, status, data, key })
    }
  })
}

//   console.log('fetchallres:', fetchAllRes)
//   fetchAllRes.forEach(async (acctRes) => {
//     const key = acctRes.pubkey.toBase58()
//     dispatch({
//       type: ActionType.Update,
//       key,
//       status: FetchStatus.Fetching,
//     })

//     let status
//     let data: SaleData | undefined
//     try {
//       console.log('in try')

//       const bundleRes = await apollo.account.bundle.fetch(acctRes.pubkey)

//       const bundle: Bundle = decodeBundle(bundleRes)
//       // const bundleOwner = await getBundleOwner(connection, bundle.bundleMint)
//       // if (!bundleOwner) {
//       //   throw new Error('Could not find bundle owner')
//       // }

//       data = {
//         pubkey: acctRes.pubkey,
//         bundle,
//         // owner: bundleOwner,
//       }

//       status = FetchStatus.Fetched
//     } catch (error) {
//       console.error(error)
//       status = FetchStatus.FetchFailed
//     }
//     dispatch({ type: ActionType.Update, status, data, key })
//   })
// }

async function fetchBidAccount(pubkey: PublicKey, dispatch: Dispatch, apollo: Program, owner?: PublicKey) {
  const url = providerURL()
  const connection = new Connection(url)
  const programAddr = apolloProgramAddress()

  dispatch({
    type: ActionType.Update,
    key: pubkey.toBase58(),
    status: FetchStatus.Fetching,
  })

  let status
  let data: BidData | undefined
  try {
    console.log('in try fetchSaleAccount')

    const bidRes = await apollo.account.bid.fetch(pubkey)
    const bid: BidV2 = decodeBidV2(bidRes)
    // if (!bundleOwner) {
    //   throw new Error('Could not find owner')
    // }
    const bidReceiptMint = pubkeyFromString(bid.receiptMint)
    if (!owner && bidReceiptMint) {
      const ownerRes = await ownerFromMint(connection, apollo, bidReceiptMint)
      owner = ownerRes
    }

    data = {
      pubkey,
      bid,
      owner: owner ? owner : undefined,
    }

    status = FetchStatus.Fetched
  } catch (error) {
    console.error(error)
    status = FetchStatus.FetchFailed
  }
  dispatch({ type: ActionType.Update, status, data, key: pubkey.toBase58() })
}

async function fetchBidAccounts(bidPubkeys: PublicKey[], dispatch: Dispatch, apollo: Program) {
  for (let i = 0; i < bidPubkeys.length; i++) {
    const bundleMint = bidPubkeys[i]
    fetchBidAccount(bundleMint, dispatch, apollo)
  }
}

export function useBidAccount(address?: string): Cache.CacheEntry<BidData> | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useSaleAccount must be used within a AccountsProvider`)
  }
  return address ? context.entries[address] : undefined
}

export function useBidAccounts(addresses: string[] | undefined): Cache.CacheEntry<BidData>[] | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useAccountInfo must be used within a AccountsProvider`)
  }
  if (addresses === undefined) return
  return addresses.map((address) => context.entries[address]).filter((entry) => entry !== undefined)
}

export function useAllSaleAccounts(): Cache.CacheEntry<BidData>[] {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useStoreAccount must be used within a AccountsProvider`)
  }
  return Object.keys(context.entries).map((key) => context.entries[key])
}

export function useUserBidAccounts(pubkey?: PublicKey): Cache.CacheEntry<BidData>[] {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useStoreAccount must be used within a AccountsProvider`)
  }
  if (!pubkey) {
    return []
  }
  return Object.keys(context.entries)
    .map((key) => context.entries[key])
    .filter((r) => r.data?.owner?.toBase58() == pubkey.toBase58())
}

export function useFetchUserBidAccounts(apollo?: Program, refresh?: boolean) {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)
  if (!dispatch) {
    throw new Error(`useFetchStoreAccounts must be used within a AccountsProvider`)
  }

  return React.useCallback(
    (pubkey: PublicKey) => {
      if (apollo) {
        if (!refresh) {
          if (!state?.entries[pubkey.toBase58()]) {
            fetchUserBidAccounts(pubkey, dispatch, apollo)
          } else {
            console.log('fetchUserBidAccounts( already fetched!')
          }
        } else {
          fetchUserBidAccounts(pubkey, dispatch, apollo)
        }
        console.log('fetchUserBidAccounts')
      } else {
        console.log('apollo not ready')
      }
    },
    [dispatch, apollo]
  )
}

export function useFetchBidAccount(apollo?: Program, refresh?: boolean) {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)
  if (!dispatch) {
    throw new Error(`useFetchStoreAccounts must be used within a AccountsProvider`)
  }

  return React.useCallback(
    (pubkey: PublicKey) => {
      if (apollo) {
        if (!refresh) {
          if (!state?.entries[pubkey.toBase58()]) {
            fetchBidAccount(pubkey, dispatch, apollo)
          } else {
            console.log('UserBundleAccounts( already fetched!')
          }
        } else {
          fetchBidAccount(pubkey, dispatch, apollo)
        }
        console.log('useFetchBidAccount')
        fetchBidAccount(pubkey, dispatch, apollo)
      } else {
        console.log('apollo not ready')
      }
    },
    [dispatch, apollo]
  )
}
export function useFetchBidAccounts(apollo?: Program, refresh?: boolean) {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)
  if (!dispatch) {
    throw new Error(`useFetchStoreAccounts must be used within a AccountsProvider`)
  }
  const before = state?.entries

  return React.useCallback(
    (pubkeys: PublicKey[]) => {
      if (apollo && pubkeys) {
        console.log('useFetchBidAccounts')
        if (!refresh && before) {
          const filtered = pubkeys.filter((p) => !before[p.toBase58()])
          console.log('useFetchBidAccounts filtered', filtered)
          fetchBidAccounts(filtered, dispatch, apollo)
        } else {
          fetchBidAccounts(pubkeys, dispatch, apollo)
        }
      } else {
        console.log('apollo not ready')
      }
    },
    [apollo, refresh, before, dispatch]
  )
}
