import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { User } from 'api/types'
import { User } from './models'

export const updateFirebaseUserRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ userAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; user: User; preserveOffChain?: boolean }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; userAddress: string; requestID: string }>
}> = {
  pending: createAction('users/createUserRequest/pending'),
  fulfilled: createAction('users/createUserRequest/fulfilled'),
  rejected: createAction('users/createUserRequest/rejected'),
}
export const fetchFirebaseUserRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ userAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; user: User }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; userAddress: string }>
}> = {
  pending: createAction('users/fetchFirebaseUserRequest/pending'),
  fulfilled: createAction('users/fetchFirebaseUserRequest/fulfilled'),
  rejected: createAction('users/fetchFirebaseUserRequest/rejected'),
}
export const fetchChainUserRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ userAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; user: User }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('users/fetchChainUserRequest/pending'),
  fulfilled: createAction('users/fetchChainUserRequest/fulfilled'),
  rejected: createAction('users/fetchChainUserRequest/rejected'),
}

export const addRequiredUsers = createAction<string[]>('users/addRequiredUsers')
export const editSavedMint =
  createAction<{ userAddress: string; mintAddress: string; saved: boolean }>('users/addSavedMint')
export const refreshUsers = createAction<string[]>('users/refreshUsers')
// export const  = createAction<string>('lists/disableList')
