/* eslint-disable @typescript-eslint/no-redeclare */

import { array, boolean, enums, Infer, nullable, number, optional, string, type } from 'superstruct'
import { PublicKeyFromString } from 'validators/pubkey'

export type VoteAccountType = Infer<typeof VoteAccountType>
export const VoteAccountType = enums(['vote'])

export type AuthorizedVoter = Infer<typeof AuthorizedVoter>
export const AuthorizedVoter = type({
  authorizedVoter: PublicKeyFromString,
  epoch: number(),
})

export type PriorVoter = Infer<typeof PriorVoter>
export const PriorVoter = type({
  authorizedPubkey: PublicKeyFromString,
  epochOfLastAuthorizedSwitch: number(),
  targetEpoch: number(),
})

export type EpochCredits = Infer<typeof EpochCredits>
export const EpochCredits = type({
  epoch: number(),
  credits: string(),
  previousCredits: string(),
})

export type Vote = Infer<typeof Vote>
export const Vote = type({
  slot: number(),
  confirmationCount: number(),
})

export type VoteAccountInfo = Infer<typeof VoteAccountInfo>
export const VoteAccountInfo = type({
  authorizedVoters: array(AuthorizedVoter),
  authorizedWithdrawer: PublicKeyFromString,
  commission: number(),
  epochCredits: array(EpochCredits),
  lastTimestamp: type({
    slot: number(),
    timestamp: number(),
  }),
  nodePubkey: PublicKeyFromString,
  priorVoters: array(PriorVoter),
  rootSlot: nullable(number()),
  votes: array(Vote),
})

export type VoteAccount = Infer<typeof VoteAccount>
export const VoteAccount = type({
  type: VoteAccountType,
  info: VoteAccountInfo,
})

// export interface SaleData {
//   pubkey: PublicKey
//   sale: Sale
//   asks?: AskV2[]
//   bids?: BidV2[]
//   owner?: PublicKey
//   metadata?: MetadataCombined
// }
// export type Sale = {
//   tokenMint: Pubkey
//   receiptMint: Pubkey
//   amount: number
//   closed: boolean
//   acceptedAsk: Pubkey
// }
// export type AskV2 = {
//   pubkey?: Pubkey
//   sale: Pubkey
//   tokenMint: Pubkey
//   amount: number
//   expirationDate: number
//   cancelled: boolean
//   accepted: boolean
//   feesPaid: number
//   fundsWithdrawn: boolean
// }
// export type BidV2 = {
//   pubkey?: Pubkey
//   sale: Pubkey
//   receiptMint: Pubkey
//   tokenMint: Pubkey
//   amount: number
//   expirationDate: number
//   cancelled: boolean
//   accepted: boolean
//   feesStored: number
//   redeemed: boolean
// }
// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   mint: StringPublicKey
//   data?: Data
//   primarySaleHappened: boolean
//   isMutable: boolean
//   editionNonce: number | null
//   extended?: IMetadataExtension
// }
// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | null
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | null
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }
// export interface IMetadataExtension {
//   name: string
//   symbol: string
//   creators: Creator[] | null
//   description: string
//   image: string
//   animation_url?: string
//   external_url: string
//   seller_fee_basis_points: number
//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }
//   attributes?: IMetadataAttribute[] | null
//   collection?: IMetadataCollection | null
// }

// export interface IMetadataCollection {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }

// export class Edition {
//   key: MetadataKey
//   /// Points at MasterEdition struct
//   parent: StringPublicKey
//   /// Starting at 0 for master record, this is incremented for each edition minted.
//   edition: BN

//   constructor(args: { key: MetadataKey; parent: StringPublicKey; edition: BN }) {
//     this.key = MetadataKey.EditionV1
//     this.parent = args.parent
//     this.edition = args.edition
//   }
// }
// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.address = args.address
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export type FileOrString = MetadataFile | string
// export enum MetadataKey {
//   Uninitialized = 0,
//   MetadataV1 = 4,
//   EditionV1 = 1,
//   MasterEditionV1 = 2,
//   MasterEditionV2 = 6,
//   EditionMarker = 7,
// }

// export enum MetadataCategory {
//   Audio = 'audio',
//   Video = 'video',
//   Image = 'image',
//   VR = 'vr',
//   HTML = 'html',
// }

// export type MetadataFile = {
//   uri: string
//   type: string
// }
