import { useWallet } from '@solana/wallet-adapter-react'
import WalletModal from 'components/WalletModal'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { addRequiredUsers } from 'state/users/actions'
import { Label, TYPE } from 'theme'

import rainbow_icon from '../../../assets/images/rainbow-icon.png'
// import { Button, ButtonInner, Content, Logo } from '../../styled'
// import Fountain from '../EmojiPop'
import { Button, ButtonInner, Content } from './styled'

export function ConnectButton() {
  const walletModalToggle = useWalletModalToggle()
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const theme = useTheme()
  const wallet = useWallet()
  const dispatch = useDispatch()

  // const connect = useCallback(() => {
  //   walletModalToggle()
  // }, [walletModalToggle])

  //   useEffect(() => {
  //     animate && new Fountain()
  //   }, [animate])

  return (
    <div>
      <Content>
        <Button
          id={'conneccct'}
          onClick={() => {
            walletModalToggle()
          }}
        >
          <ButtonInner>
            {/* <Logo src={rainbow_icon} width="34" /> */}
            {/* <ButtonLabel /> */}
            <Label fontSize={'14px'} color={theme.darkMode ? theme.text1 : theme.text5}>
              Connect Wallet
            </Label>
          </ButtonInner>
        </Button>
        <WalletModal />
      </Content>
    </div>
  )
}
