import ActionCard from 'components/ActionCard'
import { AutoColumn } from 'components/Column'
import Loader from 'components/Loader'
import { AutoRow } from 'components/Row'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { fetchMoreForActionQuery } from 'state/actions/actions'
import { useActionQueryActions, useQueryHasFoundEnd, useQueryIsFetching } from 'state/actions/hooks'
import { Action } from 'state/actions/models'
import styled from 'styled-components/macro'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency

export const ActionsGrid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  // padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr;

  grid-gap: 20px;

  overflow-x: visible !important;
`

function Refresher({ refresh, hidden }: { refresh: () => void; hidden?: boolean }) {
  // const isInView = useInView()
  const { ref, inView } = useInView()
  const theme = useTheme()
  console.log('Refresher inView', inView)
  console.log('Refresher refresh', refresh)

  useEffect(() => {
    if (inView && refresh) {
      refresh()
    }
  }, [inView, refresh])

  return (
    <AutoRow
      style={hidden ? { width: '1px', height: '0px' } : { width: '100%' }}
      // width={'100%'}
      align="center"
      justify="center"
      ref={ref}
    >
      <Loader size={'18px'} stroke={theme.yellow1} />
    </AutoRow>
  )
}

export default function ActionsFeed({
  actions,
  loadMore,
  loading,
  foundLast,
}: {
  actions?: Action[]
  loadMore: () => void
  loading: boolean
  foundLast: boolean
}) {
  // const actionIDs = useActionQueryActions(actionsQueryKey)
  // console.log('ActionsFeed actionIDs', actions)
  // const dispatch = useDispatch()

  // const queryIsFetching = useQueryIsFetching(actionsQueryKey)
  // const queryHasFoundEnd = useQueryHasFoundEnd(actionsQueryKey)

  // const refresh = useCallback(() => {
  //   if (actionsQueryKey && !queryIsFetching) {
  //     dispatch(fetchMoreForActionQuery({ queryKey: actionsQueryKey }))
  //   }
  // }, [queryIsFetching, actionsQueryKey, dispatch])
  const theme = useTheme()
  return actions ? (
    <>
      {/* {JSON.stringify(actionsQueryKey, null, 2)}
      {JSON.stringify(actionIDs?.length, null, 2)} */}
      <ActionsGrid>
        {actions?.map((action, i) => {
          if (i == actions.length - 5) {
            return (
              <>
                <Refresher refresh={loadMore} hidden={true}></Refresher>
                <ActionCard key={i} action={action}></ActionCard>
              </>
            )
          }
          return <ActionCard key={i} action={action}></ActionCard>
        })}

        {/* {!foundLast && <Loader size={'18px'} stroke={theme.yellow1} />} */}
        {!foundLast && !(actions?.length > 0) && <Refresher refresh={loadMore} hidden={false}></Refresher>}
        <div style={{ height: '40px' }}></div>
      </ActionsGrid>
    </>
  ) : (
    // </GridWrapper>
    <></>
  )
}
