import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import {
  addCollectionToMintLocal,
  addRequiredMints,
  appendFirebaseMintIfEmpty,
  appendMints,
  decrementMintSavedByLocal,
  fetchChainMintHistoryRequest,
  fetchChainMintRequest,
  fetchFirebaseMintRequest,
  incrementMintSavedByLocal,
  refreshMint,
  removeCollectionFromMintLocal,
} from './actions'
import { updateFirebaseMintRequest } from './actions'
import { MintData } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface MintsState {
  readonly firebaseMints: MintData[]
  readonly chainMints: MintData[]
  readonly firebaseRequests: Request[]
  readonly onChainRequests: Request[]
  readonly requiredMints: string[]
}

const initialState: MintsState = {
  firebaseMints: [],
  chainMints: [],
  firebaseRequests: [],
  onChainRequests: [],
  requiredMints: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshMint, (state, { payload: mintAddress }) => {
      console.log('refreshMint', mintAddress)
      console.log('state', state)
      state = {
        ...state,
        // requiredMints: [...state.requiredMints.filter((s) => !s.includes(mintAddress)), mintAddress],
        chainMints: state.chainMints.filter((mint) => mint.address !== mintAddress),
      }
      return state
    })
    .addCase(addRequiredMints, (state, { payload: mints }) => {
      console.log('addRequiredMints', mints)
      console.log('state', state)
      const set = [...state.requiredMints, ...mints.filter((m) => !state.requiredMints.includes(m))]
      // const filtered = set.filter((item, index) => set.indexOf(item) === index)
      // console.log('addRequiredMints set', filtered)
      state = {
        ...state,
        requiredMints: set,
      }
      return state
    })
    .addCase(appendMints, (state, { payload: mints }) => {
      const oldMints = state.firebaseMints
      const newMints = mints.filter((mint) => !oldMints.some((oldMint) => oldMint.address === mint.address))
      state = {
        ...state,
        firebaseMints: [...oldMints, ...newMints],
      }
      return state
    })
    .addCase(incrementMintSavedByLocal, (state, { payload: { mintAddress, userAddress } }) => {
      console.log('incrementMintSaves', mintAddress)
      console.log('state', state)
      const oldMint = state.firebaseMints.find((m) => m.address === mintAddress)
      if (!oldMint) {
        return state
      }
      const newMint = { ...oldMint, savedByUsers: [...(oldMint.savedByUsers ?? []), userAddress] }

      return {
        ...state,
        firebaseMints: [...state.firebaseMints.filter((m) => m.address !== mintAddress), newMint],
      }
    })
    .addCase(addCollectionToMintLocal, (state, { payload: { mintAddress, collectionID } }) => {
      console.log('addCollectionToMintLocal', mintAddress, collectionID)
      console.log('state', state)
      const oldMint = state.firebaseMints.find((m) => m.address === mintAddress)
      if (!oldMint) {
        console.error('addCollectionToMintLocal: mint not found', mintAddress)
        return state
      }
      const newMint = { ...oldMint, collections: [...(oldMint.collections ?? []), collectionID] }
      console.log('addCollectionToMintLocal newMint', newMint)
      console.log('addCollectionToMintLocal newfic', {
        collections: [...(oldMint.collections ?? []), collectionID],
      })

      return {
        ...state,
        firebaseMints: [...state.firebaseMints.filter((m) => m.address !== mintAddress), newMint],
      }
    })
    .addCase(removeCollectionFromMintLocal, (state, { payload: { mintAddress, collectionID } }) => {
      console.log('removeCollectionFromMintLocal', mintAddress, collectionID)
      console.log('state', state)
      const oldMint = state.firebaseMints.find((m) => m.address === mintAddress)
      if (!oldMint) {
        console.error('removeCollectionFromMintLocal: mint not found', mintAddress)
        return state
      }
      const newMint = {
        ...oldMint,
        collections: oldMint.collections?.filter((c) => c !== collectionID) ?? [],
      }
      console.log('removeCollectionFromMintLocal newMint', newMint)

      return {
        ...state,
        firebaseMints: [...state.firebaseMints.filter((m) => m.address !== mintAddress), newMint],
      }
    })

    .addCase(decrementMintSavedByLocal, (state, { payload: { mintAddress, userAddress } }) => {
      console.log('decrementMintSaves', mintAddress)
      console.log('state', state)
      const oldMint = state.firebaseMints.find((m) => m.address === mintAddress)
      if (!oldMint) {
        return state
      }
      const newMint = {
        ...oldMint,
        savedByUsers: oldMint.savedByUsers ? oldMint.savedByUsers.filter((u) => u !== userAddress) : undefined,
      }
      console.log('decrementMintSavedByLocal newMint', newMint)

      return {
        ...state,
        firebaseMints: [...state.firebaseMints.filter((m) => m.address !== mintAddress), newMint],
      }
    })

    .addCase(updateFirebaseMintRequest.pending, (state, { payload: { requestID, mintAddress } }) => {
      console.log('aState create mint request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          status: RequestStatus.Pending,
          type: 'update',
          context: mintAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(updateFirebaseMintRequest.fulfilled, (state, { payload: { requestID, mintData, preserveOffChain } }) => {
      console.log('aState updateFirebaseMintRequest.fulfilled', { state, requestID, mintData, preserveOffChain })
      const oldMint = state.firebaseMints.find((m) => m.address === mintData.address)
      const newMintData: MintData = { ...mintData }
      if (preserveOffChain && oldMint) {
        if (oldMint.savedByUsers) {
          newMintData.savedByUsers = oldMint.savedByUsers
        }
        if (oldMint.collections) {
          newMintData.collections = oldMint.collections
        }
      }
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: newMintData.address,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseMints: state.firebaseMints.filter((mint) => mint.address != newMintData.address).concat(newMintData),
      }
      return state
    })

    .addCase(appendFirebaseMintIfEmpty, (state, { payload: mintData }) => {
      const oldMint = state.firebaseMints.find((m) => m.address === mintData.address)
      if (oldMint) {
        return state
      }

      state = {
        ...state,
        firebaseMints: [...state.firebaseMints, mintData],
        requiredMints: [...state.requiredMints, mintData.address],
      }
      return state
    })
    .addCase(updateFirebaseMintRequest.rejected, (state, { payload: { requestID, errorMessage, mintAddress } }) => {
      console.log('aState query pending', { state, requestID, mintAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: mintAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseMintRequest.pending, (state, { payload: { requestID, mintAddress } }) => {
      console.log('aState fetchMintRequest pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: state.firebaseRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: mintAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseMintRequest.fulfilled, (state, { payload: { requestID, mintData } }) => {
      console.log('aState fetchFirebaseMintRequest fulfilled', { state, requestID, mint: mintData })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: mintData.address,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseMints: state.firebaseMints.filter((s) => s.address != mintData.address).concat(mintData),
      }
      return state
    })
    .addCase(fetchFirebaseMintRequest.rejected, (state, { payload: { requestID, errorMessage, mintAddress } }) => {
      console.log('aState fetchMintRequest rejected', { state, requestID, mintAddress })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: mintAddress,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchChainMintRequest.pending, (state, { payload: { requestID, mintAddress } }) => {
      console.log('aState fetchMintRequest pending', { state, requestID })
      state = {
        ...state,
        onChainRequests: state.onChainRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: mintAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchChainMintRequest.fulfilled, (state, { payload: { requestID, mintData } }) => {
      console.log('aState fetchChainMintRequest fulfilled', { state, requestID, mintData })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Fulfilled,
            context: mintData.address,
            timestamp: Date.now(),
          },
        ],
        chainMints: state.chainMints.filter((s) => s.address != mintData.address).concat(mintData),
        // chainMints: state.chainMints.concat(mintData),
      }
      return state
    })
    .addCase(fetchChainMintRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetchMintRequest rejected', { state, requestID })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchChainMintHistoryRequest.pending, (state, { payload: { requestID, mintAddress } }) => {
      console.log('aState fetchMintRequest pending', { state, requestID })
      state = {
        ...state,
        onChainRequests: state.onChainRequests.concat({
          requestID,
          type: 'history',
          status: RequestStatus.Pending,
          context: mintAddress,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchChainMintHistoryRequest.fulfilled, (state, { payload: { requestID, mintData } }) => {
      console.log('aState fetchChainMintRequest fulfilled', { state, requestID, mintData })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'history',
            status: RequestStatus.Fulfilled,
            context: mintData.address,
            timestamp: Date.now(),
          },
        ],
        chainMints: state.chainMints.filter((s) => s.address != mintData.address).concat(mintData),
        // chainMints: state.chainMints.concat(mintData),
      }
      return state
    })
    .addCase(fetchChainMintHistoryRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetchMintRequest rejected', { state, requestID })
      state = {
        ...state,
        onChainRequests: [
          ...state.onChainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'history',
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
