import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { User } from './models'
import { Request } from './reducer'

export function useRequiredUsers(): string[] {
  console.log('aState useRequiredUsers')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredUsers useAppSelector state: ', state)
    return state.users.requiredUsers
  })
}

export function useAllFirebaseUsers(): User[] {
  console.log('aState useHomepageUsers')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageUsers useAppSelector state: ', state)
    return state.users.firebaseUsers
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.users.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.users.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseUser(userAddress?: string): User | undefined {
  const allUsers = useAllFirebaseUsers()
  const user = useMemo(() => {
    return allUsers.find((user) => user.address === userAddress)
  }, [allUsers, userAddress])
  return user
}

export function useMintIsSaved(userAddress?: string, mint?: string): boolean {
  const allUsers = useAllFirebaseUsers()
  const user = useMemo(() => {
    console.log('useMintIsSaved userAddress1: ', userAddress)
    console.log('useMintIsSaved mint: ', mint)
    console.log('useMintIsSaved allUsers: ', allUsers)

    if (!userAddress || !mint) return false

    return allUsers.find((user) => {
      console.log('useMintIsSaved userAddress2: ', userAddress)
      console.log('useMintIsSaved user.address: ', user.address)
      if (user.address === userAddress) {
        return user.savedMints?.includes(mint)
      }
      return false
    })
      ? true
      : false
  }, [allUsers, mint, userAddress])
  return user
}

export function useUserSavedMints(userAddress?: string): string[] {
  const allUsers = useAllFirebaseUsers()
  return useMemo(() => {
    if (!userAddress) return []
    return allUsers.find((user) => user.address === userAddress)?.savedMints ?? []
  }, [allUsers, userAddress])
}

export function useAllChainUsers(): User[] {
  // console.log('aState useAllChainUsers')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllChainUsers useAppSelector state: ', state)
    return state.users.chainUsers
  })
}

export function useAllChainRequests(): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.users.onChainRequests
  })
}

export function useChainRequestsByContext(context?: string): Request[] {
  console.log('aState useChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequests useAppSelector state: ', state)
    return state.users.onChainRequests.filter((r) => r.context === context)
  })
}

export function useChainUser(userAddress?: string): User | undefined {
  const allUsers = useAllChainUsers()
  const user = useMemo(() => {
    return allUsers.find((user) => user.address === userAddress)
  }, [allUsers, userAddress])
  return user
}

export function useSafeUser(userAddress?: string): User | undefined {
  const firebaseUser = useFirebaseUser(userAddress)
  // const chainUser = useChainUser(userAddress)
  const allRequiredUsers = useRequiredUsers()

  return useMemo(() => {
    console.log('aState useSafeUser useMemo firebaseUser: ', firebaseUser)
    // console.log('aState useSafeUser useMemo chainUser: ', chainUser)
    return firebaseUser
  }, [firebaseUser])
}

export function useSafeUsers(userAddresses?: string[]): User[] {
  const firebaseUsers = useAllFirebaseUsers()
  const chainUsers = useAllChainUsers()

  return useMemo(() => {
    console.log('aState useSafeUsers useMemo firebaseUsers: ', firebaseUsers)
    console.log('aState useSafeUsers useMemo chainUsers: ', chainUsers)
    const users: User[] = []
    userAddresses?.forEach((userAddress) => {
      const firebaseUser = firebaseUsers.find((user) => user.address === userAddress)
      const chainUser = chainUsers.find((user) => user.address === userAddress)
      if (firebaseUser) {
        users.push(firebaseUser)
      } else if (chainUser) {
        users.push(chainUser)
      }
    })
    return users
  }, [chainUsers, firebaseUsers, userAddresses])
}
