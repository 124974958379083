import { createReducer } from '@reduxjs/toolkit'

import { addRequiredFeeds, fetchFirebaseFeedRequest, refreshFeed } from './actions'
import { Feed } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface FeedsState {
  readonly feeds: Feed[]
  // readonly chainFeeds: Feed[]
  readonly requests: Request[]
  // readonly onChainRequests: Request[]
  readonly requiredFeeds: string[]
}

const initialState: FeedsState = {
  feeds: [],
  // chainFeeds: [],
  requests: [],
  // onChainRequests: [],
  requiredFeeds: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshFeed, (state, { payload: feedID }) => {
      console.log('refreshFeed', feedID)
      console.log('state', state)
      state = {
        ...state,
        // requiredFeeds: [...state.requiredFeeds.filter((s) => !s.includes(feedID)), feedID],
        feeds: state.feeds.filter((feed) => feed.id !== feedID),
      }
      return state
    })
    .addCase(addRequiredFeeds, (state, { payload: feeds }) => {
      console.log('addRequiredFeeds', feeds)
      console.log('state', state)
      const set = [...state.requiredFeeds, ...feeds.filter((m) => !state.requiredFeeds.includes(m))]
      // const filtered = set.filter((item, index) => set.indexOf(item) === index)
      // console.log('addRequiredFeeds set', filtered)
      state = {
        ...state,
        requiredFeeds: set,
      }
      return state
    })
    // .addCase(incrementFeedSavedByLocal, (state, { payload: { feedID, userAddress } }) => {
    //   console.log('incrementFeedSaves', feedID)
    //   console.log('state', state)
    //   const oldFeed = state.feeds.find((m) => m.id === feedID)
    //   if (!oldFeed) {
    //     return state
    //   }
    //   const newFeed = { ...oldFeed, savedByUsers: [...(oldFeed.savedByUsers ?? []), userAddress] }

    //   return {
    //     ...state,
    //     feeds: [...state.feeds.filter((m) => m.id !== feedID), newFeed],
    //   }
    // })
    // .addCase(decrementFeedSavedByLocal, (state, { payload: { feedID, userAddress } }) => {
    //   console.log('decrementFeedSaves', feedID)
    //   console.log('state', state)
    //   const oldFeed = state.feeds.find((m) => m.id === feedID)
    //   if (!oldFeed) {
    //     return state
    //   }
    //   const newFeed = {
    //     ...oldFeed,
    //     savedByUsers: oldFeed.savedByUsers
    //       ? oldFeed.savedByUsers.filter((u) => u !== userAddress)
    //       : undefined,
    //   }
    //   console.log('decrementFeedSavedByLocal newFeed', newFeed)

    //   return {
    //     ...state,
    //     feeds: [...state.feeds.filter((m) => m.id !== feedID), newFeed],
    //   }
    // })

    // .addCase(updateFirebaseFeedRequest.pending, (state, { payload: { requestID, feedID } }) => {
    //   console.log('aState create feed request pending', { state, requestID })
    //   state = {
    //     ...state,
    //     requests: state.requests.concat({
    //       requestID,
    //       status: RequestStatus.Pending,
    //       type: 'update',
    //       context: feedID,
    //       timestamp: Date.now(),
    //     }),
    //   }
    //   return state
    // })
    // .addCase(
    //   updateFirebaseFeedRequest.fulfilled,
    //   (state, { payload: { requestID, feed, preserveOffChain } }) => {
    //     console.log('aState updateFirebaseFeedRequest.fulfilled', {
    //       state,
    //       requestID,
    //       feed,
    //       preserveOffChain,
    //     })
    //     const oldFeed = state.feeds.find((m) => m.id === feed.id)
    //     const newFeed: Feed = { ...feed }
    //     if (preserveOffChain && oldFeed) {
    //       if (oldFeed.savedByUsers) {
    //         newFeed.savedByUsers = oldFeed.savedByUsers
    //       }
    //       if (oldFeed.feeds) {
    //         newFeed.feeds = oldFeed.feeds
    //       }
    //     }
    //     state = {
    //       ...state,
    //       requests: [
    //         ...state.requests.filter((r) => r.requestID !== requestID),
    //         {
    //           requestID,
    //           type: 'update',
    //           context: newFeed.id,
    //           status: RequestStatus.Fulfilled,
    //           timestamp: Date.now(),
    //         },
    //       ],
    //       feeds: state.feeds
    //         .filter((feed) => feed.id != newFeed.id)
    //         .concat(newFeed),
    //     }
    //     return state
    //   }
    // )

    // .addCase(appendFirebaseFeedIfEmpty, (state, { payload: feed }) => {
    //   const oldFeed = state.feeds.find((m) => m.id === feed.id)
    //   if (oldFeed) {
    //     return state
    //   }

    //   state = {
    //     ...state,
    //     feeds: [...state.feeds, feed],
    //     requiredFeeds: [...state.requiredFeeds, feed.id],
    //   }
    //   return state
    // })
    // .addCase(
    //   updateFirebaseFeedRequest.rejected,
    //   (state, { payload: { requestID, errorMessage, feedID } }) => {
    //     console.log('aState query pending', { state, requestID, feedID })
    //     state = {
    //       ...state,
    //       requests: [
    //         ...state.requests.filter((r) => r.requestID !== requestID),
    //         {
    //           requestID,
    //           type: 'update',
    //           context: feedID,
    //           status: RequestStatus.Rejected,
    //           errorMessage: errorMessage.toString(),
    //           timestamp: Date.now(),
    //         },
    //       ],
    //     }
    //     return state
    //   }
    // )
    .addCase(fetchFirebaseFeedRequest.pending, (state, { payload: { requestID, feedID } }) => {
      console.log('aState fetchFeedRequest pending', { state, requestID })
      state = {
        ...state,
        requests: state.requests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: feedID,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseFeedRequest.fulfilled, (state, { payload: { requestID, feed } }) => {
      console.log('aState fetchFirebaseFeedRequest fulfilled', { state, requestID, feedID: feed.id })
      state = {
        ...state,
        requests: [
          ...state.requests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: feed.id,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        feeds: state.feeds.filter((s) => s.id != feed.id).concat(feed),
      }
      return state
    })
    .addCase(fetchFirebaseFeedRequest.rejected, (state, { payload: { requestID, errorMessage, feedID } }) => {
      console.log('aState fetchFeedRequest rejected', { state, requestID, feedID })
      state = {
        ...state,
        requests: [
          ...state.requests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: feedID,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
