import { array, boolean, Infer, nullable, number, optional, string, type } from 'superstruct'
import { MetadataCombined } from 'validators/accounts/sales'

export type Collection = Infer<typeof Collection>
export const Collection = type({
  candyMachine: string(),
  createdDate: string(),
  description: string(),
  discord: string(),
  id: string(),
  mints: array(string()),
  name: string(),
  image: optional(string()),
  onChainCollectionKey: string(),
  twitter: string(),
  type: string(),
  updatedDate: string(),
  user: string(),
  website: string(),
})
