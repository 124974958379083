import { Trans } from '@lingui/macro'
import { useWallet } from '@solana/wallet-adapter-react'
import { pubkeyFromStringForced } from 'apollo/utils'
import { BaseButton, ButtonCreate, ButtonSecondary } from 'components/Button'
import { OutlineCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer } from 'components/Loader/styled'
import Row, { AutoRow, RowBetween } from 'components/Row'
import UserListModal, { UserListModalType } from 'components/UserListModal/UserListModal'
import UserLogo from 'components/UserLogo'
import { walletDisplayText } from 'components/UserRow'
import useTheme from 'hooks/useTheme'
import { opacify, transparentize } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card } from 'rebass'
import { addRequiredActionQueries } from 'state/actions/actions'
import { ActionType, RequiredActionQuery } from 'state/actions/reducer'
import { useToggleModal, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { addRequiredWallets } from 'state/wallets/actions'
import { useEditWalletFollowing } from 'state/wallets/firebase'
import { useSafeWallet } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { StyledInternalLink, TYPE } from 'theme'
import { truncateString } from 'utils'

import UserListPreview from './UserListPreview'

const PageWrapper = styled.div`
  // width: 90%;
  // overflow-y: scroll;
  min-width: 250px;
  // max-width: 500px;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SectionButton = styled(BaseButton)<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.bg5 : theme.bg1)};
  color: ${({ isActive, theme }) => (isActive ? theme.text5 : theme.secondary1)};
  
  text-decoration: none !important;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  width: fit-content;
  hover: {
    background-color: ${({ isActive, theme }) => (isActive ? theme.bg0 : theme.bg1)};
    color: ${({ isActive, theme }) => (isActive ? theme.text1 : theme.text1)};
  },
`

const ButtonsGrid = styled(AutoRow)`
  margin-top: 10px;
  // justify-content: center;
  // align-items: center;
  gap: 0.5rem;
  width: 100%;
`

enum BottomSection {
  Owned = 'Owned',
  Saved = 'Saved',
  ForSale = 'ForSale',
  Offers = 'Offers',
  Activity = 'Activity',
}

const BannerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

export const DropCard = styled(Card)`
  // background-color: ${({ theme }) => opacify(0, theme.bg2)};
  border-radius: 4px 4px 4px 4px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 3.6759px 137.846px rgba(0, 0, 0, 0.13));
  margin-bottom: 10px;
  width: 100%;
  // height: 155px;
  color: ${({ theme }) => theme.text2};
  z-index: 100;
  padding: 5px 5px;
`
const FollowsContainer = styled.div`
  margin-left: 30px;
  // border: 1px solid ${({ theme }) => theme.text3};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 3px 6px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const VerticalSepLine = styled.div`
  width: 10px;
  height: 100%;
  background-color: ${({ theme }) => theme.yellow1};
  margin: 0 10px;
`

export const FollowButton = styled(BaseButton)<{ xColor?: string }>`
  // ${({ theme }) => theme.flexColumnNoWrap}
  border: ${({ xColor, theme }) => `2px solid ${xColor ?? theme.text3}`};
  color: ${({ xColor, theme }) => xColor ?? theme.text3};
  padding: ${({ padding }) => padding ?? '5px 7px'};
  font-weight: 600;
  width: 100px;
  border-radius: 8px;
  font-size: 14px;
  background-color: ${({ theme }) => transparentize(0.5, theme.bg0)};
  color: ${({ theme }) => theme.text2};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.1, theme.bg0)};
    color: ${({ theme }) => theme.primary1};
  }
  &:hover {
    background-color: ${({ theme }) => transparentize(0.1, theme.bg0)};
    color: ${({ theme }) => theme.primary1};
  }
  &:active {
    background-color: ${({ theme }) => transparentize(0.1, theme.bg0)};
    color: ${({ theme }) => theme.primary1};
  }
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
export default function UserHeader({ userAddress }: { userAddress?: string }) {
  // const wallet = useAnchorWallet()
  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()
  const { publicKey, disconnect } = useWallet()

  // const user = useSafeUser(userAddress)

  // useEffect(() => {
  //   if (userAddress) {
  //     dispatch(addRequiredUsers([userAddress]))
  //   }
  // }, [userAddress, dispatch])

  // const actionQueryRequest = useMemo(
  //   () => (userAddress ? new RequiredActionQuery(ActionType.purchase, [userAddress]) : undefined),
  //   [userAddress]
  // )
  // console.log('ExplorePage actionQueryRequest', actionQueryRequest)

  // useEffect(() => {
  //   if (actionQueryRequest) {
  //     dispatch(addRequiredActionQueries([actionQueryRequest]))
  //   }
  // }, [dispatch, actionQueryRequest])

  // const actions = useActionQueryActions()

  const pgWallet = useSafeWallet(userAddress)
  useEffect(() => {
    if (userAddress) {
      try {
        const tryPubkey = pubkeyFromStringForced(userAddress)
        dispatch(addRequiredWallets([tryPubkey.toBase58()]))
      } catch (e) {
        console.error(e)
      }
    }
  }, [userAddress, dispatch, pgWallet])

  const nameDisplayText = useMemo(
    () => (pgWallet ? walletDisplayText(pgWallet) : truncateString(userAddress)),
    [userAddress, pgWallet]
  )

  const clientWallet = useSafeWallet(publicKey?.toBase58())
  useEffect(() => {
    if (publicKey) {
      dispatch(addRequiredWallets([publicKey.toBase58()]))
    }
  }, [dispatch, publicKey])

  const userIsFollowing = useMemo(() => {
    if (userAddress && clientWallet) {
      return clientWallet?.following?.includes(userAddress)
    }
    return false
  }, [userAddress, clientWallet])

  const editWalletFollowing = useEditWalletFollowing()
  const toggleConnectWallet = useWalletModalToggle()
  const toggleFollowWarningModal = useToggleModal(ApplicationModal.MAX_FOLLOWING)
  const onPressFollow = useCallback(() => {
    if (!publicKey || !clientWallet) {
      toggleConnectWallet()
    } else {
      if (userIsFollowing) {
        if (userAddress && clientWallet) {
          editWalletFollowing(clientWallet, userAddress, false)
        }
      } else {
        // if (clientWallet?.following?.length >= 10) {
        // toggleFollowWarningModal()
        // return
        // }
        if (userAddress && clientWallet) {
          editWalletFollowing(clientWallet, userAddress, true)
        }
      }
    }
  }, [publicKey, clientWallet, toggleConnectWallet, userIsFollowing, userAddress, editWalletFollowing])

  const [openFollowersModal, setOpenFollowersModal] = useState(false)
  const [openFollowingModal, setOpenFollowingModal] = useState(false)
  return userAddress ? (
    <>
      <Wrapper>
        {/* <BannerWrapper>
          <UserBanner userAddress={userAddress} height={'350px'}></UserBanner>
        </BannerWrapper> */}

        {/* <HeaderCardWrapper> */}
        <DropCard>
          <RowBetween>
            <Row width={'fit-content'} alignItems={'start'}>
              {/* <LogoWrapper> */}
              <UserLogo size="36px" userAddress={userAddress}></UserLogo>
              {/* </LogoWrapper> */}
              <TYPE.heavy fontSize={'32px'} color={theme.text2} style={{ marginLeft: '10px' }}>
                {nameDisplayText}
              </TYPE.heavy>
              {/* <ExplorerLink address={userAddress} /> */}
              <FollowsContainer>
                <AutoColumn>
                  <TYPE.heavy fontSize={'14px'} color={theme.text3}>
                    Followers
                  </TYPE.heavy>
                  <UserListPreview
                    walletAddresses={pgWallet?.followedBy}
                    onClick={() => {
                      setOpenFollowersModal(true)
                    }}
                  />
                </AutoColumn>
                <div
                  style={{
                    height: '48px',
                    width: '1px',
                    backgroundColor: theme.text4,
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                ></div>
                <AutoColumn>
                  <TYPE.heavy fontSize={'14px'} color={theme.text3}>
                    Following
                  </TYPE.heavy>
                  <UserListPreview
                    walletAddresses={pgWallet?.following}
                    onClick={() => {
                      setOpenFollowingModal(true)
                    }}
                  />
                </AutoColumn>
              </FollowsContainer>
            </Row>
            {publicKey?.toBase58() == userAddress ? (
              <>
                <WalletAction
                  style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                  onClick={() => {
                    disconnect()
                  }}
                >
                  <Trans>Disconnect</Trans>
                </WalletAction>
              </>
            ) : (
              <FollowButton onClick={onPressFollow}>{userIsFollowing ? 'Unfollow' : 'Follow'}</FollowButton>
            )}
          </RowBetween>
          {/* <ButtonsGrid>
            <SectionButton
              as={StyledInternalLink}
              to={`/user/${userAddress}`}
              isActive={history.location.pathname == `/user/${userAddress}`}
            >
              Activity
            </SectionButton>
            <SectionButton
              as={StyledInternalLink}
              to={`/user/${userAddress}/owned`}
              isActive={history.location.pathname.includes(`/user/${userAddress}/owned`)}
            >
              Owned
            </SectionButton>
            {userAddress == publicKey?.toBase58() && (
              <SectionButton
                // width={'100%'}
                as={'button'}
                style={{ backgroundColor: theme.transparent }}
                isActive={false}
                onClick={() => {
                  console.log('clicked')
                  disconnect()
                  // walletModalToggle()
                }}
              >
                <TYPE.heavy fontSize={'14px'} color={theme.blue1}>
                  Disconnect
                </TYPE.heavy>
              </SectionButton>
            )}
          </ButtonsGrid> */}
        </DropCard>
        {/* </HeaderCardWrapper> */}
      </Wrapper>
      <UserListModal
        userAddress={userAddress}
        isOpen={openFollowersModal}
        onDismiss={() => {
          setOpenFollowersModal(false)
        }}
        type={UserListModalType.followers}
      />
      <UserListModal
        userAddress={userAddress}
        isOpen={openFollowingModal}
        onDismiss={() => {
          setOpenFollowingModal(false)
        }}
        type={UserListModalType.following}
      />
    </>
  ) : (
    <>
      <PageWrapper>
        <AutoRow>
          <TYPE.heavy>User not found</TYPE.heavy>
        </AutoRow>
      </PageWrapper>
    </>
  )
}
