import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import Vibrant from 'node-vibrant/lib/bundle'
import { shade } from 'polished'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'

import { fetchFirebaseFeedRequest } from './actions'
import { Feed } from './models'

export const FEEDS_COLLECTION = 'feeds'

export function useFetchFirebaseFeed(): (feedID: string) => Promise<Feed | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (feedID: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseFeedRequest.pending({ feedID, requestID }))
      try {
        const db = getFirestore()
        const feedSnap = await getDoc(doc(db, FEEDS_COLLECTION, feedID))
        console.log('firebase feeds: ', feedSnap)
        if (feedSnap.exists()) {
          console.log('firebase Document data:', feedSnap.data())
          const feed = decodeFirebaseFeed(feedSnap.data())
          console.log('firebase feed decoded: ', feed)
          dispatch(fetchFirebaseFeedRequest.fulfilled({ requestID, feed }))
          return feed
        } else {
          console.log('firebase Feed not found. Creating.')
          return undefined
        }
        // return feed
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseFeedRequest.rejected({ requestID, errorMessage: e, feedID }))
        throw e
      }
    },
    [dispatch]
  )
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   feed: StringPublicKey
//   data: Data
//   primaryFeedHappened: boolean
//   isMutable: boolean
//   editionNonce: number | undefined
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | undefined
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | undefined
//   feed?: IMetadataFeed | undefined
// }

// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.id = args.id
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | undefined
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | undefined
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }

// export interface IMetadataFeed {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }
//
const encodeFeedObject = (feed: Feed) => {
  return feed
  console.log('encodeFeedObject: ', feed)
  // const feedObj = {
  //   // ...feed,
  //   pubkey: feed.pubkey.toBase58(),
  //   feed: encodeFeed(feed.feed),
  //   metadata: feed.metadata
  //     ? {
  //         key: feed.metadata.key,
  //         updateAuthority: feed.metadata.updateAuthority.toBase58(),
  //         feed: feed.metadata.feed.toBase58(),
  //         data: feed.metadata.data
  //           ? {
  //               name: feed.metadata.data?.name,
  //               symbol: feed.metadata.data.symbol,
  //               uri: feed.metadata.data.uri,
  //               sellerFeeBasisPoints: feed.metadata.data.sellerFeeBasisPoints,
  //               creators: feed.metadata.data.creators
  //                 ? feed.metadata.data.creators.map((creator) => ({
  //                     address: creator.id.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //             }
  //           : {},
  //         primaryFeedHappened: feed.metadata.primaryFeedHappened,
  //         isMutable: feed.metadata.isMutable,
  //         editionNonce: feed.metadata.editionNonce ?? '',
  //         extended: feed.metadata.extended
  //           ? {
  //               name: feed.metadata.extended.name,
  //               symbol: feed.metadata.extended.symbol,
  //               creators: feed.metadata.extended.creators
  //                 ? feed.metadata.extended.creators.map((creator) => ({
  //                     address: creator.id.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //               description: feed.metadata.extended.description,
  //               image: feed.metadata.extended.image,
  //               animation_url: feed.metadata.extended.animation_url ?? '',
  //               external_url: feed.metadata.extended.external_url,
  //               seller_fee_basis_points: feed.metadata.extended.seller_fee_basis_points,
  //               properties: {
  //                 files: feed.metadata.extended.properties.files
  //                   ? feed.metadata.extended.properties.files.map((file) => {
  //                       return {
  //                         file: file.valueOf(),
  //                         content_type: file.type ?? '',
  //                       }
  //                     })
  //                   : [],
  //                 category: feed.metadata.extended.properties.category,
  //                 maxSupply: feed.metadata.extended.properties.maxSupply ?? '',
  //               },
  //               attributes: feed.metadata.extended.attributes
  //                 ? feed.metadata.extended.attributes.map((attribute) => ({
  //                     trait_type: attribute.trait_type,
  //                     value: attribute.value,
  //                   }))
  //                 : [],
  //               feed: feed.metadata.extended.feed
  //                 ? {
  //                     name: feed.metadata.extended.feed.name,
  //                     family: feed.metadata.extended.feed.family,
  //                   }
  //                 : {},
  //             }
  //           : {},
  //       }
  //     : {},
  //   asks: feed.asks ? feed.asks.map((ask) => encodeAskV2(ask)) : [],
  //   bids: feed.bids ? feed.bids.map((bid) => encodeBidV2(bid)) : [],
  //   owner: feed.owner?.toBase58() ?? '',
  // }

  // console.log('encodeFeedObject: ', feedObj)

  // return feedObj
}

export const decodeFirebaseFeed = (res: any) => {
  console.log('decodeFirebaseFeed: ', res)
  const md2 = create({ ...res }, Feed)
  return md2
}

// export function useUpdateFirebaseFeed(): (feed: Feed) => Promise<void> {
//   const dispatch = useAppDispatch()
//   return useCallback(
//     async (feed: Feed) => {
//       const requestID = nanoid()
//       dispatch(updateFirebaseFeedRequest.pending({ feedID: feed.id, requestID }))
//       if (feed.savedByUsers || feed.feeds) {
//         throw new Error('Cannot update feed with savedByUsers or feeds')
//       }
//       try {
//         const db = getFirestore()
//         // let color = null
//         console.log('useUpdateFirebaseFeed feed: ', feed)
//         const feedObj = encodeFeedObject({ ...feed })
//         console.log('useUpdateFirebaseFeed feedObj: ', feedObj)
//         console.log('firebase uploading feed: ', feedObj)
//         setDoc(doc(db, COLLECTIONS_COLLECTION, feed.id), feedObj).then(() => {
//           console.log('firebase feed uploaded')
//           dispatch(updateFirebaseFeedRequest.fulfilled({ requestID, feed, preserveOffChain: true }))
//         })
//       } catch (e) {
//         console.error(e)
//         dispatch(
//           updateFirebaseFeedRequest.rejected({
//             feedID: feed.id,
//             requestID,
//             errorMessage: e,
//           })
//         )
//         // throw e
//       }
//     },
//     [dispatch]
//   )
// }

export async function getColorFromUriPath(uri?: string): Promise<string | null> {
  if (!uri) {
    return null
  }
  const formattedPath = uriToHttp(uri)[0]

  const palette = await Vibrant.from(formattedPath).getPalette()
  if (!palette?.Vibrant) {
    return null
  }

  let detectedHex = palette.Vibrant.hex
  let AAscore = hex(detectedHex, '#FFF')
  while (AAscore < 3) {
    detectedHex = shade(0.005, detectedHex)
    AAscore = hex(detectedHex, '#FFF')
  }

  return detectedHex
}
