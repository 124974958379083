import { Edition, EditionMarker, MasterEditionV1, MasterEditionV2 } from 'metaplex/classes'
import { array, boolean, defaulted, enums, Infer, nullable, number, optional, string, type, union } from 'superstruct'
import { BoolFromNumber, NumberFromBool, NumberFromString, StringFromPublicKey } from 'validators/pubkey'

export type MakeListingAction = Infer<typeof MakeListingAction>
export const MakeListingAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  assetMint: string(),
  assetSize: number(),
  paymentMint: string(),
  paymentSize: number(),
  time: number(),
})

export type MakeOfferAction = Infer<typeof MakeOfferAction>
export const MakeOfferAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  assetMint: string(),
  assetSize: number(),
  paymentMint: string(),
  paymentSize: number(),
  time: number(),
})

export type PurchaseAction = Infer<typeof PurchaseAction>
export const PurchaseAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  seller: string(),
  buyer: string(),
  assetMint: string(),
  assetSize: number(),
  paymentMint: string(),
  paymentSize: number(),
  time: number(),
})

export type CancelListingAction = Infer<typeof CancelListingAction>
export const CancelListingAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  assetMint: string(),
  assetSize: number(),
  paymentMint: string(),
  paymentSize: number(),
  time: number(),
})

export type TransferAction = Infer<typeof TransferAction>
export const TransferAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  sender: string(),
  receiver: string(),
  assetMint: string(),
  assetSize: number(),
  time: number(),
})

export type MintAction = Infer<typeof MintAction>
export const MintAction = type({
  id: string(),
  type: string(),
  wallet: string(),
  assetMint: string(),
  assetSize: number(),
  time: number(),
})

export type Action = Infer<typeof Action>
export const Action = union([
  MakeListingAction,
  MakeOfferAction,
  PurchaseAction,
  CancelListingAction,
  TransferAction,
  MintAction,
])
