import { Program } from '@project-serum/anchor'
import { Connection, PublicKey } from '@solana/web3.js'
import { pubkeyFromString } from 'apollo/utils'
import { useApolloProgram } from 'components/SolanaManager'
import { info } from 'console'
import { providerURL } from 'hooks/web3'
import * as Cache from 'providers/cache'
import { ActionType, FetchStatus } from 'providers/cache'
import React from 'react'
import { create } from 'superstruct'
import { TokenAccountInfo } from 'validators/accounts/token'

import { bidFromMint, saleFromMint } from './utils/bundleMints'

export type TokenInfoWithPubkey = {
  info: TokenAccountInfo
  pubkey: PublicKey
}

interface AccountTokens {
  tokens?: TokenInfoWithPubkey[]
}

type State = Cache.State<AccountTokens>
type Dispatch = Cache.Dispatch<AccountTokens>

const StateContext = React.createContext<State | undefined>(undefined)
const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

type ProviderProps = { children: React.ReactNode }
export function TokensProvider({ children }: ProviderProps) {
  const [state, dispatch] = Cache.useReducer<AccountTokens>()

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')

const url = providerURL()
const connection = new Connection(url)
async function fetchAccountTokens(dispatch: Dispatch, pubkey: PublicKey) {
  const key = pubkey.toBase58()
  dispatch({
    type: ActionType.Update,
    key,
    status: FetchStatus.Fetching,
  })

  let status
  let data
  try {
    console.log('tokens fetching tokens for', pubkey.toBase58())
    // console.log('url', url)
    const { value } = await new Connection(url, 'processed').getParsedTokenAccountsByOwner(pubkey, {
      programId: TOKEN_PROGRAM_ID,
    })
    const tokens: TokenInfoWithPubkey[] = []
    for (const accountInfo of value) {
      try {
        console.log('tokens accountInfo', accountInfo)
        const parsedInfo = accountInfo.account.data.parsed.info
        const info = create(parsedInfo, TokenAccountInfo)
        console.log('tokens info parsed', info)
        console.log('tokens info parsed owner', info.owner.toBase58())
        // const salePubkey = await saleFromMint(connection, parsedInfo.mint)
        // console.log('tokens salePubkey', salePubkey)
        // console.log('tokens userTokens salePubkey', salePubkey)
        // const bidPubkey = await bidFromMint(connection, parsedInfo.mint)
        // console.log('tokens idPubkey', bidPubkey)
        // console.log('tokens userTokens bidPubkey', bidPubkey)
        info.sale = undefined
        info.bid = undefined
        tokens.push({ info: { ...info }, pubkey: accountInfo.pubkey })
      } catch (e) {
        console.log('tokens error', e)
      }
    }
    data = {
      tokens,
    }
    console.log(
      'fetched tokens:',
      data.tokens.map((token) => token.pubkey.toBase58())
    )
    status = FetchStatus.Fetched
  } catch (error) {
    console.error('tokens error', error)
    status = FetchStatus.FetchFailed
  }
  dispatch({ type: ActionType.Update, status, data, key })
}

export function useAccountOwnedTokens(address?: string): Cache.CacheEntry<AccountTokens> | undefined {
  const context = React.useContext(StateContext)

  if (!context) {
    throw new Error(`useAccountOwnedTokens must be used within a AccountsProvider`)
  }

  return address ? context.entries[address] : undefined
}

export function useFetchAccountOwnedTokens(refresh?: boolean) {
  const dispatch = React.useContext(DispatchContext)
  const state = React.useContext(StateContext)

  if (!dispatch) {
    throw new Error(`useFetchAccountOwnedTokens must be used within a AccountsProvider`)
  }
  if (!state) {
    throw new Error(`useFetchAccountOwnedTokens must be used within a AccountsProvider`)
  }

  const before = state.entries
  // console.log('useFetchAccountOwnedTokens before', before)
  return React.useCallback(
    (address: string) => {
      const pubkey = pubkeyFromString(address)
      if (pubkey) {
        if (!refresh) {
          if (!before[pubkey.toBase58()]) {
            fetchAccountTokens(dispatch, pubkey)
          } else {
            console.log('useFetchAccountOwnedTokens already fetched!')
          }
        } else {
          fetchAccountTokens(dispatch, pubkey)
        }
      }
    },
    [refresh, before, dispatch]
  )
}
