import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import { disableCollectionFilter, enableCollectionFilter, querySalesByCollectionIDsRequest } from './actions'

export interface ApolloState {
  readonly currentHomepageSales: string[]
  readonly pendingUpdate: string[] | null
  readonly loadingRequestId: string | null
  readonly error: string | null
  readonly collectionIDsFilter: string[]
}

type Mutable<T> = { -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U> ? U[] : T[P] }

const initialState: ApolloState = {
  currentHomepageSales: [],
  loadingRequestId: null,
  pendingUpdate: null,
  error: null,
  collectionIDsFilter: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(querySalesByCollectionIDsRequest.pending, (state, { payload: { requestId, collectionIDs } }) => {
      console.log('aState query pending', { state, requestId, collectionIDs })
      state = {
        ...state,
        loadingRequestId: requestId,
      }
      return state
    })
    .addCase(querySalesByCollectionIDsRequest.fulfilled, (state, { payload: { requestId, collectionIDs, sales } }) => {
      console.log('aState query fulfilled', { state, requestId, collectionIDs, sales })
      state.currentHomepageSales = sales
      return state
    })
    .addCase(querySalesByCollectionIDsRequest.rejected, (state, { payload: { requestId, errorMessage } }) => {
      console.log('aState query rejected', { state, requestId, errorMessage })
      if (state.loadingRequestId !== requestId) {
        // no-op since it's not the latest request
        return
      }

      state = {
        ...state,
        error: errorMessage,
      }
      return state
    })
    .addCase(enableCollectionFilter, (state, { payload: collectionID }) => {
      if (!state.collectionIDsFilter.includes(collectionID)) {
        state.collectionIDsFilter.push(collectionID)
      }
      return state
    })
    .addCase(disableCollectionFilter, (state, { payload: collectionID }) => {
      if (state.collectionIDsFilter.includes(collectionID)) {
        state.collectionIDsFilter = state.collectionIDsFilter.filter((id) => id !== collectionID)
      }

      return state
    })
)
