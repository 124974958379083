import {
  getFilteredProgramAccounts,
  getHashedName,
  getNameAccountKey,
  getNameOwner,
  NAME_PROGRAM_ID,
  NameRegistryState,
} from '@bonfida/spl-name-service'
import { Connection, PublicKey } from '@solana/web3.js'
import BN from 'bn.js'
import { useEffect, useState } from 'react'

import { providerURL } from './web3'

// Name auctionning Program ID
export const PROGRAM_ID = new PublicKey('jCebN34bUfdeUYJT13J1yG16XWQpt5PDx6Mse9GUqhR')

export interface DomainInfo {
  name: string
  address: PublicKey
  class: PublicKey
}

async function getDomainKey(name: string, nameClass?: PublicKey, nameParent?: PublicKey) {
  const hashedDomainName = await getHashedName(name)
  const nameKey = await getNameAccountKey(hashedDomainName, nameClass, nameParent)
  return nameKey
}

export async function findOwnedNameAccountsForUser(
  connection: Connection,
  userAccount: PublicKey
): Promise<PublicKey[]> {
  const filters = [
    {
      memcmp: {
        offset: 32,
        bytes: userAccount.toBase58(),
      },
    },
  ]
  const accounts = await getFilteredProgramAccounts(connection, NAME_PROGRAM_ID, filters)
  return accounts.map((a) => a.publicKey)
}

export async function performReverseLookup(connection: Connection, nameAccounts: PublicKey[]): Promise<DomainInfo[]> {
  const [centralState] = await PublicKey.findProgramAddress([PROGRAM_ID.toBuffer()], PROGRAM_ID)

  const reverseLookupAccounts = await Promise.all(
    nameAccounts.map((name) => getDomainKey(name.toBase58(), centralState))
  )

  const names = await NameRegistryState.retrieveBatch(connection, reverseLookupAccounts)

  return names
    .map((name: any) => {
      if (!name?.data) {
        return undefined
      }
      const nameLength = new BN(name!.data.slice(0, 4), 'le').toNumber()
      return {
        name: name.data.slice(4, 4 + nameLength).toString() + '.sol',
        address: name.address,
        class: name.class,
      }
    })
    .filter((e: any) => !!e) as DomainInfo[]
}

export const useUserDomains = (address?: PublicKey): [DomainInfo[] | null, boolean] => {
  const [result, setResult] = useState<DomainInfo[] | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const resolve = async () => {
      if (!address) {
        return
      }
      // Allow only mainnet and custom
      const connection = new Connection(providerURL(), 'confirmed')
      try {
        setLoading(true)
        const domains = await findOwnedNameAccountsForUser(connection, address)
        const names = await performReverseLookup(connection, domains)
        names.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
        setResult(names)
      } catch (err) {
        console.log(`Error fetching user domains ${err}`)
      } finally {
        setLoading(false)
      }
    }
    resolve()
  }, [address]) // eslint-disable-line react-hooks/exhaustive-deps

  return [result, loading]
}

const SOL_TLD_AUTHORITY = new PublicKey('58PwtjSDuFHuUkYjH9BYnnQKHfwo9reZhC2zMJv9JPkx')
export async function getDomainInfo(domain: string, connection: Connection) {
  const domainKey = await getDomainKey(
    domain.slice(0, -4), // remove .sol
    undefined,
    SOL_TLD_AUTHORITY
  )
  try {
    const registry = await getNameOwner(connection, domainKey)
    return registry && registry.registry.owner
      ? {
          owner: registry.registry.owner.toString(),
          address: domainKey.toString(),
        }
      : null
  } catch {
    return null
  }
}
