import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { TokenList } from '@uniswap/token-lists'

export const querySalesByCollectionIDsRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ collectionIDs: string[]; requestId: string }>
  fulfilled: ActionCreatorWithPayload<{ collectionIDs: string[]; sales: string[]; requestId: string }>
  rejected: ActionCreatorWithPayload<{ collectionIDs: string[]; errorMessage: string; requestId: string }>
}> = {
  pending: createAction('apollo/querySalesByCollectionIDs/pending'),
  fulfilled: createAction('apollo/querySalesByCollectionIDs/fulfilled'),
  rejected: createAction('apollo/querySalesByCollectionIDs/rejected'),
}

// select which lists to search across from loaded lists
export const enableCollectionFilter = createAction<string>('apollo/enableCollectionFilter')
export const disableCollectionFilter = createAction<string>('apollo/disableCollectionFilter')
