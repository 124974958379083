import { createReducer } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'

import { DEFAULT_ACTIVE_LIST_URLS } from '../../constants/lists'
import { DEFAULT_LIST_OF_LISTS } from '../../constants/lists'
import { updateVersion } from '../global/actions'
import {
  addRequiredOrderQueries,
  addRequiredOrders,
  createOrderOnChainRequest,
  fetchFirebaseOrderQueryRequest,
  fetchFirebaseOrderRequest,
  matchOrdersOnChainRequest,
  refreshOrder,
  revokeOrderOnChainRequest,
  updateFirebaseOrderRequest,
} from './actions'
import { ORDER_SIDE } from './chain'
import { Order } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
  txRes?: string
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export type ChainOrderStatus = {
  orderPDA: string
  found: boolean
  revoked: boolean
}

export type OrderQuery = {
  queryKey: string
  orderPDAs: string[]
}

export class RequiredOrderQuery {
  public assetMints: string[] | null
  public orderSide: ORDER_SIDE | null
  public key: string
  public onlyLive: boolean
  public all: boolean
  // public live: boolean

  constructor(assetMints?: string[], orderSide?: ORDER_SIDE, onlyLive?: boolean, all?: boolean) {
    this.assetMints = assetMints ?? null
    this.orderSide = orderSide ?? null
    this.onlyLive = onlyLive ?? true
    this.all = all ?? false
    this.key = all
      ? 'all'
      : `${assetMints?.sort((a, b) => a.localeCompare(b)).map((am) => am + '-')}${orderSide ?? ''}-${this.onlyLive}`
  }
}

export interface OrdersState {
  readonly requiredOrders: string[]
  readonly firebaseOrders: Order[]
  readonly firebaseRequests: Request[]
  readonly chainOrderStatuses: Order[]
  readonly chainRequests: Request[]
  readonly orderQueries: OrderQuery[]
  readonly requiredOrderQueries: RequiredOrderQuery[]
  readonly queryRequests: Request[]
}

const initialState: OrdersState = {
  requiredOrders: [],
  firebaseOrders: [],
  firebaseRequests: [],
  chainOrderStatuses: [],
  chainRequests: [],
  orderQueries: [],
  requiredOrderQueries: [],
  queryRequests: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshOrder, (state, { payload: orderPDA }) => {
      console.log('refreshOrder', orderPDA)
      state = {
        ...state,
        firebaseOrders: state.firebaseOrders.filter((o) => o.delegatePDA !== orderPDA),
      }
      return state
    })
    .addCase(addRequiredOrders, (state, { payload: orders }) => {
      console.log('addRequiredOrders', orders)
      console.log('state', state)
      const set = [...state.requiredOrders, ...orders]
      const filtered = set.filter((item, index) => set.indexOf(item) === index)
      console.log('addRequiredOrders set', filtered)
      state = {
        ...state,
        requiredOrders: filtered,
      }
      return state
    })
    .addCase(addRequiredOrderQueries, (state, { payload: orderQueries }) => {
      console.log('addRequiredOrderQueries', orderQueries)
      const newKeys = orderQueries.map((oq) => oq.key)
      const set = state.requiredOrderQueries.filter((rq) => !newKeys.includes(rq.key)).concat(orderQueries)
      console.log('addRequiredOrderQueries set', set)
      state = {
        ...state,
        requiredOrderQueries: set,
      }
      return state
    })
    .addCase(updateFirebaseOrderRequest.pending, (state, { payload: { requestID, orderPDA } }) => {
      console.log('aState create order request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests,
          { requestID, status: RequestStatus.Pending, type: 'update', context: orderPDA, timestamp: Date.now() },
        ],
      }
      return state
    })
    .addCase(updateFirebaseOrderRequest.fulfilled, (state, { payload: { requestID, order } }) => {
      console.log('aState create order request fulfilled', { state, requestID })
      state = {
        ...state,
        firebaseOrders: [...state.firebaseOrders.filter((o) => o.delegatePDA != order.delegatePDA), order],
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            context: order.delegatePDA,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(updateFirebaseOrderRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState create order request rejected', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'update',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseOrderRequest.pending, (state, { payload: { requestID } }) => {
      console.log('aState fetch order request pending', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests,
          { requestID, status: RequestStatus.Pending, type: 'fetch', timestamp: Date.now() },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseOrderRequest.fulfilled, (state, { payload: { requestID, order } }) => {
      console.log('aState fetch order request fulfilled', { state, requestID })
      state = {
        ...state,
        firebaseOrders: [...state.firebaseOrders.filter((o) => o.delegatePDA != order.delegatePDA), order],
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseOrderRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState fetch order request rejected', { state, requestID })
      state = {
        ...state,
        firebaseRequests: [
          ...state.firebaseRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseOrderQueryRequest.pending, (state, { payload: { requestID, queryKey } }) => {
      console.log('aState fetchOrderRequest pending', { state, requestID })
      state = {
        ...state,
        queryRequests: state.queryRequests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: queryKey,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseOrderQueryRequest.fulfilled, (state, { payload: { requestID, orders, queryKey } }) => {
      console.log('aState fetchFirebaseOrderQueryRequest fulfilled', {
        state,
        requestID,
        orders,
      })
      state = {
        ...state,
        queryRequests: [
          ...state.queryRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: queryKey,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        firebaseOrders: [
          ...state.firebaseOrders.filter((s) => !orders.map((c) => c.delegatePDA).includes(s.delegatePDA)),
          ...orders,
        ],
        orderQueries: [
          ...state.orderQueries.filter((s) => s.queryKey != queryKey),
          {
            queryKey,
            orderPDAs: orders.map((o) => o.delegatePDA),
          },
        ],
      }
      return state
    })
    .addCase(fetchFirebaseOrderQueryRequest.rejected, (state, { payload: { requestID, errorMessage, queryKey } }) => {
      console.log('aState fetchOrderRequest rejected', { state, requestID, queryKey })
      state = {
        ...state,
        queryRequests: [
          ...state.queryRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: queryKey,
            status: RequestStatus.Rejected,
            errorMessage: errorMessage.toString(),
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(createOrderOnChainRequest.pending, (state, { payload: { requestID, order } }) => {
      console.log('aState create order request pending', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests,
          {
            requestID,
            status: RequestStatus.Pending,
            type: 'create',
            context: order.delegatePDA,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(createOrderOnChainRequest.fulfilled, (state, { payload: { requestID, order, res } }) => {
      console.log('aState create order request fulfilled', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'create',
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
            txRes: res,
          },
        ],
      }
      return state
    })
    .addCase(createOrderOnChainRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState create order request rejected', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'create',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(revokeOrderOnChainRequest.pending, (state, { payload: { requestID, order } }) => {
      console.log('aState revoke order request pending', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests,
          {
            requestID,
            status: RequestStatus.Pending,
            type: 'revoke',
            context: order.delegatePDA,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(revokeOrderOnChainRequest.fulfilled, (state, { payload: { requestID, order } }) => {
      console.log('aState revoke order request fulfilled', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'revoke',
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(revokeOrderOnChainRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState revoke order request rejected', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'revoke',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(matchOrdersOnChainRequest.pending, (state, { payload: { requestID, matchedOrder, newOrder } }) => {
      console.log('aState match order request pending', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests,
          {
            requestID,
            status: RequestStatus.Pending,
            type: 'match',
            context: matchedOrder.delegatePDA + newOrder.delegatePDA,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
    .addCase(matchOrdersOnChainRequest.fulfilled, (state, { payload: { requestID, txRes } }) => {
      console.log('aState match order request fulfilled', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'match',
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
            txRes,
          },
        ],
      }
      return state
    })
    .addCase(matchOrdersOnChainRequest.rejected, (state, { payload: { requestID, errorMessage } }) => {
      console.log('aState match order request rejected', { state, requestID })
      state = {
        ...state,
        chainRequests: [
          ...state.chainRequests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'match',
            status: RequestStatus.Rejected,
            errorMessage,
            timestamp: Date.now(),
          },
        ],
      }
      return state
    })
)
