import { createReducer } from '@reduxjs/toolkit'

import {
  addRequiredCollections,
  appendFirebaseCollectionLocal,
  deleteFirebaseCollectionRequest,
  fetchFirebaseCollectionQueryRequest,
  fetchFirebaseCollectionRequest,
  refreshCollection,
  updateFirebaseCollectionRequest,
} from './actions'
import { Collection } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export type CollectionQuery = {
  queryKey: string
  collectionIDs: string[]
}

export interface CollectionsState {
  readonly collections: Collection[]
  readonly collectionRequests: Request[]
  readonly queryRequests: Request[]
  readonly requiredCollections: string[]
  readonly collectionQueries: CollectionQuery[]
}

const initialState: CollectionsState = {
  collections: [],
  collectionRequests: [],
  queryRequests: [],
  requiredCollections: [],
  collectionQueries: [],
}

export default createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(refreshCollection, (state, { payload: collectionPubkey }) => {
        console.log('refreshCollection', collectionPubkey)
        state = {
          ...state,
          collections: state.collections.filter((c) => c.id !== collectionPubkey),
        }
        return state
      })
      .addCase(addRequiredCollections, (state, { payload: collections }) => {
        console.log('addRequiredCollections', collections)
        const set = [...state.requiredCollections, ...collections]
        const filtered = set.filter((item, index) => set.indexOf(item) === index)
        console.log('addRequiredCollections set', filtered)
        state = {
          ...state,
          requiredCollections: filtered,
        }
        return state
      })
      .addCase(updateFirebaseCollectionRequest.pending, (state, { payload: { requestID, collectionID } }) => {
        console.log('aState create collection request pending', { state, requestID })
        state = {
          ...state,
          collectionRequests: state.collectionRequests.concat({
            requestID,
            status: RequestStatus.Pending,
            type: 'update',
            context: collectionID,
            timestamp: Date.now(),
          }),
        }
        return state
      })
      .addCase(updateFirebaseCollectionRequest.fulfilled, (state, { payload: { requestID, collection } }) => {
        console.log('aState createCollectionRequest fulfilled', { state, requestID, collection })
        state = {
          ...state,
          collectionRequests: [
            ...state.collectionRequests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'update',
              context: collection.id,
              status: RequestStatus.Fulfilled,
              timestamp: Date.now(),
            },
          ],
          collections: [...state.collections.filter((c) => c.id != collection.id), collection],
        }
        return state
      })
      .addCase(
        updateFirebaseCollectionRequest.rejected,
        (state, { payload: { requestID, errorMessage, collectionID } }) => {
          console.log('aState query pending', { state, requestID, collectionID })
          state = {
            ...state,
            collectionRequests: [
              ...state.collectionRequests.filter((r) => r.requestID !== requestID),
              {
                requestID,
                type: 'update',
                context: collectionID,
                status: RequestStatus.Rejected,
                errorMessage: errorMessage.toString(),
                timestamp: Date.now(),
              },
            ],
          }
          return state
        }
      )
      .addCase(deleteFirebaseCollectionRequest.pending, (state, { payload: { requestID, collectionID } }) => {
        console.log('aState create collection request pending', { state, requestID })
        state = {
          ...state,
          collectionRequests: state.collectionRequests.concat({
            requestID,
            status: RequestStatus.Pending,
            type: 'delete',
            context: collectionID,
            timestamp: Date.now(),
          }),
        }
        return state
      })
      .addCase(deleteFirebaseCollectionRequest.fulfilled, (state, { payload: { requestID, collectionID } }) => {
        console.log('aState createCollectionRequest fulfilled', { state, requestID, collectionID })
        state = {
          ...state,
          collectionRequests: [
            ...state.collectionRequests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'delete',
              context: collectionID,
              status: RequestStatus.Fulfilled,
              timestamp: Date.now(),
            },
          ],
          collections: state.collections.filter((c) => c.id !== collectionID),
        }
        return state
      })
      .addCase(
        deleteFirebaseCollectionRequest.rejected,
        (state, { payload: { requestID, errorMessage, collectionID } }) => {
          console.log('aState query pending', { state, requestID, collectionID })
          state = {
            ...state,
            collectionRequests: [
              ...state.collectionRequests.filter((r) => r.requestID !== requestID),
              {
                requestID,
                type: 'delete',
                context: collectionID,
                status: RequestStatus.Rejected,
                errorMessage: errorMessage.toString(),
                timestamp: Date.now(),
              },
            ],
          }
          return state
        }
      )
      .addCase(fetchFirebaseCollectionRequest.pending, (state, { payload: { requestID, collectionID } }) => {
        console.log('aState fetchCollectionRequest pending', { state, requestID })
        state = {
          ...state,
          collectionRequests: state.collectionRequests.concat({
            requestID,
            type: 'fetch',
            status: RequestStatus.Pending,
            context: collectionID,
            timestamp: Date.now(),
          }),
        }
        return state
      })
      .addCase(fetchFirebaseCollectionRequest.fulfilled, (state, { payload: { requestID, collection } }) => {
        console.log('aState fetchFirebaseCollectionRequest fulfilled', {
          state,
          requestID,
          collection: collection.id,
        })
        state = {
          ...state,
          collectionRequests: [
            ...state.collectionRequests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'fetch',
              context: collection.id,
              status: RequestStatus.Fulfilled,
              timestamp: Date.now(),
            },
          ],
          collections: state.collections.filter((s) => s.id != collection.id).concat(collection),
        }
        return state
      })
      .addCase(
        fetchFirebaseCollectionRequest.rejected,
        (state, { payload: { requestID, errorMessage, collectionID } }) => {
          console.log('aState fetchCollectionRequest rejected', { state, requestID, collectionID })
          state = {
            ...state,
            collectionRequests: [
              ...state.collectionRequests.filter((r) => r.requestID !== requestID),
              {
                requestID,
                type: 'fetch',
                context: collectionID,
                status: RequestStatus.Rejected,
                errorMessage: errorMessage.toString(),
                timestamp: Date.now(),
              },
            ],
          }
          return state
        }
      )
      .addCase(fetchFirebaseCollectionQueryRequest.pending, (state, { payload: { requestID, queryKey } }) => {
        console.log('aState fetchCollectionRequest pending', { state, requestID })
        state = {
          ...state,
          queryRequests: state.queryRequests.concat({
            requestID,
            type: 'fetch',
            status: RequestStatus.Pending,
            context: queryKey,
            timestamp: Date.now(),
          }),
        }
        return state
      })
      .addCase(
        fetchFirebaseCollectionQueryRequest.fulfilled,
        (state, { payload: { requestID, collections, queryKey } }) => {
          console.log('aState fetchFirebaseCollectionQueryRequest fulfilled', {
            state,
            requestID,
            collections,
          })
          state = {
            ...state,
            queryRequests: [
              ...state.queryRequests.filter((r) => r.requestID !== requestID),
              {
                requestID,
                type: 'fetch',
                context: queryKey,
                status: RequestStatus.Fulfilled,
                timestamp: Date.now(),
              },
            ],
            collections: [
              ...state.collections.filter((s) => !collections.map((c) => c.id).includes(s.id)),
              ...collections,
            ],
            collectionQueries: [
              ...state.collectionQueries.filter((s) => s.queryKey != queryKey),
              {
                queryKey,
                collectionIDs: collections.map((c) => c.id),
              },
            ],
          }
          return state
        }
      )
      .addCase(
        fetchFirebaseCollectionQueryRequest.rejected,
        (state, { payload: { requestID, errorMessage, queryKey } }) => {
          console.log('aState fetchCollectionRequest rejected', { state, requestID, queryKey })
          state = {
            ...state,
            queryRequests: [
              ...state.queryRequests.filter((r) => r.requestID !== requestID),
              {
                requestID,
                type: 'fetch',
                context: queryKey,
                status: RequestStatus.Rejected,
                errorMessage: errorMessage.toString(),
                timestamp: Date.now(),
              },
            ],
          }
          return state
        }
      )
      // .addCase(appendFirebaseMintIfEmpty, (state, { payload: mintData }) => {
      //   const oldMint = state.firebaseMints.find((m) => m.address === mintData.address)
      //   if (oldMint) {
      //     return state
      //   }

      //   state = {
      //     ...state,
      //     firebaseMints: [...state.firebaseMints, mintData],
      //     requiredMints: [...state.requiredMints, mintData.address],
      //   }
      //   return state
      // })
      .addCase(appendFirebaseCollectionLocal, (state, { payload: collection }) => {
        const oldCollection = state.collections.find((c) => c.id === collection.id)
        if (oldCollection) {
          return state
        }

        state = {
          ...state,
          collections: [...state.collections, collection],
          requiredCollections: [...state.requiredCollections, collection.id],
        }
        return state
      })
  // .addCase(appendFirebaseCollection, (state, { payload: collection }) => {
)
