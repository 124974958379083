import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'

import { Action } from './models'
import { ActionQuery, RequiredActionQuery } from './reducer'
// import { Action } from 'api/types'

export const fetchFirebaseActionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ actionPDA: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; action: Action }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; actionPDA: string }>
}> = {
  pending: createAction('actions/fetchFirebaseAction/pending'),
  fulfilled: createAction('actions/fetchFirebaseAction/fulfilled'),
  rejected: createAction('actions/fetchFirebaseAction/rejected'),
}

export const fetchFirebaseActionQueryRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ queryKey: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{
    requestID: string
    actions: Action[]
    queryKey: string
    page: number
    last: QueryDocumentSnapshot<DocumentData>
    foundEnd: boolean
  }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; queryKey: string }>
}> = {
  pending: createAction('actions/fetchFirebaseActionQueryRequest/pending'),
  fulfilled: createAction('actions/fetchFirebaseActionQueryRequest/fulfilled'),
  rejected: createAction('actions/fetchFirebaseActionQueryRequest/rejected'),
}
// export const createAction: Readonly<{
//   pending: ActionCreatorWithPayload<{ requestID: string }>
//   fulfilled: ActionCreatorWithPayload<{ requestID: string; action: Action }>
//   rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
// }> = {
//   pending: createAction('actions/createAction/pending'),
//   fulfilled: createAction('actions/createAction/fulfilled'),
//   rejected: createAction('actions/createAction/rejected'),
// }

export const updateFirebaseActionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ actionPDA: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; action: Action }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; actionPDA: string; requestID: string }>
}> = {
  pending: createAction('actions/updateFirebaseActionRequest/pending'),
  fulfilled: createAction('actions/updateFirebaseActionRequest/fulfilled'),
  rejected: createAction('actions/updateFirebaseActionRequest/rejected'),
}

export const addRequiredActions = createAction<string[]>('actions/addRequiredActions')
export const addRequiredActionQueries = createAction<RequiredActionQuery[]>('actions/addRequiredActionQueries')
export const refreshAction = createAction<string>('actions/refreshAction')
export const refreshQuery = createAction<string>('actions/refreshQuery')
// export const  = createAction<string>('lists/disableList')

export const fetchMoreForActionQuery = createAction<{
  queryKey: string
}>('actions/fetchMoreForActionQuery')
