import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchFirebaseActionsQuery } from './firebase'
import { useAllActionQueries, useAllActionQueryRequests, useRequiredActionQueries } from './hooks'
import { Action } from './models'
import { ChainActionStatus, RequestStatus } from './reducer'

export default function Updater(): null {
  const dispatch = useAppDispatch()

  const requiredActionQueries = useRequiredActionQueries()
  const actionQueryRequests = useAllActionQueryRequests()
  const actionQueries = useAllActionQueries()
  const fetchActionQuery = useFetchFirebaseActionsQuery()

  useEffect(() => {
    console.log('requiredActionQueries: ', requiredActionQueries)
    requiredActionQueries.forEach((roq) => {
      const query = actionQueries.find((m) => m.queryKey == roq.key)
      const queryRequest = actionQueryRequests.find((r) => r.context == roq.key && r.type == 'fetch')
      if (!query && !queryRequest) {
        fetchActionQuery(roq, 0, 1)
      }
      console.log('requiredActionQueries query: ', query)
      console.log('requiredActionQueries roq: ', roq)
      if (query && !queryRequest) {
        if (query.pFetched < roq.pToFetch) {
          fetchActionQuery(roq, query.pFetched, roq.pToFetch)
        }
      }
    })
  }, [dispatch, fetchActionQuery, actionQueries, actionQueryRequests, requiredActionQueries])

  return null
}
