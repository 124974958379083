import { pubkeyFromString } from 'apollo/utils'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { useFetchFirebaseMintData, useUpdateFirebaseMint } from './firebase'
import {
  useAllChainMints,
  useAllChainRequests,
  useAllFirebaseMints,
  useAllFirebaseRequests,
  useRequiredMints,
} from './hooks'
import { MintData } from './models'
import { RequestStatus } from './reducer'

const shouldRefreshFirebaseMint = (chainMint: MintData, firebaseMint: MintData) => {
  return JSON.stringify(chainMint.owners) != JSON.stringify(firebaseMint.owners)
}
// firebaseMint.owner != chainMint.owner

export default function Updater(): null {
  const dispatch = useAppDispatch()

  // get all loaded lists, and the active urls
  const requiredMints = useRequiredMints()
  // const allChainMints = useAllChainMints()
  const allFirebaseMints = useAllFirebaseMints()
  // const allChainMintRequests = useAllChainRequests()
  const allFirebaseRequests = useAllFirebaseRequests()
  const fetchFirebaseMint = useFetchFirebaseMintData()
  const updateFirebaseMint = useUpdateFirebaseMint()
  // const fetchChainMint = useFetchMintFromChain()
  // const requiredMints = useChainRequestsByContext('mint')
  // const useAllChainRequests = useAllChainRequests()

  useEffect(() => {
    console.log('requiredMints requiredMints: ', requiredMints)
    requiredMints.forEach((mintAddress) => {
      // const pubkey = pubkeyFromString(mintAddress)
      // if (!pubkey) return
      // console.log('requiredMints allFirebaseMints: ', allFirebaseMints)
      const firebaseMint = allFirebaseMints.find((m) => m.address == mintAddress)
      const firebaseFetchRequest = allFirebaseRequests.find(
        (r) => r.context == mintAddress && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
        // r.timestamp > Date.now() - 10 * 1000
      )
      // const firebaseCreateRequest = allFirebaseRequests.find(
      //   (r) => r.context == pubkey.toBase58() && r.type == 'update' && r.status != RequestStatus.Fulfilled
      // )
      // const chainMint = allChainMints.find((m) => m.address == pubkey.toBase58())
      // const chainRequest = allChainMintRequests.find(
      //   (r) =>
      //     r.context == pubkey.toBase58() &&
      //     r.type == 'fetch' &&
      //     r.status != RequestStatus.Fulfilled &&
      //     r.timestamp > Date.now() - 60 * 1000
      // )
      // .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // const chainRequests = allChainMintRequests
      //   .filter((r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled)
      //   .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // console.log('requiredMints mintAddress: ', mintAddress)
      // console.log('requiredMints pubkey: ', pubkey)
      // console.log('requiredMints firebaseMint: ', firebaseMint)
      // console.log('requiredMints firebaseFetchRequest: ', firebaseFetchRequest)
      // console.log('requiredMints firebaseCreateRequest: ', firebaseCreateRequest)
      // console.log('requiredMints chainMint: ', chainMint)
      // // console.log('requiredMints chainRequests: ', chainRequests)
      // console.log('requiredMints chainRequest: ', chainRequest)

      if (
        !firebaseMint &&
        !firebaseFetchRequest
        // ((firebaseFetchRequest?.timestamp ?? 0) < Date.now() - 10 * 1000 && !firebaseMint?.metadata)
      ) {
        console.log('requiredMints request1')
        fetchFirebaseMint(mintAddress)
        return
      }
      // if (!firebaseMint && !chainMint && !chainRequest) {
      //   console.log('requiredMints request2')
      //   fetchChainMint(mintAddress)
      //   return
      // }
      // if (chainMint && !firebaseMint && !firebaseCreateRequest) {
      //   console.log('requiredMints request3')
      //   updateFirebaseMint(chainMint)
      //   return
      // }

      // if (chainMint && firebaseMint) {
      //   console.log('requiredMints request4 chainMint: ', chainMint)
      //   console.log('requiredMints request4 firebaseMint: ', firebaseMint)
      //   const shouldUpdate = shouldRefreshFirebaseMint(chainMint, firebaseMint)
      //   console.log('requiredMints shouldUpdate: ', shouldUpdate)
      //   console.log('requiredMints shouldUpdate firebaseCreateRequest: ', firebaseCreateRequest)
      //   if (shouldRefreshFirebaseMint(firebaseMint, chainMint) && !firebaseCreateRequest) {
      //     console.log('requiredMints request4 triggered for ' + pubkey.toBase58())
      //     updateFirebaseMint(chainMint)
      //     return
      //   }
      // }
    })
  }, [allFirebaseMints, allFirebaseRequests, updateFirebaseMint, dispatch, fetchFirebaseMint, requiredMints])
  // useEffect(() => {
  //   allChainMints.forEach((chainMint) => {
  //     if (!allFirebaseMints.find((firebaseMint) => firebaseMint.pubkey === chainMint.add)) {
  //       if (!allFirebaseRequests.find((r) => r.context == chainMint.pubkey)) {
  //       }
  //     }
  //   })
  // }, [allChainMints, allFirebaseRequests, allFirebaseMints, dispatch, requiredMints])
  // fetch all lists every 10 minutes, but only after we initialize library

  return null
}
// const i = 0
// export default i
