import { nanoid } from '@reduxjs/toolkit'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { ConfirmedSignatureInfo, PublicKey, TransactionError } from '@solana/web3.js'
import { initializeApp } from 'firebase/app'
// import { getDatabase, push, ref } from 'firebase/database'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QueryConstraint,
  setDoc,
  where,
} from 'firebase/firestore'
import { CollectionInfo } from 'providers/collections'
import { useCallback, useEffect, useState } from 'react'
import { querySalesByCollectionIDsRequest } from 'state/apollo/actions'
import { useAppDispatch } from 'state/hooks'
import { SALES_COLLECTION } from 'state/sales/firebase'

import { decodeCollection } from './types'

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_KEY
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID

const firebaseEnabled =
  typeof FIREBASE_API_KEY !== 'undefined' &&
  typeof FIREBASE_AUTH_DOMAIN !== 'undefined' &&
  typeof FIREBASE_PROJECT_ID !== 'undefined' &&
  typeof FIREBASE_STORAGE_BUCKET !== 'undefined' &&
  typeof FIREBASE_MESSAGING_SENDER_ID !== 'undefined' &&
  typeof FIREBASE_APP_ID !== 'undefined' &&
  typeof FIREBASE_MEASUREMENT_ID !== 'undefined'
console.log('FIREBASE_API_KEY: ', FIREBASE_API_KEY)
console.log('FIREBASE_AUTH_DOMAIN: ', FIREBASE_AUTH_DOMAIN)
console.log('FIREBASE_PROJECT_ID: ', FIREBASE_PROJECT_ID)
console.log('FIREBASE_STORAGE_BUCKET: ', FIREBASE_STORAGE_BUCKET)
console.log('FIREBASE_API_KEY: ', FIREBASE_API_KEY)
console.log('FIREBASE_MEASUREMENT_ID: ', FIREBASE_MEASUREMENT_ID)
if (firebaseEnabled) initializeFirebase()

const COLLECTIONS_COLLECTION = 'collections2'

export function useFirebaseLog() {
  const wallet = useAnchorWallet()
  const publicKey = wallet?.publicKey

  return useCallback(
    async function log(type: string) {
      if (!firebaseEnabled) return
      const db = getFirestore()

      try {
        console.log('firebase Logging monitoring event to Firebase...')
        await setDoc(doc(db, 'logs', type), {
          timestamp: Date.now(),
        })
      } catch (e) {
        console.log('firebase Error adding document: ', e)
      }
    },
    [wallet]
  )
}

export type DecodedTransaction = {
  slot: number
  signature: string
  err: TransactionError | null
  blockTime: number | null | undefined
  statusClass: string
  statusText: string
  signatureInfo: ConfirmedSignatureInfo
}

function decodeTransactions(transactions: ConfirmedSignatureInfo[]): DecodedTransaction[] {
  const DecodedTransactions: DecodedTransaction[] = []
  for (let i = 0; i < transactions.length; i++) {
    const slot = transactions[i].slot
    const slotTransactions = [transactions[i]]
    while (i + 1 < transactions.length) {
      const nextSlot = transactions[i + 1].slot
      if (nextSlot !== slot) break
      slotTransactions.push(transactions[++i])
    }

    for (const slotTransaction of slotTransactions) {
      let statusText
      let statusClass
      if (slotTransaction.err) {
        statusClass = 'warning'
        statusText = 'Failed'
      } else {
        statusClass = 'success'
        statusText = 'Success'
      }
      DecodedTransactions.push({
        slot,
        signature: slotTransaction.signature,
        err: slotTransaction.err,
        blockTime: slotTransaction.blockTime,
        statusClass,
        statusText,
        signatureInfo: slotTransaction,
      })
    }
  }

  return DecodedTransactions
}

export const fetchCollections = async (queryConstraints: QueryConstraint[]): Promise<CollectionInfo[]> => {
  const db = getFirestore()
  const q = query(collection(db, COLLECTIONS_COLLECTION), ...queryConstraints)
  const querySnapshot = await getDocs(q)
  const collections = querySnapshot.docs.map((doc) => {
    return { id: doc.id.toString(), collection: decodeCollection(doc.data()) }
  })
  return collections
}
export const fetchCollectionFromID = async (collectionID: string) => {
  const db = getFirestore()
  const collectionSnap = await getDoc(doc(db, COLLECTIONS_COLLECTION, collectionID))
  console.log('firebase collections: ', collectionSnap)
  if (collectionSnap.exists()) {
    console.log('firebase Document data:', collectionSnap.data())
    return decodeCollection(collectionSnap.data())
  } else {
    console.log('firebase Sale not found. Creating.')
    return undefined
  }
}

// export function useFirebaseSaleRefresher(apollo?: Program, salePubkeyRaw?: string) {
//   // const wallet = useAnchorWallet()
//   // const userPubkey = wallet?.publicKey
//   const salePubkey = useMemo(() => (salePubkeyRaw ? new PublicKey(salePubkeyRaw) : undefined), [salePubkeyRaw])
//   const fetchSale = useFetchSaleAccount(apollo)
//   console.log(`firebase sale pubkey:`, salePubkey?.toBase58())
//   const sale = useSaleAccount(salePubkey?.toBase58())

//   const history = useAccountHistory(sale?.data?.sale.tokenMint)
//   const fetchAccountHistory = useFetchAccountHistory()
//   const loadMoreHistory = useMemo(
//     () => () => fetchAccountHistory(sale?.data?.sale.tokenMint, false),
//     [fetchAccountHistory, sale?.data?.sale.tokenMint]
//   )

//   useEffect(() => {
//     console.log('firebase history:', history)
//     if (!history || (history?.data && history?.data?.foundOldest == false)) {
//       loadMoreHistory()
//     }
//   }, [history, history?.data?.foundOldest, loadMoreHistory])

//   const initialTX = useMemo(() => {
//     console.log('firebase initialTX history:', history)
//     if (history?.data?.foundOldest) {
//       const sorted = history.data.fetched.sort((a, b) => a.slot - b.slot)
//       console.log('firebase initialTX sorted:', sorted)
//       return history.data.fetched.sort((a, b) => a.slot - b.slot)[0]
//     }
//     return undefined
//   }, [history])

//   const details = useTransactionDetails(initialTX?.signature)
//   const fetchDetails = useFetchTransactionDetails()
//   const status = useTransactionStatus(initialTX?.signature)

//   useEffect(() => {
//     console.log('firebase initialTX:', initialTX)
//     if (initialTX?.signature && !details) {
//       fetchDetails(initialTX.signature)
//     }
//   }, [status, initialTX?.signature, details, fetchDetails, initialTX])

//   const candyMachineFound = useMemo(() => {
//     console.log('firebase candyMachineFound details: ', details)
//     const res = details?.data?.transaction?.transaction.message.accountKeys.some((k) => {
//       console.log('firebase candyMachineFound k: ', k.pubkey.toBase58())
//       return k.pubkey.toBase58() == 'cndy3Z4yapfJBmL3ShUp5exZKqR3z33thTzeNMm2gRZ'
//     })
//     console.log('firebase candyMachineFound res: ', res)
//     return res
//   }, [details])

//   const candyMachine = useMemo(() => {
//     console.log('firebase candyMachine details in candyMachineMemo: ', details)
//     console.log('firebase candyMachineFound in candyMachineMemo: ', candyMachineFound)
//     return candyMachineFound ? details?.data?.transaction?.transaction.message.accountKeys[3] : undefined
//   }, [candyMachineFound, details])
//   useEffect(() => {
//     // if (!sale?.status == FetchStatus.Fetching) return
//     switch (sale?.status) {
//       case FetchStatus.Fetching:
//         return
//       case FetchStatus.Fetched:
//         if (sale.data) {
//           uploadSaleIfNeeded()
//         }
//         return
//       case FetchStatus.FetchFailed:
//         console.error('firebase sale fetch failed')
//         return
//       default:
//         if (salePubkey) fetchSale(salePubkey)
//     }
//   }, [fetchSale, sale, salePubkey])

//   const craftSaleObject = useMemo(
//     () =>
//       (
//         salePubkey: PublicKey,
//         sale: SaleData,
//         collectionName?: string,
//         candyMachine?: PublicKey,
//         collectionID?: string,
//         metadata?: MetadataCombined
//       ) => {
//         const saleObj = {
//           pubkey: salePubkey.toBase58(),
//           tokenMint: sale.sale.tokenMint.toBase58(),
//           receiptMint: sale.sale.receiptMint.toBase58(),
//           amount: sale.sale.amount.toString(),
//           closed: sale.sale.closed.toString(),
//           acceptedAsk: sale.sale.acceptedAsk.toBase58(),
//           asks: sale.asks?.map((ask: AskV2) => {
//             return {
//               pubkey: ask.pubkey?.toBase58(),
//               sale: ask.sale.toBase58(),
//               tokenMint: ask.tokenMint.toBase58(),
//               amount: ask.amount.toString(),
//               expirationDate: ask.expirationDate.toString(),
//               cancelled: ask.cancelled.toString(),
//               accepted: ask.accepted.toString(),
//               feesPaid: ask.feesPaid.toString(),
//               fundsWithdrawn: ask.fundsWithdrawn.toString(),
//             }
//           }),
//           tokenName: metadata?.data?.name ?? '',
//           collectionFamily: metadata?.extended?.collection?.family ?? 'undefined',
//           collectionName: collectionName ?? metadata?.extended?.collection?.name ?? '',
//           collectionID: collectionID ? collectionID : 'undefined',
//           candyMachineIDs: candyMachine ? candyMachine.toBase58() : [],
//         }
//         console.log('firebase saleObj: ', saleObj)

//         return saleObj
//       },
//     []
//   )

//   const findMCollectionWithCandyMachine = async (candyMachinePubkey: string) => {
//     const db = getFirestore()
//     const q = query(
//       collection(db, 'mCollectionsWithCMID'),
//       where('candyMachineIds', 'array-contains', candyMachinePubkey)
//     )
//     const querySnapshot = await getDocs(q)
//     const foundCollectionID = querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : undefined
//     if (!foundCollectionID) {
//       console.log('firebase findMCollectionWithCandyMachine no collection found')
//       return undefined
//     }
//     const foundCollectionSnap = await getDoc(doc(db, 'mCollectionsWithCMID', foundCollectionID))
//     const foundCollection = foundCollectionSnap.data()
//     console.log('firebase findMCollectionWithCandyMachine found collection: ', collection)
//     return foundCollection
//   }

//   const linkifyCollectionName = (collectionName: string, i: number) => {
//     let base = collectionName.split(' ').join('-').toLowerCase()
//     if (i > 0) {
//       base += `-${i}`
//     }
//     return base
//   }

//   const findNewCollectionSymbol = async (collectionName: string) => {
//     const db = getFirestore()
//     // let found = false
//     let i = 0
//     while (true && i < 40) {
//       const symbolAttempt = linkifyCollectionName(collectionName, i)
//       const q = query(collection(db, COLLECTIONS_COLLECTION), where('symbol', '==', symbolAttempt))
//       const querySnapshot = await getDocs(q)
//       const foundCollectionID = querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : undefined
//       if (foundCollectionID) {
//         i++
//       } else {
//         return symbolAttempt
//       }
//     }
//     return undefined
//   }

//   const uploadSaleIfNeeded = useCallback(
//     async function refresh() {
//       console.log('firebase refreshing sale account...')
//       if (!firebaseEnabled) {
//         console.error('Firebase not enabled')
//       }
//       if (!sale?.data) {
//         console.error('firebase sale?.data invalid')
//         return
//       }
//       if (candyMachineFound == undefined) {
//         console.error('firebase candymachine not found')
//         return
//       }
//       if (candyMachine == undefined) {
//         console.error('firebase candymachine invalid')
//         return
//       }
//       if (!salePubkey) {
//         console.error('firebase No sale account pubkey')
//         return
//       }
//       const db = getFirestore()
//       const saleSnap = await getDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58()))
//       console.log('firebase sale: ', saleSnap)
//       if (saleSnap.exists()) {
//         console.log('firebase Document data:', saleSnap.data())
//         return
//       } else {
//         console.log('firebase Sale not found. Creating.')
//       }
//       try {
//         console.log('firebase setting sale data: ', sale)
//         if (candyMachine) {
//           console.log('firebase searching for collection with candy machine: ', candyMachine.pubkey.toBase58())
//           const q = query(
//             collection(db, COLLECTIONS_COLLECTION),
//             where('candyMachineIDs', 'array-contains', candyMachine.pubkey.toBase58())
//           )
//           const querySnapshot = await getDocs(q)
//           const foundCollectionID = querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : undefined
//           console.log('firebase collectionID: ', foundCollectionID)
//           if (foundCollectionID) {
//             console.log('firebase found collectionID: ', foundCollectionID)
//             const saleObj = craftSaleObject(
//               salePubkey,
//               sale.data,
//               querySnapshot.docs[0].data().name,
//               candyMachine.pubkey,
//               foundCollectionID,
//               sale.data?.metadata
//             )
//             console.log('firebase uploading sale')
//             await setDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58()), saleObj)
//             const collectionRef = doc(db, COLLECTIONS_COLLECTION, foundCollectionID)
//             console.log('firebase updating collection mint list')
//             await updateDoc(collectionRef, {
//               mints: arrayUnion(sale.data?.sale?.tokenMint.toBase58()),
//             })
//           } else {
//             const newCollectionID = firestoreAutoId()
//             const saleObj = craftSaleObject(
//               salePubkey,
//               sale.data,
//               undefined,
//               candyMachine.pubkey,
//               newCollectionID,
//               sale.data?.metadata
//             )
//             await setDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58()), saleObj).then(async () => {
//               const mCollectionsRes = await findMCollectionWithCandyMachine(candyMachine.pubkey.toBase58())
//               console.log('firebase mCollectionsRes: ', mCollectionsRes)
//               if (mCollectionsRes) {
//                 const newCollectionSymbol = await findNewCollectionSymbol(mCollectionsRes.name)
//                 const newCollectionObjFromMCollection = {
//                   fromM: true,
//                   candyMachineIDs: [...mCollectionsRes.candyMachineIds] ?? [],
//                   creators: sale.data?.metadata?.data?.creators?.map((c: Creator) => c.address) ?? [],
//                   mints: [sale.data?.sale?.tokenMint.toBase58()],
//                   name: mCollectionsRes.name ?? '',
//                   symbol: newCollectionSymbol ? newCollectionSymbol : '',
//                   image: mCollectionsRes.image ?? '',
//                   description: mCollectionsRes.description ?? '',
//                   discord: mCollectionsRes.discord ?? '',
//                   website: mCollectionsRes.website ?? '',
//                   twitter: mCollectionsRes.twitter ?? '',
//                 }
//                 console.log('firebase newCollectionObjFromMCollection: ', newCollectionObjFromMCollection)
//                 return setDoc(doc(db, COLLECTIONS_COLLECTION, newCollectionID), newCollectionObjFromMCollection)
//               } else {
//                 const newCollectionSymbol = sale.data?.metadata?.extended?.collection?.name
//                   ? await findNewCollectionSymbol(sale.data?.metadata?.extended?.collection?.name)
//                   : undefined

//                 const newCollectionObjFromScratch = {
//                   fromM: false,
//                   candyMachineIDs: [candyMachine.pubkey.toBase58()] ?? [],
//                   creators: sale.data?.metadata?.data?.creators?.map((c: Creator) => c.address) ?? [],
//                   mints: [sale.data?.sale?.tokenMint.toBase58()] ?? [],
//                   name: sale.data?.metadata?.extended?.collection?.name ?? '',
//                   symbol: newCollectionSymbol ? newCollectionSymbol : '',
//                   family: sale.data?.metadata?.extended?.collection?.name ?? '',
//                   image: sale.data?.metadata?.extended?.image ?? '',
//                 }
//                 console.log('firebase newCollectionObjFromScratch: ', newCollectionObjFromScratch)
//                 return setDoc(doc(db, COLLECTIONS_COLLECTION, newCollectionID), newCollectionObjFromScratch)
//               }
//             })
//           }
//         } else {
//           const saleObj = craftSaleObject(salePubkey, sale.data, undefined, undefined, undefined, sale.data?.metadata)
//           await setDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58()), saleObj)
//         }
//         // if it doesn't, create it then set the data
//         // await setDoc(doc(db, SALES_COLLECTION, salePubkey.toBase58(), 'asks', initialAskPubkey.toBase58()), { ...initialAsk })
//       } catch (e) {
//         console.log('firebase Error adding document: ', e)
//       }
//     },
//     [candyMachine, candyMachineFound, craftSaleObject, sale, salePubkey]
//   )
//   return { uploadSaleIfNeeded }
// }

export const querySalesByCollectionIDs = async (collectionIDs: string[]) => {
  const db = getFirestore()
  // const collectionQuery = query(collection(db, SALES_COLLECTION), where('collectionID', 'in', collectionIDs))
  // const allCollectionsQuery = query(collection(db, SALES_COLLECTION))
  const q =
    collectionIDs.length == 0
      ? query(collection(db, SALES_COLLECTION))
      : query(collection(db, SALES_COLLECTION), where('collectionID', 'in', collectionIDs))
  console.log('firebase searchIncludeCollections: ', collectionIDs)
  const querySnapshot = await getDocs(q)
  console.log('firebase querySnapshot: ', querySnapshot)
  const pubkeys: PublicKey[] = []
  querySnapshot.forEach((doc) => {
    let pubkey = undefined
    try {
      pubkey = new PublicKey(doc.id)
    } catch (e) {
      return
      console.error('firebase pubkey error: ', e)
    }
    if (pubkey) {
      pubkeys.push(pubkey)
    }
  })
  return pubkeys
}

export function useQuerySalesByCollectionIDsCallback(): (
  collectionIDs: string[],
  sendDispatch?: boolean
) => Promise<PublicKey[]> {
  const dispatch = useAppDispatch()

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (collectionIDs: string[], sendDispatch = true) => {
      console.log('aState useQuerySalesByCollectionIDsCallback: ', collectionIDs)
      const requestId = nanoid()
      sendDispatch && dispatch(querySalesByCollectionIDsRequest.pending({ requestId, collectionIDs }))
      return querySalesByCollectionIDs(collectionIDs)
        .then((sales) => {
          console.log('aState sales from fetch: ', sales)
          const salesStrs = sales.map((s) => s.toBase58())
          sendDispatch &&
            dispatch(querySalesByCollectionIDsRequest.fulfilled({ collectionIDs, sales: salesStrs, requestId }))
          return sales
        })
        .catch((error) => {
          console.debug(`Failed to get sales by collectionIDs ${collectionIDs}`, error)
          sendDispatch &&
            dispatch(
              querySalesByCollectionIDsRequest.rejected({ collectionIDs, requestId, errorMessage: error.message })
            )
          throw error
        })
    },
    [dispatch]
  )
}

export function useHomepageSetCollections() {
  const [searchIncludeCollections, setSearchIncludeCollections] = useState<string[]>([''])

  useEffect(() => {
    const refresh = async () => {
      const db = getFirestore()
      // const collectionQuery = query(collection(db, SALES_COLLECTION), where('collectionID', 'in', searchIncludeCollections))
      // const allCollectionsQuery = query(collection(db, SALES_COLLECTION))
      const q =
        searchIncludeCollections.length == 0
          ? query(collection(db, SALES_COLLECTION))
          : query(collection(db, SALES_COLLECTION), where('collectionID', 'in', searchIncludeCollections))
      console.log('firebase searchIncludeCollections: ', searchIncludeCollections)
      const querySnapshot = await getDocs(q)
      console.log('firebase querySnapshot: ', querySnapshot)
      const pubkeys: PublicKey[] = []
      querySnapshot.forEach((doc) => {
        let pubkey = undefined
        try {
          pubkey = new PublicKey(doc.id)
        } catch (e) {
          return
          console.error('firebase pubkey error: ', e)
        }
        if (pubkey) {
          pubkeys.push(pubkey)
        }
      })
      console.log('firebase search new pubkeys: ', pubkeys)
    }
    refresh()
  }, [searchIncludeCollections])

  return {
    searchIncludeCollections,
    setSearchIncludeCollections,
    // searchResults,
  }
}

// export function useFirebaseLogEvent() {
//   const log = useFirebaseLog()
//   const wallet = useAnchorWallet()
//   const publicKey = wallet?.publicKey

//   return useCallback(
//     (type: string) => {
//       log(type, {
//         walletAddress: publicKey ? publicKey.toBase58() : undefined,
//       })
//     },
//     [publicKey, log]
//   )
// }

function initializeFirebase() {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
  }
  console.log('initializeFirebase: ', firebaseConfig)

  const app = initializeApp(firebaseConfig)
}

export const firestoreAutoId = (): string => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
  }
  return autoId
}
