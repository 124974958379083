import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'

import { TokenAddressMap } from '../state/lists/hooks'

export const cleanName = (name?: string): string | undefined => {
  if (!name) {
    return undefined
  }

  return name.replace(/\s+/g, '-')
}

export const getLast = <T>(arr: T[]) => {
  if (arr.length <= 0) {
    return undefined
  }

  return arr[arr.length - 1]
}

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

export const formatRemainingTime = (endDate: Date) => {
  // const date = new Date(ms);
  const now = new Date()
  const diff = endDate.valueOf() - now.valueOf()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
  const daysStr = days > 0 ? `${days}d ` : ''
  const hoursStr = days < 10 && hours > 0 ? `${hours}h ` : ''
  const minutesStr = minutes > 0 && hours < 10 ? `${minutes}m ` : ''
  const secondsStr = minutes == 0 ? `${seconds}s ` : ''
  // return string should be a maximum of 2 words
  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
}

export function formatRemainingTimeDiff(diff: number) {
  // const date = new Date(ms);

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((diff % (1000 * 60)) / 1000)
  const daysStr = days > 0 ? `${days} days ` : ''
  const hoursStr = days < 10 && hours > 0 ? `${hours} hr ` : ''
  const minutesStr = minutes > 0 && hours < 10 ? `${minutes} min ` : ''
  const secondsStr = minutes == 0 ? `${seconds} sec ` : ''

  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`
}

// abcd...wxyz
export function truncateString(str?: string) {
  if (!str) {
    return ''
  }
  return str.slice(0, 4) + '...' + str.slice(str.length - 4, str.length)
}

export const lamportsToSol = (lamports: number) => {
  return Math.round((lamports / LAMPORTS_PER_SOL) * 100) / 100
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(tokenAddressMap: TokenAddressMap, token?: Token): boolean {
  return Boolean(token?.isToken && tokenAddressMap[token.chainId]?.[token.address])
}

export function formattedFeeAmount(feeAmount: FeeAmount): number {
  return feeAmount / 10000
}
