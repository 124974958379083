import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import 'components/analytics'

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter } from 'react-router-dom'

import Blocklist from './components/Blocklist'
import { NetworkContextName } from './constants/misc'
import { LanguageProvider } from './i18n'
import App from './pages/AppTwo'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './state'
import ActionsUpdater from './state/actions/updater'
import ApplicationUpdater from './state/application/updater'
import CallectionsUpdater from './state/callections/updater'
import CollectionsUpdater from './state/collections/updater'
import FeedsUpdater from './state/feeds/updater'
import MintsUpdater from './state/mints/updater'
import MulticallUpdater from './state/multicall/updater'
import OrdersUpdater from './state/orders/updater'
import WalletsUpdater from './state/wallets/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import RadialGradientByChainUpdater from './theme/RadialGradientByChainUpdater'
import getLibrary from './utils/getLibrary'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <RadialGradientByChainUpdater />
      {/* <ListsUpdater /> */}
      {/* <ApolloUpdater /> */}
      {/* <SalesUpdater /> */}
      {/* <OrdersUpdater /> */}
      <MintsUpdater />
      {/* <UsersUpdater /> */}
      {/* <CallectionsUpdater /> */}
      <CollectionsUpdater />
      {/* <UserUpdater /> */}
      {/* <SearchUpdater /> */}
      <ActionsUpdater />
      <ApplicationUpdater />
      {/* <TransactionUpdater /> */}
      <MulticallUpdater />
      {/* <LogsUpdater /> */}
      <FeedsUpdater />
      <WalletsUpdater />
    </>
  )
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <LanguageProvider>
          {/* <Web3ReactProvider getLibrary={getLibrary}> */}
          {/* <Web3ProviderNetwork getLibrary={getLibrary}> */}
          {/* <Blocklist> */}
          <Updaters />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <App />
          </ThemeProvider>
          {/* </Blocklist> */}
          {/* </Web3ProviderNetwork> */}
          {/* </Web3ReactProvider> */}
        </LanguageProvider>
      </HashRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  // serviceWorkerRegistration.register()
}
