import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import { useGlobalColor } from 'state/application/hooks'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'

import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean, globalColor: string): Colors {
  return {
    darkMode,
    globalColor,
    // base
    white,
    black,
    transparent: 'transparent',

    // text
    text1: darkMode ? '#F7F8FA' : '#171717',
    text2: darkMode ? '#F7F8FA' : '#40444F',
    text3: darkMode ? '#CED0D9' : '#646569',
    text4: darkMode ? '#40444F' : '#D7D7D7',
    text5: darkMode ? '#171717' : '#F7F8FA',
    // 191B1F
    // backgrounds / greys191B1F
    bg0: darkMode ? '#030303' : '#FFFFFF',
    bg1: darkMode ? '#0F0F10' : '#F7F8FA',
    bg2: darkMode ? '#191B1F' : '#EDEEF2',
    bg3: darkMode ? '#40444F' : '#CED0D9',
    bg4: darkMode ? '#CED0D9' : '#2C2F36',
    bg5: darkMode ? '#F7F8FA' : '#111111',
    bg6: darkMode ? '#FFFFFF' : '#191B1F',

    movingBG: darkMode ? 'darkBackground' : 'lightBackground',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,0.425)' : 'rgba(255,255,255,0.96)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.2)',

    //primary colors
    primary1: darkMode ? '#F7F8FA' : '#161616',
    primary2: darkMode ? '#565A69' : '#888D9B',
    primary3: darkMode ? '#6C7284' : '#6C7284',
    primary4: darkMode ? '#69EEB970' : '#C9D7FF',
    primary5: darkMode ? '#69EEB970' : '#C9D7FF',

    // color text
    primaryText1: darkMode ? '#EDEEF2' : '#191B1F',

    // secondary colors
    secondary1: darkMode ? '#F7F8FA' : '#191B1F',
    secondary2: darkMode ? '#17000b60' : '#F6DDE8',
    secondary3: darkMode ? '#17000b80' : '#FDEAF1',

    headerBG: darkMode ? '#161616' : '#161616',
    headerText: darkMode ? '#F7F8FA' : '#F7F8FA',

    // other
    red1: darkMode ? '#FF4343' : '#DA2D2B',
    red2: darkMode ? '#F82D3A' : '#DF1F38',
    red3: '#D60000',
    green1: darkMode ? '#27AE60' : '#27AE60',
    yellow1: '#E3A507',
    yellow2: '#FF8F00',
    yellow3: '#F3B71E',
    blue1: darkMode ? '#0066F7' : '#0068FC',
    blue2: darkMode ? '#5199FF' : '#0068FC',
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

function theme(darkMode: boolean, globalColor: string): DefaultTheme {
  return {
    ...colors(darkMode, globalColor),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    boxShadow1: darkMode ? '1px 2px 4px hsl(220deg 60% 50%);' : '1px 2px 4px hsl(220deg 60% 50%)',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()
  const globalColor = useGlobalColor()

  const themeObject = useMemo(() => {
    const t = theme(darkMode, globalColor)
    console.log('mintColo2r themeObject:', t)
    return t
  }, [darkMode, globalColor])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
  letter-spacing: -0.05rem;
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={300} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text2'} {...props} />
  },
  heavy(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} fontSize={20} {...props} />
  },
  sectionHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} fontSize={16} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  green(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'green1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1} !important;
  // background-color: ${({ theme }) => (theme.darkMode ? '' : '')} !important;
  
  // background: linear-gradient(339deg,#0f90ec,#d6f7ca,#df7e20,#6a64e2);
}

#background-radial-gradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 200vw;
  height: 200vh;
  background: ${({ theme }) =>
    theme.darkMode
      ? 'radial-gradient(50% 50% at 50% 50%, #15101B 0%, #150F15 20%, #120C0D 40%, #0C0807 50%, #0C0807 100%)'
      : theme.bg0};
      //  'radial-gradient(50% 50% at 50% 50%, black 0%,  rgba(252, 187, 7, 0.034) 70%, #fcec0804 80%, rgba(7, 252, 150, 0.059) 90%, rgba(255, 255, 255, 0) 100%);'
        // 'linear-gradient(339deg,rgba(14, 144, 237, 0.6),rgba(222, 126, 31, 0.6),rgba(106, 100, 227, 0.6));'} !important;
  transform: translate(-50vw, -100vh);
  
  
  z-index: -1;
  background-size:  ${({ theme }) => (theme.darkMode ? '100% 100%' : '160% 180%')};
  -webkit-animation: AnimationName 25 ease infinite;
  -moz-animation: AnimationName 25 ease infinite;
  animation: AnimationName 25s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
      background-position: 75% 0%
  }
  50% {
      background-position: 26% 100%
  }
  100% {
      background-position: 75% 0%
  }
}

@-moz-keyframes AnimationName {
  0% {
      background-position: 75% 0%
  }
  50% {
      background-position: 26% 100%
  }
  100% {
      background-position: 75% 0%
  }
}

@keyframes AnimationName {
  0% {
      background-position: 75% 0%
  }
  50% {
      background-position: 26% 100%
  }
  100% {
      background-position: 75% 0%
  }
}

a {
 color: ${({ theme }) => theme.blue1}; 
}
`

export const Label = styled(TYPE.label)<{ end?: number }>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: ${({ end }) => (end ? 'flex-end' : 'flex-start')};
  align-items: center;
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`

export const ClickableText = styled(Label)`
  text-align: end;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
