import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { useSafeSales } from 'state/sales/hooks'

import { AppState } from '../index'
import { decodeFirebaseCollection } from './firebase'
import { Collection } from './models'
import { Request } from './reducer'

export function useRequiredCollections(): string[] {
  console.log('aState useRequiredCollections')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredCollections useAppSelector state: ', state)
    return state.collections.requiredCollections
  })
}

export function useAllFirebaseCollections(): Collection[] {
  console.log('aState useAllFirebaseCollections')
  return useAppSelector((state: AppState) => {
    // console.log('aState useHomepageCollections useAppSelector state: ', state)
    return state.collections.collections
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.collections.requests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.collections.requests.filter((r) => r.context === context)
  })
}

export function useFirebaseCollection(collectionID?: string): Collection | undefined {
  const allCollections = useAllFirebaseCollections()
  const collection = useMemo(() => {
    return allCollections.find((collection) => collection.id === collectionID)
  }, [allCollections, collectionID])
  return collection
}

// export function useAllChainCollections(): Collection[] {
//   // console.log('aState useAllChainCollections')
//   return useAppSelector((state: AppState) => {
//     // console.log('aState useAllChainCollections useAppSelector state: ', state)
//     return state.collections.chainCollections
//   })
// }

// export function useAllChainRequests(): Request[] {
//   console.log('aState useChainRequests')
//   return useAppSelector((state: AppState) => {
//     console.log('aState useChainRequests useAppSelector state: ', state)
//     return state.collections.onChainRequests
//   })
// }

// export function useChainRequestsByContext(context?: string): Request[] {
//   console.log('aState useChainRequests')
//   return useAppSelector((state: AppState) => {
//     console.log('aState useChainRequests useAppSelector state: ', state)
//     return state.collections.onChainRequests.filter((r) => r.context === context)
//   })
// }

// export function useChainCollection(collectionID?: string): Collection | undefined {
//   const allCollections = useAllChainCollections()
//   const collection = useMemo(() => {
//     return allCollections.find((collection) => collection.id === collectionID)
//   }, [allCollections, collectionID])
//   return collection
// }

export function useSafeCollection(collectionID?: string): Collection | undefined {
  const firebaseCollection = useFirebaseCollection(collectionID)
  // const chainCollection = useChainCollection(collectionID)

  return useMemo(() => {
    console.log('aState useSafeCollection useMemo firebaseCollection: ', firebaseCollection)
    // console.log('aState useSafeCollection useMemo chainCollection: ', chainCollection)
    return firebaseCollection ?? undefined
  }, [firebaseCollection])
}

export function useSafeCollections(collectionIDs?: string[]): Collection[] {
  const collections = useAllFirebaseCollections()
  // const chainCollections = useAllChainCollections()

  return useMemo(() => {
    console.log('aState useSafeCollections useMemo collections: ', collections)
    // console.log('aState useSafeCollections useMemo chainCollections: ', chainCollections)
    return collections.filter((collection) => collectionIDs?.includes(collection.id)) ?? []
  }, [collectionIDs, collections])
}
