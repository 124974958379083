import { Stream, StreamPlayerApi } from '@cloudflare/stream-react'
import { PublicKey } from '@solana/web3.js'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import { LoadingView } from 'components/ModalViews'
import { ErrorText } from 'components/swap/styleds'
import { useListColor } from 'hooks/useColor'
import { useCachedImage, useExtendedArt } from 'metaplex/Art/useArt'
import { pubkeyToString } from 'metaplex/utils'
import { getLast } from 'metaplex/utils'
import { darken, transparentize } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Card } from 'rebass'
import { useNFTModalToggle, useSetNFTModalMintAddress } from 'state/application/hooks'
import { setNFTModalMintAddress } from 'state/application/reducer'
import { useSafeMint } from 'state/mints/hooks'
import styled from 'styled-components/macro'
import { MetadataCombined, MetadataExtension, MetadataFile } from 'validators/accounts/sales'

import Loader from './Loader'
// import ErrorLogo from "img/logos-solana/dark-solana-logo.svg";

const MAX_TIME_LOADING_IMAGE = 1000000 /* 5 seconds */

export type MetadataRecord = {
  metadata: MetadataExtension
  address: string
}

const ArtContainer = styled(Box)<{ clickable?: boolean; xColor?: string; size: string }>`
  width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ clickable }) => (clickable ? 'all 0.2s ease-in-out' : 'all 0.2s ease-in-out')};
  overflow: visible;
  border-radius: 0.5rem;
  background-color: transparent;
  // box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px;' : 'none')};
  box-shadow: ${({ xColor }) =>
    !xColor ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px' : `0px 10px 30px ${transparentize(0.9, xColor)}`};
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
  ${({ clickable, xColor }) =>
    clickable
      ? `&:hover { box-shadow: ${
          !xColor ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px' : `0px 10px 30px ${transparentize(0.7, xColor)}`
        };} 0px 10px 30px; }`
      : ''}

  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.05)' : 'none')};
    z-index: ${({ clickable }) => (clickable ? '5' : '0')};
  }
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  // holographic border using xColor
  border: ${({ xColor }) => (xColor ? `1.5px solid ${xColor}` : 'none')};
`

export const ArtLoadingOpacityContainer = styled.div<{ $loading: boolean; size: string }>`
  ${loadingOpacityMixin}
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  min-height: ${({ size }) => size};
  min-width: ${({ size }) => size};
`

// const LoadingPlaceholder = () => (
//   <ArtLoadingOpacityContainer $loading={true}>l</ArtLoadingOpacityContainer>
//   <ContentLoader viewBox="0 0 212 200" height={150} width={150} backgroundColor="transparent">
//     <circle cx="86" cy="100" r="8" />
//     <circle cx="106" cy="100" r="8" />
//     <circle cx="126" cy="100" r="8" />
//   </ContentLoader>
// )

export const contentStyle = { borderRadius: '0.4rem', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0)' }

const CachedImageContent = ({ uri }: { uri?: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showError, setShowError] = useState<boolean>(false)
  const [timeout, setTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
  console.log('imageerr CachedImageContent uri', uri)

  useEffect(() => {
    // Set the timeout if we don't have a valid uri
    if (!uri && !timeout) {
      setTimeout(setInterval(() => setShowError(true), MAX_TIME_LOADING_IMAGE))
    }

    // We have a uri - clear the timeout
    if (uri && timeout) {
      clearInterval(timeout)
    }

    return () => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [uri, setShowError, timeout, setTimeout])

  const { cachedBlob } = useCachedImage(uri || '')
  console.log('CachedImageContent', uri, cachedBlob)
  return (
    <>
      <>
        {isLoading && <LoadingOpacityContainer $loading={true}></LoadingOpacityContainer>}
        <img
          src={cachedBlob}
          // alt={'nft'}
          style={contentStyle}
          onLoad={() => {
            setIsLoading(false)
          }}
          onError={() => {
            setShowError(true)
          }}
        />
      </>
    </>
  )
}

const VideoArtContent = ({
  files,
  uri,
  animationURL,
  active,
}: {
  files?: (MetadataFile | string)[]
  uri?: string
  animationURL?: string
  active?: boolean
}) => {
  const [playerApi, setPlayerApi] = useState<StreamPlayerApi>()

  const playerRef = useCallback(
    (ref) => {
      setPlayerApi(ref)
    },
    [setPlayerApi]
  )

  useEffect(() => {
    if (playerApi) {
      playerApi.currentTime = 0
    }
  }, [active, playerApi])

  const likelyVideo = (files || []).filter((f, index, arr) => {
    if (typeof f !== 'string') {
      return false
    }

    // TODO: filter by fileType
    return arr.length >= 2 ? index === 1 : index === 0
  })?.[0] as string

  return (
    <video
      playsInline={true}
      autoPlay={true}
      muted={true}
      controls={false}
      controlsList="nodownload"
      className={'w-full'}
      style={contentStyle}
      loop={true}
      poster={uri}
    >
      {/* {likelyVideo && <source src={likelyVideo} type="video/mp4" />} */}
      {animationURL && <source src={animationURL} type="video/mp4" />}
      {/* {files
        ?.filter((f) => typeof f !== 'string')
        .map((f: any) => (
          <source src={f.uri} type={f.type} key={f.uri} />
        ))} */}
    </video>
  )
}

const HTMLContent = ({ animationUrl, files }: { animationUrl?: string; files?: (MetadataFile | string)[] }) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const htmlURL = files && files.length > 0 && typeof files[0] === 'string' ? files[0] : animationUrl

  return (
    <>
      <ArtLoadingOpacityContainer $loading={!loaded} size={contentStyle.width} />
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        title={'html-content'}
        sandbox="allow-scripts"
        frameBorder="0"
        src={htmlURL}
        className={`${loaded ? 'd-block' : 'd-none'}`}
        style={contentStyle}
        onLoad={() => {
          setLoaded(true)
        }}
        onError={() => {
          setLoaded(true)
        }}
      ></iframe>
    </>
  )
}

export const ArtContentFromCopy = ({ dataRef, className }: { dataRef: MetadataExtension; className: string }) => {
  console.log('ArtContentFromCopy', dataRef)
  let uri
  let animationURL
  let files
  let category
  const active = true
  if (dataRef) {
    uri = dataRef.image
    animationURL = dataRef.animation_url
  }

  if (dataRef?.properties) {
    files = dataRef.properties.files
    category = dataRef.properties.category
  }

  // useEffect(() => {
  //   if (dataRef && pullMetadataExt) pullMetadataExt(id, data);
  // }, [dataRef]);

  animationURL = animationURL || ''

  const animationUrlExt = new URLSearchParams(getLast(animationURL.split('?'))).get('ext')

  const content =
    category === 'video' ? (
      <VideoArtContent files={files} uri={uri} animationURL={animationURL} active={active} />
    ) : category === 'html' || animationUrlExt === 'html' ? (
      <HTMLContent animationUrl={animationURL} files={files} />
    ) : (
      <CachedImageContent uri={uri} />
    )

  return content
}

export const ArtContent = ({
  clickable = false,
  metadata,
  size = '300px',
  // category,
  active,
  pubkey,
  // uri,
  // animationURL,
  // files,
  setIsHovered,
}: {
  clickable?: boolean
  metadata?: MetadataCombined
  size?: string
  // category?: MetadataCategory
  active?: boolean
  pubkey?: string
  // uri?: string
  // animationURL?: string
  // files?: (MetadataFile | string)[]
  setIsHovered?: (hovered: boolean) => void
}) => {
  const toggleNFTModal = useNFTModalToggle()
  const setNFTModalAddr = useSetNFTModalMintAddress()
  // const mintData = useSafeMint(pubkey)

  console.log('imageerr metadata ', metadata)

  const id = useMemo(() => pubkeyToString(pubkey), [pubkey])

  const mintColor = useListColor(metadata?.extended?.image)

  console.log('ArtContentDebug metadatac', metadata)
  const uri = useMemo(() => {
    return metadata?.extended?.image ?? ''
  }, [metadata])
  const animationURL = useMemo(() => {
    return metadata?.extended?.animation_url ?? ''
  }, [metadata])
  const files = useMemo(() => {
    return metadata?.extended?.properties?.files ?? []
  }, [metadata])
  const category = useMemo(() => {
    return metadata?.extended?.properties?.category ?? ''
  }, [metadata])

  const animationUrlExt = new URLSearchParams(getLast(animationURL.split('?'))).get('ext')

  const content = useMemo(() => {
    if (category == 'video') {
      console.log(`ArtContentDebug video files: ${files} uri: ${uri} animationURL: ${animationURL}`)
      return <VideoArtContent files={files} uri={uri} animationURL={animationURL} active={active} />
    } else if (category === 'html' || animationUrlExt === 'html') {
      console.log(`ArtContentDebug html animationURL: ${animationURL}`)
      return <HTMLContent animationUrl={animationURL} files={files} />
    } else {
      console.log(`ArtContentDebug image uri: ${uri}`)
      return <CachedImageContent uri={uri} />
    }
  }, [category, animationUrlExt, files, uri, animationURL, active])

  return (
    <ArtContainer
      size={size}
      xColor={mintColor}
      // style={{ boxShadow: mintColor ? `0px 0px 10px ${mintColor}` : 'none' }}
      clickable={clickable}
      onClick={() => {
        if (clickable) {
          setNFTModalAddr(id)
          toggleNFTModal()
        }
      }}
      onMouseEnter={() => {
        if (setIsHovered) {
          setIsHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (setIsHovered) {
          setIsHovered(false)
        }
      }}
    >
      {content}
    </ArtContainer>
  )
}
