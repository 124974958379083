import useScrollPosition from '@react-hook/window-scroll'
import { nanoid } from '@reduxjs/toolkit'
import { useWallet } from '@solana/wallet-adapter-react'
import { BaseButton, ButtonSecondary, ButtonSmallText } from 'components/Button'
import { HeaderCard } from 'components/Card'
import CollectionLogo from 'components/CollectionLogo'
import { AutoColumn } from 'components/Column'
import { ConnectButton } from 'components/ConnectButton'
import ExplorerLink from 'components/ExplorerLink'
import { StyledNavLink } from 'components/Header'
import FilterDropdown from 'components/SalesFilter/FilterDropdown'
import Search from 'components/Search'
import { useApolloProgram } from 'components/SolanaManager'
import UserLogo from 'components/UserLogo'
import UserRow from 'components/UserRow'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/misc'
import useTheme from 'hooks/useTheme'
import { darken, transparentize } from 'polished'
import { useEffect, useState } from 'react'
import { Clock, Home, List } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button } from 'rebass'
import { useErrorModalToggle, useSetErrorModalData, useWalletModalToggle } from 'state/application/hooks'
import { setErrorModalData } from 'state/application/reducer'
import { useCreateFirebaseCollection } from 'state/callections/firebase'
import {
  useCollection,
  useCollectionsByUserID,
  useCollectionsFirebaseRequestByRequestID,
} from 'state/callections/hooks'
import { Collection, CollectionType } from 'state/callections/models'
import { RequestStatus } from 'state/callections/reducer'
import { useDarkModeManager } from 'state/user/hooks'
import { addRequiredWallets } from 'state/wallets/actions'
import { useSafeWallet } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { ExternalLinkIcon, StyledInternalLink, TYPE } from 'theme'
import { shortenAddress, truncateString } from 'utils'
import { solExplorerAddressURL } from 'utils/getExplorerLink'

import LogoLightMode from '../../assets/svg/carmelDark.svg'
import LogoDarkMode from '../../assets/svg/carmelLight.svg'
// import HomeLogoLightMode from '../../assets/svg/homeDark.svg'
// import HomeLogoDarkMode from '../../assets/svg/homeLight.svg'
import Row, { AutoRow, RowBetween, RowFixed } from '../Row'
import SidebarDropdown from './SidebarDropdown'

const activeClassName = 'ACTIVE'

const randomMovingGradient = () => {
  const isRadial = Math.random() >= 0.5
  const isHorizontal = Math.random() >= 0.5
  const style = isRadial ? 'radial-gradient' : 'linear-gradient'
  const direction = isHorizontal ? 'to right' : 'to bottom'
  const color1 = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
    Math.random() * 255
  )}, 0.8)`
  const color2 = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
    Math.random() * 255
  )}, 0.8)`
  return `${style}(${direction}, ${color1}, ${color2})`
}

export const Wrapper = styled.div<{ xColor: string }>`
  // position: relative;
  // background-color: ${({ theme }) => theme.blue1};
  // position: fixed;
  // top: 110px;
  // left: 26px;
  // margin-left: 26px;
  width: 277px;
  margin-right: 37px;
  // height: 100%;
  overflow: hidden;
  position: relative;
  // flex: 0 0 auto;
  max-height: 80vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  padding: 0px 0px 10px 0px;
  // box-shadow: 1px 0px 0px 0px ${({ theme }) => theme.bg3};
`

export const Container = styled.div`
  // width: 100%;
  // position: relative;
  // top: 0;
  // left: 0;
  // right: 0;
  // padding: 20px 20px 10px 0px;
  // box-shadow: 1px 0px 0px 0px ${({ theme }) => theme.bg3};
`

const LogoWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DisconnectButton = styled(ButtonSmallText)`
  color: ${({ theme }) => theme.text3};
`

const SellNFTButton = styled(NavLink).attrs({
  activeClassName,
})<{ backgroundImage: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // word-break: break-word;
  // overflow: hidden;
  // white-space: nowrap;
  border-radius: 66px;
  &.${activeClassName} {
    font-weight: 500;
    justify-content: center;
    color: ${({ theme }) => theme.primary5};
    background-color: ${({ theme }) => transparentize(0.8, theme.headerBG)};
  }
  background-color: ${({ theme }) => transparentize(0.2, theme.headerBG)};
  mix-blend-mode: overlay;
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.3, theme.text1)};
  }
`
const CreateButton = styled(SellNFTButton).attrs({
  activeClassName,
})<{ backgroundImage: string }>`
  background ${({ theme }) => theme.transparent};
  border: 2px solid ${({ theme }) => theme.blue1};
  color: ${({ theme }) => theme.text2};

  background-size: 600% 600%;
  animation: GradientBackground 10s ease infinite;
  @keyframes GradientBackground {
    0% {
      color: ${({ theme }) => theme.text5};
      background-position: 0% 50%;
    }
    50% {
      color: ${({ theme }) => theme.text1};
      background-position: 100% 50%;
    }
    100% {
      color: ${({ theme }) => theme.text5};
      background-position: 0% 50%;
    }
  } ;
`
const SellButton = styled(SellNFTButton)`
  background ${({ theme }) => theme.transparent};
  border: 2px solid ${({ theme }) => theme.green1};
  color: ${({ theme }) => theme.text2};
  background-size: 300% 300%;
  animation: GradientBackground 20s ease infinite;
  @keyframes GradientBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  } ;
`

export const ButtonGrid = styled.div`
  display: grid;
  grid-column-gap: 9px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  margin-top: 20px;
`

const CollectionNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 18px;
  font-weight: 600;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  // word-break: break-word;
  // overflow: hidden;
  // white-space: nowrap;

  border-radius: 14px;
  &.${activeClassName} {
    font-weight: 500;
    // justify-content: center;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => transparentize(0.0, theme.bg1)};
  }

  background-color: ${({ theme }) => transparentize(1.0, theme.headerBG)};
  mix-blend-mode: overlay;
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.3, theme.text1)};
  }
`

const HomeLogo = styled(Home)`
  // transition: transform 0.1s linear;
`
const RecommendedLogo = styled(List)`
  // transition: transform 0.1s linear;
`
const RecentLogo = styled(Clock)`
  // transition: transform 0.1s linear;
`

export const RECOMMENDED_FEED = 'recommended'

export default function Sidebar() {
  const wallet = useWallet()
  const { disconnect, publicKey } = wallet
  // const userPubkey = wallet?.publicKey

  const toggleWalletModal = useWalletModalToggle()

  const [darkMode] = useDarkModeManager()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { white, black } = theme
  const scrollY = useScrollPosition()

  const clientWallet = useSafeWallet(publicKey?.toBase58())
  useEffect(() => {
    if (publicKey) {
      dispatch(addRequiredWallets([publicKey.toBase58()]))
    }
  }, [dispatch, publicKey])
  useEffect(() => {
    if (clientWallet?.following) {
      dispatch(addRequiredWallets(clientWallet.following))
    }
  }, [clientWallet?.following, dispatch, publicKey])

  // const userCollections = useCollectionsByUserID(userPubkey?.toBase58())

  const followingRows = () => {
    return clientWallet?.following?.map((followee) => {
      return <UserRow key={followee} userAddress={followee} size={'30px'}></UserRow>
    })
  }

  return (
    <Wrapper xColor="#BC8288">
      <Container>
        <AutoColumn>
          {publicKey ? (
            <AutoColumn>
              <AutoRow gap="10px">
                {/* <BaseButton
                  style={{ width: 'fit-content', padding: '0px;' }}
                  as={Link}
                  to={`/u/${userPubkey.toBase58()}`}
                > */}
                <UserLogo size="36px" userAddress={publicKey.toBase58()}></UserLogo>
                {/* </BaseButton> */}
                <AutoColumn justify="start" style={{ marginLeft: '-5px' }}>
                  <TYPE.heavy color={theme.text2} fontSize={'22px'}>
                    {truncateString(publicKey.toBase58())}
                  </TYPE.heavy>
                  <DisconnectButton
                    onClick={() => {
                      console.log('clicked')
                      disconnect()
                    }}
                  >
                    <TYPE.heavy color={theme.text3}>Disconnect</TYPE.heavy>
                  </DisconnectButton>
                </AutoColumn>
              </AutoRow>
              {/* <ButtonGrid>
                <CreateButton
                  backgroundImage={randomMovingGradient()}
                  to={'/create'}
                  isActive={(match, { pathname }) => pathname.startsWith('/create')}
                >
                  Create
                </CreateButton>
                <SellButton
                  id={`sell-nav-link`}
                  backgroundImage={randomMovingGradient()}
                  to={`/user/${userPubkey?.toBase58()}/owned`}
                  isActive={(match, { pathname }) => pathname.startsWith(`/user/${userPubkey?.toBase58()}/owned`)}
                >
                  Sell
                </SellButton>
              </ButtonGrid> */}
              <StyledInternalLink to="/home" style={{ marginTop: '20px' }}>
                <RowFixed alignItems={'center'} justifyContent={'center'}>
                  <HomeLogo stroke={theme.text2}></HomeLogo>
                  <TYPE.main color={theme.text2} marginLeft={'10px'} fontSize={'22px'} size="26px">
                    Feed
                  </TYPE.main>
                </RowFixed>
              </StyledInternalLink>

              <SidebarDropdown title={'Following'}>{followingRows()}</SidebarDropdown>
              {/* <SidebarDropdown
                title={'Sales'}
                content={() => {
                  return <>Dropdown1</>
                }}
              />
              <SidebarDropdown
                title={'Bids'}
                content={() => {
                  return <>Dropdown1</>
                }}
              /> */}
            </AutoColumn>
          ) : (
            <ConnectButton></ConnectButton>
          )}
          <StyledInternalLink to={'/feed/' + RECOMMENDED_FEED} style={{ marginTop: '20px' }}>
            <RowFixed alignItems={'center'} justifyContent={'center'}>
              <RecommendedLogo stroke={theme.text2}></RecommendedLogo>
              <TYPE.main color={theme.text2} marginLeft={'10px'} fontSize={'22px'} size="26px">
                Recommended
              </TYPE.main>
            </RowFixed>
          </StyledInternalLink>
          <StyledInternalLink to={'/explore'} style={{ marginTop: '20px' }}>
            <RowFixed alignItems={'center'} justifyContent={'center'}>
              <RecentLogo stroke={theme.text2}></RecentLogo>
              <TYPE.main color={theme.text2} marginLeft={'10px'} fontSize={'22px'} size="26px">
                Recent
              </TYPE.main>
            </RowFixed>
          </StyledInternalLink>
        </AutoColumn>
      </Container>
    </Wrapper>
  )
}
