import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import { ORDER_SIDE } from './chain'
import { Order } from './models'
import { OrderQuery, Request, RequiredOrderQuery } from './reducer'

export function useRequiredOrders(): string[] {
  console.log('aState useRequiredOrders')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredOrders useAppSelector state: ', state)
    return state.orders.requiredOrders
  })
}

export function useAllFirebaseOrders(): Order[] {
  console.log('aState useAllFirebaseOrders')
  return useAppSelector((state: AppState) => {
    console.log('aState useAllFirebaseOrders useAppSelector state: ', state)
    return state.orders.firebaseOrders
  })
}

export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.orders.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.orders.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseOrder(orderPDA?: string): Order | undefined {
  const allOrders = useAllFirebaseOrders()
  const order = useMemo(() => {
    return allOrders.find((order) => order.delegatePDA === orderPDA)
  }, [allOrders, orderPDA])
  return order
}

// export function useFirebaseOrders()

export function useAllChainRequests(): Request[] {
  console.log('aState useAllChainRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useAllChainRequests useAppSelector state: ', state)
    return state.orders.chainRequests
  })
}

export function useChainRequestsByID(id?: string): Request[] {
  console.log('aState useChainRequestsByID')
  return useAppSelector((state: AppState) => {
    console.log('aState useChainRequestsByID useAppSelector state: ', state)
    return state.orders.chainRequests.filter((r) => r.requestID === id)
  })
}

export function useOrdersByAssetMint(assetMint?: string): Order[] {
  console.log('aState useOrdersByAssetMint')
  return useAppSelector((state: AppState) => {
    console.log('aState useOrdersByAssetMint useAppSelector state: ', state)
    return state.orders.firebaseOrders.filter((order) => order.assetMint == assetMint)
  })
}

export function useRequiredOrderQueries(): RequiredOrderQuery[] {
  console.log('aState useRequiredQueries')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredQueries useAppSelector state: ', state)
    return state.orders.requiredOrderQueries
  })
}

export function useAllOrderQueryRequests(): Request[] {
  console.log('aState useAllOrderQueryRequests')
  return useAppSelector((state: AppState) => {
    console.log('aState useAllOrderQueryRequests useAppSelector state: ', state)
    return state.orders.queryRequests
  })
}

export function useAllOrderQueries(): OrderQuery[] {
  console.log('aState useAllOrderQueries')
  return useAppSelector((state: AppState) => {
    console.log('aState useAllOrderQueries useAppSelector state: ', state)
    return state.orders.orderQueries
  })
}

// REMOVING SO QUERIES DONT NEED TO BE REFRESHED AFTER CREATE ORDER
// export function useOrderQueryByAssetMintAndSide(assetMint?: string, side?: ORDER_SIDE): OrderQuery[] {
//   const allOrderQueries = useAllOrderQueries()
//   return useMemo(() => {
//     const queryKey = new RequiredOrderQuery(assetMint, side).key
//     return allOrderQueries.filter((oq) => oq.queryKey === queryKey)
//   }, [allOrderQueries, assetMint, side])
// }

export function useOrdersByAssetMintAndSide(assetMint?: string, side?: ORDER_SIDE): Order[] {
  const ordersByAssetMint = useOrdersByAssetMint(assetMint)
  // console.log('aState useOrdersByAssetMintAndSide ordersByAssetMint: ', ordersByAssetMint)
  return useMemo(() => {
    return ordersByAssetMint.filter((order) => order.side === side && !order.revoked && !order.matched)
  }, [ordersByAssetMint, side])
}

export function useAllLiveOrders(): Order[] {
  const allOrders = useAllFirebaseOrders()
  return useMemo(() => {
    return allOrders
    return allOrders.filter(
      (order) => !order.revoked && !order.matched && order.listingDate > Date.now() && order.expirationDate < Date.now()
    )
  }, [allOrders])
}
