import { Edition, EditionMarker, MasterEditionV1, MasterEditionV2 } from 'metaplex/classes'
import { array, boolean, defaulted, enums, Infer, nullable, number, optional, string, type } from 'superstruct'
import { BoolFromNumber, NumberFromBool, NumberFromString, StringFromPublicKey } from 'validators/pubkey'

export type Order = Infer<typeof Order>
export const Order = type({
  delegatePDA: string(),
  delegatePDABumpSeed: number(),
  maker: string(),
  taker: string(),
  source: string(),
  assetMint: string(),
  assetSize: number(),
  paymentMint: string(),
  paymentBaseSize: number(),
  creatorsFeeSize: number(),
  curator: string(),
  curatorBPS: number(),
  curatorFeeSize: number(),
  listingDate: number(),
  expirationDate: number(),
  side: number(),
  // secret: string(),
  revoked: boolean(),
  revokedDate: number(),
  matched: boolean(),
  matchedWith: string(),
  matchedDate: number(),
  programID: string(),
})
