import { BaseButton } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import styled from 'styled-components/macro'

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px 20px 0px 20px;
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 10px;
  width: 100%;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
`

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

export const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

export const SubmitFooter = styled(BaseButton)`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, disabled }) => (disabled ? theme.bg3 : theme.bg0)};
  color: ${({ theme, disabled }) => (disabled ? theme.text3 : theme.text1)};
  border-top: 1px solid ${({ theme }) => theme.bg2};
  font-weight: 500;
  font-size: 20px;
  margin-top: 30px;
`
