import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import Badge, { BadgeVariant } from 'components/Badge'
import { ArtContent } from 'components/BundleArtDisplay/ArtContent'
import { BaseButton } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import AmountStepCounter from 'components/create/AmountStepCounter'
import CurrencyInputPanel, { Container } from 'components/CurrencyInputPanel'
import DoubleModal from 'components/DoubleModal'
import ExpirationDurationSelector from 'components/ExpirationDateSelector'
import ExplorerLink from 'components/ExplorerLink'
import Loader from 'components/Loader'
import MintArtDisplay from 'components/MintArtDisplay'
import MintInfoContent from 'components/MintInfoContent'
import { LoadingView } from 'components/ModalViews'
import OrderModalBase from 'components/OrderModalBase'
import Row, { AutoRow, RowBetween, RowFlat } from 'components/Row'
import SolTokenLogo from 'components/SolTokenLogo'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import TXConfirmationModal, {
  ConfirmationModalContent,
  ConfirmationPendingContent,
  TransactionErrorContent,
  TransactionSubmittedContent,
} from 'components/TXConfirmationModal'
import useLast from 'hooks/useLast'
import usePrevious from 'hooks/usePrevious'
import { useExtendedArt } from 'metaplex/Art/useArt'
import { useAccountInfo, useFetchAccountInfo } from 'providers/accounts'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetCreateOrderState, typeAssetSizeInput } from 'state/createOrder/actions'
import {
  useCreateOrderActionHandlers,
  useCreateOrderDerivedInfo,
  useCreateOrderState,
  useCreateOrderSubmit,
} from 'state/createOrder/hooks'
import { useSafeMint } from 'state/mints/hooks'
import { TokenInfo } from 'state/mints/models'
import { ORDER_SIDE } from 'state/orders/chain'
import { useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { CloseIcon, CustomLightSpinner, TYPE } from 'theme'
import { WRAPPED_SOL_MINT } from 'utils/utils'
import { MetadataAttribute } from 'validators/accounts/sales'

import Circle from '../../assets/images/blue-loader.svg'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import {
  useCreateOrderModalConfig,
  useCreateOrderModalToggle,
  useModalOpen,
  useNFTModalMintAddress,
  useNFTModalToggle,
  useSetCreateOrderModalConfig,
} from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import Modal from '../Modal'
import { PaddedColumn } from './styled'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 10px;
  width: 100%;
`

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg1};
`

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

const SubmitFooter = styled(BaseButton)`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, disabled }) => (disabled ? theme.bg3 : theme.bg0)};
  color: ${({ theme, disabled }) => (disabled ? theme.text3 : theme.text1)};
  border-top: 1px solid ${({ theme }) => theme.bg2};
  font-weight: 500;
  font-size: 20px;
  margin-top: 30px;
`

export enum CreateOrderModalView {
  SELL = 'sell',
  BUY = 'buy',
}

export default function CreateOrderModal() {
  const wallet = useAnchorWallet()
  const userPubkey = wallet?.publicKey
  const dispatch = useDispatch()
  const isOpen = useModalOpen(ApplicationModal.CREATE_ORDER)
  const toggleModal = useCreateOrderModalToggle()
  const lastOpen = useLast(isOpen)
  // const [modalView, setModalView] = useState<CreateOrderModalView>(CreateOrderModalView.SELL)
  // const prevView = usePrevious(modalView)

  // let content = null

  const { assetMint, assetSize, paymentSize, side, expirationDuration } = useCreateOrderState()

  // const minHeight = side === ORDER_SIDE.SELL ? 40 : 80

  const {
    assetMintData,
    userOwnedAssetSize,
    userOwnedPaymentSize,
    paymentCurrency,
    submitDisabled,
    paymentSizeError,
    assetSizeError,
  } = useCreateOrderDerivedInfo()

  const {
    handleSubmit,
    submitError,
    firebaseSubmitError,
    chainSubmitError,
    submitting,
    firebaseOrderPDA,
    chainSubmitRes,
  } = useCreateOrderSubmit()

  useEffect(() => {
    if (userOwnedAssetSize) {
      dispatch(typeAssetSizeInput({ typedValue: `${userOwnedAssetSize}` }))
    } else {
      dispatch(typeAssetSizeInput({ typedValue: `${assetMintData?.isNFT ? 1 : 1}` }))
    }
  }, [assetMintData?.isNFT, dispatch, userOwnedAssetSize])

  useEffect(() => {
    if (userOwnedPaymentSize) {
      if (userOwnedPaymentSize > 1) {
        dispatch(typeAssetSizeInput({ typedValue: `1` }))
      } else {
        dispatch(typeAssetSizeInput({ typedValue: `${userOwnedPaymentSize}` }))
      }
    } else {
      dispatch(typeAssetSizeInput({ typedValue: `1` }))
    }
  }, [dispatch, userOwnedPaymentSize])

  const {
    onAssetSizeInput,
    onPaymentSizeInput,
    onPaymentMintChange,
    onIncrementAssetSize,
    onDecrementAssetSize,
    onSetExpirationDuration,
  } = useCreateOrderActionHandlers()

  const handleDismiss = useCallback(() => {
    // setShowConfirm(false)
    setAttemptingTxn(false)
    toggleModal()
    dispatch(resetCreateOrderState())
  }, [dispatch, toggleModal])

  const sellSide = () => {
    return (
      <ContentWrapper>
        <PaddedColumn gap="14px">
          <RowBetween>
            <TYPE.heavy fontWeight={500} fontSize={22}>
              New Sale
            </TYPE.heavy>
            <CloseIcon onClick={handleDismiss} />
          </RowBetween>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          <TYPE.heavy fontWeight={500} fontSize={16}>
            Item
          </TYPE.heavy>
          <Container hideInput={false}>
            <RowBetween width={'100%'}>
              <AutoRow>
                <MintArtDisplay mintAddress={assetMintData?.address} clickable={false} size={'100px'} />
                <AutoColumn style={{ marginLeft: '10px' }}>
                  <TYPE.main fontWeight={400} fontSize={20}>
                    {assetMintData?.isNFT ? assetMintData?.metadata?.extended?.name : assetMintData?.tokenInfo?.name}
                  </TYPE.main>
                  <TYPE.main fontWeight={400} fontSize={16}>
                    {/* {assetMintData?.isNFT ?( assetMintData?.metadata?.extended?.collection a).name : ''} */}
                  </TYPE.main>
                </AutoColumn>
              </AutoRow>
              <AmountStepCounter
                width="200px"
                value={assetSize}
                decrement={onDecrementAssetSize}
                increment={onIncrementAssetSize}
                decrementDisabled={false}
                incrementDisabled={false}
                onUserInput={onAssetSizeInput}
                title={`${userOwnedAssetSize} Owned`}
              />
            </RowBetween>
          </Container>
          <TYPE.error error={assetSizeError != null}>{assetSizeError}</TYPE.error>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          <TYPE.heavy fontWeight={500} fontSize={16}>
            Price
          </TYPE.heavy>
          <CurrencyInputPanel
            label={'Select Token'}
            value={paymentSize}
            currency={paymentCurrency}
            onUserInput={onPaymentSizeInput}
            onCurrencySelect={onPaymentMintChange}
            id="swap-currency-input"
            loading={false}
          />
          <TYPE.error error={paymentSizeError != null}>{paymentSizeError}</TYPE.error>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          <TYPE.heavy fontWeight={500} fontSize={16}>
            Expires
          </TYPE.heavy>
          <ExpirationDurationSelector
            expirDuration={expirationDuration}
            handleExpirDurationSelect={onSetExpirationDuration}
          ></ExpirationDurationSelector>
        </PaddedColumn>

        <SubmitFooter
          disabled={submitDisabled}
          onClick={() => {
            // setShowConfirm(true)
            setAttemptingTxn(true)
            handleSubmit()
          }}
        >
          <TYPE.error error={submitError != null}>{submitError ?? 'Submit'}</TYPE.error>
        </SubmitFooter>
      </ContentWrapper>
    )
  }
  const buySide = () => {
    return (
      <ContentWrapper>
        <PaddedColumn gap="14px">
          <RowBetween>
            <TYPE.heavy fontWeight={500} fontSize={22}>
              Make Offer
            </TYPE.heavy>
            <CloseIcon onClick={handleDismiss} />
          </RowBetween>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          {/* <TYPE.heavy fontWeight={500} fontSize={16}>
            Item
          </TYPE.heavy> */}
          <Container hideInput={false}>
            <RowBetween width={'100%'}>
              <AutoRow>
                <MintArtDisplay mintAddress={assetMintData?.address} clickable={false} size={'100px'} />
                <AutoColumn style={{ marginLeft: '10px' }}>
                  <TYPE.main fontWeight={400} fontSize={20}>
                    {assetMintData?.isNFT ? assetMintData?.metadata?.extended?.name : assetMintData?.tokenInfo?.name}
                  </TYPE.main>
                  <TYPE.main fontWeight={400} fontSize={16}>
                    {/* {assetMintData?.isNFT ? assetMintData?.metadata?.extended?.collection?.name : ''} */}
                  </TYPE.main>
                </AutoColumn>
              </AutoRow>
              <AmountStepCounter
                width="200px"
                value={assetSize}
                decrement={onDecrementAssetSize}
                increment={onIncrementAssetSize}
                decrementDisabled={false}
                incrementDisabled={false}
                onUserInput={onAssetSizeInput}
                title={`Amount`}
              />
            </RowBetween>
          </Container>
          <TYPE.error error={assetSizeError != null}>{assetSizeError}</TYPE.error>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          <TYPE.heavy fontWeight={500} fontSize={16}>
            Offer
          </TYPE.heavy>
          <CurrencyInputPanel
            label={'Select Token'}
            value={paymentSize}
            currency={paymentCurrency}
            onUserInput={onPaymentSizeInput}
            onCurrencySelect={onPaymentMintChange}
            id="swap-currency-input"
            loading={false}
          />
          <TYPE.error error={paymentSizeError != null}>{paymentSizeError}</TYPE.error>
        </PaddedColumn>
        <PaddedColumn gap="14px">
          <TYPE.heavy fontWeight={500} fontSize={16}>
            Expires
          </TYPE.heavy>
          <ExpirationDurationSelector
            expirDuration={expirationDuration}
            handleExpirDurationSelect={onSetExpirationDuration}
          ></ExpirationDurationSelector>
        </PaddedColumn>

        <SubmitFooter
          disabled={submitDisabled}
          onClick={() => {
            // setShowConfirm(true)
            setAttemptingTxn(true)
            handleSubmit()
          }}
        >
          <TYPE.error error={submitError != null}>{submitError ?? 'Submit'}</TYPE.error>
        </SubmitFooter>
      </ContentWrapper>
    )
  }

  const submissionContent = () => {
    return submitError ?? firebaseSubmitError ?? chainSubmitError ? (
      <TransactionErrorContent
        message={submitError ?? firebaseSubmitError ?? chainSubmitError}
        onDismiss={handleDismiss}
      ></TransactionErrorContent>
    ) : chainSubmitRes ? (
      <TransactionSubmittedContent
        // chainId={chainId}
        hash={chainSubmitRes ?? undefined}
        onDismiss={handleDismiss}
        doneLink={`/m/${assetMint}`}
        // currencyToAdd={currencyToAdd}
      />
    ) : (
      <ConfirmationPendingContent onDismiss={handleDismiss} pendingText={'pendingText'} />
    )
    // <TYPE.main height={'200px'} fontSize="8px">
    //   {JSON.stringify({ firebaseSubmitError, chainSubmitError, submitting })}
    // </TYPE.main>
  }

  // const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)

  return (
    <OrderModalBase isOpen={isOpen} onDismiss={handleDismiss} minHeight={false} maxHeight={700}>
      {/* <TXConfirmationModal
        isOpen={attemptingTxn}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={chainSubmitRes ? attemptingTxn : false}
        hash={chainSubmitRes ?? undefined}
        errorText={chainSubmitError ?? firebaseSubmitError ?? undefined}
        doneLink={`item/${assetMint}`}
        content={() => <></>}
        pendingText={'...'}
      /> */}
      {attemptingTxn ? submissionContent() : side == ORDER_SIDE.SELL ? sellSide() : buySide()}
    </OrderModalBase>
  )
}
