import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import CollectionLogo from 'components/CollectionLogo'
import Row from 'components/Row'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { darken, opacify, transparentize } from 'polished'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useSafeCollection } from 'state/collections/hooks'
import { useSafeUser } from 'state/users/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { truncateString } from 'utils'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
const activeClassName = 'active'
const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  margin: 0 0px;
  // font-weight: 600;

  &.${activeClassName} {
    border-radius: 12px;
    color: ${({ theme }) => theme.text1};
    font-weight: 600;
  }

  :hover {
    color: ${({ theme }) => darken(0.1, theme.text2)};
  }
`
export default function CollectionRow({ collectionID, size = '18px' }: { collectionID?: string; size?: string }) {
  const collection = useSafeCollection(collectionID)
  const theme = useTheme()

  const truncatedName = useMemo(() => {
    if (collection?.name) {
      if (collection?.name.length > 20) {
        return `${collection.name.substring(0, 20)}...`
      }
      return collection.name
    }
    return ''
  }, [collection])

  return collectionID ? (
    <Row
      // to={`/collection/${collection?.id}`}
      style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}
    >
      <CollectionLogo size={size} collectionID={collectionID} style={{}}></CollectionLogo>
      <TYPE.body fontSize={size} color={theme.text3} style={{ marginLeft: '4px' }}>
        {truncatedName}
      </TYPE.body>
    </Row>
  ) : (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
      {/* <UserLogo size={size} userAddress={userAddress} style={{ marginLeft: '10px' }}></UserLogo> */}
      <TYPE.body fontSize={'18px'} color={theme.text3} style={{ marginLeft: '4px' }}>
        Unknown Collection
      </TYPE.body>
    </div>
  )
}
