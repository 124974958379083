import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import ActionsFeed from 'components/ActionsFeed'
import { BaseButton } from 'components/Button'
import { DarkCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import MintsFeed from 'components/MintsFeed'
import NiceNav from 'components/NiceNav'
import { AutoRow } from 'components/Row'
import UserHeader from 'components/UserHeader'
import { useFetchFeedResults } from 'hooks/feed'
import getLatestSlot from 'hooks/useLatestSlot'
import useTheme from 'hooks/useTheme'
import { Separator } from 'pages/App'
import { transparentize } from 'polished'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, RouteComponentProps, useLocation } from 'react-router-dom'
import { Button } from 'rebass'
import { addRequiredActionQueries, refreshQuery } from 'state/actions/actions'
import { ActionType, RequiredActionQuery } from 'state/actions/reducer'
import { addRequiredFeeds, refreshFeed } from 'state/feeds/actions'
import { useSafeFeed } from 'state/feeds/hooks'
import { addRequiredUsers } from 'state/users/actions'
import { addRequiredWallets } from 'state/wallets/actions'
import { useSyncWalletToSlot } from 'state/wallets/firebase'
import { useSafeWallet, useSafeWallets } from 'state/wallets/hooks'
import styled from 'styled-components/macro'
import { StyledInternalLink } from 'theme'

export const ContentPageWrapper = styled.div`
  // height: fit-content;
  position: relative;
  min-width: 250px;
  // max-width: 500px;
  width: 100%;
  padding-top: 10px;
  // overflow-x: visible !important;
  // overflow: scroll;
  max-width: 1200px;
`

export const Grid = styled(AutoColumn)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: start;
  align-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 0px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

export const CollectionRowGrid = styled(DarkCard)`
  display: grid;
  height: 100%;
  width: 100%;

  padding-top: 30px;
  position: relative;
  top: 0;

  align-items: center;
  // align-content: space-between;
  grid-template-columns: 15px 250px 75px 150px 110px 60px 50px;

  grid-gap: 10px;

  overflow-x: visible !important;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 1fr 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: 1fr 1fr;
`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr ;
`};
`

const SearchSettingsWrapper = styled(Column)`
  position: relative;
  top: 0;
  width: 100%;
  padding: 0px 0px 10px 0px;
`
const activeClassName = 'ACTIVE'

export const ExploreToggleElement = styled(NavLink).attrs({
  activeClassName,
})`
  display: flex;
  align-items: center;

  padding: 6px 8px;
  baction-radius: 6px;
  justify-content: start;
  height: 100%;
  background: ${({ theme }) => 'none'};
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme }) => theme.text2};
  }
  font-size: 14px;

  &.${activeClassName} {
    background: ${({ theme }) => theme.bg1};
    color: ${({ theme }) => theme.text2};
    font-weight: 700;
  }
  text-decoration: none;
`

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  baction-bottom: 1px solid ${({ theme }) => transparentize(0, theme.bg3)};
  background: ${({ theme }) => transparentize(0.9, theme.bg1)};
`
export const TopBarLeftWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`
export const FiltersWrapper = styled.div`
  display: flex;
  width: fit-content;

  gap: 10px;
  align-items: center;
`

enum FeedPageType {
  Home = 'home',
  User = 'user',
  Recent = 'recent',
  Feed = 'feed',
  Top = 'top',
}

enum RefinementType {
  Feed = 'feed',
  Purchases = 'purchases',
  Mints = 'mints',
  Offers = 'offers',
  Listings = 'listings',
  Transfers = 'transfers',
  Owned = 'owned',
}

const GridWrapper = styled(ScrollAreaPrimitive.Root)<{ offset: string }>`
  // max-height: 88vh;
  overflow-y: scroll;
  // max-height: 100%;

  position: relative;
  left: 0px;
  right: 0px;
  max-height: ${({ offset }) => `calc(100vh - ${offset});`};
  // max-height: 100%;

  // padding-left: 30px;
  // padding-right: 30px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const TypeButton = styled(BaseButton)<{ isActive: boolean }>`
  padding: 6px 8px;
  border-radius: 6px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.bg5 : theme.bg1)};
  color: ${({ isActive, theme }) => (isActive ? theme.text5 : theme.secondary1)};
  box-shadow: ${({ isActive, theme }) => (isActive ? ' 0px 4px 19px rgba(0, 0, 0, 0.13)' : 'none')};
  text-decoration: none !important;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  width: fit-content;
  hover: {
    background-color: ${({ isActive, theme }) => (isActive ? theme.bg0 : theme.bg1)};
    color: ${({ isActive, theme }) => (isActive ? theme.text1 : theme.text1)};
  },
`

const ButtonsGrid = styled(AutoRow)`
  margin-top: 10px;
  margin-bottom: 10px;
  // justify-content: center;
  // align-items: center;
  gap: 0.5rem;
  width: 100%;
  // border-bottom: 1px solid ${({ theme }) => transparentize(0, theme.bg3)};
`

export default function FeedPage({
  match: {
    params: { type: typeFromURL, id: idFromURL, refinement: refinementFromURL },
  },
}: RouteComponentProps<{ type?: string; id?: string; refinement?: string }>) {
  const wallet = useAnchorWallet()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const theme = useTheme()
  const debouncedSetStateRef = useRef(null)
  // const ÷
  const scrollRef = useRef(null)

  useEffect(() => {
    if (wallet?.publicKey) {
      console.log('mintissaved dispatching addRequiredUsers', wallet.publicKey)
      dispatch(addRequiredUsers([wallet?.publicKey.toBase58()]))
    }
  }, [dispatch, wallet?.publicKey])

  const clientWallet = useSafeWallet(wallet?.publicKey.toBase58())

  const pageType = useMemo(() => {
    if (typeFromURL == 'all' || typeFromURL == undefined) {
      return FeedPageType.Home
    }
    if (typeFromURL == 'user' && idFromURL) {
      return FeedPageType.User
    }
    if (typeFromURL == 'feed' && idFromURL) {
      return FeedPageType.Feed
    }
    if (typeFromURL == 'recent') {
      return FeedPageType.Recent
    }
    if (typeFromURL == 'top') {
      return FeedPageType.Top
    }
    return FeedPageType.Top
    // return ['gacMrsrxNisAhCfgsUAVbwmTC3w9nJB6NychLAnTQFv']
  }, [idFromURL, typeFromURL])

  // const feedID = useMemo(() => {
  //   if (pageType == FeedPageType.Feed) {
  //     return idFromURL
  //   }
  //   return undefined
  // }, [idFromURL, pageType])

  // useEffect(() => {
  //   if (feedID) {
  //     dispatch(addRequiredFeeds([feedID]))
  //   }
  // }, [dispatch, feedID])
  // const feed = useSafeFeed(feedID)

  // useEffect(() => {
  //   if (feedID) {
  //     dispatch(refreshFeed(feedID))
  //   }
  // }, [])

  const walletsRaw = useMemo(() => {
    if (pageType == FeedPageType.User && idFromURL) {
      return [idFromURL]
    }
    if (pageType == FeedPageType.Home) {
      if (!clientWallet) {
        return []
      }
      return []
    }
    if (pageType == FeedPageType.Recent) {
      return []
    }
    if (pageType == FeedPageType.Feed) {
      // if (!feed) {
      //   return []
      // }
      return []
    }
    return []
  }, [clientWallet, idFromURL, pageType])
  console.log('feedpage debug walletsraw:', walletsRaw)

  const refinementType = useMemo(() => {
    const refinement = pageType == FeedPageType.User || pageType == FeedPageType.Feed ? refinementFromURL : idFromURL
    // if (pageType == FeedPageType.User && refinementFromURL) {
    if (refinement == 'owned') {
      return RefinementType.Owned
    }
    if (refinement == 'mints') {
      return RefinementType.Mints
    }
    if (refinement == 'purchases') {
      return RefinementType.Purchases
    }
    if (refinement == 'bids') {
      return RefinementType.Offers
    }
    if (refinement == 'listings') {
      return RefinementType.Listings
    }
    if (refinement == 'transfers') {
      return RefinementType.Transfers
    }
    return RefinementType.Feed
    // }
    // return RefinementType.Feed
  }, [idFromURL, pageType, refinementFromURL])

  const gridOffset = useMemo(() => {
    if (pageType == FeedPageType.User) {
      return '200px'
    }
    return '100px'
  }, [pageType])

  const wallets = useSafeWallets(walletsRaw)
  console.log('feedpage debug wallets:', wallets)
  useEffect(() => {
    if (walletsRaw) {
      dispatch(addRequiredWallets(walletsRaw))
    }
  }, [dispatch, walletsRaw])

  const feedID = useMemo(() => {
    // console.log('feedpage debug feedID:', idFromURL)
    if (pageType == FeedPageType.Home) {
      if (wallet) {
        return `user-${wallet?.publicKey.toBase58()}`
      }
    }
    return undefined
  }, [pageType, wallet])

  const userOwnedMints = useMemo(() => {
    if (pageType == FeedPageType.User && refinementType == RefinementType.Owned) {
      return wallets.map((wallet) => wallet.ownedTokens).flat()
    }
    return undefined
  }, [pageType, refinementType, wallets])

  const syncWalletToSlot = useSyncWalletToSlot()

  // const debouncedWallets = useDebounce(wallets, 10000)

  const [latestSlot, setLatestSlot] = useState<number | null>(null)
  console.log('feedpage debug latestSlot:', latestSlot)
  useEffect(() => {
    const getSlot = async () => {
      const slot = await getLatestSlot()
      setLatestSlot(slot)
    }
    getSlot()
  }, [])

  useEffect(() => {
    // const syncToSlots = async () => {
    if (latestSlot) {
      wallets.forEach((wallet) => {
        if (wallet.syncToSlot + 1000 < latestSlot) syncWalletToSlot(wallet.pubkey, latestSlot)
      })
    }
    // }
    // syncToSlots()
  }, [latestSlot, syncWalletToSlot, wallets])

  const actionType = useMemo(() => {
    // if
    return refinementType == RefinementType.Mints
      ? ActionType.mint
      : refinementType == RefinementType.Purchases
      ? ActionType.purchase
      : refinementType == RefinementType.Offers
      ? ActionType.makeOffer
      : refinementType == RefinementType.Listings
      ? ActionType.makeListing
      : refinementType == RefinementType.Transfers
      ? ActionType.transfer
      : undefined
  }, [refinementType])
  const actionQueryRequest = useMemo(() => {
    return new RequiredActionQuery(feedID, actionType, walletsRaw)
  }, [actionType, feedID, walletsRaw])
  console.log('ExplorePage actionQueryRequest', actionQueryRequest)

  useEffect(() => {
    if (actionQueryRequest) {
      dispatch(addRequiredActionQueries([actionQueryRequest]))
    }
  }, [dispatch, actionQueryRequest])

  // useEffect(() => {
  //   dispatch(refreshQuery(actionQueryRequest.key))
  // }, [])

  // const syncedToSlots = useMemo(() => wallets.map((wallet) => wallet.syncedToSlot), [wallets])
  // console.log('feedpage debug syncedToSlots:', syncedToSlots)
  // const actionIDs = useActionQueryActions(actionQueryRequest?.key)

  // useEffect(() => {
  //   if (actionIDs?.length == 0 && actionQueryRequest.key) {
  //     dispatch(refreshQuery(actionQueryRequest.key))
  //   }
  // }, [syncedToSlots, dispatch])

  const baseURL = useMemo(() => {
    if (typeFromURL == 'user') {
      if (idFromURL) {
        return `/${typeFromURL}/${idFromURL}`
      }
      return `/${typeFromURL}`
    }
    if (typeFromURL == 'feed') {
      if (idFromURL) {
        return `/${typeFromURL}/${idFromURL}`
      }
      return `/${typeFromURL}`
    }
    if (typeFromURL == 'recent') {
      return `/${typeFromURL}`
    }
    if (typeFromURL == 'top') {
      return `/${typeFromURL}`
    }
    return '/home'
  }, [idFromURL, typeFromURL])

  console.log('FeedPage typeFromURL', typeFromURL)
  console.log('FeedPage idFromURL', idFromURL)
  console.log('FeedPage refinementType', refinementFromURL)
  console.log('FeedPage pathname', pathname)
  console.log('FeedPage baseURL', baseURL)

  const { actions, loading, loadMore, foundLast } = useFetchFeedResults(
    pageType == FeedPageType.Home ? wallet?.publicKey.toBase58() ?? undefined : undefined,
    actionType,
    pageType == FeedPageType.User ? idFromURL : undefined,
    pageType == FeedPageType.Top
  )

  return (
    <ContentPageWrapper>
      {/* <NiceNav></NiceNav> */}
      {/* {JSON.stringify(actions, null, 2)} */}
      <NiceNav></NiceNav>

      {pageType == FeedPageType.User && <UserHeader userAddress={idFromURL}></UserHeader>}
      {/* <button onClick={() => loadMore()}>Load More</button> */}

      <ButtonsGrid>
        <TypeButton as={StyledInternalLink} to={`${baseURL}`} isActive={pathname == `${baseURL}`}>
          All
        </TypeButton>
        <TypeButton
          as={StyledInternalLink}
          to={`${baseURL}/purchases`}
          isActive={pathname.includes(`${baseURL}/purchases`)}
        >
          Purchases
        </TypeButton>
        <TypeButton as={StyledInternalLink} to={`${baseURL}/mints`} isActive={pathname.includes(`${baseURL}/mints`)}>
          Mints
        </TypeButton>
        <TypeButton
          as={StyledInternalLink}
          to={`${baseURL}/listings`}
          isActive={pathname.includes(`${baseURL}/listings`)}
        >
          Listings
        </TypeButton>
        <TypeButton as={StyledInternalLink} to={`${baseURL}/bids`} isActive={pathname.includes(`${baseURL}/bids`)}>
          Bids
        </TypeButton>
        <TypeButton
          as={StyledInternalLink}
          to={`${baseURL}/transfers`}
          isActive={pathname.includes(`${baseURL}/transfers`)}
        >
          Transfers
        </TypeButton>
        {pageType == FeedPageType.User && (
          <TypeButton as={StyledInternalLink} to={`${baseURL}/owned`} isActive={pathname.includes(`${baseURL}/owned`)}>
            Owned
          </TypeButton>
        )}
        {/* <BottomContentContainer>{bottomContent()}</BottomContentContainer> */}
      </ButtonsGrid>
      <Separator></Separator>

      {/* <TYPE.heavy marginBottom={'20px'}>All Activity</TYPE.heavy> */}
      {/* <TopBar> */}
      {/* <TopBarLeftWrapper> */}
      {/* <ToggleWrapper>
            <ExploreToggleElement
              to={'/explore/mints'}
              isActive={(match, { pathname }) => displayType == ExploreType.NFTs}
            >
              NFTs
            </ExploreToggleElement>
            <ExploreToggleElement
              to={'/explore/collections'}
              isActive={(match, { pathname }) => displayType == ExploreType.Collections}
            >
              Collections
            </ExploreToggleElement>

            <ExploreToggleElement
              to={'/explore/activity'}
              isActive={(match, { pathname }) => displayType == ExploreType.Activity}
            >
              Activity
            </ExploreToggleElement>
          </ToggleWrapper> */}
      {/* <FiltersWrapper>
            <MintsStatusFilter></MintsStatusFilter>
            <MintsCollectionFilter></MintsCollectionFilter>
            <MintsPriceFilter></MintsPriceFilter>
          </FiltersWrapper>
        </TopBarLeftWrapper> */}
      {/* <NiceNav></NiceNav>
      </TopBar> */}
      {/* {JSON.stringify(wallets, null, 2)}
      {JSON.stringify(actionQueryRequest, null, 2)} */}
      {/* {JSON.stringify(wallets)}
      {JSON.stringify(actions)} */}
      {/* {typeFromURL} */}
      <GridWrapper offset={gridOffset} ref={scrollRef}>
        {pageType == FeedPageType.User && refinementType == RefinementType.Owned ? (
          <MintsFeed walletAddress={idFromURL}></MintsFeed>
        ) : (
          <ActionsFeed actions={actions} loadMore={loadMore} loading={loading} foundLast={foundLast}></ActionsFeed>
        )}
      </GridWrapper>
    </ContentPageWrapper>
  )
}
