import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { Collection } from 'api/types'
import { Collection } from './models'

export const fetchFirebaseCollectionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ collectionID: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; collection: Collection }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; collectionID: string }>
}> = {
  pending: createAction('collections/fetchFirebaseCollectionRequest/pending'),
  fulfilled: createAction('collections/fetchFirebaseCollectionRequest/fulfilled'),
  rejected: createAction('collections/fetchFirebaseCollectionRequest/rejected'),
}

export const addRequiredCollections = createAction<string[]>('collections/addRequiredCollections')
export const refreshCollection = createAction<string>('collections/refreshCollection')

export const incrementCollectionSavedByLocal = createAction<{ collectionID: string; userAddress: string }>(
  'collections/incrementCollectionSavedByLocal'
)
export const decrementCollectionSavedByLocal = createAction<{ collectionID: string; userAddress: string }>(
  'collections/decrementCollectionSavedByLocal'
)
