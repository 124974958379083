import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { pubkeyFromString } from 'apollo/utils'
import { ExpiationDuration } from 'components/ExpirationDateSelector'
import { useTokenRegistry } from 'providers/mints/token-registry'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AppState } from 'state'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useSafeMint } from 'state/mints/hooks'
import { MintData, TokenInfo } from 'state/mints/models'
import { ORDER_SIDE, useCreateBuyOrder, useCreateSellOrder } from 'state/orders/chain'
import { useAllChainRequests, useAllFirebaseRequests } from 'state/orders/hooks'
import { RequestStatus } from 'state/orders/reducer'
import { useSafeUser } from 'state/users/hooks'
import { mintIsNative, WRAPPED_SOL_MINT } from 'utils/utils'

import { setExpirationDuration, setPaymentMint, typeAssetSizeInput, typePaymentSizeInput } from './actions'
import { CreateOrderState } from './reducer'

export function useCreateOrderState(): CreateOrderState {
  console.log('aState useRequiredCollections')
  return useAppSelector((state: AppState) => {
    console.log('aState useRequiredSales useAppSelector state: ', state)
    return state.createOrder
  })
}

export function useCreateOrderActionHandlers(): {
  onAssetSizeInput: (typedValue: string) => void
  onPaymentSizeInput: (typedValue: string) => void
  onPaymentMintChange: (token: TokenInfo) => void
  onIncrementAssetSize: () => string
  onDecrementAssetSize: () => string
  onSetExpirationDuration: (selection: ExpiationDuration) => void
  // onLeftRangeInput: (typedValue: string) => void
  // onRightRangeInput: (typedValue: string) => void
  // onStartPriceInput: (typedValue: string) => void
} {
  const dispatch = useAppDispatch()
  const { assetSize } = useCreateOrderState()
  const { assetMintData, userOwnedAssetSize } = useCreateOrderDerivedInfo()

  const onAssetSizeInput = useCallback(
    (typedValue: string) => {
      // (value: string) => {
      //   const floatValue = parseFloat(value)
      //   console.log('floatValue', floatValue)
      //   if (floatValue > userOwnedAssetQuantity) {
      //     setAssetAmount(userOwnedAssetQuantity)
      //   } else if (floatValue < 0) {
      //     setAssetAmount(0)
      //   } else {
      //     const numDecimals = assetMintData?.isNFT ? 0 : assetMintData?.tokenInfo?.decimals ?? 0
      //     const roundedValue = Math.round(floatValue * 10 ** numDecimals) / 10 ** numDecimals
      //     setAssetAmount(roundedValue)
      //   }
      //   setAssetAmount(parseFloat(value))
      // }
      dispatch(typeAssetSizeInput({ typedValue }))
    },
    [dispatch]
  )

  const onPaymentSizeInput = useCallback(
    (typedValue: string) => {
      dispatch(typePaymentSizeInput({ typedValue }))
    },
    [dispatch]
  )

  const onPaymentMintChange = useCallback(
    (token: TokenInfo) => {
      dispatch(setPaymentMint({ paymentMint: token.address }))
    },
    [dispatch]
  )

  const onIncrementAssetSize = useCallback(() => {
    const floatValue = parseFloat(assetSize)
    const decimals = assetMintData?.isNFT ? 0 : assetMintData?.tokenInfo?.decimals ?? 0
    const newValue = Math.round(floatValue * 10 ** decimals) / 10 ** decimals + (decimals === 0 ? 1 : 1 / decimals)
    if (userOwnedAssetSize && userOwnedAssetSize < newValue) {
      return assetSize
    }
    dispatch(typeAssetSizeInput({ typedValue: newValue.toString() }))
    return newValue.toString()
  }, [assetMintData?.isNFT, assetMintData?.tokenInfo?.decimals, assetSize, dispatch, userOwnedAssetSize])

  const onDecrementAssetSize = useCallback(() => {
    const floatValue = parseFloat(assetSize)
    const decimals = assetMintData?.isNFT ? 0 : assetMintData?.tokenInfo?.decimals ?? 0
    const newValue = Math.round(floatValue * 10 ** decimals) / 10 ** decimals - (decimals === 0 ? 1 : 1 / decimals)
    if (newValue <= 0) {
      // dispatch(typeAssetSizeInput({ typedValue: `0` }))
      return assetSize
    }
    dispatch(typeAssetSizeInput({ typedValue: newValue.toString() }))
    return newValue.toString()
  }, [assetMintData?.isNFT, assetMintData?.tokenInfo?.decimals, assetSize, dispatch])

  const onSetExpirationDuration = useCallback(
    (selection: ExpiationDuration) => {
      // const isValid = date.getTime() > Date.now()
      dispatch(setExpirationDuration({ expirationDuration: selection }))
    },
    [dispatch]
  )

  return {
    onAssetSizeInput,
    onPaymentSizeInput,
    onPaymentMintChange,
    onIncrementAssetSize,
    onDecrementAssetSize,
    onSetExpirationDuration,
  }
}

export function useCreateOrderDerivedInfo(): {
  // currencyA?: Currency,
  // currencyB?: Currency,
  // feeAmount?: FeeAmount,
  // baseCurrency?: Currency,
  // override for existing position
  // existingPosition?: Position
  // pool?: Pool | null
  // poolState: PoolState
  // ticks: { [bound in Bound]?: number | undefined }
  // price?: Price<Token, Token>
  // pricesAtTicks: {
  //   [bound in Bound]?: Price<Token, Token> | undefined
  // }
  // currencies: { [field in Field]?: Currency }
  // currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  // dependentField: Field
  // parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  // position: Position | undefined
  // noLiquidity?: boolean
  // errorMessage?: ReactNode
  // invalidPool: boolean
  // outOfRange: boolean
  // invalidRange: boolean
  // depositADisabled: boolean
  // depositBDisabled: boolean
  // invertPrice: boolean
  // ticksAtLimit: { [bound in Bound]?: boolean | undefined }
  assetMintData?: MintData
  // paymentMintData?: MintData
  userOwnedAssetSize?: number
  userOwnedPaymentSize?: number
  paymentCurrency: TokenInfo | null
  submitDisabled: boolean
  paymentSizeError: string | null
  assetSizeError: string | null
  assetSizeNumber: number | null
  paymentSizeNumber: number | null
} {
  const wallet = useWallet()
  const userPubkey = wallet?.publicKey
  const user = useSafeUser(userPubkey?.toBase58() ?? '')

  const { assetMint, assetSize, paymentMint, paymentSize, side } = useCreateOrderState()

  const assetMintData = useSafeMint(assetMint)
  // const paymentMintData = useSafeMint(paymentMint)

  const { tokenRegistry } = useTokenRegistry()

  const userOwnedAsset = useMemo(() => {
    if (!user) return null
    return user.ownedMints.find((a) => a.address === assetMint) ?? null
  }, [user, assetMint])

  const userOwnedAssetSize = useMemo(() => {
    if (!userOwnedAsset) return 0
    try {
      return parseFloat(userOwnedAsset.amount)
    } catch (e) {
      return undefined
    }
  }, [userOwnedAsset])

  const userOwnedPayment = useMemo(() => {
    if (!user) return null
    return user.ownedMints.find((a) => a.address === paymentMint) ?? null
  }, [user, paymentMint])

  const userOwnedPaymentSize = useMemo(() => {
    if (user && mintIsNative(paymentMint)) {
      return user.lamports
    }
    if (!userOwnedPayment) return 0
    try {
      return parseFloat(userOwnedPayment.amount)
    } catch (e) {
      return undefined
    }
  }, [paymentMint, user, userOwnedPayment])

  const [paymentMintError, setPaymentMintError] = useState<string | null>(null)

  const [assetMintError, setAssetMintError] = useState<string | null>(null)
  // const [assetSizeError, setAssetSizeError] = useState<string | null>(null)

  const paymentCurrency = useMemo(() => {
    const pubkeyed = pubkeyFromString(paymentMint)
    const r = pubkeyed ? tokenRegistry.get(pubkeyed.toBase58()) : tokenRegistry.get(WRAPPED_SOL_MINT.toBase58())
    return r ?? null
    // if (!paymentMintData) return safeWSol
    // if (!paymentMintData.tokenInfo) {
    //   setPaymentMintError('Invalid token mint')
    //   return safeWSol
    // }
    // return paymentMintData.tokenInfo
  }, [paymentMint, tokenRegistry])

  const paymentSizeNumber = useMemo(() => {
    try {
      if (!paymentCurrency?.decimals) {
        throw new Error('Invalid token')
      }
      const floatValue = parseFloat(paymentSize)
      const value = mintIsNative(paymentMint) ? floatValue * LAMPORTS_PER_SOL : floatValue
      console.log('paymentSizeNumber paymentMint', paymentMint)
      console.log('paymentSizeNumber value', value)
      console.log('paymentSizeNumber mintIsNative(paymentMint)', mintIsNative(paymentMint))
      const decimals = paymentCurrency?.decimals
      const roundedValue = Math.round(value * 10 ** decimals) / 10 ** decimals
      return roundedValue
    } catch (e) {
      return null
    }
  }, [paymentCurrency?.decimals, paymentMint, paymentSize])

  const paymentSizeError = useMemo(() => {
    if (!paymentSizeNumber) return 'Invalid price'
    if (paymentSizeNumber <= 0) return 'Must be greater than 0'
    if (side == ORDER_SIDE.BUY && userOwnedPaymentSize && paymentSizeNumber > userOwnedPaymentSize) {
      const isWrapped = paymentMint
      return `Insufficent funds: Have ${
        isWrapped ? userOwnedPaymentSize / LAMPORTS_PER_SOL : userOwnedPayment
      } but need ${isWrapped ? paymentSizeNumber / LAMPORTS_PER_SOL : paymentSizeNumber}`
    }
    return null
  }, [paymentMint, paymentSizeNumber, userOwnedPayment, userOwnedPaymentSize])

  const assetSizeNumber = useMemo(() => {
    try {
      const floatValue = parseFloat(assetSize)
      const decimals = assetMintData?.isNFT ? 0 : assetMintData?.tokenInfo?.decimals ?? 0
      const roundedValue = Math.round(floatValue * 10 ** decimals) / 10 ** decimals
      return roundedValue
    } catch (e) {
      return null
    }
  }, [assetMintData?.isNFT, assetMintData?.tokenInfo?.decimals, assetSize])

  const assetSizeError = useMemo(() => {
    if (!assetSizeNumber) return 'Invalid asset size'
    if (assetSizeNumber <= 0) return 'Must be greater than 0'
    console.log('assetSizeError userOwnedAssetSize', userOwnedAssetSize)
    console.log('assetSizeError assetSizeNumber', assetSizeNumber)

    if (side == ORDER_SIDE.SELL) {
      if (!userOwnedAssetSize) return 'Unable to validate ownership'
      if (assetSizeNumber > userOwnedAssetSize) return 'Must be greater than or equal to your owned asset size'
    }
    return null
  }, [assetSizeNumber, side, userOwnedAssetSize])

  const submitDisabled = useMemo(() => {
    return paymentMintError || paymentSizeError || assetMintError || assetSizeError ? true : false
  }, [paymentMintError, paymentSizeError, assetMintError, assetSizeError])

  return {
    // paymentMintData,
    assetMintData,
    userOwnedPaymentSize,
    userOwnedAssetSize,
    paymentCurrency,
    submitDisabled,
    paymentSizeError,
    assetSizeError,
    assetSizeNumber,
    paymentSizeNumber,
  }
}
export function useCreateOrderSubmit(): {
  // currencyA?: Currency,
  // currencyB?: Currency,
  // feeAmount?: FeeAmount,
  // baseCurrency?: Currency,
  // override for existing position
  // existingPosition?: Position
  // pool?: Pool | null
  // poolState: PoolState
  // ticks: { [bound in Bound]?: number | undefined }
  // price?: Price<Token, Token>
  // pricesAtTicks: {
  //   [bound in Bound]?: Price<Token, Token> | undefined
  // }
  // currencies: { [field in Field]?: Currency }
  // currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  // dependentField: Field
  // parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  // position: Position | undefined
  // noLiquidity?: boolean
  // errorMessage?: ReactNode
  // invalidPool: boolean
  // outOfRange: boolean
  // invalidRange: boolean
  // depositADisabled: boolean
  // depositBDisabled: boolean
  // invertPrice: boolean
  // ticksAtLimit: { [bound in Bound]?: boolean | undefined }
  submitError?: string | null
  handleSubmit: () => void
  chainSubmitError: string | null
  firebaseSubmitError: string | null
  chainSubmitRes: string | null
  firebaseOrderPDA: string | null
  submitting: boolean
} {
  const wallet = useWallet()
  const userPubkey = wallet?.publicKey

  const { side, expirationDuration } = useCreateOrderState()
  const { assetMintData, paymentCurrency, assetSizeNumber, paymentSizeNumber } = useCreateOrderDerivedInfo()

  const createSellOrder = useCreateSellOrder()
  const createBuyOrder = useCreateBuyOrder()
  const allChainRequests = useAllChainRequests()
  const allFirebaseRequests = useAllFirebaseRequests()

  const [submitError, setSubmitError] = useState<string | null>(null)
  const [requestID, setRequestID] = useState<string | null>(null)

  useEffect(() => {
    setRequestID(null)
  }, [side, expirationDuration, assetMintData, paymentCurrency, assetSizeNumber, paymentSizeNumber])

  const chainSubmitError = useMemo(() => {
    if (!requestID) return null
    const request = allChainRequests.find((r) => r.requestID === requestID && r.status === RequestStatus.Rejected)
    if (!request) return null
    return request.errorMessage ?? null
  }, [requestID, allChainRequests])

  const firebaseSubmitError = useMemo(() => {
    if (!requestID) return null
    const request = allFirebaseRequests.find((r) => r.requestID === requestID && r.status === RequestStatus.Rejected)
    if (!request) return null
    return request.errorMessage ?? null
  }, [requestID, allFirebaseRequests])

  const chainSubmitRes = useMemo(() => {
    if (!requestID) return null
    const request = allChainRequests.find((r) => r.requestID === requestID && r.status === RequestStatus.Fulfilled)
    if (!request) return null
    return request.txRes ?? null
  }, [requestID, allChainRequests])

  const firebaseOrderPDA = useMemo(() => {
    if (!requestID) return null
    const request = allFirebaseRequests.find((r) => r.requestID === requestID && r.status === RequestStatus.Fulfilled)
    if (!request) return null
    return request.context ?? null
  }, [requestID, allFirebaseRequests])

  // const success = useMemo(() => {
  //   return (chainSubmitRes && firebaseOrderPDA) ?? false
  // }, [chainSubmitRes, firebaseOrderPDA])

  const submitting = useMemo(() => {
    return requestID !== null
  }, [requestID])

  const handleSubmit = useCallback(() => {
    const run = async () => {
      try {
        if (side == ORDER_SIDE.SELL) {
          if (!userPubkey) {
            throw new Error('No user pubkey')
          }
          if (!assetMintData) {
            throw new Error('No asset mint data')
          }
          const seller = userPubkey.toBase58()
          const assetMint = assetMintData.address
          const assetDecimals = assetMintData.isNFT ? 0 : assetMintData.tokenInfo?.decimals ?? undefined
          if (assetDecimals == undefined) {
            throw new Error('No asset decimals')
          }
          if (!assetSizeNumber) {
            throw new Error('No asset size')
          }
          const assetSize = assetSizeNumber
          if (!paymentCurrency) {
            throw new Error('No payment currency')
          }
          const paymentMint = paymentCurrency.address
          if (!paymentSizeNumber) {
            throw new Error('No payment size')
          }
          const paymentBaseSize = paymentSizeNumber
          const listingDate = Date.now()
          const expirationDate = Date.now() + expirationDuration * 60 * 60 * 1000
          // const requestPayload = {
          //   seller,
          //   assetMint,
          //   assetDecimals,
          //   assetSize,
          //   paymentMint,
          //   paymentBaseSize,
          //   listingDate,
          //   expirationDate,
          // }
          // console.log('requestPayload', requestPayload)
          const requestID = await createSellOrder(
            seller,
            assetMint,
            assetDecimals,
            assetSize,
            paymentMint,
            paymentBaseSize,
            listingDate,
            expirationDate
          )
          setRequestID(requestID)
        } else {
          if (!userPubkey) {
            throw new Error('No user pubkey')
          }
          if (!assetMintData) {
            throw new Error('No asset mint data')
          }
          const buyer = userPubkey.toBase58()
          const assetMint = assetMintData.address
          const assetDecimals = assetMintData.isNFT ? 0 : assetMintData.tokenInfo?.decimals ?? undefined
          if (assetDecimals == undefined) {
            throw new Error('No asset decimals')
          }
          if (!assetSizeNumber) {
            throw new Error('No asset size')
          }
          const assetSize = assetSizeNumber
          if (!paymentCurrency) {
            throw new Error('No payment currency')
          }
          const paymentMint = paymentCurrency.address
          if (!paymentSizeNumber) {
            throw new Error('No payment size')
          }
          const paymentBaseSize = paymentSizeNumber
          const listingDate = Date.now()
          const expirationDate = Date.now() + expirationDuration * 60 * 60 * 1000
          // const requestPayload = {
          //   seller,
          //   assetMint,
          //   assetDecimals,
          //   assetSize,
          //   paymentMint,
          //   paymentBaseSize,
          //   listingDate,
          //   expirationDate,
          // }
          // console.log('requestPayload', requestPayload)
          const requestID = await createBuyOrder(
            buyer,
            assetMint,
            assetDecimals,
            assetSize,
            paymentMint,
            paymentBaseSize,
            listingDate,
            expirationDate
          )
          setRequestID(requestID)
        }
      } catch (e) {
        console.error(e)
        setSubmitError(e.message)
        setRequestID(null)
      }
    }
    run()
  }, [
    assetMintData,
    assetSizeNumber,
    createBuyOrder,
    createSellOrder,
    expirationDuration,
    paymentCurrency,
    paymentSizeNumber,
    side,
    userPubkey,
  ])

  return {
    handleSubmit,
    submitError,
    chainSubmitError,
    firebaseSubmitError,
    chainSubmitRes,
    firebaseOrderPDA,
    submitting,
  }
}

// export function useRangeHopCallbacks(
//   baseCurrency: Currency | undefined,
//   quoteCurrency: Currency | undefined,
//   feeAmount: FeeAmount | undefined,
//   tickLower: number | undefined,
//   tickUpper: number | undefined,
//   pool?: Pool | undefined | null
// ) {
//   const dispatch = useAppDispatch()

//   const baseToken = useMemo(() => baseCurrency?.wrapped, [baseCurrency])
//   const quoteToken = useMemo(() => quoteCurrency?.wrapped, [quoteCurrency])

//   const getDecrementLower = useCallback(() => {
//     if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
//       const newPrice = tickToPrice(baseToken, quoteToken, tickLower - TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     // use pool current tick as starting tick if we have pool but no tick input
//     if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
//       const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     return ''
//   }, [baseToken, quoteToken, tickLower, feeAmount, pool])

//   const getIncrementLower = useCallback(() => {
//     if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
//       const newPrice = tickToPrice(baseToken, quoteToken, tickLower + TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     // use pool current tick as starting tick if we have pool but no tick input
//     if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
//       const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     return ''
//   }, [baseToken, quoteToken, tickLower, feeAmount, pool])

//   const getDecrementUpper = useCallback(() => {
//     if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
//       const newPrice = tickToPrice(baseToken, quoteToken, tickUpper - TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     // use pool current tick as starting tick if we have pool but no tick input
//     if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
//       const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     return ''
//   }, [baseToken, quoteToken, tickUpper, feeAmount, pool])

//   const getIncrementUpper = useCallback(() => {
//     if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
//       const newPrice = tickToPrice(baseToken, quoteToken, tickUpper + TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     // use pool current tick as starting tick if we have pool but no tick input
//     if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
//       const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount])
//       return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
//     }
//     return ''
//   }, [baseToken, quoteToken, tickUpper, feeAmount, pool])

//   const getSetFullRange = useCallback(() => {
//     dispatch(setFullRange())
//   }, [dispatch])

//   return { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange }
// }
