import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { Wallet } from 'api/types'
import { Wallet } from './models'

export const updateFirebaseWalletRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ walletAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; wallet: Wallet }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; walletAddress: string; requestID: string }>
}> = {
  pending: createAction('wallets/createWalletRequest/pending'),
  fulfilled: createAction('wallets/createWalletRequest/fulfilled'),
  rejected: createAction('wallets/createWalletRequest/rejected'),
}

export const createFirebaseWalletRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ walletAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; wallet: Wallet }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; walletAddress: string; requestID: string }>
}> = {
  pending: createAction('wallets/createWalletRequest/pending'),
  fulfilled: createAction('wallets/createWalletRequest/fulfilled'),
  rejected: createAction('wallets/createWalletRequest/rejected'),
}

export const syncWalletToSlot: Readonly<{
  pending: ActionCreatorWithPayload<{ walletAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; walletAddress: string; syncToSlot: number }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; walletAddress: string; requestID: string }>
}> = {
  pending: createAction('wallets/syncWalletToSlot/pending'),
  fulfilled: createAction('wallets/syncWalletToSlot/fulfilled'),
  rejected: createAction('wallets/syncWalletToSlot/rejected'),
}

export const fetchFirebaseWalletRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ walletAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; wallet: Wallet }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; walletAddress: string }>
}> = {
  pending: createAction('wallets/fetchFirebaseWalletRequest/pending'),
  fulfilled: createAction('wallets/fetchFirebaseWalletRequest/fulfilled'),
  rejected: createAction('wallets/fetchFirebaseWalletRequest/rejected'),
}
export const fetchChainWalletRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ walletAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; wallet: Wallet }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('wallets/fetchChainWalletRequest/pending'),
  fulfilled: createAction('wallets/fetchChainWalletRequest/fulfilled'),
  rejected: createAction('wallets/fetchChainWalletRequest/rejected'),
}

export const addRequiredWallets = createAction<string[]>('wallets/addRequiredWallets')
export const editSavedMint =
  createAction<{ walletAddress: string; mintAddress: string; saved: boolean }>('wallets/addSavedMint')
export const refreshWallets = createAction<string[]>('wallets/refreshWallets')
// export const  = createAction<string>('lists/disableList')

export const appendWallets = createAction<Wallet[]>('wallets/appendWallets')
