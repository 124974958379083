import { nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { doc, getDoc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore'
import Vibrant from 'node-vibrant/lib/bundle'
import { shade } from 'polished'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'

import { fetchChainMintRequest, fetchFirebaseMintRequest, updateFirebaseMintRequest } from './actions'
import { MintData } from './models'

export const MINTS_COLLECTION = 'mints4'

export function useFetchFirebaseMintData(): (mintAddress: string) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (mintAddress: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseMintRequest.pending({ mintAddress, requestID }))
      try {
        const db = getFirestore()
        const unsubscribe = onSnapshot(doc(db, MINTS_COLLECTION, mintAddress), (mintSnap) => {
          // console.log('firebase wallet data:', mintSnap.data())
          if (mintSnap.data()) {
            const mintData = decodeFirebaseMintData(mintSnap.data())
            // console.log('firebase dbagg wallet decoded (useFetchFirebaseWallet): ', wallet)
            dispatch(fetchFirebaseMintRequest.fulfilled({ requestID, mintData }))
          }
          // unsubscribe()
        })
        // const mintSnap = await getDoc(doc(db, MINTS_COLLECTION, mintAddress))
        // console.log(`firebase mintSnap for: ${mintAddress}, ${mintSnap}`)
        // if (mintSnap.exists()) {
        //   console.log('firebase Document data:', mintSnap.data())
        //   const mintData = decodeFirebaseMintData(mintSnap.data())
        //   console.log('firebase mintData decoded: ', mintData)
        //   dispatch(fetchFirebaseMintRequest.fulfilled({ requestID, mintData }))
        //   return mintData
        // } else {
        //   console.log('firebase Mint not found. Creating.')
        //   return undefined
        // }
        // return mint
      } catch (e) {
        console.error(e)
        dispatch(fetchChainMintRequest.rejected({ requestID, errorMessage: e }))
        throw e
      }
    },
    [dispatch]
  )
}

// export interface MetadataCombined {
//   key: MetadataKey
//   updateAuthority: StringPublicKey
//   mint: StringPublicKey
//   data: Data
//   primaryMintHappened: boolean
//   isMutable: boolean
//   editionNonce: number | undefined
//   extended?: IMetadataExtension
// }

// export interface IMetadataExtension {
//   name: string
//   symbol: string

//   creators: Creator[] | undefined
//   description: string
//   // preview image absolute URI
//   image: string
//   animation_url?: string

//   // stores link to item on meta
//   external_url: string

//   seller_fee_basis_points: number

//   properties: {
//     files?: FileOrString[]
//     category: MetadataCategory
//     maxSupply?: number
//     creators?: {
//       address: string
//       shares: number
//     }[]
//   }

//   attributes?: IMetadataAttribute[] | undefined
//   collection?: IMetadataCollection | undefined
// }

// export class Creator {
//   address: StringPublicKey
//   verified: boolean
//   share: number

//   constructor(args: { address: StringPublicKey; verified: boolean; share: number }) {
//     this.address = args.address
//     this.verified = args.verified
//     this.share = args.share
//   }
// }

// export class Data {
//   name: string
//   symbol: string
//   uri: string
//   sellerFeeBasisPoints: number
//   creators: Creator[] | undefined
//   constructor(args: {
//     name: string
//     symbol: string
//     uri: string
//     sellerFeeBasisPoints: number
//     creators: Creator[] | undefined
//   }) {
//     this.name = args.name
//     this.symbol = args.symbol
//     this.uri = args.uri
//     this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
//     this.creators = args.creators
//   }
// }

// export interface IMetadataCollection {
//   name: string
//   family: string
// }

// export interface IMetadataAttribute {
//   trait_type: string
//   value: string
// }
//
const encodeMintObject = (mintData: MintData) => {
  return mintData
  console.log('encodeMintObject: ', mintData)
  // const mintObj = {
  //   // ...mint,
  //   pubkey: mintData.pubkey.toBase58(),
  //   mint: encodeMint(mintData.mint),
  //   metadata: mintData.metadata
  //     ? {
  //         key: mintData.metadata.key,
  //         updateAuthority: mintData.metadata.updateAuthority.toBase58(),
  //         mint: mintData.metadata.mint.toBase58(),
  //         data: mintData.metadata.data
  //           ? {
  //               name: mintData.metadata.data?.name,
  //               symbol: mintData.metadata.data.symbol,
  //               uri: mintData.metadata.data.uri,
  //               sellerFeeBasisPoints: mintData.metadata.data.sellerFeeBasisPoints,
  //               creators: mintData.metadata.data.creators
  //                 ? mintData.metadata.data.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //             }
  //           : {},
  //         primaryMintHappened: mintData.metadata.primaryMintHappened,
  //         isMutable: mintData.metadata.isMutable,
  //         editionNonce: mintData.metadata.editionNonce ?? '',
  //         extended: mintData.metadata.extended
  //           ? {
  //               name: mintData.metadata.extended.name,
  //               symbol: mintData.metadata.extended.symbol,
  //               creators: mintData.metadata.extended.creators
  //                 ? mintData.metadata.extended.creators.map((creator) => ({
  //                     address: creator.address.toBase58(),
  //                     verified: creator.verified,
  //                     share: creator.share,
  //                   }))
  //                 : [],
  //               description: mintData.metadata.extended.description,
  //               image: mintData.metadata.extended.image,
  //               animation_url: mintData.metadata.extended.animation_url ?? '',
  //               external_url: mintData.metadata.extended.external_url,
  //               seller_fee_basis_points: mintData.metadata.extended.seller_fee_basis_points,
  //               properties: {
  //                 files: mintData.metadata.extended.properties.files
  //                   ? mintData.metadata.extended.properties.files.map((file) => {
  //                       return {
  //                         file: file.valueOf(),
  //                         content_type: file.type ?? '',
  //                       }
  //                     })
  //                   : [],
  //                 category: mintData.metadata.extended.properties.category,
  //                 maxSupply: mintData.metadata.extended.properties.maxSupply ?? '',
  //               },
  //               attributes: mintData.metadata.extended.attributes
  //                 ? mintData.metadata.extended.attributes.map((attribute) => ({
  //                     trait_type: attribute.trait_type,
  //                     value: attribute.value,
  //                   }))
  //                 : [],
  //               collection: mintData.metadata.extended.collection
  //                 ? {
  //                     name: mintData.metadata.extended.collection.name,
  //                     family: mintData.metadata.extended.collection.family,
  //                   }
  //                 : {},
  //             }
  //           : {},
  //       }
  //     : {},
  //   asks: mintData.asks ? mintData.asks.map((ask) => encodeAskV2(ask)) : [],
  //   bids: mintData.bids ? mintData.bids.map((bid) => encodeBidV2(bid)) : [],
  //   owner: mintData.owner?.toBase58() ?? '',
  // }

  // console.log('encodeMintObject: ', mintObj)

  // return mintObj
}

export const decodeFirebaseMintData = (res: any) => {
  console.log('decodeFirebaseMintData: ', res)
  const md2 = create({ ...res }, MintData)
  return md2
}

export function useUpdateFirebaseMint(): (mintData: MintData) => Promise<void> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (mintData: MintData) => {
      const requestID = nanoid()
      dispatch(updateFirebaseMintRequest.pending({ mintAddress: mintData.address, requestID }))
      if (mintData.savedByUsers || mintData.collections) {
        throw new Error('Cannot update mint with savedByUsers or collections')
      }
      try {
        const db = getFirestore()
        // let color = null
        console.log('useUpdateFirebaseMint mintData: ', mintData)
        const mintObj = encodeMintObject({ ...mintData })
        console.log('useUpdateFirebaseMint mintObj: ', mintObj)
        console.log('firebase uploading mint: ', mintObj)
        setDoc(doc(db, MINTS_COLLECTION, mintData.address), mintObj).then(() => {
          console.log('firebase mint uploaded')
          dispatch(updateFirebaseMintRequest.fulfilled({ requestID, mintData, preserveOffChain: true }))
        })
      } catch (e) {
        console.error(e)
        dispatch(updateFirebaseMintRequest.rejected({ mintAddress: mintData.address, requestID, errorMessage: e }))
        // throw e
      }
    },
    [dispatch]
  )
}

export async function getColorFromUriPath(uri?: string): Promise<string | null> {
  if (!uri) {
    return null
  }
  const formattedPath = uriToHttp(uri)[0]

  const palette = await Vibrant.from(formattedPath).getPalette()
  if (!palette?.Vibrant) {
    return null
  }

  let detectedHex = palette.Vibrant.hex
  let AAscore = hex(detectedHex, '#FFF')
  while (AAscore < 3) {
    detectedHex = shade(0.005, detectedHex)
    AAscore = hex(detectedHex, '#FFF')
  }

  return detectedHex
}
