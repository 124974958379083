import { Idl, Program, Provider, TransactionFn } from '@project-serum/anchor'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { AnchorWallet, ConnectionProvider, useAnchorWallet, WalletProvider } from '@solana/wallet-adapter-react'
// import { WalletDisconnectButton, WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets'
import { clusterApiUrl, Connection, Keypair, PublicKey, Transaction } from '@solana/web3.js'
import APOLLO_IDL from 'apollo/v4.json'
import DeleteCollectionModal from 'components/CollectionModals/DeleteCollectionModal'
import EditCollectionModal from 'components/CollectionModals/EditCollectionModal'
import CreateOrderModal from 'components/CreateOrderModal'
import ErrorModal from 'components/ErrorModal'
import NFTInfoModal from 'components/NFTInfoModal'
import NFTInfoModalTwo from 'components/NFTInfoModalTwo'
import MaxFollowingModal from 'components/WarningModals/MaxFollowingModal'
import { apolloProgramAddress, providerURL } from 'hooks/web3'
import { AccountsProvider } from 'providers/accounts'
import { MintsProvider } from 'providers/mints'
// import { SupplyProvider } from 'providers/supply'
import { useMemo } from 'react'

// Default styles that can be overridden by your app
// require('@solana/wallet-adapter-react-ui/styles.css')
const connection = new Connection(providerURL(), 'processed')

export const useAnchorProvider = () => {
  // if (!requireWallet) {
  //   return new Provider(
  //     // @ts-ignore
  //     connection,
  //     undefined,
  //     { preflightCommitment: 'processed' }
  //   )
  // }
  // const network =
  // const apolloProgramAddressStr = apolloProgramAddress()
  const wallet = useAnchorWallet()
  console.log('wallet', wallet)

  // const programID = translateAddress(apolloProgramAddressStr)
  const provider = useMemo(() => {
    return wallet
      ? new Provider(connection, wallet, { preflightCommitment: 'processed' })
      : new Provider(connection, DummyWallet.local(), { preflightCommitment: 'processed' })
  }, [wallet])
  return provider
}

export class DummyWallet implements AnchorWallet {
  constructor(readonly payer: Keypair) {}

  static local(): DummyWallet {
    const REACT_APP_DUMMY_PRIVATE_KEY: string = process.env.REACT_APP_DUMMY_PRIVATE_KEY as string
    // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
    const privateKey = new Uint8Array(JSON.parse(REACT_APP_DUMMY_PRIVATE_KEY))
    const keypair = Keypair.fromSecretKey(privateKey)
    const payer = keypair
    return new DummyWallet(payer)
  }

  async signTransaction(tx: Transaction): Promise<Transaction> {
    tx.partialSign(this.payer)
    return tx
  }

  async signAllTransactions(txs: Transaction[]): Promise<Transaction[]> {
    return txs.map((t) => {
      t.partialSign(this.payer)
      return t
    })
  }

  get publicKey(): PublicKey {
    return this.payer.publicKey
  }
}

export const useApolloProgram = () => {
  const provider = useAnchorProvider()
  const apolloProgramAddr = apolloProgramAddress()
  console.log('apolloProgramAddr', apolloProgramAddr)
  console.log('provider:', provider)

  // return new Program(SAXIDL as Idl, SAXProgramID, provider);
  return provider
    ? new Program(APOLLO_IDL as Idl, apolloProgramAddr, provider)
    : new Program(APOLLO_IDL as Idl, apolloProgramAddr)
}

export default function SolanaManager({ children }: { children: JSX.Element }) {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      // getTorusWallet({
      //   options: { clientId: 'Get a client ID @ https://developer.tor.us' },
      // }),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  )

  return (
    // <SupplyProvider>
    //   <RichListProvider>
    <AccountsProvider>
      {/* <BlockProvider> */}
      {/* <EpochProvider> */}
      {/* <Anchor */}
      <MintsProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            {/* <NFTInfoModal></NFTInfoModal> */}
            <NFTInfoModalTwo></NFTInfoModalTwo>
            <ErrorModal></ErrorModal>
            <CreateOrderModal></CreateOrderModal>
            <MaxFollowingModal></MaxFollowingModal>
            {/* <EditCollectionModal></EditCollectionModal>
            <DeleteCollectionModal></DeleteCollectionModal> */}
            {/* <WalletModalProvider>
          <WalletMultiButton />
          
          <WalletDisconnectButton />
        </WalletModalProvider> */}
            {children}
          </WalletProvider>
        </ConnectionProvider>
      </MintsProvider>
      {/* </EpochProvider> 
           </BlockProvider>*/}
    </AccountsProvider>
    //   </RichListProvider>
    // </SupplyProvider>
  )
}
