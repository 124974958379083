import { Wrapper } from 'components/swap/styleds'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

import Logo from '../Logo'

export const StyledLogo = styled(Logo)<{ clickable?: boolean; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.2) 0px 10px 30px;' : 'none')};
  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'none')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
  transition: ${({ clickable }) => (clickable ? ' all 0.2s ease-in-out' : 'none')};
`

const WrapperButton = styled(Wrapper)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;

  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
`

const LogoWrapper = styled.div<{ offset: boolean }>`
  margin-left: ${({ offset }) => (offset ? '-12px;' : '0px')};
`

// const stringHash = require('string-hash')
// const hslTriad = require('hsl-triad')
// const hslRgb = require('hsl-rgb')

export default function UserListPreview({
  walletAddresses,
  onClick,
  size = '22px',
}: {
  walletAddresses?: string[]
  onClick?: () => void
  size?: string
}) {
  const theme = useTheme()

  const firstFiveWallets = walletAddresses?.slice(0, 5)
  const extraCount = walletAddresses && firstFiveWallets ? walletAddresses?.length - firstFiveWallets?.length : 0
  if (walletAddresses && walletAddresses.length > 0) {
    return firstFiveWallets ? (
      <WrapperButton onClick={onClick}>
        {firstFiveWallets.map((address, index) => (
          <LogoWrapper key={index} offset={index != 0}>
            <UserLogo userAddress={address} size={size} key={index} navToProfile={false} />
          </LogoWrapper>
        ))}
        {extraCount > 0 && (
          <TYPE.main size={size} color={theme.text2}>
            +{walletAddresses.length - firstFiveWallets.length}
          </TYPE.main>
        )}
      </WrapperButton>
    ) : (
      <TYPE.main size={size}></TYPE.main>
    )
  } else {
    return <div style={{ height: size }}></div>
  }
}

{
  /* <Image
alt={name}
src={`data:image/svg+xml;base64,${btoa(gradientAvatar(uid))}`}
{...sizeProps}
{...props}
/> */
}
