import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { useActiveWeb3React } from '../../hooks/web3'
import { AppState } from '../index'
import {
  addPopup,
  ApplicationModal,
  CreateOrderModalConfig,
  EditCollectionModalConfig,
  ErrorModalData,
  PopupContent,
  removePopup,
  setCreateOrderModalConfig,
  setErrorModalData,
  setGlobalColor,
  setNFTModalMintAddress,
  setOpenModal,
} from './reducer'

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useAppSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useModalOpen(modal: ApplicationModal | string): boolean {
  const openModal = useAppSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal | string): () => void {
  const open = useModalOpen(modal)
  console.log('mouse useToggleModal. open:', open)
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useSetNFTModalMintAddress(): (mintAddress: string | null) => void {
  const dispatch = useAppDispatch()
  return useCallback((mintAddress: string | null) => dispatch(setNFTModalMintAddress(mintAddress)), [dispatch])
}

export function useSetCreateOrderModalConfig(): (config: CreateOrderModalConfig) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (config: CreateOrderModalConfig) => {
      dispatch(setCreateOrderModalConfig({ config })), [dispatch]
    },
    [dispatch]
  )
}

export function useCreateOrderModalConfig(): CreateOrderModalConfig {
  const config = useAppSelector((state: AppState) => state.application.createOrderModalConfig)
  return config
}

export function useEditCollectionModalConfig(): EditCollectionModalConfig | null {
  const config = useAppSelector((state: AppState) => state.application.editCollectionModalConfig)
  return config
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useNFTModalToggle(): () => void {
  return useToggleModal(ApplicationModal.NFT_INFO)
}
export function useErrorModalToggle(): () => void {
  return useToggleModal(ApplicationModal.ERROR)
}

export function useCreateOrderModalToggle(): () => void {
  return useToggleModal(ApplicationModal.CREATE_ORDER)
}

export function useNFTModalMintAddress(): string | null {
  const nftModalMintAddress = useAppSelector((state: AppState) => state.application.nftModalMintAddress)
  return nftModalMintAddress
}

export function useErrorModalData(): ErrorModalData | null {
  const errorModalData = useAppSelector((state: AppState) => state.application.errorModalData)
  return errorModalData
}

export function useSetErrorModalData(errorData: ErrorModalData): void {
  const dispatch = useAppDispatch()
  dispatch(setErrorModalData(errorData))
}

export function useGlobalColor(): string {
  const globalColor = useAppSelector((state: AppState) => state.application.globalColor)
  return globalColor
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS)
}

export function useShowClaimPopup(): boolean {
  return useModalOpen(ApplicationModal.CLAIM_POPUP)
}

export function useToggleShowClaimPopup(): () => void {
  return useToggleModal(ApplicationModal.CLAIM_POPUP)
}

export function useToggleSelfClaimModal(): () => void {
  return useToggleModal(ApplicationModal.SELF_CLAIM)
}

export function useToggleDelegateModal(): () => void {
  return useToggleModal(ApplicationModal.DELEGATE)
}

export function useToggleVoteModal(): () => void {
  return useToggleModal(ApplicationModal.VOTE)
}

export function useTogglePrivacyPolicy(): () => void {
  return useToggleModal(ApplicationModal.PRIVACY_POLICY)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string, removeAfterMs?: number) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (content: PopupContent, key?: string, removeAfterMs?: number) => {
      dispatch(addPopup({ content, key, removeAfterMs: removeAfterMs ?? DEFAULT_TXN_DISMISS_MS }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useAppSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}
