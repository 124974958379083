import { Connection, PublicKey } from '@solana/web3.js'
import algoliasearch from 'algoliasearch'
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appendFirebaseMintIfEmpty, appendMints } from 'state/mints/actions'
import { decodeFirebaseMintData, MINTS_COLLECTION } from 'state/mints/firebase'
import { useAllFirebaseMints } from 'state/mints/hooks'
import { appendWallets } from 'state/wallets/actions'
import { useAllFirebaseWallets } from 'state/wallets/hooks'
import { decodeFirebaseWallet } from 'state/wallets/models'

import useDebounce from './useDebounce'
import { getDomainInfo, useUserDomains } from './useUserDomains'
import { providerURL } from './web3'

export const AlgoliaSearchClient = algoliasearch('TRZH8CI75S', 'e1b5bdfec3ab52f862250491d5da9408')

type SearchResultPubkeyData = {
  pubkey: string
  dotSolNames: string[]
}
const connection = new Connection(providerURL(), 'confirmed')

export function useFetchSearchResults(value: string): {
  wallets: string[]
  mints: string[]
  calcedPubkey: string | undefined
  loading: boolean
} {
  // AlgoliaSearchClient.search
  // const allTokens = useAllTokenData()
  // const allPools = useAllPoolData()

  const [wallets, setWallets] = useState<string[] | undefined>()
  const [mints, setMints] = useState<string[] | undefined>()

  const [calcedPubkey, setCalcedPubkey] = useState<string | undefined>()

  // const [domains, r] = useUserDomains(calcedPubkey)
  // const domainNames = useMemo(() => {
  //   if (!domains) return []
  //   return domains.map((d) => d.name)
  // }, [domains])

  // const debounced = useDebounce(value, 300)
  useEffect(() => {
    const fetch = async () => {
      if (value.includes('.sol')) {
        const domainInfo = await getDomainInfo(value, connection)
        if (domainInfo?.owner) {
          setCalcedPubkey(domainInfo?.owner ?? undefined)
        } else {
          setCalcedPubkey(undefined)
        }
      } else if (value.length > 30) {
        try {
          const pubkey = new PublicKey(value)
          setCalcedPubkey(pubkey.toBase58())
        } catch (e) {
          setCalcedPubkey(undefined)
          console.error(e)
        }
      } else {
        setCalcedPubkey(undefined)
      }
    }
    fetch()
  }, [value])

  // const allMints = useAllFirebaseMints()
  // const allWallets = useAllFirebaseWallets()
  const dispatch = useDispatch()

  // fetch data based on search input
  useEffect(() => {
    async function fetch() {
      try {
        const res = await AlgoliaSearchClient.search([
          {
            indexName: 'wallets4',
            query: value,
          },
          // {
          //   indexName: 'mints',
          //   query: value,
          // },
        ])
        console.log('algolia search results: ', res)
        const walletsRes = res.results[0].hits.map((hit) => hit)
        const walletsDecoded = walletsRes.map((doc) => {
          console.log('decoding wallet: ', doc)
          return decodeFirebaseWallet(doc)
        })
        dispatch(appendWallets(walletsDecoded))
        const walletPubkeys = walletsDecoded.map((w) => w.pubkey)
        console.log('algolia walletPubkeys', walletPubkeys)
        setWallets(walletPubkeys)

        // console.log('algolia search results: ', res)
        // const mintsRes = res.results[1].hits.map((hit) => hit)
        // const mintsDecoded = mintsRes.map((doc) => {
        //   console.log('decoding mint: ', doc)
        //   return decodeFirebaseMintData(doc)
        // })
        // dispatch(appendMints(mintsDecoded))
        // const mintAddresses = mintsDecoded.map((m) => m.address)
        // console.log('algolia mintAddresses', mintAddresses)
        // setMints(mintAddresses)
      } catch (e) {
        console.log(e)
      }
    }
    if (value && value.length > 0) {
      fetch()
    }
  }, [dispatch, value])

  // const allFetchedTokens = useMemo(() => {
  //   if (tokenData) {
  //     return [...tokenData.asAddress, ...tokenData.asName, ...tokenData.asSymbol]
  //   }
  //   return []
  // }, [tokenData])

  // const allFetchedPools = useMemo(() => {
  //   if (poolData) {
  //     return [...poolData.asAddress, ...poolData.as0, ...poolData.as1]
  //   }
  //   return []
  // }, [poolData])

  // format as token and pool datas
  // const { data: tokenFullDatas, loading: tokenFullLoading } = useFetchedTokenDatas(allFetchedTokens.map((t) => t.id))

  // const poolDatasFull = usePoolDatas(allFetchedPools.map((p) => p.id))
  // const formattedTokens = useMemo(() => (tokenFullDatas ? Object.values(tokenFullDatas) : []), [tokenFullDatas])

  // const newTokens = useMemo(() => {
  //   return formattedTokens.filter((t) => !Object.keys(allTokens).includes(t.address))
  // }, [allTokens, formattedTokens])

  // const combinedTokens = useMemo(() => {
  //   return [
  //     ...newTokens,
  //     ...Object.values(allTokens)
  //       .map((t) => t.data)
  //       .filter(notEmpty),
  //   ]
  // }, [allTokens, newTokens])

  // const filteredSortedTokens = useMemo(() => {
  //   return combinedTokens.filter((t) => {
  //     const regexMatches = Object.keys(t).map((tokenEntryKey) => {
  //       const isAddress = value.slice(0, 2) === '0x'
  //       if (tokenEntryKey === 'address' && isAddress) {
  //         return t[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
  //       }
  //       if (tokenEntryKey === 'symbol' && !isAddress) {
  //         return t[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
  //       }
  //       if (tokenEntryKey === 'name' && !isAddress) {
  //         return t[tokenEntryKey].match(new RegExp(escapeRegExp(value), 'i'))
  //       }
  //       return false
  //     })
  //     return regexMatches.some((m) => m)
  //   })
  // }, [combinedTokens, value])

  // const newPools = useMemo(() => {
  //   return poolDatasFull.filter((p) => !Object.keys(allPools).includes(p.address))
  // }, [allPools, poolDatasFull])

  // const combinedPools = useMemo(() => {
  //   return [
  //     ...newPools,
  //     ...Object.values(allPools)
  //       .map((p) => p.data)
  //       .filter(notEmpty),
  //   ]
  // }, [allPools, newPools])

  // const filteredSortedPools = useMemo(() => {
  //   return combinedPools.filter((t) => {
  //     const regexMatches = Object.keys(t).map((key) => {
  //       const isAddress = value.slice(0, 2) === '0x'
  //       if (key === 'address' && isAddress) {
  //         return t[key].match(new RegExp(escapeRegExp(value), 'i'))
  //       }
  //       if ((key === 'token0' || key === 'token1') && !isAddress) {
  //         return (
  //           t[key].name.match(new RegExp(escapeRegExp(value), 'i')) ||
  //           t[key].symbol.toLocaleLowerCase().match(new RegExp(escapeRegExp(value.toLocaleLowerCase()), 'i'))
  //         )
  //       }
  //       return false
  //     })
  //     return regexMatches.some((m) => m)
  //   })
  // }, [combinedPools, value])

  return {
    mints: mints ?? [],
    wallets: wallets ?? [],
    calcedPubkey,
    loading: false,
  }
}
