import * as anchor from '@project-serum/anchor'
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { getExtendedArt, pubkeyFromString } from 'apollo/utils'
import { providerURL } from 'hooks/web3'
import { getMetadata } from 'providers/accounts/utils/metadataHelpers'
import { assert, create, is } from 'superstruct'
import { Metadata } from 'validators/accounts/sales'

export const getATAForMint = async (
  mint: anchor.web3.PublicKey,
  buyer: anchor.web3.PublicKey
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID
  )
}

export const calculateCreatorFees = async (tokenMint: string, baseSize: number): Promise<number> => {
  const tokenMintPubkey = pubkeyFromString(tokenMint)
  if (!tokenMintPubkey) {
    throw new Error('calculateCreatorFees Invalid token mint: ' + tokenMint)
    return 0
  }
  const metadata = await getMetadata(tokenMintPubkey, providerURL())
  if (metadata) {
    const convertedMetadata = create(metadata, Metadata) as Metadata
    const extended = await getExtendedArt(convertedMetadata)
    if (!extended) {
      throw new Error('Metadata not found for ' + tokenMintPubkey.toBase58())
    }
    console.log('extended', extended)
    if (extended.seller_fee_basis_points) {
      return (extended.seller_fee_basis_points / 10000) * baseSize
    } else {
      return 0
    }
  } else {
    return 0
  }
}

export const calculateCuratorFees = async (curatorBasisPoints: number, baseSize: number): Promise<number> => {
  return (curatorBasisPoints / 10000) * baseSize
}
