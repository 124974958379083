import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'

// import { Mint } from 'api/types'
import { MintData } from './models'

export const updateFirebaseMintRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ mintAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; mintData: MintData; preserveOffChain?: boolean }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; mintAddress: string; requestID: string }>
}> = {
  pending: createAction('mints/createMintRequest/pending'),
  fulfilled: createAction('mints/createMintRequest/fulfilled'),
  rejected: createAction('mints/createMintRequest/rejected'),
}
export const fetchFirebaseMintRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ mintAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; mintData: MintData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; mintAddress: string }>
}> = {
  pending: createAction('mints/fetchFirebaseMintRequest/pending'),
  fulfilled: createAction('mints/fetchFirebaseMintRequest/fulfilled'),
  rejected: createAction('mints/fetchFirebaseMintRequest/rejected'),
}
export const fetchChainMintRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ mintAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; mintData: MintData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('mints/fetchChainMintRequest/pending'),
  fulfilled: createAction('mints/fetchChainMintRequest/fulfilled'),
  rejected: createAction('mints/fetchChainMintRequest/rejected'),
}

export const fetchChainMintHistoryRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ mintAddress: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; mintData: MintData }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('mints/fetchChainMintHistoryRequest/pending'),
  fulfilled: createAction('mints/fetchChainMintHistoryRequest/fulfilled'),
  rejected: createAction('mints/fetchChainMintHistoryRequest/rejected'),
}

export const addRequiredMints = createAction<string[]>('mints/addRequiredMints')
export const refreshMint = createAction<string>('mints/refreshMint')

export const incrementMintSavedByLocal = createAction<{ mintAddress: string; userAddress: string }>(
  'mints/incrementMintSavedByLocal'
)
export const decrementMintSavedByLocal = createAction<{ mintAddress: string; userAddress: string }>(
  'mints/decrementMintSavedByLocal'
)
export const addCollectionToMintLocal = createAction<{ collectionID: string; mintAddress: string }>(
  'mints/addCollectionToMintLocal'
)
export const removeCollectionFromMintLocal = createAction<{ collectionID: string; mintAddress: string }>(
  'mints/removeCollectionFromMintLocal'
)
export const appendFirebaseMintIfEmpty = createAction<MintData>('mints/appendFirebaseMintIfEmpty')

export const appendMints = createAction<MintData[]>('mints/appendMints')
