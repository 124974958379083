import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
import { CreateOrderModalConfig } from 'state/application/reducer'

import { Order } from './models'
import { OrderQuery, RequiredOrderQuery } from './reducer'
// import { Order } from 'api/types'

export const fetchFirebaseOrderRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ orderPDA: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; order: Order }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; orderPDA: string }>
}> = {
  pending: createAction('orders/fetchFirebaseOrder/pending'),
  fulfilled: createAction('orders/fetchFirebaseOrder/fulfilled'),
  rejected: createAction('orders/fetchFirebaseOrder/rejected'),
}

export const fetchFirebaseOrderQueryRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ queryKey: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; orders: Order[]; queryKey: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; queryKey: string }>
}> = {
  pending: createAction('orders/fetchFirebaseOrderQueryRequest/pending'),
  fulfilled: createAction('orders/fetchFirebaseOrderQueryRequest/fulfilled'),
  rejected: createAction('orders/fetchFirebaseOrderQueryRequest/rejected'),
}
// export const createOrder: Readonly<{
//   pending: ActionCreatorWithPayload<{ requestID: string }>
//   fulfilled: ActionCreatorWithPayload<{ requestID: string; order: Order }>
//   rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
// }> = {
//   pending: createAction('orders/createOrder/pending'),
//   fulfilled: createAction('orders/createOrder/fulfilled'),
//   rejected: createAction('orders/createOrder/rejected'),
// }
export const createOrderOnChainRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ order: Order; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; order: Order; res: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('orders/createOrderOnChainRequest/pending'),
  fulfilled: createAction('orders/createOrderOnChainRequest/fulfilled'),
  rejected: createAction('orders/createOrderOnChainRequest/rejected'),
}

export const revokeOrderOnChainRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ order: Order; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; order: Order }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('orders/fetchChainOrderRequest/pending'),
  fulfilled: createAction('orders/fetchChainOrderRequest/fulfilled'),
  rejected: createAction('orders/fetchChainOrderRequest/rejected'),
}

export const matchOrdersOnChainRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ matchedOrder: Order; newOrder: Order; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; txRes: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string }>
}> = {
  pending: createAction('orders/matchOrdersOnChainRequest/pending'),
  fulfilled: createAction('orders/matchOrdersOnChainRequest/fulfilled'),
  rejected: createAction('orders/matchOrdersOnChainRequest/rejected'),
}

export const updateFirebaseOrderRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ orderPDA: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; order: Order }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; orderPDA: string; requestID: string }>
}> = {
  pending: createAction('orders/updateFirebaseOrderRequest/pending'),
  fulfilled: createAction('orders/updateFirebaseOrderRequest/fulfilled'),
  rejected: createAction('orders/updateFirebaseOrderRequest/rejected'),
}

export const addRequiredOrders = createAction<string[]>('orders/addRequiredOrders')
export const addRequiredOrderQueries = createAction<RequiredOrderQuery[]>('orders/addRequiredOrderQueries')
export const refreshOrder = createAction<string>('orders/refreshOrder')
// export const  = createAction<string>('lists/disableList')
