import { createReducer } from '@reduxjs/toolkit'

import { addRequiredCollections, fetchFirebaseCollectionRequest, refreshCollection } from './actions'
import { Collection } from './models'

export type Request = {
  requestID: string
  status: RequestStatus
  type: string
  context?: string
  errorMessage?: string
  timestamp: number
}
export enum RequestStatus {
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
}

export interface CollectionsState {
  readonly collections: Collection[]
  // readonly chainCollections: Collection[]
  readonly requests: Request[]
  // readonly onChainRequests: Request[]
  readonly requiredCollections: string[]
}

const initialState: CollectionsState = {
  collections: [],
  // chainCollections: [],
  requests: [],
  // onChainRequests: [],
  requiredCollections: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(refreshCollection, (state, { payload: collectionID }) => {
      console.log('refreshCollection', collectionID)
      console.log('state', state)
      state = {
        ...state,
        // requiredCollections: [...state.requiredCollections.filter((s) => !s.includes(collectionID)), collectionID],
        collections: state.collections.filter((collection) => collection.id !== collectionID),
      }
      return state
    })
    .addCase(addRequiredCollections, (state, { payload: collections }) => {
      console.log('addRequiredCollections', collections)
      console.log('state', state)
      const set = [...state.requiredCollections, ...collections.filter((m) => !state.requiredCollections.includes(m))]
      // const filtered = set.filter((item, index) => set.indexOf(item) === index)
      // console.log('addRequiredCollections set', filtered)
      state = {
        ...state,
        requiredCollections: set,
      }
      return state
    })
    // .addCase(incrementCollectionSavedByLocal, (state, { payload: { collectionID, userAddress } }) => {
    //   console.log('incrementCollectionSaves', collectionID)
    //   console.log('state', state)
    //   const oldCollection = state.collections.find((m) => m.id === collectionID)
    //   if (!oldCollection) {
    //     return state
    //   }
    //   const newCollection = { ...oldCollection, savedByUsers: [...(oldCollection.savedByUsers ?? []), userAddress] }

    //   return {
    //     ...state,
    //     collections: [...state.collections.filter((m) => m.id !== collectionID), newCollection],
    //   }
    // })
    // .addCase(decrementCollectionSavedByLocal, (state, { payload: { collectionID, userAddress } }) => {
    //   console.log('decrementCollectionSaves', collectionID)
    //   console.log('state', state)
    //   const oldCollection = state.collections.find((m) => m.id === collectionID)
    //   if (!oldCollection) {
    //     return state
    //   }
    //   const newCollection = {
    //     ...oldCollection,
    //     savedByUsers: oldCollection.savedByUsers
    //       ? oldCollection.savedByUsers.filter((u) => u !== userAddress)
    //       : undefined,
    //   }
    //   console.log('decrementCollectionSavedByLocal newCollection', newCollection)

    //   return {
    //     ...state,
    //     collections: [...state.collections.filter((m) => m.id !== collectionID), newCollection],
    //   }
    // })

    // .addCase(updateFirebaseCollectionRequest.pending, (state, { payload: { requestID, collectionID } }) => {
    //   console.log('aState create collection request pending', { state, requestID })
    //   state = {
    //     ...state,
    //     requests: state.requests.concat({
    //       requestID,
    //       status: RequestStatus.Pending,
    //       type: 'update',
    //       context: collectionID,
    //       timestamp: Date.now(),
    //     }),
    //   }
    //   return state
    // })
    // .addCase(
    //   updateFirebaseCollectionRequest.fulfilled,
    //   (state, { payload: { requestID, collection, preserveOffChain } }) => {
    //     console.log('aState updateFirebaseCollectionRequest.fulfilled', {
    //       state,
    //       requestID,
    //       collection,
    //       preserveOffChain,
    //     })
    //     const oldCollection = state.collections.find((m) => m.id === collection.id)
    //     const newCollection: Collection = { ...collection }
    //     if (preserveOffChain && oldCollection) {
    //       if (oldCollection.savedByUsers) {
    //         newCollection.savedByUsers = oldCollection.savedByUsers
    //       }
    //       if (oldCollection.collections) {
    //         newCollection.collections = oldCollection.collections
    //       }
    //     }
    //     state = {
    //       ...state,
    //       requests: [
    //         ...state.requests.filter((r) => r.requestID !== requestID),
    //         {
    //           requestID,
    //           type: 'update',
    //           context: newCollection.id,
    //           status: RequestStatus.Fulfilled,
    //           timestamp: Date.now(),
    //         },
    //       ],
    //       collections: state.collections
    //         .filter((collection) => collection.id != newCollection.id)
    //         .concat(newCollection),
    //     }
    //     return state
    //   }
    // )

    // .addCase(appendFirebaseCollectionIfEmpty, (state, { payload: collection }) => {
    //   const oldCollection = state.collections.find((m) => m.id === collection.id)
    //   if (oldCollection) {
    //     return state
    //   }

    //   state = {
    //     ...state,
    //     collections: [...state.collections, collection],
    //     requiredCollections: [...state.requiredCollections, collection.id],
    //   }
    //   return state
    // })
    // .addCase(
    //   updateFirebaseCollectionRequest.rejected,
    //   (state, { payload: { requestID, errorMessage, collectionID } }) => {
    //     console.log('aState query pending', { state, requestID, collectionID })
    //     state = {
    //       ...state,
    //       requests: [
    //         ...state.requests.filter((r) => r.requestID !== requestID),
    //         {
    //           requestID,
    //           type: 'update',
    //           context: collectionID,
    //           status: RequestStatus.Rejected,
    //           errorMessage: errorMessage.toString(),
    //           timestamp: Date.now(),
    //         },
    //       ],
    //     }
    //     return state
    //   }
    // )
    .addCase(fetchFirebaseCollectionRequest.pending, (state, { payload: { requestID, collectionID } }) => {
      console.log('aState fetchCollectionRequest pending', { state, requestID })
      state = {
        ...state,
        requests: state.requests.concat({
          requestID,
          type: 'fetch',
          status: RequestStatus.Pending,
          context: collectionID,
          timestamp: Date.now(),
        }),
      }
      return state
    })
    .addCase(fetchFirebaseCollectionRequest.fulfilled, (state, { payload: { requestID, collection } }) => {
      console.log('aState fetchFirebaseCollectionRequest fulfilled', { state, requestID, collectionID: collection.id })
      state = {
        ...state,
        requests: [
          ...state.requests.filter((r) => r.requestID !== requestID),
          {
            requestID,
            type: 'fetch',
            context: collection.id,
            status: RequestStatus.Fulfilled,
            timestamp: Date.now(),
          },
        ],
        collections: state.collections.filter((s) => s.id != collection.id).concat(collection),
      }
      return state
    })
    .addCase(
      fetchFirebaseCollectionRequest.rejected,
      (state, { payload: { requestID, errorMessage, collectionID } }) => {
        console.log('aState fetchCollectionRequest rejected', { state, requestID, collectionID })
        state = {
          ...state,
          requests: [
            ...state.requests.filter((r) => r.requestID !== requestID),
            {
              requestID,
              type: 'fetch',
              context: collectionID,
              status: RequestStatus.Rejected,
              errorMessage: errorMessage.toString(),
              timestamp: Date.now(),
            },
          ],
        }
        return state
      }
    )
)
