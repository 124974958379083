import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { ButtonCreate } from 'components/Button'
import CollectionRow from 'components/CollectionRow'
import Column, { AutoColumn } from 'components/Column'
import MintArtDisplay from 'components/MintArtDisplay'
import MintInfoContent, { MintInfoField } from 'components/MintInfoContent'
import Row, { AutoRow, RowBetween } from 'components/Row'
import UserRow from 'components/UserRow'
import { useListColor } from 'hooks/useColor'
import useTheme from 'hooks/useTheme'
import moment from 'moment'
import { transparentize } from 'polished'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RotateCcw } from 'react-feather'
import { useDispatch } from 'react-redux'
import { addRequiredActions } from 'state/actions/actions'
import { useAction } from 'state/actions/hooks'
import {
  Action,
  CancelListingAction,
  MakeListingAction,
  MakeOfferAction,
  MintAction,
  PurchaseAction,
  TransferAction,
} from 'state/actions/models'
import { ActionType } from 'state/actions/reducer'
import { useCreateOrderModalToggle, useWalletModalToggle } from 'state/application/hooks'
import { addRequiredCollections } from 'state/collections/actions'
import { useSafeCollections } from 'state/collections/hooks'
import { resetToBuyOrder } from 'state/createOrder/actions'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import { addRequiredWallets } from 'state/wallets/actions'
import styled from 'styled-components/macro'
import { ButtonText, ExternalLink, TYPE } from 'theme'
import { formatPaymentAmount } from 'utils/utils'
import { MetadataAttribute } from 'validators/accounts/sales'

const ActionCardContainer = styled.div`
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.7rem;

  // height: 100%;
  align-items: center;
  text-align: left;
  outline: none;
  // cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  background-color: ${({ theme }) => transparentize(0.0, theme.bg1)};
  &:focus {
    background-color: ${({ theme }) => transparentize(0.1, theme.bg1)};
  }
  // &:hover {
  //   background-color: ${({ theme }) => transparentize(0.6, theme.bg0)};
  // }

  overflow: visible;
  // display: flex;
`

const VerticalGrid = styled.div`
  // display: grid;
  // grid-template-columns: 344px 1fr;
  flex-direction: row;
  grid-gap: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `}
  width: 100%;
  height: 100%;
  align-items: start;
  justify-items: start;
  // align-items: center;
`
const HorizontalGrid = styled.div`
  display: grid;
  grid-template-columns: 344px 1fr;
  flex-direction: row;
  grid-gap: 0.5rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `}
  width: 100%;
  height: 100%;
  align-items: start;
  justify-items: start;
  // align-items: center;
`

const DetailsSection = styled(AutoColumn)`
  padding: 15px 17px 0px 17px;
  width: 100%;
  height: 100%;
`

const MintDisplayContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 344px;
  z-index: 10;
  // overflow: auto;
  // width: 100%;
  // height: 100%;
`

const OfferButton = styled(ButtonCreate)`
  padding: 5px 20px;
  // width: 60px;
  width: fit-content;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
`

const MarketplaceLink = styled(ExternalLink)`
  // font-size: 0.825rem;
  // color: ${({ theme }) => theme.text3};
  margin-left: 0rem;
  // font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

export const AttributeBox = styled.div<{ bgColor: string }>`
  margin-left: 6px;
  margin-top: 6px;
  // height: 1px;
  width: fit-content;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 5px 8px;
  background-color: ${({ theme }) => theme.transparent};
  // border: 2px solid ${({ bgColor, theme }) => bgColor};
  border: 1.5px solid ${({ bgColor, theme }) => transparentize(0.9, bgColor)};
  border-radius: 5px;
  filter: ${({ bgColor, theme }) => `drop-shadow(0px 2.76515px 5.7083px ${transparentize(0.9, bgColor)})`};
`

export const AttributeContainer = styled.div`
  max-width: 400px;
  display: flex;

  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
  overflow: auto;
`

const HideMobile = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const ShowMobile = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `}
`

export default function ActionCard({ action }: { action?: Action }) {
  const [buttonIsHovered, setButtonIsHovered] = React.useState(false)
  const [mintCardIsHovered, setMintCardIsHovered] = React.useState(false)
  const { tokenRegistry } = useTokenRegistry()

  const wallet = useAnchorWallet()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userPubkey = wallet?.publicKey

  // const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  const [showMintDetails, setShowMintDetails] = React.useState(false)

  // const action = useAction(actionID)

  // const user = useSafeUser(userPubkey?.toBase58())
  // console.log('mint card user:', user)

  // useEffect(() => {
  //   if (actionID) {
  //     dispatch(addRequiredActions([actionID]))
  //   }
  // }, [actionID, dispatch])

  // const mintData = useSafeMint(action?.assetMint ?? '')

  useEffect(() => {
    if (action?.assetMint) {
      dispatch(addRequiredMints([action?.assetMint]))
    }
  }, [dispatch, action?.assetMint])

  useEffect(() => {
    if (action?.wallet) {
      dispatch(addRequiredWallets([action?.wallet]))
    }
  }, [dispatch, action?.wallet])

  const assetMint = useSafeMint(action?.assetMint)

  const mintCollections = useSafeCollections(assetMint?.collections ?? [])
  useEffect(() => {
    if (assetMint?.collections && assetMint?.collections?.length > 0) {
      dispatch(addRequiredCollections(assetMint?.collections))
    }
  }, [assetMint?.collections, dispatch])

  console.log('ActionCard mintCollections:', mintCollections)

  const topCollection = useMemo(() => {
    if (mintCollections.length > 0) {
      return mintCollections[0]
    } else {
      return undefined
    }
  }, [mintCollections])
  console.log('ActionCard topCollection:', topCollection)

  const purchaseAction = useMemo(() => {
    if (action?.type === ActionType.purchase) {
      try {
        return action as PurchaseAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  const makeListingAction = useMemo(() => {
    if (action?.type === ActionType.makeListing) {
      try {
        return action as MakeListingAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  const cancelListingAction = useMemo(() => {
    if (action?.type === ActionType.cancelListing) {
      try {
        return action as CancelListingAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  const transferAction = useMemo(() => {
    if (action?.type === ActionType.transfer) {
      try {
        return action as TransferAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  const makeOfferAction = useMemo(() => {
    if (action?.type === ActionType.makeOffer) {
      try {
        return action as MakeOfferAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  const mintAction = useMemo(() => {
    if (action?.type === ActionType.mint) {
      try {
        return action as MintAction
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }, [action])

  console.log('ActionCard purchaseAction:', purchaseAction)

  // const setCreateOrderModalConfig = useSetCreateOrderModalConfig()

  const timeSince = useMemo(() => {
    if (action?.time) {
      return moment(action.time * 1000).fromNow()
    } else {
      return ''
    }
  }, [action?.time])

  const toggleCreateOrderModal = useCreateOrderModalToggle()
  const toggleWalletModal = useWalletModalToggle()
  const [submitError, setSubmitError] = useState<string | undefined>()

  const handleNewOffer = useCallback(() => {
    if (!assetMint) {
      setSubmitError('No mint data')
      return
    }
    dispatch(resetToBuyOrder({ assetMint: assetMint.address }))
    if (!wallet) {
      toggleWalletModal()
    } else {
      toggleCreateOrderModal()
    }
  }, [assetMint, dispatch, toggleCreateOrderModal, toggleWalletModal, wallet])

  const mintColor = useListColor(assetMint?.metadata?.extended?.image)

  const description = () => {
    return (
      <>
        {purchaseAction && (
          <Row>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px' }}>
              {'Purchased'}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={action?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px' }}>
              {'From'}
            </TYPE.heavy>
            <div style={{ marginLeft: '10px' }}>
              <UserRow userAddress={purchaseAction.seller} />
            </div>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px', marginRight: '10px' }}>
              {'For'}
            </TYPE.heavy>
            <MintArtDisplay
              setIsHovered={setButtonIsHovered}
              mintAddress={purchaseAction.paymentMint}
              size={'14px'}
              overrideIsNative
            />
            <TYPE.heavy color={'text2'} fontSize={'14px'} marginLeft={'3px'}>
              {formatPaymentAmount(purchaseAction.paymentSize, purchaseAction.paymentMint, true)}
            </TYPE.heavy>
          </Row>
        )}
        {makeListingAction && (
          <Row>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px' }}>
              {'Listed'}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={action?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px', marginRight: '10px' }}>
              {'For'}
            </TYPE.heavy>
            <MintArtDisplay
              setIsHovered={setButtonIsHovered}
              mintAddress={makeListingAction.paymentMint}
              size={'14px'}
              overrideIsNative
            />
            <TYPE.heavy color={'text2'} fontSize={'14px'} marginLeft={'3px'}>
              {formatPaymentAmount(makeListingAction.paymentSize, makeListingAction.paymentMint, true)}
            </TYPE.heavy>
          </Row>
        )}
        {cancelListingAction && (
          <Row>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px' }}>
              {'Delisted'}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={action?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px', marginRight: '10px' }}>
              {'Previously listed for'}
            </TYPE.heavy>
            <MintArtDisplay
              setIsHovered={setButtonIsHovered}
              mintAddress={cancelListingAction.paymentMint}
              size={'14px'}
              overrideIsNative
            />
            <TYPE.heavy color={'text2'} fontSize={'14px'} marginLeft={'3px'}>
              {formatPaymentAmount(cancelListingAction.paymentSize, cancelListingAction.paymentMint, true)}
            </TYPE.heavy>
          </Row>
        )}
        {makeOfferAction && (
          <Row>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px' }}>
              {'Offered'}
            </TYPE.heavy>
            <MintArtDisplay
              setIsHovered={setButtonIsHovered}
              mintAddress={makeOfferAction.paymentMint}
              size={'14px'}
              overrideIsNative
            />
            <TYPE.heavy color={'text2'} fontSize={'14px'} marginLeft={'3px'}>
              {formatPaymentAmount(makeOfferAction.paymentSize, makeOfferAction.paymentMint, true)}
            </TYPE.heavy>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px', marginRight: '10px' }}>
              {'For'}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={makeOfferAction?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
          </Row>
        )}
        {mintAction && (
          <Row>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px' }}>
              {'Minted'}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={action?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
          </Row>
        )}
        {transferAction && (
          <Row>
            <UserRow userAddress={transferAction.sender} />
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginRight: '10px', marginLeft: '8px' }}>
              {'Transfered'}
            </TYPE.heavy>
            <TYPE.heavy color={'text2'} fontSize={'14px'} marginLeft={'3px'} marginRight={'8px'}>
              {formatPaymentAmount(transferAction.assetSize, transferAction.assetMint)}
            </TYPE.heavy>
            <MintArtDisplay
              key={action?.id ?? ''}
              setIsHovered={setButtonIsHovered}
              mintAddress={transferAction?.assetMint}
              size="20px"
              clickable
            />
            <TYPE.heavy fontSize={'16px'} color={theme.text2} style={{ marginLeft: '5px' }}>
              {assetMint?.metadata?.extended?.name}
            </TYPE.heavy>
            <TYPE.heavy fontSize={'14px'} color={theme.text3} style={{ marginLeft: '10px' }}>
              {'To'}
            </TYPE.heavy>
            <div style={{ marginLeft: '10px' }}>
              <UserRow userAddress={transferAction.receiver} />
            </div>
          </Row>
        )}
      </>
    )
  }

  const mintDetails = () => {
    return (
      <AutoColumn>
        <TYPE.main fontSize={'14px'} color={theme.text3}>
          {assetMint?.metadata?.extended?.description}
        </TYPE.main>

        <AutoColumn gap="4px">
          <AttributeContainer>
            {assetMint?.metadata?.extended?.attributes?.map((a: MetadataAttribute) => (
              // <Badge key={a.trait_type} variant={BadgeVariant.DEFAULT}>
              <AttributeBox key={a.trait_type} bgColor={mintColor}>
                <TYPE.body fontWeight={400} color={theme.text3} fontSize={'12px'}>
                  {a.trait_type.toUpperCase()}
                </TYPE.body>
                <TYPE.heavy color={theme.text2} fontSize={14}>
                  {a.value}
                </TYPE.heavy>
              </AttributeBox>

              // </Badge>
            ))}
            {/* <TYPE.main>{manifestData?.description}</TYPE.main> */}
          </AttributeContainer>
        </AutoColumn>
      </AutoColumn>
    )
  }
  if (!assetMint?.metadata?.extended) {
    return <></>
  }

  return (
    <ActionCardContainer
      // to={buttonIsHovered ? history.location : `/m/${mint?.address}`}
      key={`${action?.id}`}
      onMouseEnter={() => {
        setMintCardIsHovered(true)
      }}
      onMouseLeave={() => {
        if (!buttonIsHovered) {
          setMintCardIsHovered(false)
        }
      }}
    >
      {/* {action?.id} */}
      <VerticalGrid>
        <AutoRow>
          <RowBetween>
            <Row>
              <UserRow userAddress={action?.wallet} size="22px" />
              {topCollection ? (
                <Row style={{ marginLeft: '10px' }}>
                  <TYPE.heavy fontSize={'14px'} color={theme.text2}>
                    {`·`}
                  </TYPE.heavy>
                  <div style={{ marginLeft: '10px' }}>
                    <CollectionRow size="18px" collectionID={topCollection.id} />
                  </div>
                </Row>
              ) : (
                <></>
              )}
            </Row>
            <TYPE.main width={'120px'} color={theme.text3} fontSize={'14px'}>
              {timeSince}
            </TYPE.main>
          </RowBetween>
          <Row>{description()}</Row>

          <AutoRow style={{ marginTop: '10px' }}>
            {/* <OfferButton padding={'1rem 2rem'} xColor={mintColor} onClick={handleNewOffer}>
              Make Offer
            </OfferButton> */}
            {/* <ExternalLink
              // href={Link}
              href={'https://magiceden.io/asset/' + assetMint?.address}
              // padding={'1rem 2rem'}
              // xColor={theme.text2}
              // onClick={handleNewOffer}
              // style={{ marginLeft: '5px' }}
            >
              <Row>
                <ExternalLinkIcon size={16}></ExternalLinkIcon>
                Magic Eden
              </Row>
            </ExternalLink> */}
            {/* <MarketplaceLink href={'https://magiceden.io/asset/' + assetMint?.address}>
              Magic Eden
              <ExternalLinkIcon href={'https://magiceden.io/asset/' + assetMint?.address} />
            </MarketplaceLink>

            <OfferButton
              padding={'1rem 2rem'}
              xColor={theme.text2}
              onClick={handleNewOffer}
              style={{ marginLeft: '5px' }}
              disabled={true}
            >
              Share
            </OfferButton> */}
          </AutoRow>
        </AutoRow>
        <HorizontalGrid>
          <MintDisplayContainer>
            <div style={{ minHeight: '344px' }}>
              <MintArtDisplay
                size={'300x'}
                key={action?.id ?? ''}
                setIsHovered={setButtonIsHovered}
                mintAddress={action?.assetMint}
                clickable
              />
            </div>
          </MintDisplayContainer>
          {/* <Row>{description()}</Row> */}
          <ShowMobile>
            {!showMintDetails ? (
              <Row style={{ maxWidth: '' }}>
                {assetMint?.metadata?.extended?.description && (
                  <TYPE.main fontSize={'14px'} color={theme.text3}>
                    {assetMint.metadata.extended.description.slice(0, 100)}
                    {assetMint.metadata.extended.description.length > 100 && '...'}
                  </TYPE.main>
                )}
                <ButtonText
                  onClick={() => {
                    setShowMintDetails(true)
                  }}
                >
                  <TYPE.heavy fontSize={'14px'} color={theme.text2} style={{ marginLeft: '4px' }}>
                    {/* {`\t`} */}
                    {`more`}
                  </TYPE.heavy>
                </ButtonText>
              </Row>
            ) : (
              mintDetails()
            )}
          </ShowMobile>
          <HideMobile>{mintDetails()}</HideMobile>
          {/* {description()} */}
          {/* <MintInfoContent
            mintAddress={action?.assetMint}
            size={'14px'}
            hidden={[MintInfoField.Name]}
          ></MintInfoContent> */}
        </HorizontalGrid>
      </VerticalGrid>

      {/* <Confetti start={buttonIsHovered} /> */}
    </ActionCardContainer>
  )
}
