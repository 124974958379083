import { createSlice, nanoid } from '@reduxjs/toolkit'
import { PublicKey } from '@solana/web3.js'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { ORDER_SIDE } from 'state/orders/chain'
import { Color } from 'theme/styled'

export type PopupContent = {
  txn: {
    hash: string
  }
}

export type CreateOrderModalConfig = {
  assetMint?: string
  orderSide?: ORDER_SIDE
}

export type EditCollectionModalConfig = {
  collectionID: string | undefined
}

export type ErrorModalData = {
  requestID: string
  actionTitle: string
  errorMessage?: string
}

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
  POOL_OVERVIEW_OPTIONS,
  NETWORK_SELECTOR,
  PRIVACY_POLICY,
  NFT_INFO,
  ERROR,
  CREATE_ORDER,
  EDIT_COLLECTION,
  DELETE_COLLECTION,
  MAX_FOLLOWING,
}

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  readonly chainConnectivityWarning: boolean
  readonly chainId: number | null
  readonly implements3085: boolean
  readonly openModal: ApplicationModal | null
  readonly nftModalMintAddress: string | null
  readonly popupList: PopupList
  readonly homePageSales: PublicKey[]
  readonly createOrderModalConfig: CreateOrderModalConfig
  readonly errorModalData: ErrorModalData | null
  readonly globalColor: Color
  readonly editCollectionModalConfig: EditCollectionModalConfig | null
}

const initialState: ApplicationState = {
  blockNumber: {},
  chainConnectivityWarning: false,
  chainId: null,
  implements3085: false,
  openModal: null,
  nftModalMintAddress: null,
  popupList: [],
  homePageSales: [],
  createOrderModalConfig: {},
  errorModalData: null,
  globalColor: '#00a8ff',
  editCollectionModalConfig: null,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    updateBlockNumber(state, action) {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    setNFTModalMintAddress(state, action) {
      console.log('setNFTModalMintAddress', action)
      state.nftModalMintAddress = action.payload
    },
    addPopup(state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    },
    setImplements3085(state, { payload: { implements3085 } }) {
      state.implements3085 = implements3085
    },
    setChainConnectivityWarning(state, { payload: { warn } }) {
      state.chainConnectivityWarning = warn
    },
    // setHomepageSales(state, { payload: { sales } }) {
    //   state.homePageSales = sales
    // },

    setCreateOrderModalConfig(state, { payload: { config } }) {
      console.log('setCreateOrderModalConfig', config)
      state.createOrderModalConfig = config
    },
    setEditCollectionModalConfig(state, { payload: collectionID }) {
      console.log('setEditCollectionModalConfig', collectionID)
      state.editCollectionModalConfig = { collectionID }
    },

    setErrorModalData(state, { payload: { data } }) {
      state.errorModalData = data
    },
    setGlobalColor(state, { payload: { color } }) {
      console.log('mintColo2r setGlobalColor', color)

      state.globalColor = color
    },
  },
})

export const {
  updateChainId,
  updateBlockNumber,
  setOpenModal,
  setNFTModalMintAddress,
  addPopup,
  removePopup,
  setImplements3085,
  setChainConnectivityWarning,
  // setHomepageSales,
  setCreateOrderModalConfig,
  setEditCollectionModalConfig,
  setErrorModalData,
  setGlobalColor,
} = applicationSlice.actions
export default applicationSlice.reducer
