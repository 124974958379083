import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit'
// import { Sale } from 'api/types'
import { SaleData } from 'validators/accounts/sales'

import { Collection } from './models'

export const updateFirebaseCollectionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ collectionID: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; collection: Collection }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; collectionID: string; requestID: string }>
}> = {
  pending: createAction('collections/createCollectionRequest/pending'),
  fulfilled: createAction('collections/createCollectionRequest/fulfilled'),
  rejected: createAction('collections/createCollectionRequest/rejected'),
}
export const deleteFirebaseCollectionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ collectionID: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; collectionID: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; collectionID: string; requestID: string }>
}> = {
  pending: createAction('collections/deleteCollectionRequest/pending'),
  fulfilled: createAction('collections/deleteCollectionRequest/fulfilled'),
  rejected: createAction('collections/deleteCollectionRequest/rejected'),
}
export const fetchFirebaseCollectionRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ collectionID: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; collection: Collection }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; collectionID: string }>
}> = {
  pending: createAction('collections/fetchFirebaseCollectionRequest/pending'),
  fulfilled: createAction('collections/fetchFirebaseCollectionRequest/fulfilled'),
  rejected: createAction('collections/fetchFirebaseCollectionRequest/rejected'),
}

export const fetchFirebaseCollectionQueryRequest: Readonly<{
  pending: ActionCreatorWithPayload<{ queryKey: string; requestID: string }>
  fulfilled: ActionCreatorWithPayload<{ requestID: string; collections: Collection[]; queryKey: string }>
  rejected: ActionCreatorWithPayload<{ errorMessage: string; requestID: string; queryKey: string }>
}> = {
  pending: createAction('collections/fetchFirebaseCollectionQueryRequest/pending'),
  fulfilled: createAction('collections/fetchFirebaseCollectionQueryRequest/fulfilled'),
  rejected: createAction('collections/fetchFirebaseCollectionQueryRequest/rejected'),
}

export const addRequiredCollections = createAction<string[]>('collections/addRequiredSales')
export const refreshCollection = createAction<string>('collections/refreshSale')
export const appendFirebaseCollectionLocal = createAction<Collection>('collections/appendFirebaseCollectionLocal')
// export const  = createAction<string>('lists/disableList')
