import { nanoid } from '@reduxjs/toolkit'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from 'firebase/firestore'
import { useCallback } from 'react'
import { useAppDispatch } from 'state/hooks'
import { create } from 'superstruct'

import { fetchFirebaseActionQueryRequest, fetchFirebaseActionRequest } from './actions'
import { useAllActionQueries, useAllActions } from './hooks'
import { Action } from './models'
import { RequiredActionQuery } from './reducer'

export const ACTIONS_COLLECTION = 'actions4'
export const FEEDS_TWO_COLLECTION = 'feeds4'

export function useFetchFirebaseAction(): (actionPDA: string) => Promise<Action | undefined> {
  const dispatch = useAppDispatch()
  return useCallback(
    async (actionPDA: string) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseActionRequest.pending({ actionPDA, requestID }))
      try {
        const db = getFirestore()
        const actionSnap = await getDoc(doc(db, ACTIONS_COLLECTION, actionPDA))
        console.log('firebase action snap: ', actionSnap)
        if (actionSnap.exists()) {
          console.log('firebase Document data:', actionSnap.data())
          const action = decodeFirebaseAction(actionSnap.data())
          console.log('firebase action decoded: ', action)
          dispatch(fetchFirebaseActionRequest.fulfilled({ requestID, action }))
          return action
        } else {
          dispatch(fetchFirebaseActionRequest.rejected({ requestID, actionPDA, errorMessage: 'not found' }))
          // throw new Error('Action not found')
          return undefined
          // throw new Error(`Action ${actionPDA} does not exist`)
        }
        // return action
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseActionRequest.rejected({ requestID, actionPDA, errorMessage: e.message }))
        throw e
      }
    },
    [dispatch]
  )
}

export function useFetchFirebaseActionsQuery(): (
  roq: RequiredActionQuery,
  pFetched: number,
  pRequired: number
) => Promise<void> {
  const dispatch = useAppDispatch()
  const actionQueries = useAllActionQueries()
  const actions = useAllActions()
  return useCallback(
    async (roq: RequiredActionQuery, pFetched: number, pRequired: number) => {
      const requestID = nanoid()
      dispatch(fetchFirebaseActionQueryRequest.pending({ queryKey: roq.key, requestID }))
      try {
        const db = getFirestore()
        const queryConstraints = []
        if (roq.wallets && roq.wallets.length > 0) {
          queryConstraints.push(where('wallet', 'in', roq.wallets))
        }
        if (roq.type) {
          queryConstraints.push(where('type', '==', roq.type))
        }
        const oldQuery = actionQueries.find((m) => m.queryKey == roq.key)
        const collectionFeedPath = `${FEEDS_TWO_COLLECTION}/${roq.feed}/actions`
        console.log('collectionFeedPath: ', collectionFeedPath)
        const oldLast = oldQuery?.last ?? ''
        // if (pRequired == 1) {
        //   const q = query(
        //     roq.feed
        //       ? collection(db, `${FEEDS_TWO_COLLECTION}/${roq.feed}/actions`)
        //       : collection(db, ACTIONS_COLLECTION),
        //     ...queryConstraints,
        //     orderBy('time', 'desc'),
        //     limit(6)
        //   )
        //   // const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //   const actions = querySnapshot.docs.map((doc) => {
        //     return decodeFirebaseAction(doc.data())
        //   })
        //   const last = querySnapshot.docs[querySnapshot.docs.length - 1]
        //   console.log(`firebase query actions (${roq.key}): `, actions)
        //   dispatch(
        //     fetchFirebaseActionQueryRequest.fulfilled({
        //       requestID,
        //       queryKey: roq.key,
        //       actions,
        //       page: pRequired,
        //       last,
        //       foundEnd: actions.length < 6 || last == oldLast,
        //     })
        //   )
        //   // })
        // } else {
        const q = query(
          roq.feed ? collection(db, `${FEEDS_TWO_COLLECTION}/${roq.feed}/actions`) : collection(db, ACTIONS_COLLECTION),
          ...queryConstraints,
          orderBy('time', 'desc'),
          startAfter(oldLast),
          limit(6)
        )
        const querySnapshot = await getDocs(q)
        const actions = querySnapshot.docs.map((doc) => {
          return decodeFirebaseAction(doc.data())
        })
        const last = querySnapshot.docs[querySnapshot.docs.length - 1]
        console.log(`firebase query actions (${roq.key}): `, actions)
        dispatch(
          fetchFirebaseActionQueryRequest.fulfilled({
            requestID,
            queryKey: roq.key,
            actions,
            page: pRequired,
            last,
            foundEnd: actions.length < 6 || last == oldLast,
          })
        )
        // console.log('firebase query fulfilled data: ', {
        //   requestID,
        //   queryKey: roq.key,
        //   actions,
        //   page: pRequired,
        //   last,
        //   foundEnd: actions.length < 6 || last == oldLast,
        // })
        // }
      } catch (e) {
        console.error(e)
        dispatch(fetchFirebaseActionQueryRequest.rejected({ queryKey: roq.key, requestID, errorMessage: e }))
        throw e
      }
    },
    [actionQueries, dispatch]
  )
}

const encodeActionObject = (action: Action) => {
  return action
  console.log('encodeActionObject: ', action)
}

export const decodeFirebaseAction = (res: any) => {
  console.log('decodeFirebaseAction: ', res)
  return create(res, Action)
}

// export function useUpdateFirebaseAction(): (action: Action, requestIDFromChain?: string) => Promise<void> {
//   const dispatch = useAppDispatch()
//   return useCallback(
//     async (action: Action, requestIDFromChain?: string) => {
//       const requestID = requestIDFromChain ?? nanoid()
//       dispatch(updateFirebaseActionRequest.pending({ actionPDA: action.delegatePDA, requestID }))
//       try {
//         const db = getFirestore()
//         const actionObj = encodeActionObject(action)
//         console.log('firebase uploading action: ', actionObj)
//         setDoc(doc(db, ORDERS_COLLECTION, action.delegatePDA), actionObj).then(() => {
//           console.log('firebase action uploaded')
//           dispatch(updateFirebaseActionRequest.fulfilled({ requestID, action }))
//         })
//       } catch (e) {
//         console.error(e)
//         dispatch(updateFirebaseActionRequest.rejected({ actionPDA: action.delegatePDA, requestID, errorMessage: e }))
//         // throw e
//       }
//     },
//     [dispatch]
//   )
// }
