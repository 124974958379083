import { Trans } from '@lingui/macro'
import { TokenInfoMap } from '@solana/spl-token-registry'
import { PublicKey } from '@solana/web3.js'
import { Currency } from '@uniswap/sdk-core'
import { ArtContent } from 'components/ArtContent'
import Card from 'components/Card'
import Loader from 'components/Loader'
import SolTokenLogo from 'components/SolTokenLogo'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import { useListColor } from 'hooks/useColor'
import { Account, useAccountInfo, useFetchAccountInfo } from 'providers/accounts'
import { useTokenRegistry } from 'providers/mints/token-registry'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import { MintData } from 'state/mints/models'
import styled from 'styled-components/macro'
import { mintIsNative, WRAPPED_SOL_MINT } from 'utils/utils'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currenc

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 10px;
  width: 100%;
`

const TokenImgWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function MintArtDisplay({
  mintAddress,
  size,
  clickable = false,
  setIsHovered,
  overrideIsNative = false,
}: {
  mintAddress?: string
  size?: string
  clickable?: boolean
  setIsHovered?: (isHovered: boolean) => void
  overrideIsNative?: boolean
}) {
  const mintData = useSafeMint(mintAddress)
  const dispatch = useDispatch()
  const { tokenRegistry } = useTokenRegistry()
  // console.log('MintArtDisplay', mintData)
  useEffect(() => {
    if (mintAddress) {
      dispatch(addRequiredMints([mintAddress]))
    }
  }, [mintAddress, dispatch])

  console.log('MintArtDisplay mintData', mintData)

  const isNative = overrideIsNative ? true : mintIsNative(mintAddress || '')
  // const isNative = true
  const currency = useMemo(() => {
    if (mintAddress) {
      return tokenRegistry.get(mintAddress)
    }
    return null
  }, [mintAddress, tokenRegistry])

  if (mintAddress) {
    if (mintData?.isNFT) {
      // console.log('metadata:', metadata)
      return (
        <div style={{ width: size, minHeight: '100%', maxWidth: size }}>
          <ArtContent
            size={size}
            metadata={mintData.metadata}
            pubkey={mintData?.address}
            clickable={clickable}
            setIsHovered={setIsHovered}
          />
        </div>
      )
    } else {
      return (
        <div style={{ width: size }}>
          {/* {mintAddress}
          {JSON.stringify(currency, null, 2)} */}
          <SolTokenLogo
            size={'100%'}
            currency={isNative ? tokenRegistry.get(WRAPPED_SOL_MINT.toBase58()) : currency}
            clickable={clickable ? !isNative : false}
          />
        </div>
      )
    }
  }
  return <Loader></Loader>
}
