import { AutoColumn } from 'components/Column'
import DoubleModal from 'components/DoubleModal'
import MintArtDisplay from 'components/MintArtDisplay'
import MintInfoContent from 'components/MintInfoContent'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addRequiredMints } from 'state/mints/actions'
import { useSafeMint } from 'state/mints/hooks'
import styled from 'styled-components/macro'

import { useModalOpen, useNFTModalMintAddress, useNFTModalToggle } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import DoubleModalTwo from './DoubleModalTwo'

export const SectionBreak = styled.div`
  margin-top: 9px;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.transparent};
`

export const Section = styled(AutoColumn)`
  padding: 15px;
`

const InfoWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  // margin: 0;
  padding: 2rem 4rem;
  // background-color: ${({ theme }) => theme.bg1};
  // width: 100%;
`

export default function NFTInfoModal() {
  const walletModalOpen = useModalOpen(ApplicationModal.NFT_INFO)
  const toggleWalletModal = useNFTModalToggle()
  const mintAddress = useNFTModalMintAddress()
  const dispatch = useDispatch()
  console.log('NFTInfoModal mintAddress', mintAddress)

  const mintData = useSafeMint(mintAddress ?? '')

  useEffect(() => {
    if (mintAddress) {
      dispatch(addRequiredMints([mintAddress]))
    }
  }, [mintAddress, dispatch])

  function leftContent() {
    return <MintArtDisplay mintAddress={mintData?.address} size="100%" />
  }

  function rightContent() {
    return (
      <InfoWrapper>
        <MintInfoContent mintAddress={mintData?.address}></MintInfoContent>
      </InfoWrapper>
    )
  }

  return (
    <DoubleModalTwo
      isOpen={walletModalOpen}
      onDismiss={toggleWalletModal}
      // minHeight={false}
      // maxHeight={600}
      leftContent={leftContent}
      rightContent={rightContent}
    />
  )
}
