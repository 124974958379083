import { Connection } from '@solana/web3.js'
import { Pool, Position } from '@uniswap/v3-sdk'
import { usePool } from 'hooks/usePools'
import { PositionDetails } from 'types/position'

import { useCurrency } from './Tokens'
import useDebounce from './useDebounce'
import { providerURL } from './web3'

export default async function getLatestSlot() {
  const connection = new Connection(providerURL(), 'confirmed')
  const epochInfo = await connection.getEpochInfo()
  const lastSlot = epochInfo.absoluteSlot
  return lastSlot
}
