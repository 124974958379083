import { array, boolean, Infer, nullable, number, optional, string, type } from 'superstruct'
import { MetadataCombined } from 'validators/accounts/sales'

export type OwnedMint = Infer<typeof OwnedMint>
export const OwnedMint = type({
  address: string(),
  amount: string(),
})

export type User = Infer<typeof User>
export const User = type({
  address: string(),
  ownedMints: array(OwnedMint),
  lamports: number(),
  // profileImage: optional(string()),
  profileMint: optional(string()),
  savedMints: optional(array(string())),
})
