import * as anchor from '@project-serum/anchor'
import { Token } from '@solana/spl-token'
import {
  // PublicKey,
  Keypair,
  PublicKey as Pubkey,
  TransactionInstruction,
} from '@solana/web3.js'
import { AskV2, BidV2, Sale } from 'validators/accounts/sales'
// import { SYSTEM } from "metaplex/ids";

export class TX {
  txis: TransactionInstruction[] = []
  signers: Keypair[] = []

  append(txi: TransactionInstruction, signers: Keypair[]) {
    this.txis.push(txi)
    signers.forEach((signer) => {
      let exists = false
      this.signers.forEach((s) => {
        if (s.publicKey.equals(signer.publicKey)) {
          exists = true
        }
        if (!exists) {
          this.signers.push(signer)
        }
      })
    })
  }
  appendMulti(txis: TransactionInstruction[], signers: Keypair[]) {
    txis.forEach((txi) => {
      this.append(txi, signers)
    })
    signers.forEach((signer) => {
      let exists = false
      this.signers.forEach((s) => {
        if (s.publicKey.equals(signer.publicKey)) {
          exists = true
        }
        if (!exists) {
          this.signers.push(signer)
        }
      })
    })
  }
}

export type InitBundleBox = {
  tokenMint: Pubkey
  // tokenStoreAccount: Pubkey;
  depositAccount: Pubkey
  amount: number
}

export type SendableTXI = {
  txis: TransactionInstruction[]
  signers: Keypair[]
  addUserSign?: boolean
}

// export type Sale = {
//   tokenMint: Pubkey
//   receiptMint: Pubkey
//   amount: number
//   closed: boolean
//   acceptedAsk: Pubkey
// }

export type TokenBox = {
  bundle: Pubkey
  tokenMint: Pubkey
  amount: number
}

export type Ask = {
  uuid: Pubkey
  asker: Pubkey
  bundle: Pubkey
  tokenMint: Pubkey
  amount: number
  expirationDate: number
}

// export type AskV2 = {
//   pubkey?: Pubkey
//   sale: Pubkey
//   tokenMint: Pubkey
//   amount: number
//   expirationDate: number
//   cancelled: boolean
//   accepted: boolean
//   feesPaid: number
//   fundsWithdrawn: boolean
// }

export type FeeOwed = {
  wallet: Pubkey
  amount: number
  creator: boolean
}

// export type AskV3 = {
//   pubkey?: Pubkey
//   sale: Pubkey
//   tokenMint: Pubkey
//   amount: number
//   expirationDate: number
//   cancelled: boolean
//   accepted: boolean
//   fundsWithdrawn: boolean
//   feesOwed: FeeOwed[]
// }

// export type Bid = {
//   storeAccount: Pubkey
//   bundle: Pubkey
//   bidder: Pubkey
//   amount: number
//   expirationDate: number
//   accepted: boolean
//   retrieved: boolean
// }

export const decodeSale = (res: any) => {
  console.log('decodeSale', res)
  const sale: Sale = {
    // authority: res.authority,
    tokenMint: res.tokenMint.toBase58(),
    receiptMint: res.receiptMint.toBase58(),
    amount: res.amount / 1,
    closed: res.closed,
    acceptedAsk: res.acceptedAsk.toBase58(),
  }
  return sale
}

export const encodeSale = (sale: Sale) => {
  const res = {
    tokenMint: sale.tokenMint,
    receiptMint: sale.receiptMint,
    amount: sale.amount / 1,
    closed: sale.closed,
    acceptedAsk: sale.acceptedAsk,
  }
  return res
}

export const decodeAskV2 = (res: any) => {
  const ask: AskV2 = {
    sale: res.sale.toBase58(),
    tokenMint: res.tokenMint.toBase58(),
    amount: res.amount / 1,
    expirationDate: res.expirationDate / 1,
    cancelled: res.cancelled,
    accepted: res.accepted,
    feesPaid: res.feesPaid / 1,
    fundsWithdrawn: res.fundsWithdrawn,
  }
  return ask
}

export const encodeAskV2 = (ask: AskV2) => {
  const res: any = {
    sale: ask.sale,
    tokenMint: ask.tokenMint,
    amount: ask.amount.toString(),
    expirationDate: ask.expirationDate / 1,
    cancelled: ask.cancelled,
    accepted: ask.accepted,
    feesPaid: ask.feesPaid / 1,
    fundsWithdrawn: ask.fundsWithdrawn,
  }
  return res
}

export const decodeBidV2 = (res: any) => {
  const bid: BidV2 = {
    sale: res.sale.toBase58(),
    receiptMint: res.receiptMint.toBase58(),
    tokenMint: res.tokenMint.toBase58(),
    amount: res.amount / 1,
    expirationDate: res.expirationDate / 1,
    cancelled: res.cancelled,
    accepted: res.accepted,
    feesStored: res.feesStored / 1,
    redeemed: res.redeemed,
  }
  return bid
}

export const encodeBidV2 = (bid: BidV2) => {
  const res: any = {
    sale: bid.sale,
    receiptMint: bid.receiptMint,
    tokenMint: bid.tokenMint,
    amount: bid.amount / 1,
    expirationDate: bid.expirationDate / 1,
    cancelled: bid.cancelled,
    accepted: bid.accepted,
    feesStored: bid.feesStored / 1,
    redeemed: bid.redeemed,
  }
  return res
}

export const decodeTokenBox = (res: any) => {
  const tokenBox: TokenBox = {
    bundle: res.bundle,
    tokenMint: res.tokenMint,
    amount: res.amount,
  }
  return tokenBox
}

// export const decodeAsk = (res: any) => {
//   const ask: Ask = {
//     uuid: res.uuid,
//     bundle: res.bundle,
//     tokenMint: res.tokenMint,
//     amount: res.amount,
//     expirationDate: res.expirationDate,
//   }
//   return ask
// }
