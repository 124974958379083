import { pubkeyFromString } from 'apollo/utils'
import { SupportedChainId } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchListCallback } from '../../hooks/useFetchListCallback'
import useInterval from '../../hooks/useInterval'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { useActiveWeb3React } from '../../hooks/web3'
import { useFetchFirebaseCollection } from './firebase'
import { useAllFirebaseCollections, useAllFirebaseRequests, useRequiredCollections } from './hooks'
import { Collection } from './models'
import { RequestStatus } from './reducer'

const shouldRefreshFirebaseCollection = (chainCollection: Collection, firebaseCollection: Collection) => {
  return false
}
// firebaseCollection.owner != chainCollection.owner

export default function Updater(): null {
  const dispatch = useAppDispatch()

  // get all loaded lists, and the active urls
  const requiredCollections = useRequiredCollections()
  // const allChainCollections = useAllChainCollections()
  const allFirebaseCollections = useAllFirebaseCollections()
  // const allChainCollectionRequests = useAllChainRequests()
  const allFirebaseRequests = useAllFirebaseRequests()
  const fetchFirebaseCollection = useFetchFirebaseCollection()
  // const updateFirebaseCollection = useUpdateFirebaseCollection()
  // const fetchChainCollection = useFetchCollectionFromChain()
  // const requiredCollections = useChainRequestsByContext('collection')
  // const useAllChainRequests = useAllChainRequests()

  useEffect(() => {
    console.log('requiredCollections requiredCollections: ', requiredCollections)
    requiredCollections.forEach((collectionID) => {
      const pubkey = pubkeyFromString(collectionID)
      if (!pubkey) return
      console.log('requiredCollections allFirebaseCollections: ', allFirebaseCollections)
      const firebaseCollection = allFirebaseCollections.find((m) => m.id == pubkey.toBase58())
      const firebaseFetchRequest = allFirebaseRequests.find(
        (r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled
      )
      // const firebaseCreateRequest = allFirebaseRequests.find(
      //   (r) => r.context == pubkey.toBase58() && r.type == 'update' && r.status != RequestStatus.Fulfilled
      // )
      // const chainCollection = allChainCollections.find((m) => m.id == pubkey.toBase58())
      // const chainRequest = allChainCollectionRequests.find(
      //   (r) =>
      //     r.context == pubkey.toBase58() &&
      //     r.type == 'fetch' &&
      //     r.status != RequestStatus.Fulfilled &&
      //     r.timestamp > Date.now() - 60 * 1000
      // )
      // .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // const chainRequests = allChainCollectionRequests
      //   .filter((r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled)
      //   .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // console.log('requiredCollections collectionID: ', collectionID)
      // console.log('requiredCollections pubkey: ', pubkey)
      // console.log('requiredCollections firebaseCollection: ', firebaseCollection)
      // console.log('requiredCollections firebaseFetchRequest: ', firebaseFetchRequest)
      // console.log('requiredCollections firebaseCreateRequest: ', firebaseCreateRequest)
      // console.log('requiredCollections chainCollection: ', chainCollection)
      // // console.log('requiredCollections chainRequests: ', chainRequests)
      // console.log('requiredCollections chainRequest: ', chainRequest)

      if (!firebaseCollection && !firebaseFetchRequest) {
        console.log('requiredCollections request1')
        fetchFirebaseCollection(collectionID)
        return
      }
      // if (!firebaseCollection && !chainCollection && !chainRequest) {
      //   console.log('requiredCollections request2')
      //   fetchChainCollection(collectionID)
      //   return
      // }
      // if (chainCollection && !firebaseCollection && !firebaseCreateRequest) {
      //   console.log('requiredCollections request3')
      //   updateFirebaseCollection(chainCollection)
      //   return
      // }

      // if (chainCollection && firebaseCollection) {
      //   console.log('requiredCollections request4 chainCollection: ', chainCollection)
      //   console.log('requiredCollections request4 firebaseCollection: ', firebaseCollection)
      //   const shouldUpdate = shouldRefreshFirebaseCollection(chainCollection, firebaseCollection)
      //   console.log('requiredCollections shouldUpdate: ', shouldUpdate)
      //   console.log('requiredCollections shouldUpdate firebaseCreateRequest: ', firebaseCreateRequest)
      //   if (shouldRefreshFirebaseCollection(firebaseCollection, chainCollection) && !firebaseCreateRequest) {
      //     console.log('requiredCollections request4 triggered for ' + pubkey.toBase58())
      //     updateFirebaseCollection(chainCollection)
      //     return
      //   }
      // }
    })
  }, [allFirebaseCollections, allFirebaseRequests, dispatch, fetchFirebaseCollection, requiredCollections])
  // useEffect(() => {
  //   allChainCollections.forEach((chainCollection) => {
  //     if (!allFirebaseCollections.find((firebaseCollection) => firebaseCollection.pubkey === chainCollection.add)) {
  //       if (!allFirebaseRequests.find((r) => r.context == chainCollection.pubkey)) {
  //       }
  //     }
  //   })
  // }, [allChainCollections, allFirebaseRequests, allFirebaseCollections, dispatch, requiredCollections])
  // fetch all lists every 10 minutes, but only after we initialize library

  return null
}
// const i = 0
// export default i
