import { any, array, boolean, coerce, create, Infer, nullable, number, optional, string, type } from 'superstruct'
import { MetadataCombined } from 'validators/accounts/sales'

export const StringFromAny = coerce(string(), any(), (value) =>
  value ? (typeof value == 'string' ? value : JSON.stringify(value)) : ''
)

export const StringArrayFromAny = coerce(array(string()), any(), (value) =>
  value && Array.isArray(value) ? value.map((v) => create(v, string())) : []
)

export type OwnedToken = Infer<typeof OwnedToken>
export const OwnedToken = type({
  mint: string(),
  size: number(),
})
export const OwnedTokensFromAny = coerce(array(OwnedToken), any(), (value) =>
  value && Array.isArray(value) ? value.map((v) => create(v, OwnedToken)) : []
)

export type Wallet = Infer<typeof Wallet>
export const Wallet = type({
  pubkey: string(),
  syncedToSlot: number(),
  syncToSlot: number(),
  ownedTokens: OwnedTokensFromAny,
  dotSolNames: StringArrayFromAny,
  following: StringArrayFromAny,
  followedBy: StringArrayFromAny,
})

export const decodeFirebaseWallet = (res: any) => {
  return create(res, Wallet)
}
