import { Web3Provider } from '@ethersproject/providers'
import * as anchor from '@project-serum/anchor'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { Keypair } from '@solana/web3.js'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import { gnosisSafe, injected } from '../connectors'
import { IS_IN_IFRAME, NetworkContextName } from '../constants/misc'
import { isMobile } from '../utils/userAgent'

export function useActiveWeb3React() {
  const context = useWeb3React<Web3Provider>()
  const contextNetwork = useWeb3React<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function providerURL() {
  const REACT_APP_PROVIDER_URL: string | undefined = process.env.REACT_APP_PROVIDER_URL
  // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
  return REACT_APP_PROVIDER_URL as string
}

export function curatorBasisPoints() {
  const REACT_APP_BASIS_POINTS: string | undefined = process.env.REACT_APP_BASIS_POINTS
  // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
  return parseInt(REACT_APP_BASIS_POINTS as string)
}

export function curatorAddress() {
  const REACT_APP_CURATOR_ADDRESS: string | undefined = process.env.REACT_APP_CURATOR_ADDRESS
  // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
  return REACT_APP_CURATOR_ADDRESS as string
}

export function bundleMintUpdateAuthorityKeypair(): Keypair {
  const REACT_APP_DUMMY_PRIVATE_KEY: string = process.env.REACT_APP_DUMMY_PRIVATE_KEY as string
  // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
  const privateKey = new Uint8Array(JSON.parse(REACT_APP_DUMMY_PRIVATE_KEY))
  const keypair = anchor.web3.Keypair.fromSecretKey(privateKey)
  return keypair
}

export function apolloProgramAddress() {
  const REACT_APP_APOLLO_PROGRAM: string | undefined = process.env.REACT_APP_APOLLO_PROGRAM
  // console.log('REACT_APP_PROVIDER_URL', REACT_APP_PROVIDER_URL)
  return REACT_APP_APOLLO_PROGRAM as string
}

export function useActiveNetwork() {
  const REACT_APP_PROVIDER_URL: string | undefined = process.env.REACT_APP_PROVIDER_URL
  if (REACT_APP_PROVIDER_URL?.includes('devnet')) {
    return WalletAdapterNetwork.Devnet
  } else if (REACT_APP_PROVIDER_URL?.includes('testnet')) {
    return WalletAdapterNetwork.Testnet
  } else if (REACT_APP_PROVIDER_URL?.includes('mainnet')) {
    return WalletAdapterNetwork.Mainnet
  }
  return undefined
}

export function useEagerConnect() {
  const { activate, active } = useWeb3React()
  const [tried, setTried] = useState(false)

  // gnosisSafe.isSafeApp() races a timeout against postMessage, so it delays pageload if we are not in a safe app;
  // if we are not embedded in an iframe, it is not worth checking
  const [triedSafe, setTriedSafe] = useState(!IS_IN_IFRAME)

  // first, try connecting to a gnosis safe
  useEffect(() => {
    if (!triedSafe) {
      gnosisSafe.isSafeApp().then((loadedInSafe) => {
        if (loadedInSafe) {
          activate(gnosisSafe, undefined, true).catch(() => {
            setTriedSafe(true)
          })
        } else {
          setTriedSafe(true)
        }
      })
    }
  }, [activate, setTriedSafe, triedSafe])

  // then, if that fails, try connecting to an injected connector
  useEffect(() => {
    if (!active && triedSafe) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          if (isMobile && window.ethereum) {
            activate(injected, undefined, true).catch(() => {
              setTried(true)
            })
          } else {
            setTried(true)
          }
        }
      })
    }
  }, [activate, active, triedSafe])

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
