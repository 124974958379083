import { opacify, transparentize } from 'polished'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1rem'};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '14px'};
  border: ${({ border }) => border};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => opacify(0.2, theme.bg1)};
`

export const HeaderCard = styled(Card)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
  // border: 0.1px solid ${({ theme }) => transparentize(0.3, theme.headerBG)};
  background-color: ${({ theme }) => transparentize(0.3, theme.headerBG)};
  color: ${({ theme }) => transparentize(0.2, theme.headerText)};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '14px'};
  padding: ${({ padding }) => padding ?? '6px 8px'};
  // box-shadow: 0px 0px 1px 1px ${({ theme }) => transparentize(0.3, theme.headerBG)};
`

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg2};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg3};
`

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => transparentize(0.3, theme.bg2)};
  background-opacity: 0.5;
`

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg0};
`

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow3};
  font-weight: 500;
`

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => transparentize(0.9, theme.secondary1)};
  color: ${({ theme }) => theme.blue2};
  border-radius: 12px;
`
export const GreenCard = styled(Card)`
  background-color: ${({ theme }) => transparentize(0.9, theme.secondary1)};
  color: ${({ theme }) => theme.green1};
  border-radius: 12px;
`

export const RedCard = styled(Card)`
  background-color: ${({ theme }) => transparentize(0.9, theme.secondary1)};
  color: ${({ theme }) => theme.red1};
  border-radius: 12px;
`
