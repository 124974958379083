import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import UserLogo from 'components/UserLogo'
import useTheme from 'hooks/useTheme'
import { darken, math, opacify, transparentize } from 'polished'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useSafeUser } from 'state/users/hooks'
import { useSafeWallet } from 'state/wallets/hooks'
import { Wallet } from 'state/wallets/models'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { truncateString } from 'utils'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
const activeClassName = 'active'
const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  margin: 0 0px;
  // font-weight: 600;

  &.${activeClassName} {
    border-radius: 12px;
    color: ${({ theme }) => theme.text1};
    font-weight: 600;
  }

  :hover {
    color: ${({ theme }) => darken(0.1, theme.text2)};
  }
`

export const walletDisplayText = (wallet: Wallet) => {
  if (wallet.dotSolNames && wallet.dotSolNames.length > 0) {
    return wallet.dotSolNames[0]
  }
  return truncateString(wallet.pubkey)
}

export default function UserRow({ userAddress, size = '18px' }: { userAddress?: string; size?: string }) {
  const theme = useTheme()

  const wallet = useSafeWallet(userAddress)
  const displayText = useMemo(
    () => (wallet ? walletDisplayText(wallet) : truncateString(userAddress)),
    [userAddress, wallet]
  )

  return userAddress ? (
    <StyledNavLink to={`/user/${userAddress}`} style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
      <UserLogo size={size} userAddress={userAddress} style={{}}></UserLogo>
      <TYPE.body color={theme.text2} style={{ marginLeft: '8px' }}>
        {displayText}
      </TYPE.body>
    </StyledNavLink>
  ) : (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
      <UserLogo size={size} userAddress={userAddress} style={{}}></UserLogo>
      <TYPE.body color={theme.text2} style={{ marginLeft: '4px' }}>
        N/A
      </TYPE.body>
    </div>
  )
}
