import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { pubkeyFromString } from 'apollo/utils'
import { useEffect } from 'react'
import { useAppDispatch } from 'state/hooks'

import { useFetchFirebaseWallet } from './firebase'
import { useAllFirebaseRequests, useAllFirebaseWallets, useRequiredWallets } from './hooks'
import { RequestStatus } from './reducer'

// firebaseWallet.owner != chainWallet.owner

export default function Updater(): null {
  const dispatch = useAppDispatch()
  const wallet = useAnchorWallet()

  // get all loaded lists, and the active urls
  const requiredWallets = useRequiredWallets()
  const allFirebaseWallets = useAllFirebaseWallets()
  const allFirebaseRequests = useAllFirebaseRequests()
  const fetchFirebaseWallet = useFetchFirebaseWallet()
  // const updateFirebaseWallet = useUpdateFirebaseWallet()
  // const requiredWallets = useChainRequestsByContext('wallet')
  // const useAllChainRequests = useAllChainRequests()

  useEffect(() => {
    console.log('requiredWallets requiredWallets: ', requiredWallets)
    requiredWallets.forEach((walletAddress) => {
      // const pubkey = pubkeyFromString(walletAddress)
      // if (!pubkey) return
      console.log('requiredWallets allFirebaseWallets: ', allFirebaseWallets)
      const firebaseWallet = allFirebaseWallets.find((m) => m.pubkey == walletAddress)
      // const firebaseFetchRequest = allFirebaseRequests.find((r) => r.context == walletAddress && r.type == 'fetch')
      const firebaseFetchRequest = allFirebaseRequests.find((r) => r.context == walletAddress)
      // const firebaseCreateRequest = allFirebaseRequests.find(
      //   (r) => r.context == pubkey.toBase58() && r.type == 'update'
      // )

      // .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      // const chainRequests = allChainWalletRequests
      //   .filter((r) => r.context == pubkey.toBase58() && r.type == 'fetch' && r.status != RequestStatus.Fulfilled)
      //   .sort((a, b) => a.timestamp - b.timestamp)
      // .find((a) => true)
      console.log('requiredWallets walletAddress: ', walletAddress)
      // console.log('requiredWallets pubkey: ', pubkey)
      console.log('requiredWallets firebaseWallet: ', firebaseWallet)
      console.log('requiredWallets firebaseFetchRequest: ', firebaseFetchRequest)
      // console.log('requiredWallets firebaseCreateRequest: ', firebaseCreateRequest)
      // console.log('requiredWallets chainWallet: ', chainWallet)
      // console.log('requiredWallets chainRequests: ', chainRequests)
      // console.log('requiredWallets chainRequest: ', chainRequest)

      if (!firebaseWallet && !firebaseFetchRequest) {
        console.log('requiredWallets request1')
        fetchFirebaseWallet(walletAddress)
        return
      }
    })
  }, [allFirebaseWallets, allFirebaseRequests, dispatch, fetchFirebaseWallet, requiredWallets])

  return null
}
