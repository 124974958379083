import { createAction } from '@reduxjs/toolkit'
import { ExpiationDuration } from 'components/ExpirationDateSelector'

// export const typeInput =
//   createAction<{ field: Field; typedValue: string; noLiquidity: boolean }>('createOrder/typeInputMint')
export const typeAssetSizeInput = createAction<{ typedValue: string }>('createOrder/typeAssetSizeInput')
export const typePaymentSizeInput = createAction<{ typedValue: string }>('createOrder/typePaymentSizeInput')
export const setAssetMint = createAction<{ assetMint: string }>('createOrder/setAssetMint')
export const setPaymentMint = createAction<{ paymentMint: string }>('createOrder/setPaymentMint')
export const setExpirationDuration = createAction<{ expirationDuration: ExpiationDuration }>(
  'createOrder/setExpirationDuration'
)
export const setListDate = createAction<{ listDate: number }>('createOrder/setListDate')
export const resetToSellOrder = createAction<{ assetMint: string }>('createOrder/resetToSellOrder')
export const resetToBuyOrder = createAction<{ assetMint: string }>('createOrder/resetToBuyOrder')
export const resetCreateOrderState = createAction<void>('createOrder/resetCreateOrderState')
