import { TokenInfo } from '@solana/spl-token-registry'
import { Currency } from '@uniswap/sdk-core'
import { Wrapper } from 'components/swap/styleds'
import { SupportedChainId } from 'constants/chains'
import React, { useMemo } from 'react'
import { Button } from 'rebass'
import { useNFTModalToggle, useSetNFTModalMintAddress } from 'state/application/hooks'
import styled from 'styled-components/macro'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Logo from '../Logo'

type Network = 'ethereum' | 'arbitrum' | 'optimism'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.ARBITRUM_ONE:
      return 'arbitrum'
    case SupportedChainId.OPTIMISM:
      return 'optimism'
    default:
      return 'ethereum'
  }
}

export const getTokenLogoURL = (
  address: string,
  chainId: SupportedChainId = SupportedChainId.MAINNET
): string | void => {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [SupportedChainId.ARBITRUM_ONE, SupportedChainId.MAINNET, SupportedChainId.OPTIMISM]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export const StyledLogo = styled(Logo)<{ clickable?: boolean; size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.035);
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ clickable }) => (clickable ? 'rgba(37, 41, 46, 0.1) 0px 8px 20px;' : 'none')};
  :hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'none')};
    transform: ${({ clickable }) => (clickable ? 'scale(1.1)' : 'none')};
  }
  transition: ${({ clickable }) => (clickable ? ' all 0.2s ease-in-out' : 'none')};
`

const WrapperButton = styled(Wrapper)`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function SolTokenLogo({
  currency,
  size = '24px',
  style,
  clickable,
  ...rest
}: {
  currency?: TokenInfo | null
  size?: string
  clickable?: boolean
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    return currency?.logoURI ? [currency?.logoURI] : []
  }, [currency])

  const toggleNFTModal = useNFTModalToggle()
  const setNFTModalAddr = useSetNFTModalMintAddress()
  // if (!currency || currency.isNative) return []

  //   if (currency.isToken) {
  //     const defaultUrls = []
  //     const url = getTokenLogoURL(currency.address, currency.chainId)
  //     if (url) {
  //       defaultUrls.push(url)
  //     }
  //     if (currency instanceof WrappedTokenInfo) {
  //       return [...uriLocations, ...defaultUrls]
  //     }
  //     return defaultUrls
  //   }
  //   return []
  // }, [currency, uriLocations])

  // if (currency?.isNative) {
  //   return <StyledEthereumLogo src={EthereumLogo} alt="ethereum logo" size={size} style={style} {...rest} />
  // }

  return (
    <WrapperButton
      onClick={() => {
        if (currency) {
          setNFTModalAddr(currency.address)
          toggleNFTModal()
        }
      }}
    >
      <StyledLogo
        size={size}
        srcs={srcs}
        clickable={clickable}
        alt={`${currency?.symbol ?? 'token'} logo`}
        style={style}
        {...rest}
      />
    </WrapperButton>
  )
}

{
  /* <Image
alt={name}
src={`data:image/svg+xml;base64,${btoa(gradientAvatar(uid))}`}
{...sizeProps}
{...props}
/> */
}
