import { Connection, PublicKey } from '@solana/web3.js'
import algoliasearch from 'algoliasearch'
import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ACTIONS_COLLECTION, decodeFirebaseAction, FEEDS_TWO_COLLECTION } from 'state/actions/firebase'
import { Action } from 'state/actions/models'
import { ActionType } from 'state/actions/reducer'
import { appendFirebaseMintIfEmpty, appendMints } from 'state/mints/actions'
import { decodeFirebaseMintData, MINTS_COLLECTION } from 'state/mints/firebase'
import { useAllFirebaseMints } from 'state/mints/hooks'
import { appendWallets } from 'state/wallets/actions'
import { useAllFirebaseWallets } from 'state/wallets/hooks'
import { decodeFirebaseWallet } from 'state/wallets/models'

import useDebounce from './useDebounce'
import { getDomainInfo, useUserDomains } from './useUserDomains'
import { providerURL } from './web3'

export const AlgoliaSearchClient = algoliasearch('TRZH8CI75S', 'e1b5bdfec3ab52f862250491d5da9408')

type SearchResultPubkeyData = {
  pubkey: string
  dotSolNames: string[]
}
const connection = new Connection(providerURL(), 'confirmed')

export function useFetchFeedResults(
  user?: string,
  type?: ActionType,
  wallet?: string,
  sortTop?: boolean
): { actions?: Action[]; loading: boolean; loadMore: () => void; foundLast: boolean } {
  // AlgoliaSearchClient.search
  // const allTokens = useAllTokenData()
  // const allPools = useAllPoolData()

  console.log('useFetchFeedResults user', user)
  console.log('useFetchFeedResults type', type)
  console.log('useFetchFeedResults wallet', wallet)

  // console.log('useFetchFeedResults queryConstraints', queryConstraints)

  const [actions, setActions] = useState<Action[] | undefined>()

  const [last, setLast] = useState<QueryDocumentSnapshot<DocumentData> | undefined>()
  const [shouldLoadMore, setShouldLoadMore] = useState(false)
  const loadMore = useCallback(() => {
    setShouldLoadMore(true)
  }, [])
  const [loading, setLoading] = useState(false)
  const [foundLast, setFoundLast] = useState(false)

  useEffect(() => {
    setActions([])
    setLast(undefined)
    setFoundLast(false)
  }, [user, type, wallet, sortTop])

  const db = useMemo(() => getFirestore(), [])
  const collectionPath = useMemo(
    () => (user ? `${FEEDS_TWO_COLLECTION}/user-${user}/actions` : ACTIONS_COLLECTION),
    [user]
  )
  const q = useMemo(() => {
    if (!shouldLoadMore) {
      return undefined
    }
    const queryConstraints = []
    if (wallet) {
      queryConstraints.push(where('wallet', '==', wallet))
    }
    // roq.types.forEach((type) => {
    if (type) {
      queryConstraints.push(where('type', '==', type))
    }
    // if (sortTop) {
    //   const now = new Date().getTime()
    //   queryConstraints.push(where('time', '>', 0))
    // }

    if (sortTop) {
      console.log('sortTop called')
      queryConstraints.push(orderBy('paymentSize', 'desc'))
    } else {
      console.log('sortTop not called')
      queryConstraints.push(orderBy('time', 'desc'))
    }
    //  else {
    //   queryConstraints.push(orderBy('time', 'desc'))
    // }

    if (last) {
      queryConstraints.push(...[startAfter(last), limit(6)])
    } else {
      queryConstraints.push(...[limit(6)])
    }
    return query(collection(db, collectionPath), ...queryConstraints)
  }, [collectionPath, db, last, shouldLoadMore, sortTop, type, wallet])

  // const [unsubscribe, setUnsubscribe] = useState<() => void>()

  // const appendActions = useCallback(
  //   (newActions: Action[]) => {
  //     if (newActions.length === 0) {
  //       return
  //     }
  //     setActions((actions) => {
  //       if (!actions) {
  //         return newActions
  //       }
  //       return [...newActions, ...actions]
  //     })
  //   },
  //   [setActions]
  // )

  console.log('useFetchFeedResults q', q)
  useEffect(() => {
    setLoading(true)
    setShouldLoadMore(false)
    const fetch = async () => {
      if (!q) {
        return
      }
      const querySnapshot = await getDocs(q)
      const newActions = querySnapshot.docs.map((doc) => {
        return decodeFirebaseAction(doc.data())
      })
      setActions((actions) => {
        if (!actions) {
          return newActions
        }
        return [...actions, ...newActions]
      })
      const last = querySnapshot.docs[querySnapshot.docs.length - 1]
      if (querySnapshot.docs.length < 6) {
        setFoundLast(true)
      }
      setLast(last)
      setLoading(false)
    }
    fetch()

    // unsubscribe()
    // })
    // setUnsubscribe(unsubscribeT)
  }, [q])

  // useEffect(() => {
  //   if (unsubscribe) {
  //     // unsubscribe()
  //   }
  // }, [actions])

  return {
    actions,
    loading,
    loadMore,
    foundLast,
  }
}
