import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import { Action } from './models'
import { ActionQuery, Request, RequestStatus, RequiredActionQuery } from './reducer'

export function useRequiredActions(): string[] {
  console.log('aState useRequiredActions')
  return useAppSelector((state: AppState) => {
    return state.actions.requiredActions
  })
}

export function useAllFirebaseActions(): Action[] {
  console.log('aState useAllFirebaseActions')
  return useAppSelector((state: AppState) => {
    return state.actions.firebaseActions
  })
}

export function useActionQueries(): ActionQuery[] {
  console.log('aState useActionQueries')
  return useAppSelector((state: AppState) => {
    return state.actions.actionQueries
  })
}
export function useAllFirebaseRequests(): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.actions.firebaseRequests
  })
}

export function useFirebaseRequestsByContext(context?: string): Request[] {
  // console.log('aState useAllRequests')
  return useAppSelector((state: AppState) => {
    // console.log('aState useAllRequests useAppSelector state: ', state)
    return state.actions.firebaseRequests.filter((r) => r.context === context)
  })
}

export function useFirebaseAction(actionID?: string): Action | undefined {
  const allActions = useAllFirebaseActions()
  return useMemo(() => {
    return allActions.find((action) => action.id === actionID)
  }, [allActions, actionID])
}

export function useActionQueryActions(actionQueryID?: string): string[] | undefined {
  const actionQueries = useActionQueries()
  const actionQuery = useMemo(() => {
    return actionQueries.find((aq) => aq.queryKey === actionQueryID)
  }, [actionQueries, actionQueryID])
  console.log('aState useActionQueryActions actionQuery: ', actionQuery)
  return actionQuery?.actionIDs || undefined
}

export function useQueryIsFetching(queryKey?: string): boolean {
  const queryRequests = useAllFirebaseRequests()
  const queryRequest = queryRequests.find(
    (r) =>
      r.context == queryKey &&
      r.type == 'fetch' &&
      r.status != RequestStatus.Fulfilled &&
      r.status != RequestStatus.Rejected
  )
  return !!queryRequest
}

export function useQueryHasFoundEnd(actionQueryID?: string): boolean {
  const actionQueries = useActionQueries()
  const actionQuery = useMemo(() => {
    return actionQueries.find((aq) => aq.queryKey === actionQueryID)
  }, [actionQueries, actionQueryID])
  return actionQuery?.foundEnd || false
}

// export function useFirebaseActions()

// export function useActionsByAssetMint(assetMint?: string): Action[] {
//   console.log('aState useActionsByAssetMint')
//   return useAppSelector((state: AppState) => {
//     console.log('aState useActionsByAssetMint useAppSelector state: ', state)
//     return state.actions.firebaseActions.filter((action) => action.assetMint == assetMint)
//   })
// }

export function useRequiredActionQueries(): RequiredActionQuery[] {
  console.log('aState useRequiredQueries')
  return useAppSelector((state: AppState) => {
    return state.actions.requiredActionQueries
  })
}

export function useAllActionQueryRequests(): Request[] {
  console.log('aState useAllActionQueryRequests')
  return useAppSelector((state: AppState) => {
    return state.actions.queryRequests
  })
}

export function useAllActionQueries(): ActionQuery[] {
  console.log('aState useAllActionQueries')
  return useAppSelector((state: AppState) => {
    return state.actions.actionQueries
  })
}

// REMOVING SO QUERIES DONT NEED TO BE REFRESHED AFTER CREATE ORDER
// export function useActionQueryByAssetMintAndSide(assetMint?: string, side?: ORDER_SIDE): ActionQuery[] {
//   const allActionQueries = useAllActionQueries()
//   return useMemo(() => {
//     const queryKey = new RequiredActionQuery(assetMint, side).key
//     return allActionQueries.filter((oq) => oq.queryKey === queryKey)
//   }, [allActionQueries, assetMint, side])
// }

export function useAllActions(): Action[] {
  const allActions = useAllFirebaseActions()
  return useMemo(() => {
    return allActions
    return allActions.filter((action) => true)
  }, [allActions])
}

export function useAction(actionID?: string): Action | undefined {
  const allActions = useAllFirebaseActions()
  return useMemo(() => {
    return allActions.find((action) => action.id === actionID)
  }, [allActions, actionID])
}

export function useActions(actionIDs?: string[]): Action[] {
  const allActions = useAllFirebaseActions()
  return useMemo(() => {
    return allActions.filter((action) => actionIDs?.includes(action.id))
  }, [allActions, actionIDs])
}
